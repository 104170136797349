const initialState = {
  dataFile: {},
  loadingFile: false,
  statusFile: 0,

  dataDelete: {},
  loadingDelete: false,
  statusDelete: 0,
};

export default initialState;
