import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Image,
  Table,
  TextArea,
} from "semantic-ui-react";

import {
  updateMatriz5,
  resetUpdateMatriz5,
} from "../../../actions/matriz5/index";
import ModalActions from "../../Modal/ModalActions";
import ohError from "../../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const Instrumento = ({ tabla, label, datos, state, id }) => {
  const dispatch = useDispatch();

  const minero = localStorage.getItem("minero");

  const [data, setData] = useState([]);

  const [checkedTabla, setCheckedTabla] = useState([]);
  const [dropdownId, setDropdownId] = useState("");

  const [form, setForm] = useState({
    nro_filas: tabla.parte_variable ? tabla.parte_variable.length : 1,
    comentario: "",
  });

  const [formTable, setFormTable] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [newDataTabla, setNewDataTabla] = useState("");

  useEffect(() => {
    if (tabla.parte_variable) {
      setForm({ nro_filas: tabla.parte_variable.length });
    }
  }, [tabla.parte_variable]);

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  useEffect(() => {
    const arrFormTable = tabla.parte_variable
      ? [...tabla.parte_variable]
      : [...formTable];
    const arr = [];

    const number =
      form.nro_filas === 0 || form.nro_filas === "" ? 0 : form.nro_filas;

    for (let i = 0; i < number; i++) {
      if (arrFormTable[i]) {
        arr.push(arrFormTable[i]);
      } else {
        arr.push({
          col1: i + 1,
          col2: "",
          col3: "",
          col4: "",
          col5: "",
        });
      }
    }

    setFormTable(arr);
    // eslint-disable-next-line
  }, [tabla, form.nro_filas]);

  useEffect(() => {
    const arr = [];

    if (tabla.tabla === label) {
      if (tabla.dropdown.length > 0) {
        tabla.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });

        setData(arr);
      }
    }
  }, [tabla, label]);

  useEffect(() => {
    if (datos === false) {
      if (data.length > 0) {
        const dataSelectedTabla = data.find(
          (element) => element.seleccionado === "true"
        );

        if (dataSelectedTabla !== undefined) {
          setNewDataTabla(dataSelectedTabla.value);
        }
      }
    }

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const callsAPI = async () => {
      if (datos === false) {
        if (newDataTabla !== undefined) {
          setDropdownId(newDataTabla);

          setForm({
            nro_filas: Array.isArray(tabla.parte_variable)
              ? tabla.parte_variable.length
              : 0,
            comentario:
              state.data.datos.datos.comentario.valor !== null
                ? state.data.datos.datos.comentario.valor
                : "",
          });

          const arrTabla = [];

          tabla.documentos_que_respaldan?.map(
            (e) => e.seleccionado === true && arrTabla.push(e.clave)
          );

          setCheckedTabla(arrTabla);
        }
      }
    };
    callsAPI();
    //eslint-disable-next-line
  }, [tabla, newDataTabla]);

  const headerTable = (arr) => {
    let col = 0;

    // eslint-disable-next-line array-callback-return
    Object.keys(arr).map((item, i) => {
      if (item.includes("col")) {
        col++;
      }
    });
    return col;
  };

  const handleChangeCheckboxTabla = (obj) => {
    const arr = [...checkedTabla];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedTabla(arr);
  };

  const handleClickUpdate = () => {
    const arr = [];
    formTable.forEach((item) => {
      if (headerTable(tabla.datos_duro_tabla.linea2) > 4) {
        arr.push([item.col1, item.col2, item.col3, item.col4, item.col5]);
      } else {
        arr.push([item.col1, item.col2, item.col3, item.col4]);
      }
    });

    dispatch(
      updateMatriz5({
        deposito_id: id,
        m5_tabla_id: tabla.m5_tabla_id,
        tipo_instrumento: tabla.tipo_instrumento,
        opcion_informacion_id: dropdownId,
        documentos_que_respaldan: checkedTabla,
        parte_variable: arr,
        comentario: form.comentario,
      })
    );
  };

  const resetData = () => {
    dispatch(resetUpdateMatriz5());
  };

  const handleChangeTable = (index, col, e) => {
    let newFormTable = [...formTable];
    newFormTable[index][col] = e.target.value;
    setFormTable(newFormTable);
  };

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({ id: "IngreseComentario" });

  const placeholderCantidadPuntosControl = intl.formatMessage({
    id: "IngreseCantidadPuntosControl",
  });

  const renderCeldas = (params) => {
    if (params) {
      const arr = [];
      const number =
        form.nro_filas === 0 || form.nro_filas === "" ? 0 : form.nro_filas;

      if (formTable.length === parseInt(number)) {
        for (let i = 0; i < number; i++) {
          arr.push(
            <Table.Row key={i} className="table__matrix5__row-body">
              <Table.Cell colSpan={115}>{i + 1}</Table.Cell>
              <Table.Cell colSpan={115}>
                <Input
                  name={`valor_${i}`}
                  type="text"
                  onChange={(e) => {
                    let index = parseInt(e.target.id, 10);
                    handleChangeTable(index, "col2", e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={formTable[i]?.col2}
                  id={i}
                  disabled={minero === "false" ? true : false}
                />
              </Table.Cell>
              <Table.Cell colSpan={115}>
                <Input
                  name={`valor_${i}`}
                  type="text"
                  onChange={(e) => {
                    let index = parseInt(e.target.id, 10);
                    handleChangeTable(index, "col3", e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={formTable[i]?.col3}
                  id={i}
                  disabled={minero === "false" ? true : false}
                />
              </Table.Cell>

              <Table.Cell colSpan={115}>
                <Input
                  name={`valor_${i}`}
                  type="number"
                  onChange={(e) => {
                    let index = parseInt(e.target.id, 10);
                    handleChangeTable(index, "col4", e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={formTable[i]?.col4}
                  id={i}
                  disabled={minero === "false" ? true : false}
                />
              </Table.Cell>

              {headerTable(tabla.datos_duro_tabla.linea2) > 4 && (
                <Table.Cell colSpan={115}>
                  <Input
                    name={`valor_${i}`}
                    type="number"
                    onChange={(e) => {
                      let index = parseInt(e.target.id, 10);
                      handleChangeTable(index, "col5", e);
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={formTable[i]?.col5}
                    id={i}
                    disabled={minero === "false" ? true : false}
                  />
                </Table.Cell>
              )}
            </Table.Row>
          );
        }
      }

      return arr;
    }
    return null;
  };

  return (
    <div>
      <>
        <div style={{ marginTop: "25px" }}>
          <p className="card-matrix__title">
            <FormattedMessage id="ComentarioGeneral" />
          </p>
        </div>
        <TextArea
          placeholder={placeholderComentario}
          name="comentario"
          onChange={(e) => {
            handleChange(e);
          }}
          style={{ width: "100%" }}
          value={form.comentario}
          disabled={minero === "false" ? true : false}
          maxLength="1000"
        />

        <div className="matrix5__container-table">
          <Dropdown
            placeholder="Seleccione"
            selection
            options={data}
            value={datos === false ? newDataTabla : dropdownId}
            onChange={(_, data) => {
              setDropdownId(data.value);
              setNewDataTabla(data.value);
            }}
            disabled={minero === "false" ? true : false}
          />
        </div>
        <div>
          {(dropdownId === 1 || newDataTabla === 1) && (
            <>
              <div>
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  <FormattedMessage id="CantidadPuntosControl" />
                </p>
                <Input
                  name="nro_filas"
                  placeholder={placeholderCantidadPuntosControl}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => handleChange(e)}
                  maxLength="2"
                  value={form.nro_filas}
                  disabled={minero === "false" ? true : false}
                />
              </div>

              <Table celled fixed className="table__matrix5">
                <Table.Header className="table__matrix5__row-header">
                  {tabla.datos_duro_tabla?.linea1 && (
                    <>
                      <Table.Row>
                        {Object.keys(tabla.datos_duro_tabla.linea1).map(
                          // eslint-disable-next-line array-callback-return
                          (item, i) => {
                            if (item.includes("col")) {
                              return (
                                <Table.HeaderCell
                                  colSpan={`${
                                    115 *
                                    tabla.datos_duro_tabla.linea1[item].ancho
                                  }px`}
                                  key={`${tabla.datos_duro_tabla.linea1[item].label}-linea1`}
                                >
                                  {tabla.datos_duro_tabla.linea1[item].label}
                                </Table.HeaderCell>
                              );
                            }
                          }
                        )}
                      </Table.Row>
                      <Table.Row>
                        {Object.keys(tabla.datos_duro_tabla.linea2).map(
                          // eslint-disable-next-line array-callback-return
                          (item, i) => {
                            if (item.includes("col")) {
                              return (
                                <Table.HeaderCell
                                  colSpan={`${
                                    115 *
                                    tabla.datos_duro_tabla.linea2[item].ancho
                                  }px`}
                                  // key={`${tabla.datos_duro_tabla.linea2[item].label}-linea2`}
                                  key={i}
                                >
                                  {tabla.datos_duro_tabla.linea2[item].label}
                                </Table.HeaderCell>
                              );
                            }
                          }
                        )}
                      </Table.Row>
                    </>
                  )}
                </Table.Header>
                <Table.Body>{renderCeldas(tabla.parte_variable)}</Table.Body>
              </Table>

              {tabla.datos_duro_tabla?.notas.length > 0 && (
                <>
                  <h3>
                    <FormattedMessage id="Notas" />
                  </h3>
                  {tabla.datos_duro_tabla?.notas.map((item, i) => (
                    <p key={i}>{item}</p>
                  ))}

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      <FormattedMessage id="DocumentosRespaldan" />
                    </p>
                  </div>
                </>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {tabla.documentos_que_respaldan?.map((item, i) => {
                  let index = checkedTabla.includes(item.clave);

                  return (
                    <Checkbox
                      key={i}
                      label={item.label}
                      onChange={() => handleChangeCheckboxTabla(item)}
                      value={item.clave}
                      checked={index}
                      disabled={
                        minero === "false"
                          ? true
                          : !item.editable
                          ? true
                          : false
                      }
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
        {minero === "true" && (dropdownId !== "" || newDataTabla !== "") && (
          <div className="btn-update-matrix1" style={{ marginTop: "15px" }}>
            <Button onClick={onShowModal}>
              <FormattedMessage id="GuardarMayus" />
            </Button>
          </div>
        )}
        <>
          <ModalActions
            title={<FormattedMessage id="ActualizarMatriz5" />}
            show={showModal}
            setShow={setShowModal}
            status={state.statusUpdate}
            action={handleClickUpdate}
            reset={resetData}
            deleteAction={"Delete"}
            estado={state.dataUpdate}
            loading={state.loadingUpdate}
          >
            {state.statusUpdate === 0 ? (
              <FormattedMessage id="EstaSeguroMatriz5" />
            ) : state.dataUpdate.ejecucion.estado ? (
              <div className="container-modal">
                <p className="message-ok">
                  {state.dataUpdate.ejecucion.mensaje}
                </p>
                <Icon name="checkmark" className="status-ok" />
              </div>
            ) : (
              <div className="container-modal">
                <p className="message-fail">
                  {state.dataUpdate.ejecucion.mensaje}
                </p>
                <Image src={ohError} className="status-fail" />
              </div>
            )}
          </ModalActions>
        </>
      </>
    </div>
  );
};

export default Instrumento;
