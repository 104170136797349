import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "semantic-ui-react";
import { getAllComunas } from "../../actions/all-geolocations";
import { useIntl } from "react-intl";

// DROPDOWN COMUNA
export function DropdownAllComuna({ formik, providencia_id, updateChore }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.allGeolocations);

  const [data, setData] = useState([]);

  const { dataComuna, statusComuna, loadingComuna } = state;

  useEffect(() => {
    if (updateChore) {
      if (providencia_id !== "") {
        dispatch(getAllComunas({ provincia_id: providencia_id }));
      }
    } else {
      if (providencia_id !== "") {
        dispatch(getAllComunas({ provincia_id: providencia_id }));
      }
    }
  }, [dispatch, providencia_id, updateChore]);

  useEffect(() => {
    const arr = [];
    if (statusComuna === 200) {
      if (dataComuna.ejecucion.estado) {
        dataComuna?.datos.forEach((e) => {
          arr.push({
            key: e.comunaid,
            value: e.comunaid,
            text: e.nombre,
          });
        });
      }
    }
    setData(arr);
  }, [dataComuna, statusComuna]);

  const intl = useIntl();

  const placeHolderCargando = intl.formatMessage({ id: "CargandoMayus" });
  const placeHolderComuna = intl.formatMessage({ id: "ComunaMayus" });

  return (
    <Form.Dropdown
      placeholder={loadingComuna ? placeHolderCargando : placeHolderComuna}
      label={placeHolderComuna}
      selection
      options={data}
      value={!loadingComuna ? updateChore && formik.values.comuna_id : null}
      onChange={(_, data) => formik.setFieldValue("comuna_id", data.value)}
      errors={formik.errors.comuna_id}
      disabled={loadingComuna ? true : false}
    />
  );
}
