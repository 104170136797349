import {
  RESET_GET_DETAILS_COMPANY,
  GET_DETAILS_COMPANY_INIT,
  GET_DETAILS_COMPANY_SUCCESS,
  GET_DETAILS_COMPANY_FAILURE,
  RESET_DATA_COMPANY,
  GET_DATA_COMPANY_INIT,
  GET_DATA_COMPANY_SUCCESS,
  GET_DATA_COMPANY_FAILURE,
} from "../../actions/company/types";
import initialState from "./initialState";

export default function company(state = initialState, action) {
  switch (action.type) {
    case RESET_GET_DETAILS_COMPANY:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_DETAILS_COMPANY_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_DETAILS_COMPANY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_DETAILS_COMPANY_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    // DATA
    case RESET_DATA_COMPANY:
      return {
        ...state,
        dataCompany: {},
        loadingCompany: true,
        statusCompany: 0,
      };
    case GET_DATA_COMPANY_INIT:
      return {
        ...state,
        loadingCompany: true,
      };
    case GET_DATA_COMPANY_SUCCESS:
      return {
        ...state,
        dataCompany: action.payload.data,
        loadingCompany: false,
        statusCompany: action.payload.status,
      };
    case GET_DATA_COMPANY_FAILURE:
      return {
        ...state,
        dataCompany: {},
        loadingCompany: false,
        statusCompany: 501,
      };

    default:
      return state;
  }
}
