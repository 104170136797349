import axios from "axios";

const API = {
  data: {
    async getEvaluaciones(obj) {
      const response = await axios.get(
        `/api/evaluation/evaluaciones/${encodeURIComponent(
          JSON.stringify(obj)
        )}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async eliminarEvaluacion(obj) {
      const response = await axios.delete(
        `/api/evaluation/delete-evaluacion/${encodeURIComponent(
          JSON.stringify(obj)
        )}`
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async nuevaEvaluacion(obj) {
      const response = await axios.post(
        `/api/evaluation/nueva-evaluacion`,
        obj
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async publicarEvaluacion(obj) {
      const response = await axios.put(
        `/api/evaluation/publicar-evaluacion`,
        obj
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async validarEvaluacion(obj) {
      const response = await axios.get(
        `/api/evaluation/validar/${encodeURIComponent(JSON.stringify(obj))}`
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
