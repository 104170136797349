import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Table,
  TextArea,
  Image,
} from "semantic-ui-react";

import ModalActions from "../../Modal/ModalActions";
import {
  updateMatriz3,
  resetUpdateMatriz3,
} from "./../../../actions/matriz3/index";
import Expired from "./../../Expired/index";
import MaskedInput from "react-text-mask";

import ohError from "../../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const PastaEspesado = ({ id, state }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [error, setError] = useState(false);

  const [datos, setDatos] = useState();

  const [form, setForm] = useState({
    valor_gravedad: "",
    valor_solidos: "",
    valor_liquido: "",
    valor_plastico: "",
    valor_contraccion: "",
    valor_plasticidad: "",
    valor_humedad: "",

    valor_fila3_1: "",
    valor_fila3_2: 100,

    valor_fila4_1: "",
    valor_fila4_2: "",

    valor_fila5_1: "",
    valor_fila5_2: "",

    valor_fila6_1: "",
    valor_fila6_2: "",

    valor_densidad: "",
    valor_tension: "",
    valor_espesor: "",

    comentario: "",
  });

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const minero = localStorage.getItem("minero");

  //   DATA
  const [dataGravedad, setDataGravedad] = useState([]);
  const [dataGravedadId, setDataGravedadId] = useState("");

  const [dataSolidos, setDataSolidos] = useState([]);
  const [dataSolidosId, setDataSolidosId] = useState("");

  const [dataDistribucion, setDataDistribucion] = useState([]);
  const [dataDistribucionId, setDataDistribucionId] = useState("");

  const [dataAtterberg, setDataAtterberg] = useState([]);
  const [dataAtterbergId, setDataAtterbergId] = useState("");

  const [dataHumedad, setDataHumedad] = useState([]);
  const [dataHumedadId, setDataHumedadId] = useState("");

  const [dataDensidad, setDataDensidad] = useState([]);
  const [dataDensidadId, setDataDensidadId] = useState("");

  const [dataTension, setDataTension] = useState([]);
  const [dataTensionId, setDataTensionId] = useState("");

  const [dataEspesor, setDataEspesor] = useState([]);
  const [dataEspesorId, setDataEspesorId] = useState("");

  // CHECKBOX
  const [checkedGravedad, setCheckedGravedad] = useState([]);
  const [checkedSolidos, setCheckedSolidos] = useState([]);
  const [checkedDistribucion, setCheckedDistribucion] = useState([]);
  const [checkedAtterberg, setCheckedAtterberg] = useState([]);
  const [checkedHumedad, setCheckedHumedad] = useState([]);
  const [checkedDensidad, setCheckedDensidad] = useState([]);
  const [checkedTension, setCheckedTension] = useState([]);
  const [checkedEspesor, setCheckedEspesor] = useState([]);

  // NEW DATA
  const [newDataGravedad, setNewDataGravedad] = useState("");
  const [newDataSolidos, setNewDataSolidos] = useState("");
  const [newDataDistribucion, setNewDataDistribucion] = useState("");
  const [newDataAtterberg, setNewDataAtterberg] = useState("");
  const [newDataHumedad, setNewDataHumedad] = useState("");
  const [newDataDensidad, setNewDataDensidad] = useState("");
  const [newDataTension, setNewDataTension] = useState("");
  const [newDataEspesor, setNewDataEspesor] = useState("");

  useEffect(() => {
    if (
      (form.valor_gravedad === "" && dataGravedadId === 1) ||
      (form.valor_solidos === "" && dataSolidosId === 1) ||
      (form.valor_liquido === "" && dataAtterbergId === 1) ||
      (form.valor_plastico === "" && dataAtterbergId === 1) ||
      (form.valor_contraccion === "" && dataAtterbergId === 1) ||
      (form.valor_plasticidad === "" && dataAtterbergId === 1) ||
      (form.valor_humedad === "" && dataHumedadId === 1) ||
      (form.valor_fila3_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila4_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila4_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila5_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila5_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila6_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila6_2 === "" && dataDistribucionId === 1) ||
      (form.valor_densidad === "" && dataDensidadId === 1) ||
      (form.valor_tension === "" && dataTensionId === 1) ||
      (form.valor_espesor === "" && dataEspesorId === 1)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [
    form,
    dataGravedadId,
    dataSolidosId,
    dataDensidadId,
    dataTensionId,
    dataEspesorId,
    dataAtterbergId,
    dataDistribucionId,
    dataHumedadId,
  ]);

  // DATA GRAVEDAD
  // INICIO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.gravedad_especifica.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataGravedad(arr);
  }, [state.status, state.data.datos]);

  //   CHECKBOX GRAVEDAD
  const handleChangeCheckboxGravedad = (obj) => {
    const arr = [...checkedGravedad];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedGravedad(arr);
  };
  //   FIN DATA GRAVEDAD

  // DATA PORCENTAJES SOLIDOS
  // INICIO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.porcentaje_solidos.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataSolidos(arr);
  }, [state.status, state.data.datos]);

  //   CHECKBOX SOLIDOS
  const handleChangeCheckboxSolidos = (obj) => {
    const arr = [...checkedSolidos];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedSolidos(arr);
  };
  //   FIN DATA SOLIDOS

  // DATA DISTRIBUCION
  // INICIO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.distribucion_granulometrica.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataDistribucion(arr);
  }, [state.status, state.data.datos]);

  //   CHECKBOX DISTRIBUCION
  const handleChangeCheckboxDistribucion = (obj) => {
    const arr = [...checkedDistribucion];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedDistribucion(arr);
  };
  //   FIN DATA DISTRIBUCION

  // DATA ATTERBERG
  // INICIO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.limites_de_atterberg.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataAtterberg(arr);
  }, [state.status, state.data.datos]);

  //   CHECKBOX ATTERBERG
  const handleChangeCheckboxAtterberg = (obj) => {
    const arr = [...checkedAtterberg];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedAtterberg(arr);
  };
  //   FIN DATA ATTERBERG

  // DATA HUMEDAD
  // INICIO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.humedad_natural.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataHumedad(arr);
  }, [state.status, state.data.datos]);

  //   CHECKBOX HUMEDAD
  const handleChangeCheckboxHumedad = (obj) => {
    const arr = [...checkedHumedad];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedHumedad(arr);
  };
  //   FIN DATA HUMEDAD

  // DATA DENSIDAD SECA
  // INICIO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.densidad_seca.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataDensidad(arr);
  }, [state.status, state.data.datos]);

  //   CHECKBOX DENSIDAD SECA
  const handleChangeCheckboxDensidad = (obj) => {
    const arr = [...checkedDensidad];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedDensidad(arr);
  };
  //   FIN DATA DENSIDAD SECA

  // DATA TENSION CORTE
  // INICIO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.tension_corte.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataTension(arr);
  }, [state.status, state.data.datos]);

  //   CHECKBOX TENSION CORTE
  const handleChangeCheckboxTension = (obj) => {
    const arr = [...checkedTension];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedTension(arr);
  };
  //   FIN DATA TENSION CORTE

  // DATA ESPESOR CAPA
  // INICIO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.espesor_capa.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataEspesor(arr);
  }, [state.status, state.data.datos]);

  //   CHECKBOX ESPESOR CAPA
  const handleChangeCheckboxEspesor = (obj) => {
    const arr = [...checkedEspesor];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedEspesor(arr);
  };
  //   FIN DATA ESPESOR CAPA

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz3({
        tipo_matriz: state.data.datos.tipo_matriz,
        deposito_id: id,
        gravedad_especifica: {
          opcion_informacion_id: dataGravedadId,
          valor: form.valor_gravedad,
          documentos_que_respaldan: checkedGravedad,
        },
        porcentaje_solidos: {
          opcion_informacion_id: dataSolidosId,
          valor: form.valor_solidos,
          documentos_que_respaldan: checkedSolidos,
        },
        distribucion_granulometrica: {
          opcion_informacion_id: dataDistribucionId,
          valor_fila3_col3: form.valor_fila3_1,
          valor_fila3_col4: form.valor_fila3_2,
          valor_fila4_col3: form.valor_fila4_1,
          valor_fila4_col4: form.valor_fila4_2,
          valor_fila5_col3: form.valor_fila5_1,
          valor_fila5_col4: form.valor_fila5_2,
          valor_fila6_col3: form.valor_fila6_1,
          valor_fila6_col4: form.valor_fila6_2,
          documentos_que_respaldan: checkedDistribucion,
        },
        limites_de_atterberg: {
          opcion_informacion_id: dataAtterbergId,
          liquido: {
            valor: form.valor_liquido,
          },
          plastico: {
            valor: form.valor_plastico,
          },
          contraccion: {
            valor: form.valor_contraccion,
          },
          indice_plasticidad: {
            valor: form.valor_plasticidad,
          },
          documentos_que_respaldan: checkedAtterberg,
        },
        humedad_natural: {
          opcion_informacion_id: dataHumedadId,
          valor: form.valor_humedad,
          documentos_que_respaldan: checkedHumedad,
        },
        densidad_seca: {
          opcion_informacion_id: dataDensidadId,
          valor: form.valor_densidad,
          documentos_que_respaldan: checkedDensidad,
        },
        tension_corte: {
          opcion_informacion_id: dataTensionId,
          valor: form.valor_tension,
          documentos_que_respaldan: checkedTension,
        },
        espesor_capa: {
          opcion_informacion_id: dataEspesorId,
          valor: form.valor_espesor,
          documentos_que_respaldan: checkedEspesor,
        },
        comentario: form.comentario,
      })
    );
  };

  const resetData = () => {
    dispatch(resetUpdateMatriz3());
  };

  // CAMBIAR DATOS A FALSE SI EL ENDPOINT TRAE DATA
  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.loading]);

  // CARGAR DATA CUANDO VIENE DEL ENDPOINT
  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setForm({
              valor_gravedad:
                state.data.datos.datos.gravedad_especifica.valor.length > 0
                  ? state.data.datos.datos.gravedad_especifica.valor[0].valor
                  : "",
              valor_solidos:
                state.data.datos.datos.porcentaje_solidos.valor.length > 0
                  ? state.data.datos.datos.porcentaje_solidos.valor[0].valor
                  : "",
              valor_liquido:
                state.data.datos.datos.limites_de_atterberg.valor.length > 0
                  ? state.data.datos.datos.limites_de_atterberg.valor[0].liquido
                      .valor
                  : "",
              valor_plastico:
                state.data.datos.datos.limites_de_atterberg.valor.length > 0
                  ? state.data.datos.datos.limites_de_atterberg.valor[0]
                      .plastico.valor
                  : "",
              valor_contraccion:
                state.data.datos.datos.limites_de_atterberg.valor.length > 0
                  ? state.data.datos.datos.limites_de_atterberg.valor[0]
                      .contraccion.valor
                  : "",
              valor_plasticidad:
                state.data.datos.datos.limites_de_atterberg.valor.length > 0
                  ? state.data.datos.datos.limites_de_atterberg.valor[0]
                      .indice_plasticidad.valor
                  : "",

              valor_humedad:
                state.data.datos.datos.humedad_natural.valor.length > 0
                  ? state.data.datos.datos.humedad_natural.valor[0].valor
                  : "",

              valor_fila3_1:
                state.data.datos.datos.distribucion_granulometrica.fila3
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila3
                      .valor_col3.valor
                  : "",
              valor_fila3_2:
                state.data.datos.datos.distribucion_granulometrica.fila3
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila3
                      .valor_col4.valor
                  : 100,

              valor_fila4_1:
                state.data.datos.datos.distribucion_granulometrica.fila4
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila4
                      .valor_col3.valor
                  : "",
              valor_fila4_2:
                state.data.datos.datos.distribucion_granulometrica.fila4
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila4
                      .valor_col4.valor
                  : "",

              valor_fila5_1:
                state.data.datos.datos.distribucion_granulometrica.fila5
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila5
                      .valor_col3.valor
                  : "",
              valor_fila5_2:
                state.data.datos.datos.distribucion_granulometrica.fila5
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila5
                      .valor_col4.valor
                  : "",

              valor_fila6_1:
                state.data.datos.datos.distribucion_granulometrica.fila6
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila6
                      .valor_col3.valor
                  : "",
              valor_fila6_2:
                state.data.datos.datos.distribucion_granulometrica.fila6
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila6
                      .valor_col4.valor
                  : "",

              valor_densidad:
                state.data.datos.datos.densidad_seca.valor.length > 0
                  ? state.data.datos.datos.densidad_seca.valor[0].valor
                  : "",

              valor_tension:
                state.data.datos.datos.tension_corte.porcentaje_tension_corte
                  .length > 0
                  ? state.data.datos.datos.tension_corte
                      .porcentaje_tension_corte[0].valor
                  : "",
              valor_espesor:
                state.data.datos.datos.espesor_capa.valor.length > 0
                  ? state.data.datos.datos.espesor_capa.valor[0].valor
                  : "",

              comentario:
                state.data.datos.datos.comentario !== null
                  ? state.data.datos.datos.comentario
                  : "",
            });

            const arrGravedad = [];
            const arrSolidos = [];
            const arrDistribucion = [];
            const arrAtterberg = [];
            const arrHumedad = [];
            const arrDensidad = [];
            const arrTension = [];
            const arrEspesor = [];

            state.data.datos.datos.gravedad_especifica.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrGravedad.push(e.clave)
            );
            setCheckedGravedad(arrGravedad);

            state.data.datos.datos.porcentaje_solidos.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrSolidos.push(e.clave)
            );
            setCheckedSolidos(arrSolidos);

            state.data.datos.datos.distribucion_granulometrica.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrDistribucion.push(e.clave)
            );
            setCheckedDistribucion(arrDistribucion);

            state.data.datos.datos.limites_de_atterberg.limites_de_atterberg_documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrAtterberg.push(e.clave)
            );
            setCheckedAtterberg(arrAtterberg);

            state.data.datos.datos.humedad_natural.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrHumedad.push(e.clave)
            );
            setCheckedHumedad(arrHumedad);

            state.data.datos.datos.densidad_seca.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrDensidad.push(e.clave)
            );
            setCheckedDensidad(arrDensidad);

            state.data.datos.datos.tension_corte.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrTension.push(e.clave)
            );
            setCheckedTension(arrTension);

            state.data.datos.datos.espesor_capa.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrEspesor.push(e.clave)
            );
            setCheckedEspesor(arrEspesor);
          }
        }
      }
    };
    callsAPI();
  }, [state.loading, state.status, state.data]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDataGravedadId(newDataGravedad);
          setDataSolidosId(newDataSolidos);
          setDataDistribucionId(newDataDistribucion);
          setDataAtterbergId(newDataAtterberg);
          setDataHumedadId(newDataHumedad);
          setDataDensidadId(newDataDensidad);
          setDataTensionId(newDataTension);
          setDataEspesorId(newDataEspesor);
        }
      }
    }

    // eslint-disable-next-line
  }, [
    newDataGravedad,
    newDataSolidos,
    newDataDistribucion,
    newDataAtterberg,
    newDataHumedad,
    newDataDensidad,
    newDataTension,
    newDataEspesor,
  ]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          if (dataGravedad.length > 0) {
            const dataSelectedGravedad = dataGravedad.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataGravedad(dataSelectedGravedad.value);
          }

          if (dataSolidos.length > 0) {
            const dataSelectedSolidos = dataSolidos.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataSolidos(dataSelectedSolidos.value);
          }

          if (dataDistribucion.length > 0) {
            const dataSelectedDistribucion = dataDistribucion.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataDistribucion(dataSelectedDistribucion.value);
          }

          if (dataAtterberg.length > 0) {
            const dataSelectedAtterberg = dataAtterberg.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataAtterberg(dataSelectedAtterberg.value);
          }

          if (dataHumedad.length > 0) {
            const dataSelectedHumedad = dataHumedad.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataHumedad(dataSelectedHumedad.value);
          }

          if (dataDensidad.length > 0) {
            const dataSelectedDensidad = dataDensidad.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataDensidad(dataSelectedDensidad.value);
          }

          if (dataTension.length > 0) {
            const dataSelectedTension = dataTension.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataTension(dataSelectedTension.value);
          }

          if (dataEspesor.length > 0) {
            const dataSelectedEspesor = dataEspesor.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataEspesor(dataSelectedEspesor.value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [
    dataGravedad,
    dataSolidos,
    dataDistribucion,
    dataAtterberg,
    dataHumedad,
    dataDensidad,
    dataTension,
    dataEspesor,
  ]);

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({
    id: "IngreseComentario",
  });
  const placeholderValor = intl.formatMessage({ id: "IngreseValor" });

  const placeholderGravedad = intl.formatMessage({ id: "SeleccioneGravedad" });
  const placeholderSolidos = intl.formatMessage({ id: "SeleccioneSolidos" });
  const placeholderDistribucion = intl.formatMessage({
    id: "SeleccioneDistribucion",
  });
  const placeholderAtterberg = intl.formatMessage({
    id: "SeleccioneAtterberg",
  });
  const placeholderHumedad = intl.formatMessage({ id: "SeleccioneHumedad" });
  const placeholderDensidad = intl.formatMessage({ id: "SeleccioneDensidad" });
  const placeholderEspesor = intl.formatMessage({ id: "SeleccioneEspesor" });
  const placeholderTension = intl.formatMessage({ id: "SeleccioneTension" });

  /* When the token is expired*/
  if (state.status === 501) {
    return <Expired />;
  }

  return (
    <>
      <Card fluid className="card-deposit">
        {state.data.ejecucion.estado ? (
          <>
            <Card.Content>
              <div
                className="card-deposit-info"
                style={{ flexDirection: "row" }}
              >
                {state.data.datos.datos.titulo_matriz}
              </div>
            </Card.Content>

            <Card.Content className="card-container__matrix3">
              {/* GRAVEDAD */}
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.gravedad_especifica.titulo}
                </p>
              </div>
              <div className="matrix3__container-nivel">
                <div>
                  <Dropdown
                    placeholder={placeholderGravedad}
                    selection
                    options={dataGravedad}
                    value={datos === false ? newDataGravedad : dataGravedadId}
                    onChange={(_, data) => {
                      setDataGravedadId(data.value);
                      setNewDataGravedad(data.value);
                    }}
                    style={{ width: "95%" }}
                    disabled={minero === "false" ? true : false}
                  />
                  {dataGravedadId === "" && newDataGravedad === "" && (
                    <Icon name="warning" className="icon-warning" />
                  )}
                </div>

                {(dataGravedadId === 1 || newDataGravedad === 1) && (
                  <>
                    <div className="matrix3__container-valor">
                      <div>
                        <p>
                          {state.data.datos.datos.gravedad_especifica.label}
                        </p>
                        <Input
                          name="valor_gravedad"
                          placeholder={placeholderValor}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => handleChange(e)}
                          maxLength="5"
                          value={form.valor_gravedad}
                          disabled={minero === "false" ? true : false}
                          className={
                            error && form.valor_gravedad === ""
                              ? "border-red"
                              : "border-blue"
                          }
                        />
                      </div>
                      <div className="matrix3__compactacion-docs">
                        <p>
                          {
                            state.data.datos.datos
                              .titulo_documentos_respaldan_matriz
                          }
                        </p>
                        {state.data.datos.datos.gravedad_especifica.documentos_que_respaldan.map(
                          (item, i) => {
                            let index = checkedGravedad.includes(item.clave);
                            return (
                              <Checkbox
                                key={i}
                                label={item.label}
                                onChange={() =>
                                  handleChangeCheckboxGravedad(item)
                                }
                                value={item.clave}
                                checked={index}
                                disabled={
                                  minero === "false"
                                    ? true
                                    : !item.editable
                                    ? true
                                    : false
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* PORCENTAJES SOLIDOS */}
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.porcentaje_solidos.titulo}
                </p>
              </div>
              <div className="matrix3__container-nivel">
                <div>
                  <Dropdown
                    placeholder={placeholderSolidos}
                    selection
                    options={dataSolidos}
                    value={datos === false ? newDataSolidos : dataSolidosId}
                    onChange={(_, data) => {
                      setDataSolidosId(data.value);
                      setNewDataSolidos(data.value);
                    }}
                    style={{ width: "95%" }}
                    disabled={minero === "false" ? true : false}
                  />

                  {dataSolidosId === "" && newDataSolidos === "" && (
                    <Icon name="warning" className="icon-warning" />
                  )}
                </div>

                {(dataSolidosId === 1 || newDataSolidos === 1) && (
                  <>
                    <div className="matrix3__container-valor">
                      <div>
                        <p>{state.data.datos.datos.porcentaje_solidos.label}</p>
                        <div className="ui input">
                          <MaskedInput
                            mask={[/\d/, /\d/, ".", /\d/]}
                            name="valor_solidos"
                            placeholder={placeholderValor}
                            onChange={(e) => handleChange(e)}
                            maxLength="4"
                            guide={false}
                            value={form.valor_solidos}
                            disabled={minero === "false" ? true : false}
                            className={
                              error && form.valor_solidos === ""
                                ? "border-red"
                                : "border-blue"
                            }
                          />
                        </div>
                      </div>
                      <div className="matrix3__compactacion-docs">
                        <p>
                          {
                            state.data.datos.datos
                              .titulo_documentos_respaldan_matriz
                          }
                        </p>
                        {state.data.datos.datos.porcentaje_solidos.documentos_que_respaldan.map(
                          (item, i) => {
                            let index = checkedSolidos.includes(item.clave);
                            return (
                              <Checkbox
                                key={i}
                                label={item.label}
                                onChange={() =>
                                  handleChangeCheckboxSolidos(item)
                                }
                                value={item.clave}
                                checked={index}
                                disabled={
                                  minero === "false"
                                    ? true
                                    : !item.editable
                                    ? true
                                    : false
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* DISTRIBUCION */}
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.distribucion_granulometrica.titulo}
                </p>
              </div>
              <div className="matrix3__container-nivel">
                <div>
                  <Dropdown
                    placeholder={placeholderDistribucion}
                    selection
                    options={dataDistribucion}
                    value={
                      datos === false ? newDataDistribucion : dataDistribucionId
                    }
                    onChange={(_, data) => {
                      setDataDistribucionId(data.value);
                      setNewDataDistribucion(data.value);
                    }}
                    style={{ width: "95%" }}
                    disabled={minero === "false" ? true : false}
                  />

                  {dataDistribucionId === "" && newDataDistribucion === "" && (
                    <Icon name="warning" className="icon-warning" />
                  )}
                </div>

                {(dataDistribucionId === 1 || newDataDistribucion === 1) && (
                  <>
                    <div className="matrix3__container-valor">
                      <div>
                        <p>
                          {
                            state.data.datos.datos.distribucion_granulometrica
                              .label
                          }
                        </p>
                        <Table celled fixed className="table__matrix3">
                          <Table.Header>
                            <Table.Row style={{ textAlign: "center" }}>
                              <Table.HeaderCell></Table.HeaderCell>
                              <Table.HeaderCell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila1.lbl_col1
                                }
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila1.lbl_col2
                                }
                              </Table.HeaderCell>
                              <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>

                            <Table.Row style={{ textAlign: "center" }}>
                              <Table.HeaderCell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila2.lbl_col1
                                }
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila2.lbl_col2
                                }
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila2.lbl_col3
                                }
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila2.lbl_col4
                                }
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            <Table.Row style={{ textAlign: "center" }}>
                              <Table.Cell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila3.lbl_col1
                                }
                              </Table.Cell>
                              <Table.Cell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila3.lbl_col2
                                }
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  name="valor_fila3_1"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => handleChange(e)}
                                  maxLength="3"
                                  value={form.valor_fila3_1}
                                  disabled={minero === "false" ? true : false}
                                  className={
                                    error && form.valor_fila3_1 === ""
                                      ? "border-red"
                                      : "border-blue"
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  name="valor_fila3_2"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  // onChange={(e) => handleChange(e)}
                                  maxLength="3"
                                  value={form.valor_fila3_2}
                                  disabled={minero === "false" ? true : false}
                                  className={
                                    error && form.valor_fila3_2 === ""
                                      ? "border-red"
                                      : "border-blue"
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row style={{ textAlign: "center" }}>
                              <Table.Cell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila4.lbl_col1
                                }
                              </Table.Cell>
                              <Table.Cell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila4.lbl_col2
                                }
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  name="valor_fila4_1"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => handleChange(e)}
                                  maxLength="3"
                                  value={form.valor_fila4_1}
                                  disabled={minero === "false" ? true : false}
                                  className={
                                    error && form.valor_fila4_1 === ""
                                      ? "border-red"
                                      : "border-blue"
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  name="valor_fila4_2"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => handleChange(e)}
                                  maxLength="3"
                                  value={form.valor_fila4_2}
                                  disabled={minero === "false" ? true : false}
                                  className={
                                    error && form.valor_fila4_2 === ""
                                      ? "border-red"
                                      : "border-blue"
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>

                            {/* 3 */}
                            <Table.Row style={{ textAlign: "center" }}>
                              <Table.Cell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila5.lbl_col1
                                }
                              </Table.Cell>
                              <Table.Cell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila5.lbl_col2
                                }
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  name="valor_fila5_1"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => handleChange(e)}
                                  maxLength="3"
                                  value={form.valor_fila5_1}
                                  disabled={minero === "false" ? true : false}
                                  className={
                                    error && form.valor_fila5_1 === ""
                                      ? "border-red"
                                      : "border-blue"
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  name="valor_fila5_2"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => handleChange(e)}
                                  maxLength="3"
                                  value={form.valor_fila5_2}
                                  disabled={minero === "false" ? true : false}
                                  className={
                                    error && form.valor_fila5_2 === ""
                                      ? "border-red"
                                      : "border-blue"
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>
                            {/* 4 */}
                            <Table.Row style={{ textAlign: "center" }}>
                              <Table.Cell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila6.lbl_col1
                                }
                              </Table.Cell>
                              <Table.Cell>
                                {
                                  state.data.datos.datos
                                    .distribucion_granulometrica.fila6.lbl_col2
                                }
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  name="valor_fila6_1"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => handleChange(e)}
                                  maxLength="3"
                                  value={form.valor_fila6_1}
                                  disabled={minero === "false" ? true : false}
                                  className={
                                    error && form.valor_fila6_1 === ""
                                      ? "border-red"
                                      : "border-blue"
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Input
                                  name="valor_fila6_2"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => handleChange(e)}
                                  maxLength="3"
                                  value={form.valor_fila6_2}
                                  disabled={minero === "false" ? true : false}
                                  className={
                                    error && form.valor_fila6_2 === ""
                                      ? "border-red"
                                      : "border-blue"
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </div>
                    </div>
                    <div className="matrix3__compactacion-docs1">
                      <p>
                        {
                          state.data.datos.datos
                            .titulo_documentos_respaldan_matriz
                        }
                      </p>
                      {state.data.datos.datos.distribucion_granulometrica.documentos_que_respaldan.map(
                        (item, i) => {
                          let index = checkedDistribucion.includes(item.clave);
                          return (
                            <Checkbox
                              key={i}
                              label={item.label}
                              onChange={() =>
                                handleChangeCheckboxDistribucion(item)
                              }
                              value={item.clave}
                              checked={index}
                              disabled={
                                minero === "false"
                                  ? true
                                  : !item.editable
                                  ? true
                                  : false
                              }
                            />
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </div>
              {/* LIMITES ATTERBERG */}
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.limites_de_atterberg.titulo}
                </p>
              </div>
              <div className="matrix3__container-nivel">
                <div>
                  <Dropdown
                    placeholder={placeholderAtterberg}
                    selection
                    options={dataAtterberg}
                    value={datos === false ? newDataAtterberg : dataAtterbergId}
                    onChange={(_, data) => {
                      setDataAtterbergId(data.value);
                      setNewDataAtterberg(data.value);
                    }}
                    style={{ width: "95%" }}
                    disabled={minero === "false" ? true : false}
                  />
                  {dataAtterbergId === "" && newDataAtterberg === "" && (
                    <Icon name="warning" className="icon-warning" />
                  )}
                </div>

                {(dataAtterbergId === 1 || newDataAtterberg === 1) && (
                  <>
                    <div className="matrix3__container-valor">
                      <div>
                        <p>
                          {
                            state.data.datos.datos.limites_de_atterberg.valor[0]
                              .liquido.subtitulo
                          }
                        </p>
                        <Input
                          name="valor_liquido"
                          placeholder={placeholderValor}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => handleChange(e)}
                          maxLength="2"
                          value={form.valor_liquido}
                          disabled={minero === "false" ? true : false}
                          className={
                            error && form.valor_liquido === ""
                              ? "border-red"
                              : "border-blue"
                          }
                        />
                        <p>
                          {
                            state.data.datos.datos.limites_de_atterberg.valor[0]
                              .plastico.subtitulo
                          }
                        </p>
                        <Input
                          name="valor_plastico"
                          placeholder={placeholderValor}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => handleChange(e)}
                          maxLength="2"
                          value={form.valor_plastico}
                          disabled={minero === "false" ? true : false}
                          className={
                            error && form.valor_plastico === ""
                              ? "border-red"
                              : "border-blue"
                          }
                        />
                        <p>
                          {
                            state.data.datos.datos.limites_de_atterberg.valor[0]
                              .contraccion.subtitulo
                          }
                        </p>
                        <Input
                          name="valor_contraccion"
                          placeholder={placeholderValor}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => handleChange(e)}
                          maxLength="2"
                          value={form.valor_contraccion}
                          disabled={minero === "false" ? true : false}
                          className={
                            error && form.valor_contraccion === ""
                              ? "border-red"
                              : "border-blue"
                          }
                        />
                        <p>
                          {
                            state.data.datos.datos.limites_de_atterberg.valor[0]
                              .indice_plasticidad.subtitulo
                          }
                        </p>
                        <Input
                          name="valor_plasticidad"
                          placeholder=""
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          // onChange={(e) => handleChange(e)}
                          maxLength="5"
                          value={
                            (form.valor_plasticidad =
                              form.valor_liquido - form.valor_plastico)
                          }
                          disabled={minero === "false" ? true : false}
                          className={
                            error && form.valor_plasticidad === ""
                              ? "border-red"
                              : "border-blue"
                          }
                        />
                      </div>
                      <div className="matrix3__compactacion-docs">
                        <p>
                          {
                            state.data.datos.datos
                              .titulo_documentos_respaldan_matriz
                          }
                        </p>
                        {state.data.datos.datos.limites_de_atterberg.limites_de_atterberg_documentos_que_respaldan.map(
                          (item, i) => {
                            let index = checkedAtterberg.includes(item.clave);
                            return (
                              <Checkbox
                                key={i}
                                label={item.label}
                                onChange={() =>
                                  handleChangeCheckboxAtterberg(item)
                                }
                                value={item.clave}
                                checked={index}
                                disabled={
                                  minero === "false"
                                    ? true
                                    : !item.editable
                                    ? true
                                    : false
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* HUMEDAD NATURAL */}
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.humedad_natural.titulo}
                </p>
              </div>
              <div className="matrix3__container-nivel">
                <div>
                  <Dropdown
                    placeholder={placeholderHumedad}
                    selection
                    options={dataHumedad}
                    value={datos === false ? newDataHumedad : dataHumedadId}
                    onChange={(_, data) => {
                      setDataHumedadId(data.value);
                      setNewDataHumedad(data.value);
                    }}
                    style={{ width: "95% " }}
                    disabled={minero === "false" ? true : false}
                  />
                  {dataHumedadId === "" && newDataHumedad === "" && (
                    <Icon name="warning" className="icon-warning" />
                  )}
                </div>

                {(dataHumedadId === 1 || newDataHumedad === 1) && (
                  <>
                    <div className="matrix3__container-valor">
                      <div>
                        <p>{state.data.datos.datos.humedad_natural.label}</p>
                        <div className="ui input">
                          <MaskedInput
                            mask={[/\d/, /\d/, ".", /\d/]}
                            name="valor_humedad"
                            placeholder={placeholderValor}
                            onChange={(e) => handleChange(e)}
                            guide={false}
                            maxLength="4"
                            value={form.valor_humedad}
                            disabled={minero === "false" ? true : false}
                            className={
                              error && form.valor_humedad === ""
                                ? "border-red"
                                : "border-blue"
                            }
                          />
                        </div>
                      </div>
                      <div className="matrix3__compactacion-docs">
                        <p>
                          {
                            state.data.datos.datos
                              .titulo_documentos_respaldan_matriz
                          }
                        </p>
                        {state.data.datos.datos.humedad_natural.documentos_que_respaldan.map(
                          (item, i) => {
                            let index = checkedHumedad.includes(item.clave);
                            return (
                              <Checkbox
                                key={i}
                                label={item.label}
                                onChange={() =>
                                  handleChangeCheckboxHumedad(item)
                                }
                                value={item.clave}
                                checked={index}
                                disabled={
                                  minero === "false"
                                    ? true
                                    : !item.editable
                                    ? true
                                    : false
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* DENSIDAD SECA */}
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.densidad_seca.titulo}
                </p>
              </div>
              <div className="matrix3__container-nivel">
                <div>
                  <Dropdown
                    placeholder={placeholderDensidad}
                    selection
                    options={dataDensidad}
                    value={datos === false ? newDataDensidad : dataDensidadId}
                    onChange={(_, data) => {
                      setDataDensidadId(data.value);
                      setNewDataDensidad(data.value);
                    }}
                    style={{ width: "95%" }}
                    disabled={minero === "false" ? true : false}
                  />
                  {dataDensidadId === "" && newDataDensidad === "" && (
                    <Icon name="warning" className="icon-warning" />
                  )}
                </div>

                {(dataDensidadId === 1 || newDataDensidad === 1) && (
                  <>
                    <div className="matrix3__container-valor">
                      <div>
                        <p>{state.data.datos.datos.densidad_seca.label}</p>
                        <Input
                          name="valor_densidad"
                          placeholder={placeholderValor}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => handleChange(e)}
                          maxLength="5"
                          value={form.valor_densidad}
                          disabled={minero === "false" ? true : false}
                          className={
                            error && form.valor_densidad === ""
                              ? "border-red"
                              : "border-blue"
                          }
                        />
                      </div>
                      <div className="matrix3__compactacion-docs">
                        <p>
                          {
                            state.data.datos.datos
                              .titulo_documentos_respaldan_matriz
                          }
                        </p>
                        {state.data.datos.datos.densidad_seca.documentos_que_respaldan.map(
                          (item, i) => {
                            let index = checkedDensidad.includes(item.clave);
                            return (
                              <Checkbox
                                key={i}
                                label={item.label}
                                onChange={() =>
                                  handleChangeCheckboxDensidad(item)
                                }
                                value={item.clave}
                                checked={index}
                                disabled={
                                  minero === "false"
                                    ? true
                                    : !item.editable
                                    ? true
                                    : false
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* TENSION CORTE */}
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.tension_corte.titulo}
                </p>
              </div>
              <div className="matrix3__container-nivel">
                <div>
                  <Dropdown
                    placeholder={placeholderTension}
                    selection
                    options={dataTension}
                    value={datos === false ? newDataTension : dataTensionId}
                    onChange={(_, data) => {
                      setDataTensionId(data.value);
                      setNewDataTension(data.value);
                    }}
                    style={{ width: "95%" }}
                    disabled={minero === "false" ? true : false}
                  />
                  {dataTensionId === "" && newDataTension === "" && (
                    <Icon name="warning" className="icon-warning" />
                  )}
                </div>

                {(dataTensionId === 1 || newDataTension === 1) && (
                  <>
                    <div className="matrix3__container-valor">
                      <div>
                        <p>{state.data.datos.datos.tension_corte.label}</p>
                        <Input
                          name="valor_tension"
                          placeholder={placeholderValor}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => handleChange(e)}
                          maxLength="5"
                          value={form.valor_tension}
                          disabled={minero === "false" ? true : false}
                          className={
                            error && form.valor_tension === ""
                              ? "border-red"
                              : "border-blue"
                          }
                        />
                      </div>

                      <div className="matrix3__compactacion-docs">
                        <p>
                          {
                            state.data.datos.datos
                              .titulo_documentos_respaldan_matriz
                          }
                        </p>
                        {state.data.datos.datos.tension_corte.documentos_que_respaldan.map(
                          (item, i) => {
                            let index = checkedTension.includes(item.clave);
                            return (
                              <Checkbox
                                key={i}
                                label={item.label}
                                onChange={() =>
                                  handleChangeCheckboxTension(item)
                                }
                                value={item.clave}
                                checked={index}
                                disabled={
                                  minero === "false"
                                    ? true
                                    : !item.editable
                                    ? true
                                    : false
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* ESPESOR CAPA */}
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.espesor_capa.titulo}
                </p>
              </div>
              <div className="matrix3__container-nivel">
                <div>
                  <Dropdown
                    placeholder={placeholderEspesor}
                    selection
                    options={dataEspesor}
                    value={datos === false ? newDataEspesor : dataEspesorId}
                    onChange={(_, data) => {
                      setDataEspesorId(data.value);
                      setNewDataEspesor(data.value);
                    }}
                    style={{ width: "95%" }}
                    disabled={minero === "false" ? true : false}
                  />
                  {dataEspesorId === "" && newDataEspesor === "" && (
                    <Icon name="warning" className="icon-warning" />
                  )}
                </div>

                {(dataEspesorId === 1 || newDataEspesor === 1) && (
                  <>
                    <div className="matrix3__container-valor">
                      <div>
                        <p>{state.data.datos.datos.espesor_capa.label}</p>
                        <Input
                          name="valor_espesor"
                          placeholder={placeholderValor}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => handleChange(e)}
                          maxLength="2"
                          value={form.valor_espesor}
                          disabled={minero === "false" ? true : false}
                          className={
                            error && form.valor_espesor === ""
                              ? "border-red"
                              : "border-blue"
                          }
                        />
                      </div>

                      <div className="matrix3__compactacion-docs">
                        <p>
                          {
                            state.data.datos.datos
                              .titulo_documentos_respaldan_matriz
                          }
                        </p>
                        {state.data.datos.datos.espesor_capa.documentos_que_respaldan.map(
                          (item, i) => {
                            let index = checkedEspesor.includes(item.clave);
                            return (
                              <Checkbox
                                key={i}
                                label={item.label}
                                onChange={() =>
                                  handleChangeCheckboxEspesor(item)
                                }
                                value={item.clave}
                                checked={index}
                                disabled={
                                  minero === "false"
                                    ? true
                                    : !item.editable
                                    ? true
                                    : false
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {dataGravedadId !== "" &&
                dataSolidosId !== "" &&
                dataDistribucionId !== "" &&
                dataAtterbergId !== "" &&
                dataHumedadId !== "" &&
                dataDensidadId !== "" &&
                dataTensionId !== "" &&
                dataEspesorId !== "" && (
                  <div>
                    <TextArea
                      placeholder={placeholderComentario}
                      name="comentario"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      style={{ width: "100%", marginTop: "20px" }}
                      value={form.comentario}
                      disabled={minero === "false" ? true : false}
                      maxLength="1000"
                    />

                    {minero === "true" && error === false && (
                      <div
                        className="btn-update-matrix1"
                        style={{ marginTop: "15px" }}
                      >
                        <Button onClick={onShowModal}>
                          <FormattedMessage id="GuardarMayus" />
                        </Button>
                      </div>
                    )}
                  </div>
                )}

              <ModalActions
                title={<FormattedMessage id="ActualizarMatriz3" />}
                show={showModal}
                setShow={setShowModal}
                status={state.statusUpdate}
                action={handleClickUpdate}
                reset={resetData}
                deleteAction={"Delete"}
                estado={state.dataUpdate}
                loading={state.loadingUpdate}
              >
                {state.statusUpdate === 0 ? (
                  <FormattedMessage id="EstaSeguroMatriz3" />
                ) : state.dataUpdate.ejecucion.estado ? (
                  <div className="container-modal">
                    <p className="message-ok">
                      {state.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Icon name="checkmark" className="status-ok" />
                  </div>
                ) : (
                  <div className="container-modal">
                    <p className="message-fail">
                      {state.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Image src={ohError} className="status-fail" />
                  </div>
                )}
              </ModalActions>
            </Card.Content>
          </>
        ) : (
          <Card.Content>{state.data.ejecucion.mensaje}</Card.Content>
        )}
      </Card>
    </>
  );
};

export default PastaEspesado;
