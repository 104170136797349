import {
  RESET_ADD_DEPOSIT,
  ADD_DEPOSIT_INIT,
  ADD_DEPOSIT_SUCCESS,
  ADD_DEPOSIT_FAILURE,
} from "./types";
import API from "./api";

export function resetAddDeposit() {
  return {
    type: RESET_ADD_DEPOSIT,
  };
}
function addDepositInit() {
  return {
    type: ADD_DEPOSIT_INIT,
  };
}
function addDepositSuccess(data) {
  return {
    type: ADD_DEPOSIT_SUCCESS,
    payload: data,
  };
}
function addDepositFailure(error) {
  return {
    type: ADD_DEPOSIT_FAILURE,
    payload: error,
  };
}
export function addDeposit(data) {
  return async (dispatch) => {
    dispatch(addDepositInit());
    try {
      const resp = await API.data.addDeposit(data);
      dispatch(addDepositSuccess(resp));
    } catch (error) {
      dispatch(addDepositFailure(error));
    }
  };
}
