const initialState = {
  data: {},
  loading: true,
  status: 0,

  dataDelete: {},
  loadingDelete: false,
  statusDelete: 0,

  dataNueva: {},
  loadingNueva: false,
  statusNueva: 0,

  dataPublicar: {},
  loadingPublicar: false,
  statusPublicar: 0,

  dataValidar: {},
  loadingValidar: false,
  statusValidar: 0,
};

export default initialState;
