import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import Loading from "../../Loading/index";

import { Matrix3 } from "./TranqueArena";
import EmbalseRelave from "./EmbalseRelave";

import { getMatriz3, resetGetMatriz3 } from "../../../actions/matriz3/index";
import PastaEspesado from "./Pasta&Espesado";
import FiltradoRelave from "./FiltradoRelave";
import Expired from "./../../Expired/index";

const NewMatrix3 = ({ id }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.matriz3);

  useEffect(() => {
    dispatch(
      getMatriz3({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetMatriz3());
    };
  }, [dispatch]);

  /* When the token is expired*/
  if (state.status === 501) {
    return <Expired />;
  }

  return (
    <Container>
      {state.loading ? (
        <Loading />
      ) : (
        <div>
          {state.data.datos.tipo_matriz === "m3_tranque_arena_relave" && (
            <Matrix3 id={id} state={state} />
          )}
          {state.data.datos.tipo_matriz === "m3_embalse_relave" && (
            <EmbalseRelave id={id} state={state} />
          )}

          {(state.data.datos.tipo_matriz === "m3_pasta_relave" ||
            state.data.datos.tipo_matriz === "m3_espesado_relave") && (
            <PastaEspesado id={id} state={state} />
          )}

          {state.data.datos.tipo_matriz === "m3_filtrado_relave" && (
            <FiltradoRelave id={id} state={state} />
          )}
        </div>
      )}
    </Container>
  );
};

export default NewMatrix3;
