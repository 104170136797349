import {
  RESET_GET_MATRIZ5,
  GET_MATRIZ5_INIT,
  GET_MATRIZ5_SUCCESS,
  GET_MATRIZ5_FAILURE,
  RESET_UPDATE_MATRIZ5,
  UPDATE_MATRIZ5_INIT,
  UPDATE_MATRIZ5_SUCCESS,
  UPDATE_MATRIZ5_FAILURE,
} from "./types";
import API from "./api";

// GET
export function resetGetMatriz5() {
  return {
    type: RESET_GET_MATRIZ5,
  };
}
function getMatriz5Init() {
  return {
    type: GET_MATRIZ5_INIT,
  };
}
function getMatriz5Success(data) {
  return {
    type: GET_MATRIZ5_SUCCESS,
    payload: data,
  };
}
function getMatriz5Failure(error) {
  return {
    type: GET_MATRIZ5_FAILURE,
    payload: error,
  };
}
export function getMatriz5(data) {
  return async (dispatch) => {
    dispatch(getMatriz5Init());
    try {
      const resp = await API.data.getMatriz5(data);
      dispatch(getMatriz5Success(resp));
    } catch (error) {
      dispatch(getMatriz5Failure(error));
    }
  };
}

// UPDATE
export function resetUpdateMatriz5() {
  return {
    type: RESET_UPDATE_MATRIZ5,
  };
}
function updateMatriz5Init() {
  return {
    type: UPDATE_MATRIZ5_INIT,
  };
}
function updateMatriz5Success(data) {
  return {
    type: UPDATE_MATRIZ5_SUCCESS,
    payload: data,
  };
}
function updateMatriz5Failure(error) {
  return {
    type: UPDATE_MATRIZ5_FAILURE,
    payload: error,
  };
}
export function updateMatriz5(data) {
  return async (dispatch) => {
    dispatch(updateMatriz5Init());
    try {
      const resp = await API.data.updateMatriz5(data);
      dispatch(updateMatriz5Success(resp));
    } catch (error) {
      dispatch(updateMatriz5Failure(error));
    }
  };
}
