const initialState = {
  data: {},
  loading: true,
  status: 0,

  dataOpcion: {},
  loadingOpcion: true,
  statusOpcion: 0,

  dataDelete: {},
  loadingDelete: false,
  statusDelete: 0,
};

export default initialState;
