import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Container, Table, Icon, Image } from "semantic-ui-react";

import DividerGrey from "../../components/DividerGrey";
import DashboardUbication from "../../components/DashboardUbication";
import Loading from "./../../components/Loading/index";
import Expired from "./../../components/Expired/index";
import { useLocation } from "react-router-dom";
import {
  deleteEvaluacion,
  getEvaluaciones,
  nuevaEvaluacion,
  publicarEvaluacion,
  resetDeleteEvaluacion,
  resetEvaluaciones,
  resetNuevaEvaluacion,
  resetPublicarEvaluacion,
  resetValidarEvaluacion,
  validarEvaluacion,
} from "../../actions/evaluation";
import { getObtenerUrl } from "../../actions/url";

import ModalDelete from "../../components/Modal/ModalDelete";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage } from "react-intl";

const Evaluacion = () => {
  let minero = localStorage.getItem("minero");
  let servicio = localStorage.getItem("servicio");
  minero = minero === "true";
  servicio = servicio === "true";

  const location = useLocation();
  const { state, stateDeposit } = location.state;

  const [validar, setValidar] = useState(false);

  const dispatch = useDispatch();
  const stateEvaluacion = useSelector((state) => state.evaluacion);
  const stateUrl = useSelector((state) => state.url);

  const [id, setId] = useState("");

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEvaluacion, setShowModalEvaluacion] = useState(false);
  const [showModalPublicar, setShowModalPublicar] = useState(false);
  const [showModalValidar, setShowModalValidar] = useState(false);

  const onShowModalEvaluacion = () => {
    setShowModalEvaluacion(true);
  };

  const onShowModalValidar = () => setShowModalValidar(true);

  const onShowModalDelete = (id) => {
    setId(id);
    setShowModalDelete(true);
  };

  const onShowModalPublicar = (id) => {
    setId(id);
    setShowModalPublicar(true);
  };

  const handleEliminarEvaluacion = () => {
    dispatch(
      deleteEvaluacion({
        evaluacion_id: id,
      })
    );
  };

  const handleValidarEvaluacion = () => {
    dispatch(
      validarEvaluacion({
        periodo_id: stateEvaluacion.data.datos.periodo_id,
      })
    );
  };

  const handleNuevaEvaluacion = () => {
    dispatch(
      nuevaEvaluacion({
        periodo_id: stateEvaluacion.data.datos.periodo_id,
      })
    );
  };

  const handlePublicarEvaluacion = () => {
    dispatch(
      publicarEvaluacion({
        evaluacion_id: id,
      })
    );
  };

  const resetDataDelete = () => {
    dispatch(resetDeleteEvaluacion());
    window.location.reload();
  };

  const resetDataNueva = () => {
    dispatch(resetNuevaEvaluacion());
    window.location.reload();
  };

  const resetDataValidar = () => {
    dispatch(resetValidarEvaluacion());
  };

  const resetDataPublicar = () => {
    dispatch(resetPublicarEvaluacion());
    window.location.reload();
  };

  const closeModalValidar = () => {
    dispatch(resetValidarEvaluacion());
  };

  const closeModalDelete = () => {
    dispatch(resetDeleteEvaluacion());
  };
  const closeModalEvaluacion = () => {
    dispatch(resetNuevaEvaluacion());
  };
  const closeModalPublicar = () => {
    dispatch(resetPublicarEvaluacion());
  };

  useEffect(() => {
    dispatch(
      getEvaluaciones({
        deposito_id: stateDeposit.dataDeposit.datos.id,
        es_minero: minero,
        es_servicio: servicio,
      })
    );
    dispatch(getObtenerUrl());
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (stateEvaluacion.statusDelete === 200) {
      if (stateEvaluacion.dataDelete.ejecucion.estado) {
        resetDeleteEvaluacion();
      }
    }
    //eslint-disable-next-line
  }, [stateEvaluacion]);

  useEffect(() => {
    return () => {
      dispatch(resetEvaluaciones());
      dispatch(resetDeleteEvaluacion());
      dispatch(resetPublicarEvaluacion());
      dispatch(resetNuevaEvaluacion());
      dispatch(resetValidarEvaluacion());
    };
  }, [dispatch]);

  useEffect(() => {
    if (stateEvaluacion.status === 200) {
      if (stateEvaluacion.statusValidar === 200) {
        if (stateEvaluacion.dataValidar.datos.evaluable) {
          setValidar(true);
        } else {
          setValidar(false);
        }
      }
    }
  }, [
    stateEvaluacion.status,
    stateEvaluacion.dataValidar,
    stateEvaluacion.statusValidar,
  ]);

  if (stateEvaluacion.status === 501) {
    return <Expired />;
  }

  return (
    <Container>
      <>
        <DashboardUbication>
          {state.statusChore === 200 ? (
            `${state.dataChore.datos.razon_social}/${state.dataChore.datos.nombre}/Evaluación`
          ) : (
            <>
              <FormattedMessage id="CargandoMinus" />
            </>
          )}
        </DashboardUbication>
        <DividerGrey />

        <div>
          {stateEvaluacion.loading ? (
            <Loading />
          ) : (
            <div>
              {stateEvaluacion.data.ejecucion.estado ? (
                <>
                  <div className="nueva_evaluacion">
                    <p>
                      <FormattedMessage id="Deposito" /> :{" "}
                      {stateEvaluacion.data.datos.deposito_nombre}
                    </p>
                    <p>
                      <FormattedMessage id="Deposito" /> :{" "}
                      {stateEvaluacion.data.datos.periodo_clave}
                    </p>
                    {!validar && (
                      <>
                        <p>
                          <FormattedMessage id="ClickChequeo" />
                        </p>
                        <Button
                          onClick={onShowModalValidar}
                          disabled={servicio ? true : false}
                        >
                          <FormattedMessage id="ChequearData" />
                        </Button>
                      </>
                    )}
                    {minero && validar && (
                      <>
                        <p>
                          <FormattedMessage id="CheckOk" />
                        </p>
                        <Button onClick={onShowModalEvaluacion}>
                          <FormattedMessage id="RealizarEvaluacion" />
                        </Button>
                      </>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      overflowY: "scroll",
                      maxHeight: "50vh",
                      marginTop: "15px",
                    }}
                  >
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>ID</Table.HeaderCell>
                          <Table.HeaderCell>
                            <FormattedMessage id="FechaEvaluacion" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <FormattedMessage id="Periodo" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <FormattedMessage id="NombreArchivo" />
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <FormattedMessage id="Estado" />
                          </Table.HeaderCell>
                          {minero && (
                            <>
                              <Table.HeaderCell>
                                <FormattedMessage id="Publicar" />
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <FormattedMessage id="Eliminar" />
                              </Table.HeaderCell>
                            </>
                          )}
                          <Table.HeaderCell>
                            <FormattedMessage id="DescargarMinus" />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      {stateEvaluacion.data.datos.evaluaciones_historicas.map(
                        (item, i) => {
                          return (
                            <Table.Body key={i}>
                              <Table.Row>
                                <Table.Cell>{item.evaluacion_id}</Table.Cell>
                                <Table.Cell>{item.fecha_evaluacion}</Table.Cell>
                                <Table.Cell>{item.clave_periodo}</Table.Cell>
                                <Table.Cell>{item.nombre_archivo}</Table.Cell>
                                <Table.Cell>{item.estado}</Table.Cell>
                                {minero && (
                                  <>
                                    <Table.Cell>
                                      <Button
                                        onClick={() =>
                                          onShowModalPublicar(
                                            item.evaluacion_id
                                          )
                                        }
                                        className="btn-publicar"
                                        disabled={
                                          item.estado === "Publicado"
                                            ? true
                                            : false
                                        }
                                      >
                                        <FormattedMessage id="Publicar" />
                                      </Button>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Button
                                        onClick={() =>
                                          onShowModalDelete(item.evaluacion_id)
                                        }
                                        className="btn-eliminar"
                                      >
                                        <FormattedMessage id="Eliminar" />
                                      </Button>
                                    </Table.Cell>
                                  </>
                                )}

                                <Table.Cell>
                                  <Button
                                    className="btn-download"
                                    href={`${stateUrl.dataUrl.datos}${item.archivo}`}
                                    download
                                    target="_blank"
                                  >
                                    <FormattedMessage id="Descargar" />
                                  </Button>
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          );
                        }
                      )}
                    </Table>
                  </div>

                  <ModalDelete
                    title={<FormattedMessage id="EliminarEvaluacion" />}
                    show={showModalDelete}
                    setShow={setShowModalDelete}
                    action={handleEliminarEvaluacion}
                    reset={resetDataDelete}
                    status={stateEvaluacion.statusDelete}
                    estado={stateEvaluacion.dataDelete}
                    loading={stateEvaluacion.loadingDelete}
                    close={closeModalDelete}
                  >
                    {stateEvaluacion.statusDelete === 0 ? (
                      <FormattedMessage id="EstaSeguroEliminarEvaluacion" />
                    ) : stateEvaluacion.dataDelete.ejecucion.estado ? (
                      <div className="container-modal">
                        <p className="message-ok">
                          {stateEvaluacion.dataDelete.ejecucion.mensaje}
                        </p>
                        <Icon name="checkmark" className="status-ok" />
                      </div>
                    ) : (
                      <div className="container-modal">
                        <p className="message-fail">
                          {stateEvaluacion.dataDelete.ejecucion.mensaje}
                        </p>
                        <Image src={ohError} className="status-fail" />
                      </div>
                    )}
                  </ModalDelete>

                  <ModalDelete
                    title={<FormattedMessage id="NuevaEvaluacion" />}
                    show={showModalEvaluacion}
                    setShow={setShowModalEvaluacion}
                    action={handleNuevaEvaluacion}
                    reset={resetDataNueva}
                    status={stateEvaluacion.statusNueva}
                    estado={stateEvaluacion.dataNueva}
                    loading={stateEvaluacion.loadingNueva}
                    close={closeModalEvaluacion}
                  >
                    {stateEvaluacion.statusNueva === 0 ? (
                      <FormattedMessage id="EstaSeguroRealizarEvaluacion" />
                    ) : stateEvaluacion.dataNueva.ejecucion.estado ? (
                      <div className="container-modal">
                        <p className="message-ok">
                          {stateEvaluacion.dataNueva.ejecucion.mensaje}
                        </p>
                        <Icon name="checkmark" className="status-ok" />
                      </div>
                    ) : (
                      <div className="container-modal">
                        <p className="message-fail">
                          {stateEvaluacion.dataNueva.ejecucion.mensaje}
                        </p>
                        <Image src={ohError} className="status-fail" />
                      </div>
                    )}
                  </ModalDelete>

                  <ModalDelete
                    title={<FormattedMessage id="PublicarEvaluacion" />}
                    show={showModalPublicar}
                    setShow={setShowModalPublicar}
                    action={handlePublicarEvaluacion}
                    reset={resetDataPublicar}
                    status={stateEvaluacion.statusPublicar}
                    estado={stateEvaluacion.dataPublicar}
                    loading={stateEvaluacion.loadingPublicar}
                    close={closeModalPublicar}
                  >
                    {stateEvaluacion.statusPublicar === 0 ? (
                      <FormattedMessage id="EstaSeguroPublicarEvaluacion" />
                    ) : stateEvaluacion.dataPublicar.ejecucion.estado ? (
                      <div className="container-modal">
                        <p className="message-ok">
                          {stateEvaluacion.dataPublicar.ejecucion.mensaje}
                        </p>
                        <Icon name="checkmark" className="status-ok" />
                      </div>
                    ) : (
                      <div className="container-modal">
                        <p className="message-fail">
                          {stateEvaluacion.dataPublicar.ejecucion.mensaje}
                        </p>
                        <Image src={ohError} className="status-fail" />
                      </div>
                    )}
                  </ModalDelete>

                  <ModalDelete
                    title={<FormattedMessage id="ValidarEvaluacion" />}
                    show={showModalValidar}
                    setShow={setShowModalValidar}
                    action={handleValidarEvaluacion}
                    reset={resetDataValidar}
                    status={stateEvaluacion.statusValidar}
                    estado={stateEvaluacion.dataValidar}
                    loading={stateEvaluacion.loadingValidar}
                    close={closeModalValidar}
                  >
                    {stateEvaluacion.statusValidar === 0 ? (
                      <FormattedMessage id="EstaSeguroValidarEvaluacion" />
                    ) : stateEvaluacion.dataValidar.datos.evaluable ? (
                      <div className="container-modal">
                        <p className="message-ok">
                          {stateEvaluacion.dataValidar.ejecucion.mensaje}
                        </p>
                        <Icon name="checkmark" className="status-ok" />
                      </div>
                    ) : (
                      <div className="container-modal">
                        <p className="message-fail">
                          {stateEvaluacion.dataValidar.ejecucion.mensaje}
                        </p>
                        <Image src={ohError} className="status-fail" />
                      </div>
                    )}

                    <br />
                    {stateEvaluacion.statusValidar === 200 &&
                      stateEvaluacion.dataValidar.datos.evaluable === false &&
                      stateEvaluacion.dataValidar.datos.observaciones.map(
                        (item, i) => {
                          return (
                            <p key={i} style={{ marginTop: "5px" }}>
                              -{item}
                            </p>
                          );
                        }
                      )}
                  </ModalDelete>
                </>
              ) : (
                <div>{stateEvaluacion.data.ejecucion.mensaje}</div>
              )}
            </div>
          )}
        </div>
      </>
    </Container>
  );
};

export default Evaluacion;
