import { Button, Icon, Image, Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { resetUploadFile, uploadFile } from "./../../actions/uploadFile/index";
import { useParams } from "react-router-dom";
import Loading from "./../Loading/index";
import ohError from "./../../assets/icons/ohhh_error.png";
import { FormattedMessage } from "react-intl";

const ModalUpload = (props) => {
  const { show, setShow, title, name, nameDocument } = props;

  const onClose = () => setShow(false);

  const dispatch = useDispatch();
  const state = useSelector((state) => state.uploadFile);

  const reload = () => {
    if (state.statusFile === 200) {
      window.location.reload();
    }
  };

  const { id } = useParams();

  const handleUpload = (e) => {
    e.preventDefault();
    dispatch(
      uploadFile({
        deposito_id: id,
        tipo_documento: name,
      })
    );
  };

  const reset = () => {
    dispatch(resetUploadFile());
  };

  return (
    <Modal className="basic-modal" open={show} onClose={onClose}>
      <Modal.Header>
        <span>{`${title} a ${nameDocument}`}</span>
        <Icon
          name="close"
          onClick={() => {
            onClose();
            reload();
            reset();
          }}
        />
      </Modal.Header>
      <Modal.Content>
        {state.loadingFile ? (
          <Loading />
        ) : (
          state.statusFile === 0 && (
            <input type="file" id="recfile" name="recfile" />
          )
        )}
        {state.statusFile === 200 && (
          <div className="container-modal">
            <p className="message-ok">{state.dataFile.ejecucion.mensaje}</p>
            <Icon name="checkmark" className="status-ok" />
          </div>
        )}
        {state.statusFile === 501 && (
          <div className="container-modal">
            <p className="message-fail">
              <FormattedMessage id="ErrorSubida" />
            </p>
            <Image src={ohError} className="status-fail" />
          </div>
        )}

        {state.statusFile === 500 && (
          <div className="container-modal">
            <p className="message-fail">
              <FormattedMessage id="NoSeleccionoArchivo" />
            </p>
            <Image src={ohError} className="status-fail" />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        {state.statusFile === 200 || state.statusFile === 500 ? (
          <Button
            color="blue"
            content={<FormattedMessage id="Cerrar" />}
            onClick={() => {
              onClose();
              reload();
              reset();
            }}
          />
        ) : (
          <>
            <Button
              color="red"
              onClick={() => {
                onClose();
                reload();
                reset();
              }}
            >
              No
            </Button>
            <Button
              content={<FormattedMessage id="Subir" />}
              color="blue"
              onClick={handleUpload}
              type="submit"
            />
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ModalUpload;
