import initialState from "./initialState";
import {
  RESET_EMPRESAS_CON_FAENA,
  GET_EMPRESAS_CON_FAENA_INIT,
  GET_EMPRESAS_CON_FAENA_SUCCESS,
  GET_EMPRESAS_CON_FAENA_FAILURE,
  RESET_COMPLETE_REGISTRATION,
  COMPLETE_REGISTRATION_INIT,
  COMPLETE_REGISTRATION_SUCCESS,
  COMPLETE_REGISTRATION_FAILURE,
} from "../../actions/enrollment/types";

export default function enrollment(state = initialState, action) {
  switch (action.type) {
    case RESET_EMPRESAS_CON_FAENA:
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case GET_EMPRESAS_CON_FAENA_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPRESAS_CON_FAENA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_EMPRESAS_CON_FAENA_FAILURE:
      return {
        data: {},
        loading: false,
        status: 501,
      };

    case RESET_COMPLETE_REGISTRATION:
      return {
        ...state,
        dataRegister: {},
        loadingRegister: false,
        statusRegister: 0,
      };
    case COMPLETE_REGISTRATION_INIT:
      return {
        ...state,
        loadingRegister: true,
      };
    case COMPLETE_REGISTRATION_SUCCESS:
      return {
        ...state,
        dataRegister: action.payload.data,
        loadingRegister: false,
        statusRegister: action.payload.status,
      };
    case COMPLETE_REGISTRATION_FAILURE:
      return {
        ...state,
        dataRegister: {},
        loadingRegister: false,
        statusRegister: 501,
      };

    default:
      return state;
  }
}
