import { format, isValid } from "date-fns";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Image,
  Input,
  Table,
  TextArea,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";

import {
  updateParams,
  resetUpdateParams,
  getHistoricParams,
  deleteHistoricParams,
  resetDeleteHistoricParams,
  resetHistoricParams,
} from "../../../../actions/params";

import historic from "../../../../assets/icons/icono_historia.png";
import ModalActions from "../../../Modal/ModalActions";
import ohError from "../../../../assets/icons/ohhh_error.png";
import Divider from "../../../Divider";
import Loading from "../../../Loading";
import { parse } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";

const NewGlAltura = ({ stateParams }) => {
  const [formTable, setFormTable] = useState([]);
  const [lastFormTable, setLastFormTable] = useState([]);

  const dispatch = useDispatch();

  let servicio = localStorage.getItem("servicio");

  const [form, setForm] = useState({});
  const [checked, setChecked] = useState({});
  const [dataId, setDataId] = useState({});

  const [sistema, setSistema] = useState("");
  const [indice, setIndice] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalHistoric, setShowModalHistoric] = useState(false);

  const [nroFilas, setNroFilas] = useState([]);
  const [banco, setBanco] = useState(false);

  const [id, setId] = useState("");

  const onShowModal = (e, i) => {
    setShowModal(true);

    setSistema(e.sistema);
    setIndice(i + 1);
  };
  const onShowModalDelete = (id) => {
    setId(id);
    setShowModalDelete(true);
  };

  const onShowModalHistoric = (e) => {
    setShowModalHistoric(true);

    if (e) {
      dispatch(
        getHistoricParams({
          periodo_id: stateParams.dataParams.datos.periodo.id,
          sistema: e.sistema,
        })
      );
    }
  };

  const handleChange = (event, index) => {
    const arr = [...nroFilas];
    arr[index].nro_filas = event.target.value;
    setBanco(arr[index].banco);
    setNroFilas(arr);
  };

  const [datos, setDatos] = useState(true);

  useEffect(() => {
    if (
      stateParams.dataParams.datos.datos.parte2.gl.gl_altura.sin_datos === false
    ) {
      setDatos(false);
    }
  }, [stateParams.dataParams]);

  //part 1,
  useEffect(() => {
    const arrForm = [];

    stateParams.dataParams.datos.datos.parte2.gl.gl_altura.tablas.forEach(
      (e) => {
        arrForm.push({
          nro_filas: e.parte_variable.length,
          banco: e.subtitulo_banco,
        });
      }
    );

    setNroFilas(arrForm);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetHistoricParams());
    };
  }, [dispatch]);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");

    const updatedFormTable =
      stateParams.dataParams.datos.datos.parte2.gl.gl_altura.tablas.flatMap(
        (e) => {
          return e.parte_variable.map((item, i) => {
            const col5 = isValid(parse(formattedDate, "dd-MM-yyyy", new Date()))
              ? formattedDate
              : "";
            return {
              col1: i + 1,
              col2: item.col2 || "",
              col3: item.col3 || "",
              col4: item.col4 || "",
              col5: col5,
              key: `${e.subtitulo_banco}-${i}`,
            };
          });
        }
      );

    setFormTable(updatedFormTable);
    // eslint-disable-next-line
  }, [stateParams.dataParams.datos.datos.parte2.gl.gl_altura.tablas]);

  const handleClickUpdate = () => {
    const groupedData = formTable.reduce((result, current) => {
      const key = current.key;
      const match = key.match(/Banco #(\d+)-(\d+)/);
      if (match) {
        const groupKey = match[1];
        if (!result[groupKey]) {
          result[groupKey] = [];
        }
        // Aquí se crea un objeto sin incluir la clave "key"
        const dataWithoutKey = { ...current };
        delete dataWithoutKey.key;
        result[groupKey].push(dataWithoutKey);
      }
      return result;
    }, {});

    if (groupedData[indice]) {
      // se transforma de objeto a array sin los indices
      let newParteVariable = groupedData[indice].map((obj) =>
        Object.values(obj)
      );
      dispatch(
        updateParams({
          periodo_id: stateParams.dataParams.datos.periodo.id,
          datos_recopilados: [
            {
              sistema: sistema,
              opcion_informacion_id: dataId[indice - 1],
              documentos_que_respaldan:
                checked[indice - 1] !== undefined ? [checked[indice - 1]] : [],
              comentario:
                form[indice - 1] !== undefined ? form[indice - 1] : "",
              parte_variable: newParteVariable,
            },
          ],
        })
      );
    } else {
      dispatch(
        updateParams({
          periodo_id: stateParams.dataParams.datos.periodo.id,
          datos_recopilados: [
            {
              sistema: sistema,
              opcion_informacion_id: dataId[indice - 1],
              documentos_que_respaldan:
                checked[indice - 1] !== undefined ? [checked[indice - 1]] : [],
              comentario:
                form[indice - 1] !== undefined ? form[indice - 1] : "",
              parte_variable: [],
            },
          ],
        })
      );
    }
  };

  const resetData = () => {
    dispatch(resetUpdateParams());
  };

  const handleDelete = () => {
    dispatch(
      deleteHistoricParams({
        id: id,
        sistema: {
          modelo:
            stateParams.dataParams.datos.datos.parte2.gl.gl_altura.tablas[0]
              .sistema.modelo,
          corresponde_a:
            stateParams.dataParams.datos.datos.parte2.gl.gl_altura.tablas[0]
              .sistema.corresponde_a,
        },
      })
    );
  };

  useEffect(() => {
    if (stateParams.dataHistoricParams) {
      if (stateParams.dataHistoricParams.dataHistoricParams) {
        if (stateParams.dataHistoricParams.datos.length < 0) {
          setShowModalHistoric(false);
        }
      }
    }
  }, [stateParams]);

  const resetDataDelete = () => {
    dispatch(resetDeleteHistoricParams());
    dispatch(
      getHistoricParams({
        periodo_id: stateParams.dataParams.datos.periodo.id,
        sistema: {
          modelo:
            stateParams.dataParams.datos.datos.parte2.gl.gl_altura.tablas[0]
              .sistema.modelo,
          corresponde_a:
            stateParams.dataParams.datos.datos.parte2.gl.gl_altura.tablas[0]
              .sistema.corresponde_a,
        },
      })
    );
  };

  const resetDataHistoric = () => {
    dispatch(resetHistoricParams());
  };

  const handleChangeTable = (index, col, e) => {
    let newFormTable = [...formTable];
    newFormTable[index][col] = e.target.value;
    setFormTable(newFormTable);
  };

  const handleDateChange = (date, index) => {
    const formattedDate = format(date, "dd-MM-yyyy");

    const newFormTable = [...formTable];
    newFormTable[index].col5 = formattedDate;
    setFormTable(newFormTable);
  };

  const dataFinal = useMemo(() => {
    const arrFinal = [];

    nroFilas.forEach((item) => {
      let arrMemo = formTable
        .filter((o) => o.key.includes(item.banco))
        .slice(0, item.nro_filas);

      if (banco === item.banco) {
        for (let e = arrMemo.length; e < item.nro_filas; e++) {
          arrMemo.push({
            col1: e + 1,
            col2: "",
            col3: "",
            col4: "",
            col5: "",
            key: `${item.banco}-${e}`,
          });
        }
      }

      arrFinal.push(arrMemo);
    });
    setLastFormTable(arrFinal.flat());
    return arrFinal.flat();
  }, [nroFilas, formTable, banco]);

  useEffect(() => {
    if (lastFormTable.length !== formTable.length) {
      setFormTable(lastFormTable);
    }
    // eslint-disable-next-line
  }, [dataFinal]);

  return (
    <div>
      <h1>{stateParams.dataParams.datos.datos.parte2.gl.gl_altura.label}</h1>
      {stateParams.dataParams.datos.datos.parte2.gl.gl_altura.tablas.map(
        (e, i) => {
          const renderCeldas = () => {
            const arr = [];
            const number = nroFilas[i].nro_filas;
            for (let i1 = 0; i1 < number; i1++) {
              let index = dataFinal.findIndex(
                (item) => item.key === `${e.subtitulo_banco}-${i1}`
              );
              if (index >= 0) {
                arr.push(
                  <Table.Row key={i1} className="table__matrix5__row-body">
                    <Table.Cell colSpan={115}>{i1 + 1}</Table.Cell>
                    <Table.Cell colSpan={115}>
                      <Input
                        name={`valor_${index}_col2`}
                        type="text"
                        onChange={(e) => {
                          handleChangeTable(index, "col2", e);
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={dataFinal[index]?.col2 || ""}
                        id={index}
                        disabled={servicio === "true" ? true : false}
                      />
                    </Table.Cell>
                    <Table.Cell colSpan={115}>
                      <Input
                        name={`valor_${index}_col3`}
                        type="text"
                        onChange={(e) => {
                          handleChangeTable(index, "col3", e);
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={dataFinal[index]?.col3 || ""}
                        id={index}
                        disabled={servicio === "true" ? true : false}
                      />
                    </Table.Cell>

                    <Table.Cell colSpan={115}>
                      <Input
                        name={`valor_${index}_col4`}
                        type="number"
                        onChange={(e) => {
                          handleChangeTable(index, "col4", e);
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={dataFinal[index]?.col4 || ""}
                        id={index}
                        disabled={servicio === "true" ? true : false}
                      />
                    </Table.Cell>

                    <Table.Cell colSpan={115}>
                      <DatePicker
                        placeholderText="DD-MM-YYYY"
                        dateFormat="dd-MM-yyyy"
                        selected={
                          dataFinal[index]?.col5
                            ? parse(
                                dataFinal[index]?.col5,
                                "dd-MM-yyyy",
                                new Date()
                              )
                            : null
                        }
                        onChange={(date) => handleDateChange(date, index)}
                        className="datepicker-controles"
                        disabled={servicio === "true"}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              }
            }

            return arr;
          };

          return (
            <div key={i}>
              <div>
                <h3>{e.subtitulo_banco}</h3>
                <hr />

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <DropdownComponent
                    dropdown={e.dropdown}
                    servicio={servicio}
                    datos={datos}
                    dataId={dataId}
                    setDataId={setDataId}
                    e={e}
                    i={i}
                  />
                  {stateParams.dataParams.datos.datos.parte2.gl.gl_altura
                    .tablas[i].tiene_historia && (
                    <Image
                      src={historic}
                      className="icon-historic"
                      onClick={() => onShowModalHistoric(e)}
                    />
                  )}
                </div>

                {dataId[i] === 1 && (
                  <>
                    <div>
                      <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                        <FormattedMessage id="CantidadPuntosControl" />
                      </p>
                      <Input
                        name={`nro_filas`}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => handleChange(e, i)}
                        maxLength="2"
                        value={nroFilas[i]?.nro_filas}
                        disabled={servicio === "true" ? true : false}
                      />
                    </div>

                    <Table celled fixed className="table__matrix5">
                      <Table.Header className="table__matrix5__row-header">
                        {e.datos_duro?.linea1 && (
                          <>
                            <Table.Row>
                              {Object.keys(e.datos_duro.linea1).map(
                                // eslint-disable-next-line array-callback-return
                                (item, i) => {
                                  if (item.includes("col")) {
                                    return (
                                      <Table.HeaderCell
                                        colSpan={`${
                                          115 * e.datos_duro.linea1[item].ancho
                                        }`}
                                        key={`${e.datos_duro.linea1[item].label}-linea1`}
                                      >
                                        {e.datos_duro.linea1[item].label}
                                      </Table.HeaderCell>
                                    );
                                  }
                                }
                              )}
                            </Table.Row>

                            <Table.Row>
                              {Object.keys(e.datos_duro.linea2).map(
                                // eslint-disable-next-line array-callback-return
                                (item, i) => {
                                  if (item.includes("col")) {
                                    return (
                                      <Table.HeaderCell
                                        colSpan={`${
                                          115 * e.datos_duro.linea2[item].ancho
                                        }`}
                                        key={`${e.datos_duro.linea2[item].label}-linea1`}
                                      >
                                        {e.datos_duro.linea2[item].label}
                                      </Table.HeaderCell>
                                    );
                                  }
                                }
                              )}
                            </Table.Row>
                          </>
                        )}
                      </Table.Header>

                      <Table.Body>{renderCeldas()}</Table.Body>
                    </Table>

                    <CheckboxComponent
                      checkbox={e.documentos_que_respaldan}
                      servicio={servicio}
                      datos={datos}
                      checked={checked}
                      setChecked={setChecked}
                      i={i}
                    />
                  </>
                )}

                {(dataId[i] === 1 || dataId[i] === 2 || dataId[i] === 3) && (
                  <>
                    <ComentarioComponent
                      comentario={e.comentario}
                      servicio={servicio}
                      datos={datos}
                      form={form}
                      setForm={setForm}
                      i={i}
                    />

                    {servicio === "false" && (
                      <div
                        className="btn-update-matrix1"
                        style={{ marginTop: "15px" }}
                      >
                        <Button onClick={() => onShowModal(e, i)}>
                          <FormattedMessage id="GuardarMayus" />
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        }
      )}

      <ModalActions
        title={<FormattedMessage id="ActualizarAlturaLocal" />}
        show={showModal}
        setShow={setShowModal}
        status={stateParams.statusUpdateParams}
        action={handleClickUpdate}
        reset={resetData}
        deleteAction={"Delete"}
        estado={stateParams.dataUpdateParams}
        loading={stateParams.loadingParams}
      >
        {stateParams.statusUpdateParams === 0 ? (
          <FormattedMessage id="EstaSeguroActualizarInformacion" />
        ) : stateParams.dataUpdateParams.ejecucion.estado ? (
          <div className="container-modal">
            <p className="message-ok">
              {stateParams.dataUpdateParams.ejecucion.mensaje}
            </p>
            <Icon name="checkmark" className="status-ok" />
          </div>
        ) : (
          <div className="container-modal">
            <p className="message-fail">
              {stateParams.dataUpdateParams.ejecucion.mensaje}
            </p>
            <Image src={ohError} className="status-fail" />
          </div>
        )}
      </ModalActions>

      <ModalActions
        title={stateParams.dataParams.datos.datos.parte2.gl.gl_altura.label}
        show={showModalHistoric}
        setShow={setShowModalHistoric}
        status={stateParams.statusHistoricParams}
        action={() => {}}
        reset={resetDataHistoric}
        estado={stateParams.dataHistoricParams}
        loading={stateParams.loadingHistoricParams}
        matricesOk={true}
      >
        {stateParams.loadingHistoricParams ? (
          <Loading />
        ) : (
          stateParams.dataHistoricParams.ejecucion.estado && (
            <div
              style={{
                maxHeight: "30vh",
                minHeight: "30vh",
                overflowY: "scroll",
              }}
            >
              <Divider />
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="PuntoControl" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="UTMEste" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="UTMNorte" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="FechaControl" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="Valor" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="CreadoPor" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="Eliminar" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {stateParams.dataHistoricParams.datos.map((e, i) => (
                  <Table.Body key={i}>
                    <Table.Row>
                      <Table.Cell>{e[0]}</Table.Cell>
                      <Table.Cell>{e[1]}</Table.Cell>
                      <Table.Cell>{e[2]}</Table.Cell>
                      <Table.Cell>{e[3]}</Table.Cell>
                      <Table.Cell>{e[4]}</Table.Cell>
                      <Table.Cell>{e[5]}</Table.Cell>
                      <Table.Cell>{e[7]}</Table.Cell>
                      <Table.Cell>
                        <Icon
                          name="trash"
                          style={{
                            cursor: "pointer",
                            marginRight: "15px",
                          }}
                          onClick={() => onShowModalDelete(e[0])}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                ))}
              </Table>
            </div>
          )
        )}
      </ModalActions>

      <ModalActions
        title={<FormattedMessage id="EliminarRegistroHistorico" />}
        show={showModalDelete}
        setShow={setShowModalDelete}
        status={stateParams.statusDelete}
        action={handleDelete}
        reset={resetDataDelete}
        estado={stateParams.dataDelete}
        deleteAction={"Delete"}
        loading={stateParams.loadingDelete}
        matricesOk={true}
      >
        {stateParams.statusDelete === 0 ? (
          <FormattedMessage id="EstaSeguroEliminarRegistroHistorico" />
        ) : stateParams.dataDelete.ejecucion.estado ? (
          <div className="container-modal">
            <p className="message-ok">
              {stateParams.dataDelete.ejecucion.mensaje}
            </p>
            <Icon name="checkmark" className="status-ok" />
          </div>
        ) : (
          <div className="container-modal">
            <p className="message-fail">
              {stateParams.dataDelete.ejecucion.mensaje}
            </p>
            <Image src={ohError} className="status-fail" />
          </div>
        )}
      </ModalActions>
    </div>
  );
};

const DropdownComponent = ({
  dropdown,
  servicio,
  datos,
  dataId,
  setDataId,
  i,
}) => {
  const [data, setData] = useState([]);

  const handleChange = (e, selectedOption, i) => {
    setDataId((prevValue) => ({
      ...prevValue,
      [i]: selectedOption,
    }));
  };

  useEffect(() => {
    const arr = [];

    dropdown.forEach((item) => {
      arr.push({
        key: item.id,
        value: item.id,
        text: item.glosa,
        seleccionado: item.seleccionado.toString(),
      });
    });

    setData(arr);
    // eslint-disable-next-line
  }, [dropdown]);

  useEffect(() => {
    if (datos === false) {
      if (data.length > 0) {
        const dataSelectedAltura = data.find(
          (element) => element.seleccionado === "true"
        );

        if (dataSelectedAltura) {
          setDataId((prevValue) => ({
            ...prevValue,
            [i]: dataSelectedAltura.value,
          }));
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const intl = useIntl();
  const placeholderSeleccione = intl.formatMessage({ id: "Seleccione" });

  return (
    <Dropdown
      key={i}
      placeholder={placeholderSeleccione}
      selection
      options={data}
      value={dataId[i]}
      onChange={(e, { value }) => handleChange(e, value, i)}
      disabled={servicio === "true" ? true : false}
    />
  );
};

const CheckboxComponent = ({
  checkbox,
  servicio,
  datos,
  checked,
  setChecked,
  i,
}) => {
  const handleCheckboxChange = (item) => {
    setChecked((prevState) => {
      if (prevState[i] === item.clave) {
        const { [i]: _, ...rest } = prevState;
        return rest;
      } else {
        return {
          ...prevState,
          [i]: item.clave,
        };
      }
    });
  };

  useEffect(() => {
    if (datos === false) {
      setChecked((prevState) => {
        const newState = { ...prevState };
        newState[i] = checkbox[0].seleccionado ? checkbox[0].clave : "";
        return newState;
      });
    }
    // eslint-disable-next-line
  }, [datos, setChecked]);

  return (
    <>
      <p style={{ marginTop: "10px" }}>
        <FormattedMessage id="DocumentosRespaldan" />
      </p>
      <div className="matrix2__documentos_respaldo">
        {checkbox.map((item) => {
          return (
            <Checkbox
              key={item.clave}
              label={item.label}
              checked={checked.hasOwnProperty(i) && checked[i] === item.clave}
              onChange={() => handleCheckboxChange(item)}
              disabled={servicio === "true" ? true : false}
            />
          );
        })}
      </div>
    </>
  );
};

const ComentarioComponent = ({
  comentario,
  servicio,
  form,
  setForm,
  i,
  datos,
}) => {
  const handleChange = (e) => {
    const value = e.target.value;
    setForm((prevState) => {
      if (value.trim() === "") {
        const { [i]: _, ...rest } = prevState;
        return rest;
      } else {
        return {
          ...prevState,
          [i]: value,
        };
      }
    });
  };

  useEffect(() => {
    if (datos === false) {
      setForm((prevState) => {
        const newState = { ...prevState };
        newState[i] = comentario !== undefined ? comentario : "";
        return newState;
      });
    }
    // eslint-disable-next-line
  }, [datos, setForm]);

  const intl = useIntl();
  const placeholderComentario = intl.formatMessage({ id: "Comentario" });

  return (
    <div>
      <TextArea
        placeholder={placeholderComentario}
        name="comentario"
        onChange={handleChange}
        style={{ width: "100%", marginTop: "20px" }}
        value={form.hasOwnProperty(i) ? form[i] : ""}
        disabled={servicio === "true"}
        maxLength="1000"
      />
    </div>
  );
};

export default NewGlAltura;
