import {
  RESET_RECOVER_PASSWORD,
  RECOVER_PASSWORD_INIT,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILURE,
  RESET_CHANGE_PASSWORD,
  CHANGE_PASSWORD_INIT,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "../../actions/password/types";
import initialState from "./initialState";

export default function password(state = initialState, action) {
  switch (action.type) {
    case RESET_RECOVER_PASSWORD:
      return {
        ...state,
        dataRecover: {},
        loadingRecover: false,
        statusRecover: 0,
      };
    case RECOVER_PASSWORD_INIT:
      return {
        ...state,
        loadingRecover: true,
      };
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        dataRecover: action.payload.data,
        loadingRecover: false,
        statusRecover: action.payload.status,
      };
    case RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        dataRecover: {},
        loadingRecover: false,
        statusRecover: 501,
      };
    // CHANGE PASSWORD
    case RESET_CHANGE_PASSWORD:
      return {
        ...state,
        dataChange: {},
        loadingChange: false,
        statusChange: 0,
      };
    case CHANGE_PASSWORD_INIT:
      return {
        ...state,
        loadingChange: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        dataChange: action.payload.data,
        loadingChange: false,
        statusChange: action.payload.status,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        dataChange: {},
        loadingChange: false,
        statusChange: 501,
      };

    default:
      return state;
  }
}
