import {
  RESET_ALL_REGIONES,
  GET_ALL_REGIONES_INIT,
  GET_ALL_REGIONES_SUCCESS,
  GET_ALL_REGIONES_FAILURE,
  RESET_ALL_COMUNAS,
  GET_ALL_COMUNAS_INIT,
  GET_ALL_COMUNAS_SUCCESS,
  GET_ALL_COMUNAS_FAILURE,
  RESET_ALL_PROVIDENCIAS,
  GET_ALL_PROVIDENCIAS_INIT,
  GET_ALL_PROVIDENCIAS_SUCCESS,
  GET_ALL_PROVIDENCIAS_FAILURE,
} from "./types";
import API from "./api";

export function resetAllRegiones() {
  return {
    type: RESET_ALL_REGIONES,
  };
}
function getAllRegionesInit() {
  return {
    type: GET_ALL_REGIONES_INIT,
  };
}
function getAllRegionesSuccess(data) {
  return {
    type: GET_ALL_REGIONES_SUCCESS,
    payload: data,
  };
}
function getAllRegionesFailure(error) {
  return {
    type: GET_ALL_REGIONES_FAILURE,
    payload: error,
  };
}
export function getAllRegiones(data) {
  return async (dispatch) => {
    dispatch(getAllRegionesInit());
    try {
      const resp = await API.data.getAllRegiones(data);
      dispatch(getAllRegionesSuccess(resp));
    } catch (error) {
      dispatch(getAllRegionesFailure(error));
    }
  };
}

export function resetAllProvidencias() {
  return {
    type: RESET_ALL_PROVIDENCIAS,
  };
}
function getAllProvidenciasInit() {
  return {
    type: GET_ALL_PROVIDENCIAS_INIT,
  };
}
function getAllProvidenciasSuccess(data) {
  return {
    type: GET_ALL_PROVIDENCIAS_SUCCESS,
    payload: data,
  };
}
function getAllProvidenciasFailure(error) {
  return {
    type: GET_ALL_PROVIDENCIAS_FAILURE,
    payload: error,
  };
}
export function getAllProvidencias(data) {
  return async (dispatch) => {
    dispatch(getAllProvidenciasInit());
    try {
      const resp = await API.data.getAllProvidencias(data);
      dispatch(getAllProvidenciasSuccess(resp));
    } catch (error) {
      dispatch(getAllProvidenciasFailure(error));
    }
  };
}

export function resetAllComunas() {
  return {
    type: RESET_ALL_COMUNAS,
  };
}
function getAllComunasInit() {
  return {
    type: GET_ALL_COMUNAS_INIT,
  };
}
function getAllComunasSuccess(data) {
  return {
    type: GET_ALL_COMUNAS_SUCCESS,
    payload: data,
  };
}
function getAllComunasFailure(error) {
  return {
    type: GET_ALL_COMUNAS_FAILURE,
    payload: error,
  };
}
export function getAllComunas(data) {
  return async (dispatch) => {
    dispatch(getAllComunasInit());
    try {
      const resp = await API.data.getAllComunas(data);
      dispatch(getAllComunasSuccess(resp));
    } catch (error) {
      dispatch(getAllComunasFailure(error));
    }
  };
}
