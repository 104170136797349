import {
  RESET_GET_LIST_RESOURCE,
  GET_LIST_RESOURCE_INIT,
  GET_LIST_RESOURCE_SUCCESS,
  GET_LIST_RESOURCE_FAILURE,
} from "./types";
import API from "./api";

export function resetListResource() {
  return {
    type: RESET_GET_LIST_RESOURCE,
  };
}
function getListResourceInit() {
  return {
    type: GET_LIST_RESOURCE_INIT,
  };
}
function getListResourceSuccess(data) {
  return {
    type: GET_LIST_RESOURCE_SUCCESS,
    payload: data,
  };
}
function getListResourceFailure(error) {
  return {
    type: GET_LIST_RESOURCE_FAILURE,
    payload: error,
  };
}
export function getListResource(data) {
  return async (dispatch) => {
    dispatch(getListResourceInit());
    try {
      const resp = await API.data.getListResources(data);
      dispatch(getListResourceSuccess(resp));
    } catch (error) {
      dispatch(getListResourceFailure(error));
    }
  };
}
