import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import DividerGrey from "../../components/DividerGrey";
import Loading from "../../components/Loading";
import Expired from "../../components/Expired";
import ButtonsDashboard from "./../../components/ButtonsDashboard/index";

import { Button, Container, Form, Image, Icon } from "semantic-ui-react";

import ModalActions from "./../../components/Modal/ModalActions";
import ohError from "../../assets/icons/ohhh_error.png";
import closeBtn from "../../assets/icons/icono_close-grey.png";

import { getDataCompany } from "../../actions/company";
import { DropdownAllRegion } from "../../components/Dropdown/DropdownAllRegion";
import { DropdownAllProvidencia } from "../../components/Dropdown/DropdownAllProvincia";
import { DropdownAllComuna } from "../../components/Dropdown/DropdownAllComuna";
import {
  resetUpdateCompany,
  updateCompany,
} from "./../../actions/update_company/index";
import { FormattedMessage, useIntl } from "react-intl";

const selectionEstado = [
  {
    key: 1,
    value: true,
    text: <FormattedMessage id="ActivaMayus" />,
  },
  {
    key: 0,
    value: false,
    text: <FormattedMessage id="NoActivaMayus" />,
  },
];

const EditCompany = () => {
  const state = useSelector((state) => state.company);
  const stateUpdate = useSelector((state) => state.updateCompany);

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dataForm, setDataForm] = useState("");

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  useEffect(() => {
    dispatch(getDataCompany({ empresa_id: id }));
    // eslint-disable-next-line
  }, [dispatch]);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      setDataForm(formData);
    },
  });

  //   CARGAR DATA
  useEffect(() => {
    async function setInitialValues() {
      if (state.statusCompany === 200) {
        await formik.setValues({
          razon_social:
            state.dataCompany.datos.razon_social !== null
              ? state.dataCompany.datos.razon_social
              : "",
          calle:
            state.dataCompany.datos.calle !== null
              ? state.dataCompany.datos.calle
              : "",
          nombre_representante:
            state.dataCompany.datos.nombre_representante !== null
              ? state.dataCompany.datos.nombre_representante
              : "",
          email_representante:
            state.dataCompany.datos.email_representante !== null
              ? state.dataCompany.datos.email_representante
              : "",
          telefono_representante:
            state.dataCompany.datos.telefono_representante !== null
              ? state.dataCompany.datos.telefono_representante
              : "",
          activa:
            state.dataCompany.datos.activa !== null
              ? state.dataCompany.datos.activa
              : "",

          comuna_id: state.dataCompany.datos.comuna_id,
          region_id: state.dataCompany.datos.region_id,
          providencia_id: state.dataCompany.datos.provincia_id,
        });
      }
    }
    setInitialValues();

    // eslint-disable-next-line
  }, [state.statusCompany]);

  const handleUpdate = () => {
    dispatch(
      updateCompany({
        razon_social: dataForm.razon_social,
        calle: dataForm.calle,
        nombre_representante: dataForm.nombre_representante,
        email_representante: dataForm.email_representante,
        telefono_representante: dataForm.telefono_representante,
        activa: dataForm.activa,
        comuna_id: dataForm.comuna_id,
        id: id,
      })
    );
  };

  const resetData = () => {
    dispatch(resetUpdateCompany());
  };

  const intl = useIntl();
  const placeholderNombreEmpresa = intl.formatMessage({
    id: "EscribaNombreEmpresa",
  });
  const placeholderCalleEmpresa = intl.formatMessage({
    id: "EscribaCalleEmpresa",
  });
  const placeholderNombreRepresentante = intl.formatMessage({
    id: "EscribaNombreRepresentanteLegal",
  });
  const placeholderCorreoRepresentante = intl.formatMessage({
    id: "EscribaCorreoRepresentanteLegal",
  });
  const placeholderTelefonoRepresentante = intl.formatMessage({
    id: "EscribaTelefonoRepresentanteLegal",
  });
  const placeholderEstado = intl.formatMessage({ id: "EstadoMayus" });

  if (
    /* When the token is expired*/
    state.statusCompany === 501
  ) {
    return <Expired />;
  }

  return (
    <Container>
      <DividerGrey />

      <div style={{ display: "flex" }}>
        <ButtonsDashboard id={id} />

        {location.pathname === `/dashboard/edit-company/${id}` &&
        state.loadingCompany ? (
          <Loading />
        ) : (
          <Container className="card-faena">
            <Form onSubmit={formik.handleSubmit}>
              <div className="container-card-add-faena">
                <h3 className="card-faena-title">
                  <FormattedMessage id="EditarEmpresa" />
                </h3>
                <Image
                  src={closeBtn}
                  alt="Cerrar"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
              </div>

              <Form.Input
                name="razon_social"
                label={<FormattedMessage id="NombreEmpresa" />}
                type="text"
                placeholder={placeholderNombreEmpresa}
                onChange={formik.handleChange}
                error={formik.errors.razon_social}
                value={formik.values.razon_social}
              />

              <Form.Group widths="equal">
                <DropdownAllRegion
                  formik={formik}
                  dataChore={state.dataCompany.datos.region_id}
                  updateChore={true}
                />

                <DropdownAllProvidencia
                  formik={formik}
                  region_id={formik.values.region_id}
                  dataChore={state.dataCompany.datos.provincia_id}
                  updateChore={true}
                />
              </Form.Group>

              <Form.Group widths="equal">
                <DropdownAllComuna
                  formik={formik}
                  providencia_id={formik.values.providencia_id}
                  dataChore={state.dataCompany.datos.comuna_id}
                  updateChore={true}
                />
              </Form.Group>

              <Form.Input
                name="calle"
                label={<FormattedMessage id="CalleMayus" />}
                type="text"
                placeholder={placeholderCalleEmpresa}
                onChange={formik.handleChange}
                error={formik.errors.calle}
                value={formik.values.calle}
              />
              <Form.Input
                name="nombre_representante"
                label={<FormattedMessage id="NombreRepresentanteLegalMayus" />}
                type="text"
                placeholder={placeholderNombreRepresentante}
                onChange={formik.handleChange}
                error={formik.errors.nombre_representante}
                value={formik.values.nombre_representante}
              />
              <Form.Input
                name="email_representante"
                label={<FormattedMessage id="CorreoRepresentanteLegalMayus" />}
                type="text"
                placeholder={placeholderCorreoRepresentante}
                onChange={formik.handleChange}
                error={formik.errors.email_representante}
                value={formik.values.email_representante}
              />
              <Form.Input
                name="telefono_representante"
                label={
                  <FormattedMessage id="TelefonoRepresentanteLegalMayus" />
                }
                type="number"
                placeholder={placeholderTelefonoRepresentante}
                onChange={formik.handleChange}
                error={formik.errors.telefono_representante}
                value={formik.values.telefono_representante}
              />

              <Form.Dropdown
                placeholder={placeholderEstado}
                label={<FormattedMessage id="PlanCierreEstadoMayus" />}
                selection
                options={selectionEstado}
                value={formik.values.activa}
                onChange={(_, data) =>
                  formik.setFieldValue("activa", data.value)
                }
              />

              <div>
                <Button
                  type="submit"
                  onClick={onShowModal}
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  <FormattedMessage id="ActualizarMayus" />
                </Button>
              </div>

              <ModalActions
                title={<FormattedMessage id="ActualizarEmpresa" />}
                show={showModal}
                setShow={setShowModal}
                status={stateUpdate.statusUpdate}
                action={handleUpdate}
                reset={resetData}
                estado={stateUpdate.dataUpdate}
              >
                {stateUpdate.statusUpdate === 0 ? (
                  <FormattedMessage id="EstaSeguroActualizarEmpresa" />
                ) : stateUpdate.dataUpdate.ejecucion.estado ? (
                  <div className="container-modal">
                    <p className="message-ok">
                      {stateUpdate.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Icon name="checkmark" className="status-ok" />
                  </div>
                ) : (
                  <div className="container-modal">
                    <p className="message-fail">
                      {stateUpdate.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Image src={ohError} className="status-fail" />
                  </div>
                )}
              </ModalActions>
            </Form>
          </Container>
        )}
      </div>
    </Container>
  );
};

function initialValues() {
  return {
    razon_social: "",
    calle: "",
    nombre_representante: "",
    email_representante: "",
    telefono_representante: "",

    region_id: "",
    providencia_id: "",
    comuna_id: "",
    activa: "",
  };
}

function validationSchema() {
  return {
    razon_social: Yup.string().required(true),
    calle: Yup.string().required(true),
    nombre_representante: Yup.string().required(true),
    email_representante: Yup.string().required(true),
    telefono_representante: Yup.number().required(true),
  };
}

export default EditCompany;
