export const RESET_REGIONES = "RESET_REGIONES";
export const GET_REGIONES_INIT = "GET_REGIONES_INIT";
export const GET_REGIONES_SUCCESS = "GET_REGIONES_SUCCESS";
export const GET_REGIONES_FAILURE = "GET_REGIONES_FAILURE";

export const RESET_COMUNAS = "RESET_COMUNAS";
export const GET_COMUNAS_INIT = "GET_COMUNAS_INIT";
export const GET_COMUNAS_SUCCESS = "GET_COMUNAS_SUCCESS";
export const GET_COMUNAS_FAILURE = "GET_COMUNAS_FAILURE";

export const RESET_PROVIDENCIAS = "RESET_PROVIDENCIAS";
export const GET_PROVIDENCIAS_INIT = "GET_PROVIDENCIAS_INIT";
export const GET_PROVIDENCIAS_SUCCESS = "GET_PROVIDENCIAS_SUCCESS";
export const GET_PROVIDENCIAS_FAILURE = "GET_PROVIDENCIAS_FAILURE";
