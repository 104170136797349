import {
  RESET_UPDATE_CHORE,
  UPDATE_CHORE_INIT,
  UPDATE_CHORE_SUCCESS,
  UPDATE_CHORE_FAILURE,
} from "../../actions/update_chore/types";
import initialState from "./initialState";

export default function updateChore(state = initialState, action) {
  switch (action.type) {
    case RESET_UPDATE_CHORE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_CHORE_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_CHORE_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_CHORE_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };

    default:
      return state;
  }
}
