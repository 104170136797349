import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  Dropdown,
  Checkbox,
  TextArea,
  Button,
  Icon,
  Image,
  Table,
  Input,
} from "semantic-ui-react";

import Loading from "../Loading";
import Expired from "./../Expired/index";
import {
  getMatriz2,
  resetGetMatriz2,
  updateMatriz2,
} from "../../actions/matriz2/index";
import ModalActions from "./../Modal/ModalActions";
import { resetUpdateMatriz2 } from "./../../actions/matriz2/index";
import { createDecimalMask, createOneDecimalMask } from "../../helpers";
import MaskedInput from "react-text-mask";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

export function Matrix2({ id }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.matriz2);

  const minero = localStorage.getItem("minero");

  const [error, setError] = useState(false);

  const [form, setForm] = useState({
    valor_muro: "",
    valor_coronamiento: "",
    valor_revancha: "",
    valor_angulo: "",
    comentario: "",
  });

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [datos, setDatos] = useState(false);

  const [dataMuro, setDataMuro] = useState([]);
  const [dataMuroId, setDataMuroId] = useState("");

  const [dataCoronamiento, setDataCoronamiento] = useState([]);
  const [dataCoronamientoId, setDataCoronamientoId] = useState("");

  const [dataRevancha, setDataRevancha] = useState([]);
  const [dataRevanchaId, setDataRevanchaId] = useState("");

  const [dataAngulo, setDataAngulo] = useState([]);
  const [dataAnguloId, setDataAnguloId] = useState("");

  const [newDataMuro, setNewDataMuro] = useState("");
  const [newDataCoronamiento, setNewDataCoronamiento] = useState("");
  const [newDataRevancha, setNewDataRevancha] = useState("");
  const [newDataAngulo, setNewDataAngulo] = useState("");

  const [checkedMuro, setCheckedMuro] = useState([]);
  const [checkedCoronamiento, setCheckedCoronamiento] = useState([]);
  const [checkedRevancha, setCheckedRevancha] = useState([]);
  const [checkedAngulo, setCheckedAngulo] = useState([]);

  useEffect(() => {
    if (
      (form.valor_muro === "" && dataMuroId === 1) ||
      (form.valor_coronamiento === "" && dataCoronamientoId === 1) ||
      (form.valor_revancha === "" && dataRevanchaId === 1) ||
      (form.valor_angulo === "" && dataAnguloId === 1)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [form, dataMuroId, dataCoronamientoId, dataRevanchaId, dataAnguloId]);

  useEffect(() => {
    dispatch(
      getMatriz2({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetMatriz2());
    };
  }, [dispatch]);

  // DATA MURO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.parte1.altura_muro.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataMuro(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckbox = (obj) => {
    const arr = [...checkedMuro];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedMuro(arr);
  };

  // DATA CORONAMIENTO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.parte1.ancho_de_coronamiento.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataCoronamiento(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxCoronamiento = (obj) => {
    const arr = [...checkedCoronamiento];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedCoronamiento(arr);
  };

  // DATA REVANCHA
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.parte1.revancha_operacional.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataRevancha(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxRevancha = (obj) => {
    const arr = [...checkedRevancha];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedRevancha(arr);
  };

  // DATA ANGULO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.parte1.angulo_talud_externo.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataAngulo(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxAngulo = (obj) => {
    const arr = [...checkedAngulo];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedAngulo(arr);
  };

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz2({
        tipo_matriz: state.data.datos.tipo_matriz,
        deposito_id: id,
        parte1: {
          altura_muro: {
            valor: form.valor_muro,
            nivel_msnm: null,
            opcion_informacion_id: dataMuroId,
            documentos_que_respaldan: checkedMuro,
          },
          ancho_de_coronamiento: {
            valor: form.valor_coronamiento,
            opcion_informacion_id: dataCoronamientoId,
            documentos_que_respaldan: checkedCoronamiento,
          },
          revancha_operacional: {
            valor: form.valor_revancha,
            opcion_informacion_id: dataRevanchaId,
            documentos_que_respaldan: checkedRevancha,
          },
          angulo_talud_externo: {
            valor: form.valor_angulo,
            opcion_informacion_id: dataAnguloId,
            documentos_que_respaldan: checkedAngulo,
          },
        },
        comentario: form.comentario,
      })
    );
  };

  const resetData = () => {
    dispatch(resetUpdateMatriz2());
  };
  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setForm({
              valor_muro:
                state.data.datos.datos.parte1.altura_muro.valor.length > 0
                  ? state.data.datos.datos.parte1.altura_muro.valor[0].valor
                  : "",
              valor_coronamiento:
                state.data.datos.datos.parte1.ancho_de_coronamiento.valor
                  .length > 0
                  ? state.data.datos.datos.parte1.ancho_de_coronamiento.valor[0]
                      .valor
                  : "",
              valor_revancha:
                state.data.datos.datos.parte1.revancha_operacional.valor
                  .length > 0
                  ? state.data.datos.datos.parte1.revancha_operacional.valor[0]
                      .valor
                  : "",
              valor_angulo:
                state.data.datos.datos.parte1.angulo_talud_externo.valor
                  .length > 0
                  ? state.data.datos.datos.parte1.angulo_talud_externo.valor[0]
                      .valor
                  : "",

              comentario:
                state.data.datos.datos.comentario.valor !== null
                  ? state.data.datos.datos.comentario.valor
                  : "",
            });
          }
        }
      }
    };
    callsAPI();
  }, [state.data, state.status]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDataMuroId(newDataMuro);
          setDataCoronamientoId(newDataCoronamiento);
          setDataRevanchaId(newDataRevancha);
          setDataAnguloId(newDataAngulo);
        }
      }
    }
    //  eslint-disable-next-line
  }, [newDataMuro, newDataCoronamiento, newDataRevancha, newDataAngulo]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.data.ejecucion, state.status, state.loading]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          if (dataMuro.length > 0) {
            const dataSelectedMuro = dataMuro.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataMuro(dataSelectedMuro.value);
          }

          if (dataCoronamiento.length > 0) {
            const dataSelectedCoronamiento = dataCoronamiento.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataCoronamiento(dataSelectedCoronamiento.value);
          }

          if (dataRevancha.length > 0) {
            const dataSelectedRevancha = dataRevancha.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataRevancha(dataSelectedRevancha.value);
          }

          if (dataAngulo.length > 0) {
            const dataSelectedAngulo = dataAngulo.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataAngulo(dataSelectedAngulo.value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [dataMuro, dataCoronamiento, dataRevancha, dataAngulo]);

  useEffect(() => {
    const arrMuro = [...checkedMuro];
    const arrCoronamiento = [...checkedCoronamiento];
    const arrRevancha = [...checkedRevancha];
    const arrAngulo = [...checkedAngulo];

    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        state.data.datos.datos.parte1.altura_muro.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrMuro.push(e.clave)
        );
        setCheckedMuro(arrMuro);

        state.data.datos.datos.parte1.ancho_de_coronamiento.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrCoronamiento.push(e.clave)
        );
        setCheckedCoronamiento(arrCoronamiento);

        state.data.datos.datos.parte1.revancha_operacional.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrRevancha.push(e.clave)
        );
        setCheckedRevancha(arrRevancha);

        state.data.datos.datos.parte1.angulo_talud_externo.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrAngulo.push(e.clave)
        );
        setCheckedAngulo(arrAngulo);
      }
    }

    // eslint-disable-next-line
  }, [state.status]);

  const intl = useIntl();

  const placeholderAlturaMuro = intl.formatMessage({
    id: "SeleccioneAlturaMuro",
  });
  const placeholderValor = intl.formatMessage({ id: "IngreseValor" });
  const placeholderAnchoCoronamiento = intl.formatMessage({
    id: "SeleccioneAnchoCoronamiento",
  });
  const placeholderRevancha = intl.formatMessage({ id: "SeleccioneRevancha" });
  const placeholderAngulo = intl.formatMessage({ id: "SeleccioneAngulo" });
  const placeholderComentario = intl.formatMessage({
    id: "IngreseComentario",
  });

  /* When the token is expired*/
  if (state.status === 501 || state.status === 401) {
    return <Expired />;
  }

  return (
    <Container>
      {state.loading ? (
        <Loading />
      ) : (
        <Card fluid className="card-deposit">
          {state.data.ejecucion.estado ? (
            <>
              <Card.Content>
                <div
                  className="card-deposit-info"
                  style={{ flexDirection: "row" }}
                >
                  {state.data.datos.datos.titulo_matriz}
                </div>
              </Card.Content>
              <Card.Content className="card-container__matrix2">
                <>
                  <h3>{state.data.datos.datos.parte1.subtitulo}</h3>
                  {/* ALTURA MURO */}
                  <div className="matrix2__container-dropdown">
                    <p>{state.data.datos.datos.parte1.altura_muro.label}</p>
                    <Dropdown
                      placeholder={placeholderAlturaMuro}
                      selection
                      options={dataMuro}
                      value={datos === false ? newDataMuro : dataMuroId}
                      onChange={(_, data) => {
                        setNewDataMuro(data.value);
                        setDataMuroId(data.value);
                      }}
                      disabled={minero === "false" ? true : false}
                    />
                    {dataMuroId === "" && newDataMuro === "" && (
                      <Icon name="warning" className="icon-warning" />
                    )}

                    {dataMuroId === 1 || newDataMuro === 1 ? (
                      <div className="matrix2__container-input-check">
                        <div className="ui input">
                          <MaskedInput
                            mask={createOneDecimalMask()}
                            name="valor_muro"
                            guide={false}
                            placeholder={placeholderValor}
                            onChange={(e) => handleChange(e)}
                            value={form.valor_muro}
                            disabled={minero === "false" ? true : false}
                            className={
                              error && form.valor_muro === ""
                                ? "border-red"
                                : "border-blue"
                            }
                          />
                        </div>
                        <div className="matrix2__documentos_respaldo">
                          {state.data.datos.datos.parte1.altura_muro.documentos_que_respaldan.map(
                            (item, i) => {
                              let index = checkedMuro.includes(item.clave);
                              return (
                                <Checkbox
                                  key={i}
                                  label={item.label}
                                  onChange={() => handleChangeCheckbox(item)}
                                  value={item.clave}
                                  checked={index}
                                  disabled={
                                    minero === "false"
                                      ? true
                                      : !item.editable
                                      ? true
                                      : false
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* ANCHO DE CORONAMIENTO */}
                  <div className="matrix2__container-dropdown">
                    <p>
                      {
                        state.data.datos.datos.parte1.ancho_de_coronamiento
                          .label
                      }
                    </p>
                    <Dropdown
                      placeholder={placeholderAnchoCoronamiento}
                      selection
                      options={dataCoronamiento}
                      value={
                        datos === false
                          ? newDataCoronamiento
                          : dataCoronamientoId
                      }
                      onChange={(_, data) => {
                        setDataCoronamientoId(data.value);
                        setNewDataCoronamiento(data.value);
                      }}
                      disabled={minero === "false" ? true : false}
                    />
                    {dataCoronamientoId === "" &&
                      newDataCoronamiento === "" && (
                        <Icon name="warning" className="icon-warning" />
                      )}

                    {dataCoronamientoId === 1 || newDataCoronamiento === 1 ? (
                      <div className="matrix2__container-input-check">
                        <div className="ui input">
                          <MaskedInput
                            mask={createOneDecimalMask()}
                            name="valor_coronamiento"
                            placeholder={placeholderValor}
                            onChange={(e) => handleChange(e)}
                            value={form.valor_coronamiento}
                            disabled={minero === "false" ? true : false}
                            className={
                              error && form.valor_coronamiento === ""
                                ? "border-red"
                                : "border-blue"
                            }
                          />
                        </div>
                        <div className="matrix2__documentos_respaldo">
                          {state.data.datos.datos.parte1.ancho_de_coronamiento.documentos_que_respaldan.map(
                            (item, i) => {
                              let index = checkedCoronamiento.includes(
                                item.clave
                              );
                              return (
                                <Checkbox
                                  key={i}
                                  label={item.label}
                                  onChange={() =>
                                    handleChangeCheckboxCoronamiento(item)
                                  }
                                  value={item.clave}
                                  checked={index}
                                  disabled={
                                    minero === "false"
                                      ? true
                                      : !item.editable
                                      ? true
                                      : false
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* REVANCHA OPERACIONAL */}
                  <div className="matrix2__container-dropdown">
                    <p>
                      {state.data.datos.datos.parte1.revancha_operacional.label}
                    </p>
                    <Dropdown
                      placeholder={placeholderRevancha}
                      selection
                      options={dataRevancha}
                      value={datos === false ? newDataRevancha : dataRevanchaId}
                      onChange={(_, data) => {
                        setDataRevanchaId(data.value);
                        setNewDataRevancha(data.value);
                      }}
                      disabled={minero === "false" ? true : false}
                    />
                    {dataRevanchaId === "" && newDataRevancha === "" && (
                      <Icon name="warning" className="icon-warning" />
                    )}

                    {dataRevanchaId === 1 || newDataRevancha === 1 ? (
                      <div className="matrix2__container-input-check">
                        <div className="ui input">
                          <MaskedInput
                            mask={createOneDecimalMask()}
                            name="valor_revancha"
                            placeholder={placeholderValor}
                            onChange={(e) => handleChange(e)}
                            value={form.valor_revancha}
                            disabled={minero === "false" ? true : false}
                            className={
                              error && form.valor_revancha === ""
                                ? "border-red"
                                : "border-blue"
                            }
                          />
                        </div>
                        <div className="matrix2__documentos_respaldo">
                          {state.data.datos.datos.parte1.revancha_operacional.documentos_que_respaldan.map(
                            (item, i) => {
                              let index = checkedRevancha.includes(item.clave);
                              return (
                                <Checkbox
                                  key={i}
                                  label={item.label}
                                  onChange={() =>
                                    handleChangeCheckboxRevancha(item)
                                  }
                                  value={item.clave}
                                  checked={index}
                                  disabled={
                                    minero === "false"
                                      ? true
                                      : !item.editable
                                      ? true
                                      : false
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* ANGULO TALUD */}
                  <div className="matrix2__container-dropdown">
                    <p>
                      {state.data.datos.datos.parte1.angulo_talud_externo.label}
                    </p>
                    <Dropdown
                      placeholder={placeholderAngulo}
                      selection
                      options={dataAngulo}
                      value={datos === false ? newDataAngulo : dataAnguloId}
                      onChange={(_, data) => {
                        setDataAnguloId(data.value);
                        setNewDataAngulo(data.value);
                      }}
                      disabled={minero === "false" ? true : false}
                    />
                    {dataAnguloId === "" && newDataAngulo === "" && (
                      <Icon name="warning" className="icon-warning" />
                    )}

                    {dataAnguloId === 1 || newDataAngulo === 1 ? (
                      <div className="matrix2__container-input-check">
                        <div className="ui input">
                          <MaskedInput
                            mask={createDecimalMask()}
                            name="valor_angulo"
                            placeholder={placeholderValor}
                            onChange={(e) => handleChange(e)}
                            value={form.valor_angulo}
                            disabled={minero === "false" ? true : false}
                            className={
                              error && form.valor_angulo === ""
                                ? "border-red"
                                : "border-blue"
                            }
                          />
                        </div>
                        <div className="matrix2__documentos_respaldo">
                          {state.data.datos.datos.parte1.angulo_talud_externo.documentos_que_respaldan.map(
                            (item, i) => {
                              let index = checkedAngulo.includes(item.clave);
                              return (
                                <Checkbox
                                  key={i}
                                  label={item.label}
                                  onChange={() =>
                                    handleChangeCheckboxAngulo(item)
                                  }
                                  value={item.clave}
                                  checked={index}
                                  disabled={
                                    minero === "false"
                                      ? true
                                      : !item.editable
                                      ? true
                                      : false
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {state.data.datos.tipo_matriz === "m2_filtrado_relave" && (
                    <FiltradoRelave
                      state={state}
                      id={id}
                      form={form}
                      datos={datos}
                      dataMuroId={dataMuroId}
                      checkedMuro={checkedMuro}
                      dataCoronamientoId={dataCoronamientoId}
                      checkedCoronamiento={checkedCoronamiento}
                      dataRevanchaId={dataRevanchaId}
                      checkedRevancha={checkedRevancha}
                      dataAnguloId={dataAnguloId}
                      checkedAngulo={checkedAngulo}
                      handleChange={handleChange}
                      error={error}
                    />
                  )}

                  {(state.data.datos.tipo_matriz === "m2_pasta_relave" ||
                    state.data.datos.tipo_matriz === "m2_espesado_relave") && (
                    <FiltradoEnPasta
                      state={state}
                      id={id}
                      form={form}
                      datos={datos}
                      dataMuroId={dataMuroId}
                      checkedMuro={checkedMuro}
                      dataCoronamientoId={dataCoronamientoId}
                      checkedCoronamiento={checkedCoronamiento}
                      dataRevanchaId={dataRevanchaId}
                      checkedRevancha={checkedRevancha}
                      dataAnguloId={dataAnguloId}
                      checkedAngulo={checkedAngulo}
                      handleChange={handleChange}
                      error={error}
                    />
                  )}

                  {(state.data.datos.tipo_matriz === "m2_embalse_relave" ||
                    state.data.datos.tipo_matriz ===
                      "m2_tranque_arena_relave") &&
                    dataMuroId !== "" &&
                    dataCoronamientoId !== "" &&
                    dataRevanchaId !== "" &&
                    dataAnguloId !== "" && (
                      <>
                        <TextArea
                          placeholder={placeholderComentario}
                          name="comentario"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{ width: "100%" }}
                          value={form.comentario}
                          disabled={minero === "false" ? true : false}
                          maxLength="1000"
                        />
                        {minero === "true" && (
                          <div className="btn-update-matrix1">
                            <Button onClick={onShowModal}>
                              <FormattedMessage id="GuardarMayus" />
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                </>
              </Card.Content>
              <ModalActions
                title={<FormattedMessage id="ActualizarMatriz2" />}
                show={showModal}
                setShow={setShowModal}
                status={state.statusUpdate}
                action={handleClickUpdate}
                reset={resetData}
                deleteAction={"Delete"}
                estado={state.dataUpdate}
                loading={state.loadingUpdate}
              >
                {state.statusUpdate === 0 ? (
                  <FormattedMessage id="EstaSeguroMatriz2" />
                ) : state.dataUpdate.ejecucion.estado ? (
                  <div className="container-modal">
                    <p className="message-ok">
                      {state.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Icon name="checkmark" className="status-ok" />
                  </div>
                ) : (
                  <div className="container-modal">
                    <p className="message-fail">
                      {state.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Image src={ohError} className="status-fail" />
                  </div>
                )}
              </ModalActions>
            </>
          ) : (
            <Card.Content>{state.data.ejecucion.mensaje}</Card.Content>
          )}
        </Card>
      )}
    </Container>
  );
}

//  SI TIPO MATRIZ 2 ES FILTRADO RELAVE
function FiltradoRelave({
  state,
  datos,
  form,
  dataAnguloId,
  checkedAngulo,
  dataMuroId,
  checkedMuro,
  dataCoronamientoId,
  checkedCoronamiento,
  dataRevanchaId,
  checkedRevancha,
  id,
  handleChange,
  error,
}) {
  const dispatch = useDispatch();

  const minero = localStorage.getItem("minero");

  const [formRelave, setFormRelave] = useState({
    valor_altura_global: "",
    valor_angulo_global: "",
    valor_banco_global: "",
    valor_altura_local: "",
    valor_berma_local: "",
    valor_angulo_global_local: "",
  });

  const [errorRelave, setErrorRelave] = useState(false);

  const handleChangeFiltrado = (event) => {
    setFormRelave((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  //   DATA GLOBAL
  const [dataAlturaGlobal, setDataAlturaGlobal] = useState([]);
  const [dataAlturaGlobalId, setDataAlturaGlobalId] = useState("");

  const [dataAnguloGlobal, setDataAnguloGlobal] = useState([]);
  const [dataAnguloGlobalId, setDataAnguloGlobalId] = useState("");

  const [dataBancoGlobal, setDataBancoGlobal] = useState([]);
  const [dataBancoGlobalId, setDataBancoGlobalId] = useState("");

  const [newDataAlturaGlobal, setNewDataAlturaGlobal] = useState("");
  const [newDataAnguloGlobal, setNewDataAnguloGlobal] = useState("");
  const [newDataBancoGlobal, setNewDataBancoGlobal] = useState("");

  const [checkedAlturaGlobal, setCheckedAlturaGlobal] = useState([]);
  const [checkedAnguloGlobal, setCheckedAnguloGlobal] = useState([]);
  const [checkedBancoGlobal, setCheckedBancoGlobal] = useState([]);

  const [formTable, setFormTable] = useState([]);

  useEffect(() => {
    if (
      (formRelave.valor_altura_global === "" && dataAlturaGlobalId === 1) ||
      (formRelave.valor_angulo_global === "" && dataAnguloGlobalId === 1) ||
      (formRelave.valor_banco_global === "" && dataBancoGlobalId === 1)
    ) {
      setErrorRelave(true);
    } else {
      setErrorRelave(false);
    }
  }, [formRelave, dataAlturaGlobalId, dataAnguloGlobalId, dataBancoGlobalId]);

  // GLOBAL ALTURA
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.parte2.gb.gb_altura.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataAlturaGlobal(arr);
  }, [state.data.datos, state.status]);

  const handleChangeCheckboxAlturaGlobal = (obj) => {
    const arr = [...checkedAlturaGlobal];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedAlturaGlobal(arr);
  };

  //   GLOBAL ANGULO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.parte2.gb.gb_angulo_talud_externo.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataAnguloGlobal(arr);
  }, [state.data.datos, state.status]);

  const handleChangeCheckboxAnguloGlobal = (obj) => {
    const arr = [...checkedAnguloGlobal];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedAnguloGlobal(arr);
  };

  //   GLOBAL BANCOS
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.parte2.gb.gb_cantidad_bancos_o_terrazas.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataBancoGlobal(arr);
  }, [state.data.datos, state.status]);

  const handleChangeCheckboxBancoGlobal = (obj) => {
    const arr = [...checkedBancoGlobal];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedBancoGlobal(arr);
  };

  const handleClickUpdate = () => {
    const arrBancos = [];

    formTable.forEach((item) => {
      arrBancos.push({
        numero: item.numero,
        altura: item.altura,
        angulo: item.angulo,
        ancho_berma: item.ancho_berma,
      });
    });

    dispatch(
      updateMatriz2({
        tipo_matriz: state.data.datos.tipo_matriz,
        deposito_id: id,
        parte1: {
          altura_muro: {
            valor: form.valor_muro,
            nivel_msnm: null,
            opcion_informacion_id: dataMuroId,
            documentos_que_respaldan: checkedMuro,
          },
          ancho_de_coronamiento: {
            valor: form.valor_coronamiento,
            opcion_informacion_id: dataCoronamientoId,
            documentos_que_respaldan: checkedCoronamiento,
          },
          revancha_operacional: {
            valor: form.valor_revancha,
            opcion_informacion_id: dataRevanchaId,
            documentos_que_respaldan: checkedRevancha,
          },
          angulo_talud_externo: {
            valor: form.valor_angulo,
            opcion_informacion_id: dataAnguloId,
            documentos_que_respaldan: checkedAngulo,
          },
        },
        parte2: {
          gb: {
            gb_altura: {
              valor: formRelave.valor_altura_global,
              nivel_msnm: null,
              opcion_informacion_id: dataAlturaGlobalId,
              documentos_que_respaldan: checkedAlturaGlobal,
            },
            gb_angulo_talud_externo: {
              valor: formRelave.valor_angulo_global,
              opcion_informacion_id: dataAnguloGlobalId,
              documentos_que_respaldan: checkedAnguloGlobal,
            },
            gb_cantidad_bancos_o_terrazas: {
              valor: formRelave.valor_banco_global,
              opcion_informacion_id: dataBancoGlobalId,
              documentos_que_respaldan: checkedBancoGlobal,
              bancos: arrBancos,
            },
          },
        },
        comentario: form.comentario,
      })
    );
  };

  const resetData = () => {
    dispatch(resetUpdateMatriz2());
  };

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setFormRelave({
              valor_altura_global:
                state.data.datos.datos.parte2.gb.gb_altura.valor.length > 0
                  ? state.data.datos.datos.parte2.gb.gb_altura.valor[0].valor
                  : "",
              valor_angulo_global:
                state.data.datos.datos.parte2.gb.gb_angulo_talud_externo.valor
                  .length > 0
                  ? state.data.datos.datos.parte2.gb.gb_angulo_talud_externo
                      .valor[0].valor
                  : "",
              valor_banco_global:
                state.data.datos.datos.parte2.gb.gb_cantidad_bancos_o_terrazas
                  .valor !== null
                  ? state.data.datos.datos.parte2.gb
                      .gb_cantidad_bancos_o_terrazas.valor
                  : "",
            });
          }
        }
      }
    };
    callsAPI();
  }, [state.status, state.data]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDataAlturaGlobalId(newDataAlturaGlobal);
          setDataAnguloGlobalId(newDataAnguloGlobal);
          setDataBancoGlobalId(newDataBancoGlobal);
        }
      }
    }

    //eslint-disable-next-line
  }, [newDataAlturaGlobal, newDataAnguloGlobal, newDataBancoGlobal]);

  // ESTE USE EFFECT HACE QUE SE CARGE DATA EN LOS DROPDOWN CUANDO HAY DATA GUARDADA
  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          if (dataAlturaGlobal.length > 0) {
            const dataSelectedAlturaGlobal = dataAlturaGlobal.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataAlturaGlobal(dataSelectedAlturaGlobal.value);
          }

          if (dataAnguloGlobal.length > 0) {
            const dataSelectedAnguloGlobal = dataAnguloGlobal.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataAnguloGlobal(dataSelectedAnguloGlobal.value);
          }

          if (dataBancoGlobal.length > 0) {
            const dataSelectedBancoGlobal = dataBancoGlobal.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataBancoGlobal(dataSelectedBancoGlobal.value);
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [dataAlturaGlobal, dataAnguloGlobal, dataBancoGlobal]);

  useEffect(() => {
    const arrAlturaGlobal = [...checkedAlturaGlobal];
    const arrAnguloGlobal = [...checkedAnguloGlobal];
    const arrBancoGlobal = [...checkedBancoGlobal];

    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          state.data.datos.datos.parte2.gb.gb_altura.documentos_que_respaldan.map(
            (e) => e.seleccionado === true && arrAlturaGlobal.push(e.clave)
          );
          setCheckedAlturaGlobal(arrAlturaGlobal);

          state.data.datos.datos.parte2.gb.gb_angulo_talud_externo.documentos_que_respaldan.map(
            (e) => e.seleccionado === true && arrAnguloGlobal.push(e.clave)
          );
          setCheckedAnguloGlobal(arrAnguloGlobal);

          state.data.datos.datos.parte2.gb.gb_cantidad_bancos_o_terrazas.documentos_que_respaldan.map(
            (e) => e.seleccionado === true && arrBancoGlobal.push(e.clave)
          );
          setCheckedBancoGlobal(arrBancoGlobal);
        }
      }
    }

    // eslint-disable-next-line
  }, [state.status]);

  useEffect(() => {
    const arrFormTable = state.data.datos.datos.parte2.gb
      .gb_cantidad_bancos_o_terrazas.bancos
      ? [
          ...state.data.datos.datos.parte2.gb.gb_cantidad_bancos_o_terrazas
            .bancos,
        ]
      : [...formTable];

    const arr = [];

    const number =
      formRelave.valor_banco_global === 0 ||
      formRelave.valor_banco_global === ""
        ? 0
        : formRelave.valor_banco_global;

    for (let i = 0; i < number; i++) {
      if (arrFormTable[i]) {
        arr.push(arrFormTable[i]);
      } else {
        arr.push({
          numero: i + 1,
          altura: "",
          angulo: "",
          ancho_berma: "",
        });
      }
    }

    setFormTable(arr);
    // eslint-disable-next-line
  }, [formRelave.valor_banco_global]);

  const handleChangeTable = (index, col, e) => {
    let newFormTable = [...formTable];
    newFormTable[index][col] = e.target.value;
    setFormTable(newFormTable);
  };

  const intl = useIntl();

  const placeholderValor = intl.formatMessage({ id: "IngreseValor" });
  const placeholderComentario = intl.formatMessage({ id: "IngreseComentario" });
  const placeholderAlturaGlobal = intl.formatMessage({
    id: "SeleccioneAlturaGlobal",
  });
  const placeholderAngulo = intl.formatMessage({
    id: "SeleccioneAnguloTaludGlobal",
  });
  const placeholderBanco = intl.formatMessage({ id: "SeleccioneBancoGlobal" });

  const renderCeldas = () => {
    const celdas = [];

    for (let i = 0; i < formRelave.valor_banco_global; i++) {
      celdas.push(
        <Table.Row key={i} className="table__matrix5__row-body">
          <Table.Cell>{i + 1}</Table.Cell>
          <Table.Cell>
            <Input
              name={`valor_${i}`}
              type="text"
              onChange={(e) => {
                let index = parseInt(e.target.id, 10);
                handleChangeTable(index, "altura", e);
              }}
              onKeyPress={(event) => {
                if (!/[0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={formTable[i]?.altura || ""}
              id={i}
              disabled={minero === "false" ? true : false}
            />
          </Table.Cell>
          <Table.Cell>
            <Input
              name={`valor_${i}`}
              type="text"
              onChange={(e) => {
                let index = parseInt(e.target.id, 10);
                handleChangeTable(index, "angulo", e);
              }}
              onKeyPress={(event) => {
                if (!/[0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={formTable[i]?.angulo || ""}
              id={i}
              disabled={minero === "false" ? true : false}
            />
          </Table.Cell>
          <Table.Cell>
            <Input
              name={`valor_${i}`}
              type="text"
              onChange={(e) => {
                let index = parseInt(e.target.id, 10);
                handleChangeTable(index, "ancho_berma", e);
              }}
              onKeyPress={(event) => {
                if (!/[0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={formTable[i]?.ancho_berma || ""}
              id={i}
              disabled={minero === "false" ? true : false}
            />
          </Table.Cell>
        </Table.Row>
      );
    }

    return celdas;
  };

  return (
    <div style={{ marginBottom: "15px" }}>
      {state.loading ? (
        <Loading />
      ) : (
        <>
          <h3>{state.data.datos.datos.parte2.subtitulo}</h3>

          <h4>{state.data.datos.datos.parte2.gb.subsubtitulo}</h4>

          {/* ALTURA GLOBAL */}
          <div className="matrix2__container-dropdown">
            <p>{state.data.datos.datos.parte2.gb.gb_altura.label}</p>
            <Dropdown
              placeholder={placeholderAlturaGlobal}
              selection
              options={dataAlturaGlobal}
              value={datos === false ? newDataAlturaGlobal : dataAlturaGlobalId}
              onChange={(_, data) => {
                setDataAlturaGlobalId(data?.value);
                setNewDataAlturaGlobal(data?.value);
              }}
              disabled={minero === "false" ? true : false}
            />
            {dataAlturaGlobalId === "" && newDataAlturaGlobal === "" && (
              <Icon name="warning" className="icon-warning" />
            )}
            {(dataAlturaGlobalId === 1 || newDataAlturaGlobal === 1) && (
              <div className="matrix2__container-input-check">
                <div className="ui input">
                  <MaskedInput
                    mask={createOneDecimalMask()}
                    name="valor_altura_global"
                    placeholder={placeholderValor}
                    onChange={(e) => handleChangeFiltrado(e)}
                    value={formRelave.valor_altura_global}
                    disabled={minero === "false" ? true : false}
                    className={
                      errorRelave && formRelave.valor_altura_global === ""
                        ? "border-red"
                        : "border-blue"
                    }
                  />
                </div>

                <div className="matrix2__documentos_respaldo">
                  {state.data.datos.datos.parte2.gb.gb_altura.documentos_que_respaldan.map(
                    (item, i) => {
                      let index = checkedAlturaGlobal.includes(item.clave);
                      return (
                        <Checkbox
                          key={i}
                          label={item.label}
                          onChange={() =>
                            handleChangeCheckboxAlturaGlobal(item)
                          }
                          value={item.clave}
                          checked={index}
                          disabled={
                            minero === "false"
                              ? true
                              : !item.editable
                              ? true
                              : false
                          }
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>

          {/* ANGULO GLOBAL */}
          <div className="matrix2__container-dropdown">
            <p>
              {state.data.datos.datos.parte2.gb.gb_angulo_talud_externo.label}
            </p>
            <Dropdown
              placeholder={placeholderAngulo}
              selection
              options={dataAnguloGlobal}
              value={datos === false ? newDataAnguloGlobal : dataAnguloGlobalId}
              onChange={(_, data) => {
                setDataAnguloGlobalId(data?.value);
                setNewDataAnguloGlobal(data?.value);
              }}
              disabled={minero === "false" ? true : false}
            />
            {dataAnguloGlobalId === "" && newDataAnguloGlobal === "" && (
              <Icon name="warning" className="icon-warning" />
            )}

            {(dataAnguloGlobalId === 1 || newDataAnguloGlobal === 1) && (
              <div className="matrix2__container-input-check">
                <div className="ui input">
                  <MaskedInput
                    mask={createDecimalMask()}
                    name="valor_angulo_global"
                    placeholder={placeholderValor}
                    onChange={(e) => handleChangeFiltrado(e)}
                    value={formRelave.valor_angulo_global}
                    disabled={minero === "false" ? true : false}
                    className={
                      errorRelave && formRelave.valor_angulo_global === ""
                        ? "border-red"
                        : "border-blue"
                    }
                  />
                </div>

                <div className="matrix2__documentos_respaldo">
                  {state.data.datos.datos.parte2.gb.gb_angulo_talud_externo.documentos_que_respaldan.map(
                    (item, i) => {
                      let index = checkedAnguloGlobal.includes(item.clave);
                      return (
                        <Checkbox
                          key={i}
                          label={item.label}
                          onChange={() =>
                            handleChangeCheckboxAnguloGlobal(item)
                          }
                          value={item.clave}
                          checked={index}
                          disabled={
                            minero === "false"
                              ? true
                              : !item.editable
                              ? true
                              : false
                          }
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>

          {/* BANCOS GLOBAL */}
          <div className="matrix2__container-dropdown">
            <p>
              {
                state.data.datos.datos.parte2.gb.gb_cantidad_bancos_o_terrazas
                  .label
              }
            </p>
            <Dropdown
              placeholder={placeholderBanco}
              selection
              options={dataBancoGlobal}
              value={datos === false ? newDataBancoGlobal : dataBancoGlobalId}
              onChange={(_, data) => {
                setDataBancoGlobalId(data?.value);
                setNewDataBancoGlobal(data?.value);
              }}
              disabled={minero === "false" ? true : false}
            />
            {dataBancoGlobalId === "" && newDataBancoGlobal === "" && (
              <Icon name="warning" className="icon-warning" />
            )}

            {(dataBancoGlobalId === 1 || newDataBancoGlobal === 1) && (
              <div className="matrix2__container-input-check">
                <div className="ui input">
                  <MaskedInput
                    mask={createDecimalMask()}
                    name="valor_banco_global"
                    placeholder={placeholderValor}
                    onChange={(e) => handleChangeFiltrado(e)}
                    value={formRelave.valor_banco_global}
                    disabled={minero === "false" ? true : false}
                    className={
                      errorRelave && formRelave.valor_banco_global === ""
                        ? "border-red"
                        : "border-blue"
                    }
                  />
                </div>

                <div className="matrix2__documentos_respaldo">
                  {state.data.datos.datos.parte2.gb.gb_cantidad_bancos_o_terrazas.documentos_que_respaldan.map(
                    (item, i) => {
                      let index = checkedBancoGlobal.includes(item.clave);
                      return (
                        <Checkbox
                          key={i}
                          label={item.label}
                          onChange={() => handleChangeCheckboxBancoGlobal(item)}
                          value={item.clave}
                          checked={index}
                          disabled={
                            minero === "false"
                              ? true
                              : !item.editable
                              ? true
                              : false
                          }
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>

          {formRelave.valor_banco_global > 0 && (
            <Table celled fixed className="table__matrix5">
              <Table.Header className="table__matrix5__row-header">
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage id="Numero" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="AlturaMuroValor" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="AnguloTaludValor" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="AnchoBermaValor" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{renderCeldas()}</Table.Body>
            </Table>
          )}

          {dataCoronamientoId !== "" &&
            dataRevanchaId !== "" &&
            dataAnguloId !== "" &&
            dataMuroId !== "" &&
            dataAlturaGlobalId !== "" &&
            dataAnguloGlobalId !== "" &&
            dataBancoGlobalId !== "" && (
              <>
                <TextArea
                  placeholder={placeholderComentario}
                  name="comentario"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ width: "100%" }}
                  value={form.comentario}
                  disabled={minero === "false" ? true : false}
                  maxLength="1000"
                />
                {minero === "true" && !error && !errorRelave && (
                  <div className="btn-update-matrix1">
                    <Button onClick={onShowModal}>
                      <FormattedMessage id="GuardarMayus" />
                    </Button>
                  </div>
                )}
              </>
            )}

          <ModalActions
            title={<FormattedMessage id="ActualizarMatriz2" />}
            show={showModal}
            setShow={setShowModal}
            status={state.statusUpdate}
            action={handleClickUpdate}
            reset={resetData}
            deleteAction={"Delete"}
            estado={state.dataUpdate}
            loading={state.loadingUpdate}
          >
            {state.statusUpdate === 0 ? (
              "¿Está seguro de actualizar la Matriz 2?"
            ) : state.dataUpdate.ejecucion.estado ? (
              <div className="container-modal">
                <p className="message-ok">
                  {state.dataUpdate.ejecucion.mensaje}
                </p>
                <Icon name="checkmark" className="status-ok" />
              </div>
            ) : (
              <div className="container-modal">
                <p className="message-fail">
                  {state.dataUpdate.ejecucion.mensaje}
                </p>
                <Image src={ohError} className="status-fail" />
              </div>
            )}
          </ModalActions>
        </>
      )}
    </div>
  );
}
// TERMINA TIPO MATRIZ FILTRADO RELAVE

// INICIA TIPO MATRIZ RELAVES EN PASTA
function FiltradoEnPasta({
  state,
  datos,
  form,
  dataAnguloId,
  checkedAngulo,
  dataMuroId,
  checkedMuro,
  dataCoronamientoId,
  checkedCoronamiento,
  dataRevanchaId,
  checkedRevancha,
  id,
  handleChange,
  error,
}) {
  const dispatch = useDispatch();

  const [formPasta, setFormPasta] = useState({
    valor_pasta_altura: "",
    valor_deposito_angulo: "",
  });

  const [errorPasta, setErrorPasta] = useState(false);

  const handleChangePasta = (event) => {
    setFormPasta((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const minero = localStorage.getItem("minero");

  // DATA PASTA ALTURA
  const [dataPastaAltura, setDataPastaAltura] = useState([]);
  const [dataPastaAlturaId, setDataPastaAlturaId] = useState("");

  // DATA DEPOSITO ANGULO
  const [dataDepositoAngulo, setDataDepositoAngulo] = useState([]);
  const [dataDepositoAnguloId, setDataDepositoAnguloId] = useState("");

  // NEW DATA SI EL ENDPOINT TRAE INFORMACION
  const [newDataPastaAltura, setNewDataPastaAltura] = useState("");
  const [newDataDepositoAngulo, setNewDataDepositoAngulo] = useState("");

  // STATE CHECKBOX
  const [checkedPastaAltura, setCheckedPastaAltura] = useState([]);
  const [checkedDepositoAngulo, setCheckedDepositoAngulo] = useState([]);

  useEffect(() => {
    if (
      (formPasta.valor_pasta_altura === "" && dataPastaAlturaId === 1) ||
      (formPasta.valor_deposito_angulo === "" && dataDepositoAnguloId === 1)
    ) {
      setErrorPasta(true);
    } else {
      setErrorPasta(false);
    }
  }, [formPasta, dataPastaAlturaId, dataDepositoAnguloId]);

  // PASTA ALTURA INICIO
  // PASTA ALTURA DROPDOWN
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.parte2.deposito_en_pasta_altura.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }

    setDataPastaAltura(arr);
  }, [state.data.datos, state.status]);

  // PASTA ALTURA CHECKBOX DOCUMENTOS QUE RESPALDAN
  const handleChangeCheckboxPastaAltura = (obj) => {
    const arr = [...checkedPastaAltura];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }
    setCheckedPastaAltura(arr);
  };

  // PASTA ALTURA FIN

  // DEPOSITO ANGULO INICIO
  // DEPOSITO ANGULO DROPDOWN
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.parte2.deposito_en_pasta_angulo_talud_externo.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataDepositoAngulo(arr);
  }, [state.data.datos, state.status]);

  // DEPOSITO ANGULO CHECKBOX DOCUMENTOS QUE RESPALDAN
  const handleChangeCheckboxDepositoAngulo = (obj) => {
    const arr = [...checkedDepositoAngulo];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedDepositoAngulo(arr);
  };

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz2({
        tipo_matriz: state.data.datos.tipo_matriz,
        deposito_id: id,
        parte1: {
          altura_muro: {
            valor: form.valor_muro,
            nivel_msnm: null,
            opcion_informacion_id: dataMuroId,
            documentos_que_respaldan: checkedMuro,
          },
          ancho_de_coronamiento: {
            valor: form.valor_coronamiento,
            opcion_informacion_id: dataCoronamientoId,
            documentos_que_respaldan: checkedCoronamiento,
          },
          revancha_operacional: {
            valor: form.valor_revancha,
            opcion_informacion_id: dataRevanchaId,
            documentos_que_respaldan: checkedRevancha,
          },
          angulo_talud_externo: {
            valor: form.valor_angulo,
            opcion_informacion_id: dataAnguloId,
            documentos_que_respaldan: checkedAngulo,
          },
        },
        parte2: {
          deposito_en_pasta_altura: {
            valor: formPasta.valor_pasta_altura,
            nivel_msnm: null,
            opcion_informacion_id: dataPastaAlturaId,
            documentos_que_respaldan: checkedPastaAltura,
          },
          deposito_en_pasta_angulo_talud_externo: {
            valor: formPasta.valor_deposito_angulo,
            opcion_informacion_id: dataDepositoAnguloId,
            documentos_que_respaldan: checkedDepositoAngulo,
          },
        },
        comentario: form.comentario,
      })
    );
  };

  const resetData = () => {
    dispatch(resetUpdateMatriz2());
  };

  // SE CARGA LA DATA SI HAY INFORMACION GUARDADA
  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setFormPasta({
              valor_pasta_altura:
                state.data.datos.datos.parte2.deposito_en_pasta_altura.valor
                  .length > 0
                  ? state.data.datos.datos.parte2.deposito_en_pasta_altura
                      .valor[0].valor
                  : "",
              valor_deposito_angulo:
                state.data.datos.datos.parte2
                  .deposito_en_pasta_angulo_talud_externo.valor.length > 0
                  ? state.data.datos.datos.parte2
                      .deposito_en_pasta_angulo_talud_externo.valor[0].valor
                  : "",
            });
          }
        }
      }
    };
    callsAPI();
  }, [state.status, state.data]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDataPastaAlturaId(newDataPastaAltura);
          setDataDepositoAnguloId(newDataDepositoAngulo);
        }
      }
    }

    //eslint-disable-next-line
  }, [newDataPastaAltura, newDataDepositoAngulo]);

  // ESTE USE EFFECT HACE QUE SE CARGE DATA EN LOS DROPDOWN CUANDO HAY DATA GUARDADA
  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          if (dataPastaAltura.length > 0) {
            const dataSelectedPastaAltura = dataPastaAltura.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataPastaAltura(dataSelectedPastaAltura.value);
          }

          if (dataDepositoAngulo.length > 0) {
            const dataSelectedDepositoAngulo = dataDepositoAngulo.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataDepositoAngulo(dataSelectedDepositoAngulo.value);
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [dataPastaAltura, dataDepositoAngulo]);

  // ACA SE CARGAN LOS CHECKBOX SI HAY INFORMACION
  useEffect(() => {
    const arrPastaAltura = [...checkedPastaAltura];
    const arrDepositoAngulo = [...checkedDepositoAngulo];

    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          state.data.datos.datos.parte2.deposito_en_pasta_altura.documentos_que_respaldan.map(
            (e) => e.seleccionado === true && arrPastaAltura.push(e.clave)
          );
          setCheckedPastaAltura(arrPastaAltura);

          state.data.datos.datos.parte2.deposito_en_pasta_angulo_talud_externo.documentos_que_respaldan.map(
            (e) => e.seleccionado === true && arrDepositoAngulo.push(e.clave)
          );
          setCheckedDepositoAngulo(arrDepositoAngulo);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.status]);

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({ id: "IngreseComentario" });
  const placeholderValor = intl.formatMessage({ id: "IngreseValor" });
  const placeholderPastaAltura = intl.formatMessage({
    id: "SeleccionePastaAltura",
  });
  const placeholderDepositoAngulo = intl.formatMessage({
    id: "SeleccioneDepositoAngulo",
  });

  return (
    <div style={{ marginBottom: "15px" }}>
      <h3>{state.data.datos.datos.parte2.subtitulo}</h3>

      {/* DEPOSITO EN PASTA ALTURA */}
      <div className="matrix2__container-dropdown">
        <p>{state.data.datos.datos.parte2.deposito_en_pasta_altura.label}</p>
        <Dropdown
          placeholder={placeholderPastaAltura}
          selection
          options={dataPastaAltura}
          value={datos === false ? newDataPastaAltura : dataPastaAlturaId}
          onChange={(_, data) => {
            setDataPastaAlturaId(data?.value);
            setNewDataPastaAltura(data?.value);
          }}
          disabled={minero === "false" ? true : false}
        />
        {dataPastaAlturaId === "" && newDataPastaAltura === "" && (
          <Icon name="warning" className="icon-warning" />
        )}

        {(dataPastaAlturaId === 1 || newDataPastaAltura === 1) && (
          <div className="matrix2__container-input-check">
            <div className="ui input">
              <MaskedInput
                mask={createOneDecimalMask()}
                name="valor_pasta_altura"
                placeholder={placeholderValor}
                onChange={(e) => handleChangePasta(e)}
                value={formPasta.valor_pasta_altura}
                disabled={minero === "false" ? true : false}
                className={
                  errorPasta && formPasta.valor_pasta_altura === ""
                    ? "border-red"
                    : "border-blue"
                }
              />
            </div>

            <div className="matrix2__documentos_respaldo">
              {state.data.datos.datos.parte2.deposito_en_pasta_altura.documentos_que_respaldan.map(
                (item, i) => {
                  let index = checkedPastaAltura.includes(item.clave);
                  return (
                    <Checkbox
                      key={i}
                      label={item.label}
                      onChange={() => handleChangeCheckboxPastaAltura(item)}
                      value={item.clave}
                      checked={index}
                      disabled={
                        minero === "false"
                          ? true
                          : !item.editable
                          ? true
                          : false
                      }
                    />
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>

      {/* DEPOSITO EN PASTA ANGULO TALUD EXTERNO */}
      <div className="matrix2__container-dropdown">
        <p>
          {
            state.data.datos.datos.parte2.deposito_en_pasta_angulo_talud_externo
              .label
          }
        </p>
        <Dropdown
          placeholder={placeholderDepositoAngulo}
          selection
          options={dataDepositoAngulo}
          value={datos === false ? newDataDepositoAngulo : dataDepositoAnguloId}
          onChange={(_, data) => {
            setDataDepositoAnguloId(data?.value);
            setNewDataDepositoAngulo(data?.value);
          }}
          disabled={minero === "false" ? true : false}
        />
        {dataDepositoAnguloId === "" && newDataDepositoAngulo === "" && (
          <Icon name="warning" className="icon-warning" />
        )}

        {(dataDepositoAnguloId === 1 || newDataDepositoAngulo === 1) && (
          <div className="matrix2__container-input-check">
            <div className="ui input">
              <MaskedInput
                mask={createDecimalMask()}
                name="valor_deposito_angulo"
                placeholder={placeholderValor}
                onChange={(e) => handleChangePasta(e)}
                value={formPasta.valor_deposito_angulo}
                disabled={minero === "false" ? true : false}
                className={
                  errorPasta && formPasta.valor_deposito_angulo === ""
                    ? "border-red"
                    : "border-blue"
                }
              />
            </div>

            <div className="matrix2__documentos_respaldo">
              {state.data.datos.datos.parte2.deposito_en_pasta_angulo_talud_externo.documentos_que_respaldan.map(
                (item, i) => {
                  let index = checkedDepositoAngulo.includes(item.clave);
                  return (
                    <Checkbox
                      key={i}
                      label={item.label}
                      onChange={() => handleChangeCheckboxDepositoAngulo(item)}
                      value={item.clave}
                      checked={index}
                      disabled={
                        minero === "false"
                          ? true
                          : !item.editable
                          ? true
                          : false
                      }
                    />
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>

      {dataDepositoAnguloId !== "" &&
        dataPastaAlturaId !== "" &&
        dataCoronamientoId !== "" &&
        dataMuroId !== "" &&
        dataRevanchaId !== "" &&
        dataAnguloId !== "" && (
          <>
            <TextArea
              placeholder={placeholderComentario}
              name="comentario"
              onChange={(e) => {
                handleChange(e);
              }}
              style={{ width: "100%" }}
              value={form.comentario}
              disabled={minero === "false" ? true : false}
              maxLength="1000"
            />
            {minero === "true" && !error && !errorPasta && (
              <div className="btn-update-matrix1">
                <Button onClick={onShowModal}>
                  <FormattedMessage id="GuardarMayus" />
                </Button>
              </div>
            )}
          </>
        )}

      <ModalActions
        title={<FormattedMessage id="ActualizarMatriz2" />}
        show={showModal}
        setShow={setShowModal}
        status={state.statusUpdate}
        action={handleClickUpdate}
        reset={resetData}
        deleteAction={"Delete"}
        estado={state.dataUpdate}
        loading={state.loadingUpdate}
      >
        {state.statusUpdate === 0 ? (
          <FormattedMessage id="EstaSeguroActualizarMatriz2" />
        ) : state.dataUpdate.ejecucion.estado ? (
          <div className="container-modal">
            <p className="message-ok">{state.dataUpdate.ejecucion.mensaje}</p>
            <Icon name="checkmark" className="status-ok" />
          </div>
        ) : (
          <div className="container-modal">
            <p className="message-fail">{state.dataUpdate.ejecucion.mensaje}</p>
            <Image src={ohError} className="status-fail" />
          </div>
        )}
      </ModalActions>
    </div>
  );
}
