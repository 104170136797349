import {
  RESET_LIST_DOCUMENTS,
  GET_LIST_DOCUMENTS_INIT,
  GET_LIST_DOCUMENTS_SUCCESS,
  GET_LIST_DOCUMENTS_FAILURE,
} from "../../actions/listDocuments/types";
import initialState from "./initialState";

export default function listDocuments(state = initialState, action) {
  switch (action.type) {
    case RESET_LIST_DOCUMENTS:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_LIST_DOCUMENTS_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_DOCUMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_LIST_DOCUMENTS_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };

    default:
      return state;
  }
}
