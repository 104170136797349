import {
  RESET_ALL_PARAMS,
  GET_ALL_PARAMS_INIT,
  GET_ALL_PARAMS_SUCCESS,
  GET_ALL_PARAMS_FAILURE,
  RESET_UPDATE_PARAMS,
  UPDATE_PARAMS_INIT,
  UPDATE_PARAMS_SUCCESS,
  UPDATE_PARAMS_FAILURE,
  RESET_HISTORIC_PARAMS,
  GET_HISTORIC_PARAMS_INIT,
  GET_HISTORIC_PARAMS_SUCCESS,
  GET_HISTORIC_PARAMS_FAILURE,
  RESET_DELETE_HISTORIC_PARAMS,
  DELETE_HISTORIC_PARAMS_INIT,
  DELETE_HISTORIC_PARAMS_SUCCESS,
  DELETE_HISTORIC_PARAMS_FAILURE,
} from "../../actions/params/types";
import initialState from "./initialState";

export default function params(state = initialState, action) {
  switch (action.type) {
    case RESET_ALL_PARAMS:
      return {
        ...state,
        dataParams: {},
        loadingParams: true,
        statusParams: 0,
      };
    case GET_ALL_PARAMS_INIT:
      return {
        ...state,
        loadingParams: true,
      };
    case GET_ALL_PARAMS_SUCCESS:
      return {
        ...state,
        dataParams: action.payload.data,
        loadingParams: false,
        statusParams: action.payload.status,
      };
    case GET_ALL_PARAMS_FAILURE:
      return {
        ...state,
        dataParams: {},
        loadingParams: false,
        statusParams: 501,
      };

    // UPDATE PARAMS
    case RESET_UPDATE_PARAMS:
      return {
        ...state,
        dataUpdateParams: {},
        loadingUpdateParams: false,
        statusUpdateParams: 0,
      };
    case UPDATE_PARAMS_INIT:
      return {
        ...state,
        loadingUpdateParams: true,
      };
    case UPDATE_PARAMS_SUCCESS:
      return {
        ...state,
        dataUpdateParams: action.payload.data,
        loadingUpdateParams: false,
        statusUpdateParams: action.payload.status,
      };
    case UPDATE_PARAMS_FAILURE:
      return {
        ...state,
        dataUpdateParams: {},
        loadingUpdateParams: false,
        statusUpdateParams: 501,
      };
    // HISTORIC PARAMS
    case RESET_HISTORIC_PARAMS:
      return {
        ...state,
        dataHistoricParams: {},
        loadingHistoricParams: true,
        statusHistoricParams: 0,
      };
    case GET_HISTORIC_PARAMS_INIT:
      return {
        ...state,
        loadingHistoricParams: true,
      };
    case GET_HISTORIC_PARAMS_SUCCESS:
      return {
        ...state,
        dataHistoricParams: action.payload.data,
        loadingHistoricParams: false,
        statusHistoricParams: action.payload.status,
      };
    case GET_HISTORIC_PARAMS_FAILURE:
      return {
        ...state,
        dataHistoricParams: {},
        loadingHistoricParams: false,
        statusHistoricParams: 501,
      };

    // DELETE HISTORIC PARAMS
    case RESET_DELETE_HISTORIC_PARAMS:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 0,
      };
    case DELETE_HISTORIC_PARAMS_INIT:
      return {
        ...state,
        loadingDelete: true,
      };
    case DELETE_HISTORIC_PARAMS_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload.data,
        loadingDelete: false,
        statusDelete: action.payload.status,
      };
    case DELETE_HISTORIC_PARAMS_FAILURE:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 501,
      };

    default:
      return state;
  }
}
