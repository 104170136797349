import {
  RESET_UPDATE_COMPANY,
  UPDATE_COMPANY_INIT,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
} from "../../actions/update_company/types";
import initialState from "./initialState";

export default function updateCompany(state = initialState, action) {
  switch (action.type) {
    case RESET_UPDATE_COMPANY:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_COMPANY_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };

    default:
      return state;
  }
}
