const initialState = {
  dataGetCode: {},
  loadingGetCode: false,
  statusGetCode: 0,

  dataSendCode: {},
  loadingSendCode: false,
  statusSendCode: 0,
};

export default initialState;
