import React from "react";
import Selector from "./Componentes/Selector";

const Celdas = ({ stateInstruments, clave }) => {
  return (
    <div>
      <Selector stateInstruments={stateInstruments} clave={clave} />
    </div>
  );
};

export default Celdas;
