import {
  RESET_RECOVER_PASSWORD,
  RECOVER_PASSWORD_INIT,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_FAILURE,
  RESET_CHANGE_PASSWORD,
  CHANGE_PASSWORD_INIT,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "./types";
import API from "./api";

export function resetRecoverPassword() {
  return {
    type: RESET_RECOVER_PASSWORD,
  };
}
function recoverPasswordInit() {
  return {
    type: RECOVER_PASSWORD_INIT,
  };
}
function recoverPasswordSuccess(data) {
  return {
    type: RECOVER_PASSWORD_SUCCESS,
    payload: data,
  };
}
function recoverPasswordFailure(error) {
  return {
    type: RECOVER_PASSWORD_FAILURE,
    payload: error,
  };
}
export function recoverPassword(data) {
  return async (dispatch) => {
    dispatch(recoverPasswordInit());
    try {
      const resp = await API.data.recoverPassword(data);
      dispatch(recoverPasswordSuccess(resp));
    } catch (error) {
      dispatch(recoverPasswordFailure(error));
    }
  };
}
// CHANGE PASSWORD
export function resetChangePassword() {
  return {
    type: RESET_CHANGE_PASSWORD,
  };
}
function changePasswordInit() {
  return {
    type: CHANGE_PASSWORD_INIT,
  };
}
function changePasswordSuccess(data) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data,
  };
}
function changePasswordFailure(error) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload: error,
  };
}
export function changePassword(data) {
  return async (dispatch) => {
    dispatch(changePasswordInit());
    try {
      const resp = await API.data.changePassword(data);
      dispatch(changePasswordSuccess(resp));
    } catch (error) {
      dispatch(changePasswordFailure(error));
    }
  };
}
