import {
  RESET_LIST_DOCUMENTS_DEPOSIT,
  GET_LIST_DOCUMENTS_DEPOSIT_INIT,
  GET_LIST_DOCUMENTS_DEPOSIT_SUCCESS,
  GET_LIST_DOCUMENTS_DEPOSIT_FAILURE,
} from "./types";
import API from "./api";

export default function resetListDocumentsDeposit() {
  return {
    type: RESET_LIST_DOCUMENTS_DEPOSIT,
  };
}
function getListDocumentsDepositInit() {
  return {
    type: GET_LIST_DOCUMENTS_DEPOSIT_INIT,
  };
}
function getListDocumentsDepositSuccess(data) {
  return {
    type: GET_LIST_DOCUMENTS_DEPOSIT_SUCCESS,
    payload: data,
  };
}
function getListDocumentsDepositFailure(error) {
  return {
    type: GET_LIST_DOCUMENTS_DEPOSIT_FAILURE,
    payload: error,
  };
}
export function getListDocumentsDeposit(data) {
  return async (dispatch) => {
    dispatch(getListDocumentsDepositInit());
    try {
      const resp = await API.data.getListDocumentsDeposit(data);
      dispatch(getListDocumentsDepositSuccess(resp));
    } catch (error) {
      dispatch(getListDocumentsDepositFailure(error));
    }
  };
}
