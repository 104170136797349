import {
  RESET_DEPOSIT,
  GET_DEPOSIT_INIT,
  GET_DEPOSIT_SUCCESS,
  GET_DEPOSIT_FAILURE,
  RESET_DATA_DEPOSIT,
  GET_DATA_DEPOSIT_INIT,
  GET_DATA_DEPOSIT_SUCCESS,
  GET_DATA_DEPOSIT_FAILURE,
  RESET_DELETE_DEPOSIT,
  DELETE_DEPOSIT_INIT,
  DELETE_DEPOSIT_SUCCESS,
  DELETE_DEPOSIT_FAILURE,
  RESET_GET_ALL_DEPOSITS,
  GET_ALL_DEPOSITS_INIT,
  GET_ALL_DEPOSITS_SUCCESS,
  GET_ALL_DEPOSITS_FAILURE,
} from "../../actions/deposits/types";
import initialState from "./initialState";

export default function deposit(state = initialState, action) {
  switch (action.type) {
    // GET DEPOSITS
    case RESET_DEPOSIT:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_DEPOSIT_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_DEPOSIT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_DEPOSIT_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    // GET DATA DEPOSIT
    case RESET_DATA_DEPOSIT:
      return {
        ...state,
        dataDeposit: {},
        loadingDeposit: true,
        statusDeposit: 0,
      };
    case GET_DATA_DEPOSIT_INIT:
      return {
        ...state,
        loadingDeposit: true,
      };
    case GET_DATA_DEPOSIT_SUCCESS:
      return {
        ...state,
        dataDeposit: action.payload.data,
        loadingDeposit: false,
        statusDeposit: action.payload.status,
      };
    case GET_DATA_DEPOSIT_FAILURE:
      return {
        ...state,
        dataDeposit: {},
        loadingDeposit: false,
        statusDeposit: 501,
      };
    // DELETE DEPOSIT
    case RESET_DELETE_DEPOSIT:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 0,
      };
    case DELETE_DEPOSIT_INIT:
      return {
        ...state,
        loadingDelete: true,
      };
    case DELETE_DEPOSIT_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload.data,
        loadingDelete: false,
        statusDelete: action.payload.status,
      };
    case DELETE_DEPOSIT_FAILURE:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 501,
      };
    // GET ALL DEPOSITS
    case RESET_GET_ALL_DEPOSITS:
      return {
        ...state,
        dataAllDeposits: {},
        loadingAllDeposits: true,
        statusAllDeposits: 0,
      };
    case GET_ALL_DEPOSITS_INIT:
      return {
        ...state,
        loadingAllDeposits: true,
      };
    case GET_ALL_DEPOSITS_SUCCESS:
      return {
        ...state,
        dataAllDeposits: action.payload.data,
        loadingAllDeposits: false,
        statusAllDeposits: action.payload.status,
      };
    case GET_ALL_DEPOSITS_FAILURE:
      return {
        ...state,
        dataAllDeposits: {},
        loadingAllDeposits: false,
        statusAllDeposits: 501,
      };

    default:
      return state;
  }
}
