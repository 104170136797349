import React, { useRef, useState } from "react";
import { Accordion, Icon, Image } from "semantic-ui-react";

import blueFile from "../../assets/icons/icono_file-blue.png";
import redFile from "../../assets/icons/icono_file-red.png";
import eye from "../../assets/icons/icono_eye-grey_dark.png";
import upload from "../../assets/icons/icono_up-grey_dark.png";
import uploadRed from "../../assets/icons/icono_up-grey_red.png";
import download from "../../assets/icons/icono_down-grey_dark.png";
import trash from "../../assets/icons/icono_trash-grey_dark.png";
import ModalActions from "../Modal/ModalActions";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocument, resetDeleteDocument } from "../../actions/uploadFile";
import ModalUpload from "./../Modal/ModalUpload";
import Loading from "./../Loading/index";
import ohError from "./../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const AccordionExampleStandard = ({ state, name, estado, url }) => {
  const stateDelete = useSelector((state) => state.uploadFile);
  const dispatch = useDispatch();

  const [nameDocument, setNameDocument] = useState("");

  const [id, setId] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  const downloadLinkRef = useRef(null);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const onShowModal = (id) => {
    setId(id);
    setShowModal(true);
  };

  const onShowModalUpload = (name) => {
    setNameDocument(name);
    setShowUpload(true);
  };

  const handleDelete = () => {
    dispatch(
      deleteDocument({
        documento_id: id,
      })
    );
  };

  const resetData = () => {
    dispatch(resetDeleteDocument());
  };

  const minero = localStorage.getItem("minero");

  const intl = useIntl();

  const titleModal = intl.formatMessage({ id: "SubirDocumento" });

  return (
    <Accordion>
      {estado.estado ? (
        state.map((item, index) => (
          <div key={index} className="accordion-title-true">
            {/* HAY DATA */}
            {item.contenido.length > 0 ? (
              <>
                <Accordion.Title
                  onClick={handleClick}
                  active={activeIndex === index}
                  index={index}
                  style={{ width: "100%" }}
                >
                  <div className="accordion-title-true__left">
                    <div className="accordion-title-true__left-main">
                      <Image src={blueFile} />
                      <h3>{item.titulo}</h3>
                    </div>
                    <div className="accordion-title-true__left-eye">
                      <Image src={eye} />
                    </div>
                  </div>
                </Accordion.Title>
                {minero === "true" && (
                  <div className="accordion-title-true__right">
                    <Image
                      src={upload}
                      onClick={() => onShowModalUpload(item.titulo)}
                    />
                  </div>
                )}
                <div className="accordion-title-true__left-data">
                  {item.contenido.length} <FormattedMessage id="Archivos" />
                </div>
                <Accordion.Content
                  active={activeIndex === index}
                  style={{ width: "100%" }}
                >
                  {item.contenido.length > 0 &&
                    item.contenido.map((content, i) => {
                      return (
                        <div key={i} className="accordion-content">
                          <div className="accordion-content-main">
                            <p>{content.timestamp.slice(0, 10)}</p>
                            {"*"}
                            <p>{content.peso_en_kb} kb</p>
                            {"*"}
                            <p>{content.nombre_archivo}</p>
                            {"*"}
                          </div>
                          <div className="accordion-content-actions">
                            <a
                              ref={downloadLinkRef}
                              download
                              href={`${url}${content.archivo}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Image src={download} alt="Descargar" />
                            </a>
                            {minero === "true" && (
                              <Image
                                src={trash}
                                alt="Borrar"
                                onClick={() => onShowModal(content.id)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </Accordion.Content>
              </>
            ) : (
              // NO HAY DATA
              <>
                <Accordion.Title
                  onClick={handleClick}
                  active={activeIndex === index}
                  index={index}
                  style={{ width: "100%" }}
                >
                  <div className="accordion-title-true__left">
                    <div className="accordion-title-true__left-main">
                      <Image src={redFile} />
                      <h3>{item.titulo}</h3>
                    </div>
                  </div>
                </Accordion.Title>
                <div className="accordion-title-true__left-data">
                  <FormattedMessage id="Pendientes" />
                </div>
                {minero === "true" && (
                  <div
                    className="accordion-title-true__right"
                    style={{
                      marginLeft: "79%",
                      paddingBottom: "2%",
                    }}
                  >
                    <div
                      onClick={() => onShowModalUpload(item.titulo)}
                      style={{
                        display: "flex",
                        gap: "15px",
                        cursor: "pointer",
                      }}
                    >
                      <Image src={uploadRed} />
                      <p>
                        <FormattedMessage id="SubirDocumento" />
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ))
      ) : (
        <h1>{estado.mensaje}</h1>
      )}

      <ModalActions
        title={<FormattedMessage id="EliminarDocumento" />}
        show={showModal}
        setShow={setShowModal}
        status={stateDelete.statusDelete}
        action={handleDelete}
        reset={resetData}
        estado={stateDelete.dataDelete}
        loading={stateDelete.loading}
      >
        {stateDelete.loadingDelete ? (
          <Loading />
        ) : stateDelete.statusDelete === 0 ? (
          "¿Está seguro de eliminar este Documento?"
        ) : stateDelete.dataDelete.ejecucion.estado ? (
          <div className="container-modal">
            <p className="message-ok">
              {stateDelete.dataDelete.ejecucion.mensaje}
            </p>
            <Icon name="checkmark" className="status-ok" />
          </div>
        ) : (
          <div className="container-modal">
            <p className="message-fail">
              {stateDelete.dataDelete.ejecucion.mensaje}
            </p>
            <Image src={ohError} className="status-fail" />
          </div>
        )}
      </ModalActions>
      <ModalUpload
        title={titleModal}
        show={showUpload}
        setShow={setShowUpload}
        name={name}
        nameDocument={nameDocument}
      />
    </Accordion>
  );
};

export default AccordionExampleStandard;
