export const RESET_DEPOSIT = "RESET_DEPOSIT";
export const GET_DEPOSIT_INIT = "GET_DEPOSIT_INIT";
export const GET_DEPOSIT_SUCCESS = "GET_DEPOSIT_SUCCESS";
export const GET_DEPOSIT_FAILURE = "GET_DEPOSIT_FAILURE";

export const RESET_DATA_DEPOSIT = "RESET_DATA_DEPOSIT";
export const GET_DATA_DEPOSIT_INIT = "GET_DATA_DEPOSIT_INIT";
export const GET_DATA_DEPOSIT_SUCCESS = "GET_DATA_DEPOSIT_SUCCESS";
export const GET_DATA_DEPOSIT_FAILURE = "GET_DATA_DEPOSIT_FAILURE";

export const RESET_DELETE_DEPOSIT = "RESET_DELETE_DEPOSIT";
export const DELETE_DEPOSIT_INIT = "DELETE_DEPOSIT_INIT";
export const DELETE_DEPOSIT_SUCCESS = "DELETE_DEPOSIT_SUCCESS";
export const DELETE_DEPOSIT_FAILURE = "DELETE_DEPOSIT_FAILURE";

export const RESET_GET_ALL_DEPOSITS = "RESET_GET_ALL_DEPOSITS";
export const GET_ALL_DEPOSITS_INIT = "GET_ALL_DEPOSITS_INIT";
export const GET_ALL_DEPOSITS_SUCCESS = "GET_ALL_DEPOSITS_SUCCESS";
export const GET_ALL_DEPOSITS_FAILURE = "GET_ALL_DEPOSITS_FAILURE";
