import {
  RESET_GET_MATRIZ4,
  GET_MATRIZ4_INIT,
  GET_MATRIZ4_SUCCESS,
  GET_MATRIZ4_FAILURE,
  RESET_UPDATE_MATRIZ4,
  UPDATE_MATRIZ4_INIT,
  UPDATE_MATRIZ4_SUCCESS,
  UPDATE_MATRIZ4_FAILURE,
} from "./types";
import API from "./api";

// GET
export function resetGetMatriz4() {
  return {
    type: RESET_GET_MATRIZ4,
  };
}
function getMatriz4Init() {
  return {
    type: GET_MATRIZ4_INIT,
  };
}
function getMatriz4Success(data) {
  return {
    type: GET_MATRIZ4_SUCCESS,
    payload: data,
  };
}
function getMatriz4Failure(error) {
  return {
    type: GET_MATRIZ4_FAILURE,
    payload: error,
  };
}
export function getMatriz4(data) {
  return async (dispatch) => {
    dispatch(getMatriz4Init());
    try {
      const resp = await API.data.getMatriz4(data);
      dispatch(getMatriz4Success(resp));
    } catch (error) {
      dispatch(getMatriz4Failure(error));
    }
  };
}

// UPDATE
export function resetUpdateMatriz4() {
  return {
    type: RESET_UPDATE_MATRIZ4,
  };
}
function updateMatriz4Init() {
  return {
    type: UPDATE_MATRIZ4_INIT,
  };
}
function updateMatriz4Success(data) {
  return {
    type: UPDATE_MATRIZ4_SUCCESS,
    payload: data,
  };
}
function updateMatriz4Failure(error) {
  return {
    type: UPDATE_MATRIZ4_FAILURE,
    payload: error,
  };
}
export function updateMatriz4(data) {
  return async (dispatch) => {
    dispatch(updateMatriz4Init());
    try {
      const resp = await API.data.updateMatriz4(data);
      dispatch(updateMatriz4Success(resp));
    } catch (error) {
      dispatch(updateMatriz4Failure(error));
    }
  };
}
