import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import Topbar from "./components/Topbar";

export const App = () => {
  return (
    <>
      <Topbar />
      <Outlet />
      <Footer />
    </>
  );
};
