import { useEffect, useState } from "react";
import {
  Card,
  Dropdown,
  Checkbox,
  Button,
  Icon,
  Image,
} from "semantic-ui-react";

import Loading from "../../../Loading/index";
import Expired from "../../../Expired/index";

import ModalActions from "../../../Modal/ModalActions";
import {
  resetUpdateParams,
  updateParams,
} from "./../../../../actions/params/index";
import { useDispatch } from "react-redux";
import MaskedInput from "react-text-mask";
import { createNumberMask } from "../../../../helpers";
import ohError from "../../../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const CantidadRelave = ({ stateParams }) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    valor_volumen: "",
    valor_superficie: "",
    valor_relave: "",
  });

  let servicio = localStorage.getItem("servicio");

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [datos, setDatos] = useState();

  //   DATA VOLUMEN
  const [dataVolumen, setDataVolumen] = useState([]);
  const [dataVolumenId, setDataVolumenId] = useState("");

  // DATA SUPERFICIE
  const [dataSuperficie, setDataSuperficie] = useState([]);
  const [dataSuperficieId, setDataSuperficieId] = useState("");

  // DATA RELAVE
  const [dataRelave, setDataRelave] = useState([]);
  const [dataRelaveId, setDataRelaveId] = useState("");

  // NEW DATA
  const [newDataVolumen, setNewDataVolumen] = useState("");
  const [newDataSuperficie, setNewDataSuperficie] = useState("");
  const [newDataRelave, setNewDataRelave] = useState("");

  // CHECKED DATA
  const [checkedVolumen, setCheckedVolumen] = useState([]);
  const [checkedSuperficie, setCheckedSuperficie] = useState([]);
  const [checkedRelave, setCheckedRelave] = useState([]);

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  //   DATA VOLUMEN
  useEffect(() => {
    const arr = [];
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.datos) {
        stateParams.dataParams.datos.datos.volumen.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataVolumen(arr);
  }, [stateParams.statusParams, stateParams.dataParams.datos]);

  const handleChangeCheckboxVolumen = (obj) => {
    const arr = [...checkedVolumen];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedVolumen(arr);
  };

  //   DATA SUPERFICIE
  useEffect(() => {
    const arr = [];
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.datos) {
        stateParams.dataParams.datos.datos.superficie.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataSuperficie(arr);
  }, [stateParams.statusParams, stateParams.dataParams.datos]);

  const handleChangeCheckboxSuperficie = (obj) => {
    const arr = [...checkedSuperficie];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedSuperficie(arr);
  };

  //   DATA RELAVE
  useEffect(() => {
    const arr = [];
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.datos) {
        stateParams.dataParams.datos.datos.cantidad.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataRelave(arr);
  }, [stateParams.statusParams, stateParams.dataParams.datos]);

  const handleChangeCheckboxRelave = (obj) => {
    const arr = [...checkedRelave];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedRelave(arr);
  };

  const resetData = () => {
    dispatch(resetUpdateParams());
  };

  const handleClickUpdate = () => {
    let valor_volumen =
      typeof form.valor_volumen === "string"
        ? form.valor_volumen.replace(/\./g, "")
        : form.valor_volumen;
    let valor_superficie =
      typeof form.valor_superficie === "string"
        ? form.valor_superficie.replace(/\./g, "")
        : form.valor_superficie;

    let valor_relave =
      typeof form.valor_relave === "string"
        ? form.valor_relave.replace(/\./g, "")
        : form.valor_relave;

    dispatch(
      updateParams({
        tipo_de_datos: "cantidades",
        clave_tipo_deposito: stateParams.dataParams.datos.clave_tipo_deposito,
        periodo_id: stateParams.dataParams.datos.periodo.id,
        cantidades: {
          volumen: {
            valor: parseInt(valor_volumen, 10),
            opcion_informacion_id: dataVolumenId,
            documentos_que_respaldan: checkedVolumen,
          },
          superficie: {
            valor: parseInt(valor_superficie, 10),
            opcion_informacion_id: dataSuperficieId,
            documentos_que_respaldan: checkedSuperficie,
          },
          cantidad: {
            valor: parseInt(valor_relave, 10),
            opcion_informacion_id: dataRelaveId,
            documentos_que_respaldan: checkedRelave,
          },
        },
      })
    );
  };

  useEffect(() => {
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        if (stateParams.dataParams.datos.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
  }, [stateParams.dataParams, stateParams.statusParams]);

  //   CARGAR DATA
  useEffect(() => {
    const callsAPI = async () => {
      if (stateParams.statusParams === 200) {
        if (stateParams.dataParams.ejecucion.estado) {
          if (stateParams.dataParams.datos.datos.sin_datos === false) {
            setForm({
              valor_volumen:
                stateParams.dataParams.datos.datos.volumen.valor !== null
                  ? stateParams.dataParams.datos.datos.volumen.valor
                  : "",
              valor_superficie:
                stateParams.dataParams.datos.datos.superficie.valor !== null
                  ? stateParams.dataParams.datos.datos.superficie.valor
                  : "",
              valor_relave:
                stateParams.dataParams.datos.datos.cantidad.valor !== null
                  ? stateParams.dataParams.datos.datos.cantidad.valor
                  : "",
            });
          }
        }
      }
    };
    callsAPI();
    //eslint-disable-next-line
  }, [stateParams.dataParams, stateParams.statusParams]);

  useEffect(() => {
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        if (stateParams.dataParams.datos.datos.sin_datos === false) {
          setDataVolumenId(newDataVolumen);
          setDataSuperficieId(newDataSuperficie);
          setDataRelaveId(newDataRelave);
        }
      }
    }
    // eslint-disable-next-line
  }, [newDataVolumen, newDataSuperficie]);

  useEffect(() => {
    const arrVolumen = [...checkedVolumen];
    const arrSuperficie = [...checkedSuperficie];
    const arrRelave = [...checkedRelave];

    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        stateParams.dataParams.datos.datos.volumen.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrVolumen.push(e.clave)
        );
        setCheckedVolumen(arrVolumen);

        stateParams.dataParams.datos.datos.superficie.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrSuperficie.push(e.clave)
        );
        setCheckedSuperficie(arrSuperficie);

        stateParams.dataParams.datos.datos.cantidad.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrRelave.push(e.clave)
        );
        setCheckedRelave(arrRelave);
      }
    }
    // eslint-disable-next-line
  }, [stateParams.statusParams]);

  //   CARGAR DROPDOWN DE INGRESAR DATOS
  useEffect(() => {
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        if (datos === false) {
          if (dataVolumen.length > 0) {
            const dataSelectedVolumen = dataVolumen.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataVolumen(dataSelectedVolumen.value);
          }

          if (dataSuperficie.length > 0) {
            const dataSelectedSuperficie = dataSuperficie.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataSuperficie(dataSelectedSuperficie.value);
          }

          if (dataRelave.length > 0) {
            const dataSelectedRelave = dataRelave.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataRelave(dataSelectedRelave.value);
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [dataVolumen, dataSuperficie]);

  const intl = useIntl();
  const placeholderSeleccione = intl.formatMessage({ id: "Seleccione" });
  const placeholderIngreseValor = intl.formatMessage({ id: "IngreseValor" });

  if (stateParams.statusParams === 501 || stateParams.statusParams === 401) {
    return <Expired />;
  }

  return (
    <>
      {stateParams.loadingParams ? (
        <Loading />
      ) : (
        <Card fluid className="card-ef-params">
          {stateParams.dataParams.ejecucion.estado ? (
            <>
              <h3>{stateParams.dataParams.datos.datos.volumen.titulo}</h3>
              <div className="matrix6__container-dropdown">
                <Dropdown
                  placeholder={placeholderSeleccione}
                  selection
                  options={dataVolumen}
                  style={{ width: "100%" }}
                  value={datos === false ? newDataVolumen : dataVolumenId}
                  onChange={(_, data) => {
                    setDataVolumenId(data.value);
                    setNewDataVolumen(data.value);
                  }}
                  disabled={servicio === "true" ? true : false}
                />

                {dataVolumenId === "" && newDataVolumen === "" && (
                  <Icon name="warning" className="icon-warning" />
                )}
              </div>

              {dataVolumenId === 1 && (
                <>
                  <div className="matrix3__container-valor">
                    <div>
                      <div className="ui input">
                        <MaskedInput
                          mask={createNumberMask()}
                          guide={false}
                          onChange={(e) => handleChange(e)}
                          name="valor_volumen"
                          value={form.valor_volumen}
                          disabled={servicio === "true" ? true : false}
                          maxLength={17}
                          placeholder={placeholderIngreseValor}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      <FormattedMessage id="DocumentosRespaldan" />
                    </p>
                    {stateParams.dataParams.datos.datos.volumen.documentos_que_respaldan.map(
                      (item, i) => {
                        let index = checkedVolumen.includes(item.clave);

                        return (
                          <Checkbox
                            key={i}
                            label={item.label}
                            onChange={() => handleChangeCheckboxVolumen(item)}
                            value={item.clave}
                            checked={index}
                            disabled={
                              servicio === "true"
                                ? true
                                : !item.editable
                                ? true
                                : false
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </>
              )}

              <h3>{stateParams.dataParams.datos.datos.superficie.titulo}</h3>
              <div className="matrix6__container-dropdown">
                <Dropdown
                  placeholder={placeholderSeleccione}
                  selection
                  options={dataSuperficie}
                  style={{ width: "100%" }}
                  value={datos === false ? newDataSuperficie : dataSuperficieId}
                  onChange={(_, data) => {
                    setDataSuperficieId(data.value);
                    setNewDataSuperficie(data.value);
                  }}
                  disabled={servicio === "true" ? true : false}
                />

                {dataSuperficieId === "" && newDataSuperficie === "" && (
                  <Icon name="warning" className="icon-warning" />
                )}
              </div>

              {dataSuperficieId === 1 && (
                <>
                  <div className="matrix3__container-valor">
                    <div>
                      <div className="ui input">
                        <MaskedInput
                          mask={createNumberMask()}
                          guide={false}
                          onChange={(e) => handleChange(e)}
                          name="valor_superficie"
                          value={form.valor_superficie}
                          disabled={servicio === "true" ? true : false}
                          maxLength={17}
                          placeholder={placeholderIngreseValor}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      <FormattedMessage id="DocumentosRespaldan" />
                    </p>
                    {stateParams.dataParams.datos.datos.superficie.documentos_que_respaldan.map(
                      (item, i) => {
                        let index = checkedSuperficie.includes(item.clave);

                        return (
                          <Checkbox
                            key={i}
                            label={item.label}
                            onChange={() =>
                              handleChangeCheckboxSuperficie(item)
                            }
                            value={item.clave}
                            checked={index}
                            disabled={
                              servicio === "true"
                                ? true
                                : !item.editable
                                ? true
                                : false
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </>
              )}

              <h3>{stateParams.dataParams.datos.datos.cantidad.titulo}</h3>
              <div className="matrix6__container-dropdown">
                <Dropdown
                  placeholder={placeholderSeleccione}
                  selection
                  options={dataRelave}
                  style={{ width: "100%" }}
                  value={datos === false ? newDataRelave : dataRelaveId}
                  onChange={(_, data) => {
                    setDataRelaveId(data.value);
                    setNewDataRelave(data.value);
                  }}
                  disabled={servicio === "true" ? true : false}
                />

                {dataRelaveId === "" && newDataRelave === "" && (
                  <Icon name="warning" className="icon-warning" />
                )}
              </div>

              {dataRelaveId === 1 && (
                <>
                  <div className="matrix3__container-valor">
                    <div>
                      <div className="ui input">
                        <MaskedInput
                          mask={createNumberMask()}
                          guide={false}
                          onChange={(e) => handleChange(e)}
                          name="valor_relave"
                          value={form.valor_relave}
                          disabled={servicio === "true" ? true : false}
                          maxLength={17}
                          placeholder={placeholderIngreseValor}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      <FormattedMessage id="DocumentosRespaldan" />
                    </p>
                    {stateParams.dataParams.datos.datos.cantidad.documentos_que_respaldan.map(
                      (item, i) => {
                        let index = checkedRelave.includes(item.clave);

                        return (
                          <Checkbox
                            key={i}
                            label={item.label}
                            onChange={() => handleChangeCheckboxRelave(item)}
                            value={item.clave}
                            checked={index}
                            disabled={
                              servicio === "true"
                                ? true
                                : !item.editable
                                ? true
                                : false
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </>
              )}

              {dataVolumenId !== "" &&
                dataSuperficieId !== "" &&
                dataRelaveId !== "" &&
                servicio === "false" && (
                  <div
                    className="btn-update-matrix1"
                    style={{ marginTop: "15px" }}
                  >
                    <Button onClick={onShowModal}>
                      <FormattedMessage id="GuardarMayus" />
                    </Button>
                  </div>
                )}

              <ModalActions
                title={<FormattedMessage id="ActualizarCantidadRelaves" />}
                show={showModal}
                setShow={setShowModal}
                status={stateParams.statusUpdateParams}
                action={handleClickUpdate}
                reset={resetData}
                deleteAction={"Delete"}
                estado={stateParams.dataUpdateParams}
                loading={stateParams.loadingParams}
              >
                {stateParams.statusUpdateParams === 0 ? (
                  <FormattedMessage id="EstaSeguroActualizarInformacion" />
                ) : stateParams.dataUpdateParams.ejecucion.estado ? (
                  <div className="container-modal">
                    <p className="message-ok">
                      {stateParams.dataUpdateParams.ejecucion.mensaje}
                    </p>
                    <Icon name="checkmark" className="status-ok" />
                  </div>
                ) : (
                  <div className="container-modal">
                    <p className="message-fail">
                      {stateParams.dataUpdateParams.ejecucion.mensaje}
                    </p>
                    <Image src={ohError} className="status-fail" />
                  </div>
                )}
              </ModalActions>
            </>
          ) : (
            <Card.Content>
              {stateParams.dataParams.ejecucion.mensaje}
            </Card.Content>
          )}
        </Card>
      )}
    </>
  );
};

export default CantidadRelave;
