export const RESET_ALL_PARAMS = "RESET_ALL_PARAMS";
export const GET_ALL_PARAMS_INIT = "GET_ALL_PARAMS_INIT";
export const GET_ALL_PARAMS_FAILURE = "GET_ALL_PARAMS_FAILURE";
export const GET_ALL_PARAMS_SUCCESS = "GET_ALL_PARAMS_SUCCESS";

export const RESET_UPDATE_PARAMS = "RESET_UPDATE_PARAMS";
export const UPDATE_PARAMS_INIT = "UPDATE_PARAMS_INIT";
export const UPDATE_PARAMS_SUCCESS = "UPDATE_PARAMS_SUCCESS";
export const UPDATE_PARAMS_FAILURE = "UPDATE_PARAMS_FAILURE";

export const RESET_HISTORIC_PARAMS = "RESET_HISTORIC_PARAMS";
export const GET_HISTORIC_PARAMS_INIT = "GET_HISTORIC_PARAMS_INIT";
export const GET_HISTORIC_PARAMS_SUCCESS = "GET_HISTORIC_PARAMS_SUCCESS";
export const GET_HISTORIC_PARAMS_FAILURE = "GET_HISTORIC_PARAMS_FAILURE";

export const RESET_DELETE_HISTORIC_PARAMS = "RESET_DELETE_HISTORIC_PARAMS";
export const DELETE_HISTORIC_PARAMS_INIT = "DELETE_HISTORIC_PARAMS_INIT";
export const DELETE_HISTORIC_PARAMS_SUCCESS = "DELETE_HISTORIC_PARAMS_SUCCESS";
export const DELETE_HISTORIC_PARAMS_FAILURE = "DELETE_HISTORIC_PARAMS_FAILURE";
