export const RESET_GET_EVALUACIONES = "RESET_GET_EVALUACIONES";
export const GET_EVALUACIONES_INIT = "GET_EVALUACIONES_INIT";
export const GET_EVALUACIONES_SUCCESS = "GET_EVALUACIONES_SUCCESS";
export const GET_EVALUACIONES_FAILURE = "GET_EVALUACIONES_FAILURE";

export const RESET_DELETE_EVALUACION = "RESET_DELETE_EVALUACION";
export const DELETE_EVALUACION_INIT = "DELETE_EVALUACION_INIT";
export const DELETE_EVALUACION_SUCCESS = "DELETE_EVALUACION_SUCCESS";
export const DELETE_EVALUACION_FAILURE = "DELETE_EVALUACION_FAILURE";

export const RESET_PUBLICAR_EVALUACION = "RESET_PUBLICAR_EVALUACION";
export const PUBLICAR_EVALUACION_INIT = "RESET_PUBLICAR_EVALUACION_INIT";
export const PUBLICAR_EVALUACION_SUCCESS = "RESET_PUBLICAR_EVALUACION_SUCCESS";
export const PUBLICAR_EVALUACION_FAILURE = "RESET_PUBLICAR_EVALUACION_FAILURE";

export const RESET_NUEVA_EVALUACION = "RESET_NUEVA_EVALUACION";
export const NUEVA_EVALUACION_INIT = "NUEVA_EVALUACION_INIT";
export const NUEVA_EVALUACION_SUCCESS = "NUEVA_EVALUACION_SUCCESS";
export const NUEVA_EVALUACION_FAILURE = "NUEVA_EVALUACION_FAILURE";

export const RESET_VALIDAR_EVALUACION = "RESET_VALIDAR_EVALUACION";
export const VALIDAR_EVALUACION_INIT = "VALIDAR_EVALUACION_INIT";
export const VALIDAR_EVALUACION_SUCCESS = "VALIDAR_EVALUACION_SUCCESS";
export const VALIDAR_EVALUACION_FAILURE = "VALIDAR_EVALUACION_FAILURE";
