export const RESET_CHORE = "RESET_CHORE";
export const GET_CHORE_INIT = "GET_CHORE_INIT";
export const GET_CHORE_SUCCESS = "GET_CHORE_SUCCESS";
export const GET_CHORE_FAILURE = "GET_CHORE_FAILURE";

export const RESET_DATA_CHORE = "RESET_DATA_CHORE";
export const GET_DATA_CHORE_INIT = "GET_DATA_CHORE_INIT";
export const GET_DATA_CHORE_SUCCESS = "GET_DATA_CHORE_SUCCESS";
export const GET_DATA_CHORE_FAILURE = "GET_DATA_CHORE_FAILURE";

export const RESET_DELETE_CHORE = "RESET_DELETE_CHORE";
export const DELETE_CHORE_INIT = "DELETE_CHORE_INIT";
export const DELETE_CHORE_SUCCESS = "DELETE_CHORE_SUCCESS";
export const DELETE_CHORE_FAILURE = "DELETE_CHORE_FAILURE";
