import {
  RESET_GET_MATRIZ5,
  GET_MATRIZ5_INIT,
  GET_MATRIZ5_SUCCESS,
  GET_MATRIZ5_FAILURE,
  RESET_UPDATE_MATRIZ5,
  UPDATE_MATRIZ5_INIT,
  UPDATE_MATRIZ5_SUCCESS,
  UPDATE_MATRIZ5_FAILURE,
} from "../../actions/matriz5/types";
import initialState from "./initialState";

export default function matriz5(state = initialState, action) {
  switch (action.type) {
    // GET
    case RESET_GET_MATRIZ5:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_MATRIZ5_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_MATRIZ5_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_MATRIZ5_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    // UPDATE
    case RESET_UPDATE_MATRIZ5:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_MATRIZ5_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_MATRIZ5_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_MATRIZ5_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };

    default:
      return state;
  }
}
