import initialState from "./initialState";
import {
  RESET_OBTENER_URL,
  GET_OBTENER_URL_INIT,
  GET_OBTENER_URL_SUCCESS,
  GET_OBTENER_URL_FAILURE,
} from "./../../actions/url/types";

export default function url(state = initialState, action) {
  switch (action.type) {
    // URL
    case RESET_OBTENER_URL:
      return {
        ...state,
        dataUrl: {},
        loadingUrl: false,
        statusUrl: 0,
      };
    case GET_OBTENER_URL_INIT:
      return {
        ...state,

        loadingUrl: true,
      };
    case GET_OBTENER_URL_SUCCESS:
      return {
        ...state,
        dataUrl: action.payload.data,
        loadingUrl: false,
        statusUrl: action.payload.status,
      };
    case GET_OBTENER_URL_FAILURE:
      return {
        ...state,
        dataUrl: {},
        loadingUrl: false,
        statusUrl: 501,
      };
    default:
      return state;
  }
}
