import {
  RESET_ADD_REGISTER,
  ADD_REGISTER_INIT,
  ADD_REGISTER_SUCCESS,
  ADD_REGISTER_FAILURE,
} from "../../actions/add_register/types";
import initialState from "./initialState";

export default function addRegistro(state = initialState, action) {
  switch (action.type) {
    case RESET_ADD_REGISTER:
      return {
        ...state,
        dataAdd: {},
        loadingAdd: false,
        statusAdd: 0,
      };
    case ADD_REGISTER_INIT:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_REGISTER_SUCCESS:
      return {
        ...state,
        dataAdd: action.payload.data,
        loadingAdd: false,
        statusAdd: action.payload.status,
      };
    case ADD_REGISTER_FAILURE:
      return {
        ...state,
        dataAdd: {},
        loadingAdd: false,
        statusAdd: 501,
      };

    default:
      return state;
  }
}
