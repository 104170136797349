import { Button, Form, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { LoginDeport, ResetLoginDeport } from "./../../actions/login/api";
import { Link, Navigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";

const LoginPageComponent = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { Login } = useSelector((state) => state);

  const [error, setError] = useState(false);

  let auth = localStorage.getItem("auth");
  auth = auth === "true";

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      dispatch(
        LoginDeport({
          provider: "sistema",
          sistema: { email: formData.email, password: formData.password },
        })
      );
    },
  });

  useEffect(() => {
    if (Login.status === 501) {
      setError(true);
    }
  }, [Login.status]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(ResetLoginDeport());
        setError(false);
      }, 5000);
    }
  }, [dispatch, error]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  let servicio = localStorage.getItem("servicio");

  const intl = useIntl();

  const placeHolderCorreo = intl.formatMessage({ id: "Correo" });
  const placeHolderContraseña = intl.formatMessage({ id: "Contraseña" });

  return auth ? (
    servicio === "true" ? (
      <Navigate to="/dashboard/pucv" replace={true} />
    ) : (
      <Navigate to="/dashboard/company" replace={true} />
    )
  ) : (
    <Form className="login-inputs" onSubmit={formik.handleSubmit}>
      <Form.Input
        name="email"
        type="text"
        placeholder={placeHolderCorreo}
        onChange={formik.handleChange}
        error={formik.errors.email}
      />
      <Form.Input
        name="password"
        type={showPassword ? "text" : "password"}
        placeholder={placeHolderContraseña}
        onChange={formik.handleChange}
        error={formik.errors.password}
      >
        <input />
        <div className="container-icon" onClick={handleShowPassword}>
          {!showPassword ? <Icon name="eye" /> : <Icon name="eye slash" />}
        </div>
      </Form.Input>

      <div className="recover-password">
        <Link to="/recover-password">
          <p>
            <FormattedMessage id="RecuperarClave" />
          </p>
        </Link>
      </div>

      <div>
        <Button className="btn-login" type="submit">
          {Login.loading ? (
            <FormattedMessage id="CargandoMayus" />
          ) : (
            <FormattedMessage id="IngresarMayus" />
          )}
        </Button>
      </div>

      {error && (
        <h1 className="login-error-msg">
          <FormattedMessage id="ErrorLogin" />
        </h1>
      )}
    </Form>
  );
};

function initialValues() {
  return {
    email: "",
    password: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string().email(true).required(true),
    password: Yup.string().required(true),
  };
}

export default LoginPageComponent;
