import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  Dropdown,
  Checkbox,
  TextArea,
  Button,
  Icon,
  Image,
} from "semantic-ui-react";

import Loading from "../Loading";
import {
  getMatriz4,
  resetGetMatriz4,
  updateMatriz4,
} from "../../actions/matriz4/index";
import ModalActions from "./../Modal/ModalActions";
import { resetUpdateMatriz4 } from "./../../actions/matriz4/index";
import Expired from "./../Expired/index";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

export function Matrix4({ id }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.matriz4);

  const [form, setForm] = useState({
    comentario: "",
  });

  const minero = localStorage.getItem("minero");

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [datos, setDatos] = useState();

  const [dataSuelo, setDataSuelo] = useState([]);
  const [dataSueloId, setDataSueloId] = useState("");

  const [newDataSuelo, setNewDataSuelo] = useState("");

  const [checkedSuelo, setCheckedSuelo] = useState([]);

  useEffect(() => {
    dispatch(
      getMatriz4({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetMatriz4());
    };
  }, [dispatch]);

  //   DATA SUELO
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.caracterizacion_del_suelo.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.nombre,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataSuelo(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxSuelo = (obj) => {
    const arr = [...checkedSuelo];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedSuelo(arr);
  };

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.loading]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          if (dataSuelo.length > 0) {
            const dataSelectedSuelo = dataSuelo.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataSuelo(dataSelectedSuelo.value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [dataSuelo]);

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz4({
        tipo_matriz: state.data.datos.tipo_matriz,
        deposito_id: id,
        caracterizacion_del_suelo_sin_antecedentes:
          dataSueloId === 1 ? true : false,
        caracterizacion_del_suelo_id: dataSueloId,
        documentos_que_respaldan: checkedSuelo,
        comentario: form.comentario,
      })
    );
  };

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setForm({
              comentario:
                state.data.datos.datos.comentario.valor !== null
                  ? state.data.datos.datos.comentario.valor
                  : "",
            });

            setDataSueloId(newDataSuelo);

            const arrSuelo = [];

            state.data.datos.datos.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrSuelo.push(e.clave)
            );
            setCheckedSuelo(arrSuelo);
          }
        }
      }
    };
    callsAPI();
    // eslint-disable-next-line
  }, [state.loading, state.status, state.data, newDataSuelo]);

  const resetData = () => {
    dispatch(resetUpdateMatriz4());
  };

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({ id: "IngreseComentario" });
  const placeholderSeleccione = intl.formatMessage({ id: "Seleccione" });

  /* When the token is expired*/
  if (state.status === 501 || state.status === 401) {
    return <Expired />;
  }

  return (
    <Container>
      {state.loading ? (
        <Loading />
      ) : (
        <Card fluid className="card-deposit">
          {state.data.ejecucion.estado ? (
            <>
              <Card.Content>
                <div
                  className="card-deposit-info"
                  style={{ flexDirection: "row" }}
                >
                  {state.data.datos.datos.titulo_matriz}
                </div>
              </Card.Content>

              <Card.Content className="card-container__matrix2">
                <>
                  <div className="matrix2__container-dropdown">
                    <Dropdown
                      placeholder={placeholderSeleccione}
                      selection
                      options={dataSuelo}
                      style={{ width: "100%" }}
                      value={datos === false ? newDataSuelo : dataSueloId}
                      onChange={(_, data) => {
                        setDataSueloId(data.value);
                        setNewDataSuelo(data.value);
                      }}
                      disabled={minero === "false" ? true : false}
                    />
                    {dataSueloId === "" && newDataSuelo === "" && (
                      <Icon name="warning" className="icon-warning" />
                    )}
                  </div>
                  {dataSueloId !== "" &&
                    newDataSuelo !== "" &&
                    dataSueloId !== 40 &&
                    newDataSuelo !== 40 && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {state.data.datos.datos.documentos_que_respaldan.map(
                          (item, i) => {
                            let index = checkedSuelo.includes(item.clave);

                            return (
                              <Checkbox
                                key={i}
                                label={item.label}
                                onChange={() => handleChangeCheckboxSuelo(item)}
                                value={item.clave}
                                checked={index}
                                disabled={
                                  minero === "false"
                                    ? true
                                    : !item.editable
                                    ? true
                                    : false
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    )}

                  {dataSueloId !== "" && newDataSuelo !== "" && (
                    <>
                      <TextArea
                        placeholder={placeholderComentario}
                        name="comentario"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{ width: "100%", marginTop: "20px" }}
                        value={form.comentario}
                        disabled={minero === "false" ? true : false}
                        maxLength="1000"
                      />
                      {minero === "true" && (
                        <div
                          className="btn-update-matrix1"
                          style={{ marginTop: "15px" }}
                        >
                          <Button onClick={onShowModal}>
                            <FormattedMessage id="GuardarMayus" />
                          </Button>
                        </div>
                      )}
                    </>
                  )}

                  <ModalActions
                    title={<FormattedMessage id="ActualizarMatriz4" />}
                    show={showModal}
                    setShow={setShowModal}
                    status={state.statusUpdate}
                    action={handleClickUpdate}
                    reset={resetData}
                    deleteAction={"Delete"}
                    estado={state.dataUpdate}
                    loading={state.loadingUpdate}
                  >
                    {state.statusUpdate === 0 ? (
                      <FormattedMessage id="EstaSeguroMatriz4" />
                    ) : state.dataUpdate.ejecucion.estado ? (
                      <div className="container-modal">
                        <p className="message-ok">
                          {state.dataUpdate.ejecucion.mensaje}
                        </p>
                        <Icon name="checkmark" className="status-ok" />
                      </div>
                    ) : (
                      <div className="container-modal">
                        <p className="message-fail">
                          {state.dataUpdate.ejecucion.mensaje}
                        </p>
                        <Image src={ohError} className="status-fail" />
                      </div>
                    )}
                  </ModalActions>
                </>
              </Card.Content>
            </>
          ) : (
            <Card.Content>{state.data.ejecucion.mensaje}</Card.Content>
          )}
        </Card>
      )}
    </Container>
  );
}

export default Matrix4;
