import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Container,
  Icon,
  Image,
  Form,
  Checkbox,
  Button,
  Label,
} from "semantic-ui-react";

import { useFormik } from "formik";
import * as Yup from "yup";

import folder from "../../assets/icons/icono_folder-red.png";
import edit from "../../assets/icons/icono_edit.png";
import addDepositIcon from "../../assets/icons/btn-sumar_mas-red.png";
import closeBtn from "../../assets/icons/icono_close-grey.png";

import { getDeposits, resetGetDataDeposit } from "../../actions/deposits";
import { getDataChore, resetGetDataChore } from "../../actions/chore";
import DividerGrey from "./../../components/DividerGrey/index";
import Loading from "./../../components/Loading/index";
import DashboardUbication from "../../components/DashboardUbication";
import Expired from "./../../components/Expired/index";
import ButtonsDashboard from "../../components/ButtonsDashboard";
import { getListDocumentsDeposit } from "../../actions/list-documents";
import ModalMessage from "./../../components/Modal/index";
import { addDeposit, resetAddDeposit } from "./../../actions/add_deposit/index";

import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { DropdownAllRegion } from "../../components/Dropdown/DropdownAllRegion";
import { DropdownAllProvidencia } from "../../components/Dropdown/DropdownAllProvincia";
import { DropdownAllComuna } from "./../../components/Dropdown/DropdownAllComuna";
import { DropdownRecurso } from "../../components/Dropdown/DropdownRecurso";
import { DropdownStatus } from "../../components/Dropdown/DropdownEstado";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const Chore = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.deposit);
  const stateData = useSelector((state) => state.chore);

  const location = useLocation();

  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getDeposits({
        faena_id: id,
      })
    );
    dispatch(
      getDataChore({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetDataChore());
      dispatch(resetGetDataDeposit());
    };
  }, [dispatch]);

  if (
    /* When the token is expired*/
    state.status === 501 ||
    stateData.status === 501
  ) {
    return <Expired />;
  }

  return (
    <Container>
      <div
        className={location.pathname === "/dashboard/add-deposit" ? "flex" : ""}
      >
        <>
          {location.pathname === "/dashboard/add-deposit" ? (
            <ButtonsDashboard />
          ) : (
            <>
              <DashboardUbication>
                {stateData.statusChore === 200 ? (
                  `${stateData.dataChore.datos.razon_social}/${stateData.dataChore.datos.nombre}`
                ) : (
                  <>
                    <FormattedMessage id="CargandoMinus" />
                  </>
                )}
              </DashboardUbication>
            </>
          )}
        </>
        <DividerGrey />

        {location.pathname === `/dashboard/chore/${id}` && (
          <div>
            {stateData.loadingChore || state.loading ? (
              <Loading />
            ) : (
              <div style={{ display: "flex", gap: "15px" }}>
                <CardDepositInfo state={state} stateData={stateData} id={id} />
                <CardChoreInfo stateData={stateData} id={id} />
              </div>
            )}
          </div>
        )}

        {location.pathname === "/dashboard/add-deposit" && (
          <Container className="card-faena">
            {state.loading ? (
              <Loading />
            ) : (
              <div>
                <AddDeposit />
              </div>
            )}
          </Container>
        )}
      </div>
    </Container>
  );
};

function CardDepositInfo({ state, stateData, id }) {
  const minero = localStorage.getItem("minero");
  return (
    <Container style={{ width: "40%" }}>
      <Card fluid className="card-chore">
        <Card.Content>
          <div className="card-chore-title">
            {stateData.dataChore.datos.nombre}
          </div>
        </Card.Content>

        <div className="card-chore-deposits">
          <FormattedMessage id="DepositosMayus" />
          {minero === "true" && (
            <Link to="/dashboard/add-deposit" state={{ state: id }}>
              <Image src={addDepositIcon} />
            </Link>
          )}
        </div>

        <Card.Content style={{ borderTop: "none" }}>
          {state.data.ejecucion.estado &&
            state.data.datos.map((item) => {
              return (
                <Link
                  to={`/dashboard/deposit/${item.id}`}
                  state={{ state: stateData }}
                  style={{ color: "black" }}
                  key={item.id}
                >
                  <div className="card-chore-container-name">
                    <div className="container-card-left-deposit-chore">
                      <Image
                        src={folder}
                        alt="Icono Usuario"
                        className="icon-folder"
                      />
                      <div className="card-chore-name-deposits">
                        {item.nombre}
                      </div>
                    </div>
                    <Icon name="angle right" />
                  </div>
                </Link>
              );
            })}
        </Card.Content>
      </Card>
    </Container>
  );
}

function CardChoreInfo({ stateData, id }) {
  const minero = localStorage.getItem("minero");
  return (
    <Container>
      <Card fluid className="card-chore">
        <Card.Content>
          <div className="card-chore-info">
            <FormattedMessage id="Informacion" />{" "}
            {stateData.dataChore.datos.nombre}
            <div>
              {minero === "true" && (
                <Link to={`/dashboard/edit-chore/${id}`}>
                  <Image src={edit} className="card-chore-icon" />
                </Link>
              )}
            </div>
          </div>
        </Card.Content>
        <Card.Content
          style={{ borderTop: "none", display: "flex", gap: "15px" }}
        >
          <div className="card-chore-info__container" style={{ width: "100%" }}>
            <h4>
              <FormattedMessage id="DireccionMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.calle}</h5>
          </div>
        </Card.Content>

        <Card.Content
          style={{ borderTop: "none", display: "flex", gap: "15px" }}
        >
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="UTMNorteMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.utm_norte}</h5>
          </div>
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="UTMEsteMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.utm_este}</h5>
          </div>
        </Card.Content>

        <Card.Content
          style={{ borderTop: "none", display: "flex", gap: "15px" }}
        >
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="NombreRepresentanteLegalMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.nombre_representante_legal}</h5>
          </div>
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="CorreoRepresentanteLegalMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.email_representante_legal}</h5>
          </div>
        </Card.Content>

        <Card.Content
          style={{ borderTop: "none", display: "flex", gap: "15px" }}
        >
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="RutRepresentanteLegalMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.rut_representante_legal}</h5>
          </div>
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="DireccionRepresentanteLegalMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.direccion_representante_legal}</h5>
          </div>
        </Card.Content>

        <Card.Content
          style={{ borderTop: "none", display: "flex", gap: "15px" }}
        >
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="TelefonoRepresentanteLegalMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.telefono_representante_legal}</h5>
          </div>
        </Card.Content>

        <Card.Content
          style={{ borderTop: "none", display: "flex", gap: "15px" }}
        >
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="NombreProfesionalResponsableMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.nombre_profesional_responsable}</h5>
          </div>
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="CorreoProfesionalResponsableMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.correo_profesional_responsable}</h5>
          </div>
        </Card.Content>

        <Card.Content
          style={{ borderTop: "none", display: "flex", gap: "15px" }}
        >
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="TelefonoProfesionalResponsableMayus" />
            </h4>
            <h5>
              {stateData.dataChore.datos.telefono_profesional_responsable}
            </h5>
          </div>
          <div className="card-chore-info__container">
            <h4>
              <FormattedMessage id="CargoProfesionalResponsableMayus" />
            </h4>
            <h5>{stateData.dataChore.datos.cargo_profesional_responsable}</h5>
          </div>
        </Card.Content>
      </Card>
    </Container>
  );
}

function AddDeposit() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.addDeposit);

  const location = useLocation();

  const stateListDocs = useSelector((state) => state.listDocsDeposit);

  const [showModal, setShowModal] = useState(false);
  const onShowModal = () => setShowModal(true);

  const [startDate, setStartDate] = useState(null);
  const [startDateDeposito, setStartDateDeposito] = useState(null);
  const [startDateFaena, setStartDateFaena] = useState(null);

  const navigate = useNavigate();

  const [checkedDistancia, setCheckedDistancia] = useState([]);
  const [checkedVida, setCheckedVida] = useState([]);

  const selectionRecibido = [
    {
      key: 1,
      value: 1,
      text: <FormattedMessage id="RecibidoMayus" />,
    },
    {
      key: 0,
      value: 0,
      text: <FormattedMessage id="NoRecibidoMayus" />,
    },
  ];

  const selectionEstado = [
    {
      key: 1,
      value: 1,
      text: <FormattedMessage id="AprobadoMayus" />,
    },
    {
      key: 0,
      value: 0,
      text: <FormattedMessage id="NoAprobadoMayus" />,
    },
  ];

  const handleChangeCheckBox = (obj) => {
    const arr = [...checkedDistancia];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedDistancia(arr);
  };

  const handleChangeCheckBoxVida = (obj) => {
    const arr = [...checkedVida];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedVida(arr);
  };

  useEffect(() => {
    dispatch(getListDocumentsDeposit());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      dispatch(
        addDeposit({
          nombre: formData.nombre,
          comuna_id: formData.comuna_id,
          utm_norte: formData.utmNorte,
          utm_este: formData.utmEste,
          id_recurso_primario: formData.recurso_id,
          cantidad_relaves_autorizado_m3: formData.cantidadRelavesAutorizadoM3,
          cantidad_relaves_actual_m3: formData.cantidadRelavesActualM3,
          cantidad_relaves_autorizado_ton: formData.cantidadRelavesAutorizadoT,
          cantidad_relaves_actual_ton: formData.cantidadRelavesActualT,
          metodo_construccion: formData.metodoConstruccion,
          nombre_profesional_responsable: formData.nombreProfesional,
          cargo_profesional_responsable: formData.cargo,

          distancia_peligrosa: formData.distanciaPeligrosa,
          distancia_peligrosa_documentos: checkedDistancia,

          vida_util: formData.vidaUtil,
          vida_util_documentos: checkedVida,

          fecha_inicio_operacion: format(startDate, "dd-MM-yyyy"),

          estado_id: formData.estado_id,

          res_pdc_aprueba: formData.resolucionAprobatoria,
          res_aprueba: formData.resolucionAprobatoriaD,
          res_pdc_fecha: format(startDateFaena, "dd-MM-yyyy"),
          res_aprueba_fecha: format(startDateDeposito, "dd-MM-yyyy"),
          plan_de_cierre_recibido: formData.recibido_id,
          plan_de_cierre_estado: formData.cierre_estado_id,

          faena_id: location.state.state,
        })
      );
    },
  });

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetAddDeposit());

    if (state.dataAdd.ejecucion.estado) {
      window.location.replace(`/dashboard/chore/${location.state.state}`);
    }
  };

  const intl = useIntl();
  const placeholderNombreDeposito = intl.formatMessage({
    id: "EscribaNombreDeposito",
  });
  const placeholderIndique = intl.formatMessage({ id: "Indique" });
  const placeholderCantidadRelavesM3Autorizado = intl.formatMessage({
    id: "CantidadRelavesAutorizadoM3Minus",
  });
  const placeholderCantidadRelavesM3Actual = intl.formatMessage({
    id: "CantidadRelavesActualM3Minus",
  });
  const placeholderCantidadRelavesAutorizadoT = intl.formatMessage({
    id: "CantidadRelavesAutorizadoTMinus",
  });
  const placeholderCantidadRelavesActualT = intl.formatMessage({
    id: "CantidadRelavesActualTMinus",
  });
  const placeholderMetodoConstruccion = intl.formatMessage({
    id: "MetodoConstruccionMinus",
  });
  const placeholderResolucionAprobatoria = intl.formatMessage({
    id: "ResolucionAprobatoriaFaenaMinus",
  });
  const placeholderResolucionAprobatoriaDeposito = intl.formatMessage({
    id: "ResolucionAprobatoriaDepositoMinus",
  });

  const placeholderCargando = intl.formatMessage({ id: "CargandoMinus" });
  const placeholderRecibido = intl.formatMessage({ id: "RecibidoMayus" });
  const placeholderEstado = intl.formatMessage({ id: "EstadoMayus" });
  const placeholderNombreProfesional = intl.formatMessage({
    id: "NombreProfesionalResponsableMinus",
  });
  const placeholderCargo = intl.formatMessage({
    id: "EscribaCargoProfesionalResponsable",
  });
  const placeholderDistanciaPeligrosa = intl.formatMessage({
    id: "DistanciaPeligrosaMinus",
  });
  const placeholderDuracionDeposito = intl.formatMessage({
    id: "DuracionDepositoMinus",
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="container-card-add-faena">
        <h3 className="card-faena-title">
          <FormattedMessage id="RegistroNuevoDeposito" />
        </h3>
        <Image
          src={closeBtn}
          alt="Cerrar"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Form.Group widths="equal">
        <Form.Input
          name="nombre"
          label={<FormattedMessage id="NombreMayus" />}
          type="text"
          placeholder={placeholderNombreDeposito}
          onChange={formik.handleChange}
          error={formik.errors.nombre}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <DropdownAllRegion formik={formik} />

        <DropdownAllProvidencia
          formik={formik}
          region_id={formik.values.region_id}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <DropdownAllComuna
          formik={formik}
          providencia_id={formik.values.providencia_id}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="utmNorte"
          label={<FormattedMessage id="UTMNorteMayus" />}
          type="text"
          placeholder={placeholderIndique}
          onChange={formik.handleChange}
          error={formik.errors.utmNorte}
          onKeyPress={(event) => {
            if (!/[0-9,-]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
        <Form.Input
          name="utmEste"
          label={<FormattedMessage id="UTMEsteMayus" />}
          type="text"
          placeholder={placeholderIndique}
          onChange={formik.handleChange}
          error={formik.errors.utmEste}
          onKeyPress={(event) => {
            if (!/[0-9,-]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </Form.Group>

      <DropdownRecurso formik={formik} />

      <Form.Group widths="equal">
        <Form.Input
          name="cantidadRelavesAutorizadoM3"
          label={<FormattedMessage id="CantidadRelavesAutorizadoM3Mayus" />}
          type="number"
          step=".01"
          placeholder={placeholderCantidadRelavesM3Autorizado}
          onChange={formik.handleChange}
          error={formik.errors.cantidadRelavesAutorizadoM3}
        />

        <Form.Input
          name="cantidadRelavesActualM3"
          label={<FormattedMessage id="CantidadRelavesActualM3Mayus" />}
          type="number"
          step=".01"
          placeholder={placeholderCantidadRelavesM3Actual}
          onChange={formik.handleChange}
          error={formik.errors.cantidadRelavesActualM3}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="cantidadRelavesAutorizadoT"
          label={<FormattedMessage id="CantidadRelavesAutorizadoTMayus" />}
          type="number"
          step=".01"
          placeholder={placeholderCantidadRelavesAutorizadoT}
          onChange={formik.handleChange}
          error={formik.errors.cantidadRelavesAutorizadoT}
        />

        <Form.Input
          name="cantidadRelavesActualT"
          label={<FormattedMessage id="CantidadRelavesActualTMayus" />}
          type="number"
          step=".01"
          placeholder={placeholderCantidadRelavesActualT}
          onChange={formik.handleChange}
          error={formik.errors.cantidadRelavesActualT}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="metodoConstruccion"
          label={<FormattedMessage id="MetodoConstruccionMayus" />}
          type="text"
          placeholder={placeholderMetodoConstruccion}
          onChange={formik.handleChange}
          error={formik.errors.metodoConstruccion}
        />

        <DropdownStatus formik={formik} />
      </Form.Group>

      <Form.Group
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        <Form.Input
          name="resolucionAprobatoria"
          label={<FormattedMessage id="ResolucionAprobatoriaFaenaMayus" />}
          type="text"
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          placeholder={placeholderResolucionAprobatoria}
          onChange={formik.handleChange}
          error={formik.errors.resolucionAprobatoria}
        />

        <Label
          style={{
            width: "100%",
            background: "transparent",
            fontSize: ".92857143em",
            fontWeight: "700",
            color: startDateFaena === null ? "#9f3a38" : "#8a8a8a",
          }}
        >
          <FormattedMessage id="FechaResolucionAprobatoriaFaenaMayus" />
        </Label>
        <DatePicker
          placeholderText="DD-MM-YYYY"
          dateFormat="dd-MM-yyyy"
          selected={startDateFaena}
          onChange={(date) => setStartDateFaena(date)}
        />
      </Form.Group>

      <Form.Group
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        <Form.Input
          name="resolucionAprobatoriaD"
          label={<FormattedMessage id="ResolucionAprobatoriaDepositoMayus" />}
          type="text"
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          placeholder={placeholderResolucionAprobatoriaDeposito}
          onChange={formik.handleChange}
          error={formik.errors.resolucionAprobatoriaD}
        />

        <Label
          style={{
            width: "100%",
            background: "transparent",
            fontSize: ".92857143em",
            fontWeight: "700",
            color: startDateDeposito === null ? "#9f3a38" : "#8a8a8a",
          }}
        >
          <FormattedMessage id="FechaResolucionAprobatoriaDepositoMayus" />
        </Label>
        <DatePicker
          placeholderText="DD-MM-YYYY"
          dateFormat="dd-MM-yyyy"
          selected={startDateDeposito}
          onChange={(date) => setStartDateDeposito(date)}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Dropdown
          placeholder={
            state.loading ? placeholderCargando : placeholderRecibido
          }
          label={<FormattedMessage id="PlanCierreRecibidoMayus" />}
          selection
          options={selectionRecibido}
          value={formik.values.recibido_id}
          onChange={(_, data) =>
            formik.setFieldValue("recibido_id", data.value)
          }
        />

        {formik.values.recibido_id !== 0 && (
          <Form.Dropdown
            placeholder={
              state.loading ? placeholderCargando : placeholderEstado
            }
            label={<FormattedMessage id="PlanCierreEstadoMayus" />}
            selection
            options={selectionEstado}
            value={formik.values.cierre_estado_id}
            onChange={(_, data) =>
              formik.setFieldValue("cierre_estado_id", data.value)
            }
          />
        )}
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="nombreProfesional"
          label={<FormattedMessage id="NombreProfesionalResponsableMayus" />}
          type="text"
          placeholder={placeholderNombreProfesional}
          onChange={formik.handleChange}
          error={formik.errors.nombreProfesional}
        />

        <Form.Input
          name="cargo"
          label={<FormattedMessage id="CargoProfesionalResponsableMayus" />}
          type="text"
          placeholder={placeholderCargo}
          onChange={formik.handleChange}
          error={formik.errors.cargo}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="distanciaPeligrosa"
          label={<FormattedMessage id="DistanciaPeligrosaMayus" />}
          type="number"
          step=".01"
          placeholder={placeholderDistanciaPeligrosa}
          onChange={formik.handleChange}
          error={formik.errors.distanciaPeligrosa}
        />
      </Form.Group>

      <Form.Group
        widths="equal"
        style={{ marginLeft: "15px", flexDirection: "column" }}
      >
        {stateListDocs.statusDocs === 200 &&
          stateListDocs.dataDocs.datos.map((item, i) => {
            let index = checkedDistancia.includes(item.clave);
            return (
              (item.id === 1 ||
                item.id === 2 ||
                item.id === 3 ||
                item.id === 8 ||
                item.id === 9) && (
                <Checkbox
                  key={i}
                  label={item.nombre}
                  onChange={() => handleChangeCheckBox(item)}
                  value={item.clave}
                  checked={index}
                  name="distanciaPeligrosaDocumentos"
                />
              )
            );
          })}
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="vidaUtil"
          label={<FormattedMessage id="DuracionDepositoMayus" />}
          type="number"
          step=".01"
          placeholder={placeholderDuracionDeposito}
          onChange={formik.handleChange}
          error={formik.errors.vidaUtil}
        />
      </Form.Group>

      <Form.Group
        widths="equal"
        style={{ marginLeft: "15px", flexDirection: "column" }}
      >
        {stateListDocs.statusDocs === 200 &&
          stateListDocs.dataDocs.datos.map((item, i) => {
            let index = checkedVida.includes(item.clave);
            return (
              (item.id === 1 || item.id === 2 || item.id === 9) && (
                <Checkbox
                  key={i}
                  label={item.nombre}
                  onChange={() => handleChangeCheckBoxVida(item)}
                  value={item.clave}
                  checked={index}
                  name="vidaUtilDocumentos"
                />
              )
            );
          })}
      </Form.Group>

      <Form.Group
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        <Label
          style={{
            width: "100%",
            background: "transparent",
            fontSize: ".92857143em",
            fontWeight: "700",
            color: startDate === null ? "#9f3a38" : "#8a8a8a",
          }}
        >
          <FormattedMessage id="FechaInicioOperacionMayus" />
        </Label>
        <DatePicker
          placeholderText="DD-MM-YYYY"
          dateFormat="dd-MM-yyyy"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
      </Form.Group>

      <div>
        <Button
          type="submit"
          onClick={onShowModal}
          disabled={
            !(
              formik.isValid &&
              formik.dirty &&
              startDate &&
              startDateDeposito &&
              startDateFaena
            )
          }
        >
          <FormattedMessage id="GuardarMayus" />
        </Button>
      </div>

      <ModalMessage
        title={<FormattedMessage id="CrearDeposito" />}
        message={
          state.statusAdd === 0 ? (
            <Loading />
          ) : state.dataAdd.ejecucion.estado ? (
            <div className="container-modal">
              <p className="message-ok">{state.dataAdd.ejecucion.mensaje}</p>
              <Icon name="checkmark" className="status-ok" />
            </div>
          ) : (
            <div className="container-modal">
              <p className="message-fail">{state.dataAdd.ejecucion.mensaje}</p>
              <Image src={ohError} className="status-fail" />
            </div>
          )
        }
        show={showModal}
        setShow={setShowModal}
        onCloseModal={handleCloseModal}
      />
    </Form>
  );
}

function initialValues() {
  return {
    nombre: "",
    comuna_id: "",
    utmNorte: "",
    utmEste: "",
    cantidadRelavesAutorizadoM3: "",
    cantidadRelavesActualM3: "",
    cantidadRelavesAutorizadoT: "",
    cantidadRelavesActualT: "",
    estado_id: "",
    resolucionAprobatoria: "",
    resolucionAprobatoriaD: "",
    nombreProfesional: "",
    cargo: "",
    distanciaPeligrosa: "",
    vidaUtil: "",

    recurso_id: "",
    metodoConstruccion: "",
    recibido_id: "",
    cierre_estado_id: "",
  };
}

function validationSchema() {
  return {
    nombre: Yup.string().required(true),
    cantidadRelavesAutorizadoM3: Yup.number().required(true),
    cantidadRelavesActualM3: Yup.number().required(true),
    cantidadRelavesAutorizadoT: Yup.number().required(true),
    cantidadRelavesActualT: Yup.number().required(true),
    nombreProfesional: Yup.string().required(true),
    cargo: Yup.string().required(true),
    distanciaPeligrosa: Yup.number().required(true),
    vidaUtil: Yup.number().required(true),
    utmNorte: Yup.string().required(true),
    utmEste: Yup.string().required(true),
    metodoConstruccion: Yup.string().required(true),
    resolucionAprobatoria: Yup.string().required(true),
    resolucionAprobatoriaD: Yup.string().required(true),
  };
}

export default Chore;
