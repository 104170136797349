import {
  RESET_LIST_DOCUMENTS_DEPOSIT,
  GET_LIST_DOCUMENTS_DEPOSIT_INIT,
  GET_LIST_DOCUMENTS_DEPOSIT_SUCCESS,
  GET_LIST_DOCUMENTS_DEPOSIT_FAILURE,
} from "../../actions/list-documents/types";
import initialState from "./initialState";

export default function listDocsDeposit(state = initialState, action) {
  switch (action.type) {
    case RESET_LIST_DOCUMENTS_DEPOSIT:
      return {
        ...state,
        dataDocs: {},
        loadingDocs: false,
        statusDocs: 0,
      };
    case GET_LIST_DOCUMENTS_DEPOSIT_INIT:
      return {
        ...state,
        loadingDocs: true,
      };
    case GET_LIST_DOCUMENTS_DEPOSIT_SUCCESS:
      return {
        ...state,
        dataDocs: action.payload.data,
        loadingDocs: false,
        statusDocs: action.payload.status,
      };
    case GET_LIST_DOCUMENTS_DEPOSIT_FAILURE:
      return {
        ...state,
        dataDocs: {},
        loadingDocs: false,
        statusDocs: 501,
      };

    default:
      return state;
  }
}
