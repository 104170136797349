import { useEffect, useState } from "react";
import {
  Form,
  Container,
  Card,
  Image,
  Icon,
  Button,
  Label,
  Table,
} from "semantic-ui-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import companyIcon from "../../assets/icons/icono_usuario.png";
import edit from "../../assets/icons/icono_edit.png";
import addFaena from "../../assets/icons/btn-sumar_mas-red.png";
import closeBtn from "../../assets/icons/icono_close-grey.png";

import DividerGrey from "./../../components/DividerGrey/index";
import ButtonsDashboard from "./../../components/ButtonsDashboard/index";
import { getDetailsCompany } from "../../actions/company";
import { getChores, resetGetChores } from "../../actions/chore";
import Loading from "../../components/Loading";

import { DropdownAllComuna } from "../../components/Dropdown/DropdownAllComuna";
import { DropdownAllRegion } from "../../components/Dropdown/DropdownAllRegion";
import { DropdownAllProvidencia } from "../../components/Dropdown/DropdownAllProvincia";
import { addChore } from "../../actions/add_chore";
import ModalMessage from "./../../components/Modal/index";
import { resetAddChore } from "./../../actions/add_chore/index";
import { resetGetDetailsCompany } from "./../../actions/company/index";
import Expired from "./../../components/Expired/index";
import ModalActions from "./../../components/Modal/ModalActions";
import { resetDeleteChore, deleteChore } from "./../../actions/chore/index";
import {
  resetProvidencias,
  resetComunas,
  resetRegiones,
} from "./../../actions/geolocations/index";
import verificador from "verificador-rut";
import DashboardUbication from "./../../components/DashboardUbication/index";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const Company = () => {
  const location = useLocation();
  const [dataId, setDataId] = useState("");

  const empresa = localStorage.getItem("empresa");

  let minero = localStorage.getItem("minero");
  let servicio = localStorage.getItem("servicio");

  const dispatch = useDispatch();
  const state = useSelector((state) => state.company);
  const stateChore = useSelector((state) => state.chore);

  useEffect(() => {
    if (location.state) {
      const empresa_id = location.state.empresa_id;
      setDataId(empresa_id);
    }
  }, [location.state]);

  useEffect(() => {
    return () => {
      dispatch(resetProvidencias());
      dispatch(resetComunas());
      dispatch(resetRegiones());
    };
  }, [dispatch]);

  useEffect(() => {
    if (minero === "true") {
      dispatch(
        getDetailsCompany({
          empresa_id: empresa,
        })
      );
      dispatch(
        getChores({
          empresa_id: empresa,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, empresa]);

  useEffect(() => {
    if (minero === "false" && dataId !== "") {
      dispatch(
        getDetailsCompany({
          empresa_id: dataId,
        })
      );
      dispatch(
        getChores({
          empresa_id: dataId,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, dataId]);

  useEffect(() => {
    return () => {
      dispatch(resetGetChores());
      dispatch(resetGetDetailsCompany());
    };
  }, [dispatch]);

  if (
    /* When the token is expired*/
    state.status === 501 ||
    stateChore.status === 501
  ) {
    return <Expired />;
  }

  return (
    <Container>
      <>
        {servicio === "true" && (
          <DashboardUbication>
            {state.status === 200 ? (
              state.data.datos.razon_social
            ) : (
              <FormattedMessage id="CargandoMinus" />
            )}
          </DashboardUbication>
        )}
        <DividerGrey />

        <div style={{ display: "flex" }}>
          <ButtonsDashboard dataId={dataId} state={{ state }} />

          {location.pathname === "/dashboard/company" && (
            <Container className="dashboard_container-cards">
              {state.loading ? (
                <Loading />
              ) : (
                <>
                  <CardCompany state={state} empresa={empresa} />
                  <CardFaena state={stateChore} stateEmpresa={state} />
                </>
              )}
            </Container>
          )}

          {location.pathname === "/dashboard/add-chore" && (
            <Container className="card-faena">
              {state.loading ? <Loading /> : <AddFaena />}
            </Container>
          )}
        </div>
      </>
    </Container>
  );
};

export default Company;

function CardCompany({ state, empresa }) {
  const { data, loading } = state;

  let minero = localStorage.getItem("minero");

  return loading ? (
    <Loading />
  ) : (
    <Card fluid className="dashboard-card">
      <Card.Content>
        <div className="dashboard-card__header">
          <Image src={companyIcon} alt="icono-empresa" />
          <div className="dashboard-card__titles">
            <h4>
              <FormattedMessage id="Nombre" /> : {data.datos.razon_social}
            </h4>
            <h5>
              <FormattedMessage id="RutEmpresa" />: {data.datos.rut_empresa}
            </h5>
          </div>
          {minero === "true" && (
            <div className="dashboard-card__icons">
              <Link to={`/dashboard/edit-company/${empresa}`} state={{ state }}>
                <Image src={edit} />
              </Link>
            </div>
          )}
        </div>
      </Card.Content>
      <Card.Content className="dashboard-card-faena">
        <p>
          <FormattedMessage id="DireccionMayus" />: {data.datos.calle}
        </p>
        <p>
          <FormattedMessage id="NombreRepresentanteLegalMayus" /> :{" "}
          {data.datos.representante}
        </p>

        <p>
          <FormattedMessage id="CorreoRepresentanteLegalMayus" />:{" "}
          {data.datos.email_representante}
        </p>
        <p>
          <FormattedMessage id="TelefonoRepresentanteLegalMayus" />:{" "}
          {data.datos.telefono_representante}
        </p>
        <p>
          <FormattedMessage id="ComunaMayus" />: {data.datos.comuna}
        </p>
      </Card.Content>
      <Card.Content className="dashboard-card__footer">
        <div>
          <h3>{data.datos.cantidad_de_faenas}</h3>
          <p>
            <FormattedMessage id="FaenasMayus" />
          </p>
        </div>
        <div className="dashboard-card__vertical"></div>
        <div>
          <h3>{data.datos.cantidad_de_depositos}</h3>
          <p>
            <FormattedMessage id="DepositosMayus" />
          </p>
        </div>
      </Card.Content>
    </Card>
  );
}

function CardFaena({ state }) {
  const { data, loading } = state;

  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const stateDelete = useSelector((state) => state.chore);

  const deleteChoreId = () => {
    dispatch(
      deleteChore({
        id: id,
      })
    );
  };

  const resetData = () => {
    dispatch(resetDeleteChore());
  };

  const onShowModal = (id) => {
    setId(id);
    setShowModal(true);
  };

  const minero = localStorage.getItem("minero");

  return loading ? (
    <Loading />
  ) : (
    <Card fluid className="dashboard-card faena" style={{ maxHeight: "22vh" }}>
      <Card.Content style={{ paddingBottom: "0px", paddingTop: "0px" }}>
        <div className="dashboard-card__header-faena">
          <h2>
            <FormattedMessage id="Faenas" />
          </h2>
          {minero === "true" ? (
            <Link to="/dashboard/add-chore">
              <Image
                src={addFaena}
                alt="Agregar Faena"
                style={{ cursor: "pointer" }}
              />
            </Link>
          ) : null}
        </div>
      </Card.Content>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="NombreFaena" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="NombreProfesionalResponsableMinus" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="NombreRepresentanteLegal" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="UTMNorte" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="UTMEste" />
            </Table.HeaderCell>
            {minero === "true" && (
              <Table.HeaderCell>
                <FormattedMessage id="Eliminar" />
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>
              <FormattedMessage id="Ver" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.ejecucion.estado ? (
            data.datos.map((item, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{item.id}</Table.Cell>
                  <Table.Cell>{item.nombre}</Table.Cell>
                  <Table.Cell>{item.nombre_profesional_responsable}</Table.Cell>
                  <Table.Cell>{item.nombre_representante_legal}</Table.Cell>
                  <Table.Cell>{item.utm_norte}</Table.Cell>
                  <Table.Cell>{item.utm_este}</Table.Cell>
                  {minero === "true" && (
                    <Table.Cell>
                      <Icon
                        name="trash"
                        style={{ cursor: "pointer", marginRight: "15px" }}
                        onClick={() => onShowModal(item.id)}
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    <Link
                      to={`/dashboard/chore/${item.id}`}
                      state={{ state: item }}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name="eye" style={{ color: "grey" }} />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <>{data.ejecucion.mensaje}</>
          )}
        </Table.Body>
      </Table>

      <ModalActions
        title={<FormattedMessage id="EliminarFaena" />}
        show={showModal}
        setShow={setShowModal}
        status={stateDelete.statusDelete}
        action={deleteChoreId}
        reset={resetData}
        deleteAction={"Delete"}
        estado={stateDelete.dataDelete}
      >
        {stateDelete.statusDelete === 0 ? (
          <FormattedMessage id="EstaSeguroEliminarFaena" />
        ) : stateDelete.dataDelete.ejecucion.estado ? (
          <div className="container-modal">
            <p className="message-ok">
              {stateDelete.dataDelete.ejecucion.mensaje}
            </p>
            <Icon name="checkmark" className="status-ok" />
          </div>
        ) : (
          <div className="container-modal">
            <p className="message-fail">
              {stateDelete.dataDelete.ejecucion.mensaje}
            </p>
            <Image src={ohError} className="status-fail" />
          </div>
        )}
      </ModalActions>
    </Card>
  );
}

// ADD FAENA CARD DDDDDDDDDD
function AddFaena() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.addChore);

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const empresa = localStorage.getItem("empresa");

  const [rut, setRut] = useState("");
  const [validarRut, setValidarRut] = useState(false);

  const handleChange = (e) => {
    setRut(e.target.value);
  };

  useEffect(() => {
    if (verificador(rut) === true) {
      setValidarRut(true);
    } else {
      setValidarRut(false);
    }
  }, [rut]);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      dispatch(
        addChore({
          nombre: formData.nombre,
          calle: formData.direccion,
          region_id: formData.region_id,
          utm_norte: formData.utmNorte,
          comuna_id: formData.comuna_id,
          utm_este: formData.utmEste,
          provincia_id: formData.providencia_id,

          nombre_representante_legal: formData.representante,
          email_representante_legal: formData.correoRepresentante,
          rut_representante_legal: rut,
          direccion_representante_legal: formData.direccionRepresentante,
          telefono_representante_legal: formData.telefonoRepresentante,

          nombre_profesional_responsable: formData.nombreProfesional,
          correo_profesional_responsable: formData.correoProfesional,
          telefono_profesional_responsable: formData.telefonoProfesional,
          cargo_profesional_responsable: formData.cargoProfesional,

          empresa_id: parseInt(empresa),
        })
      );
    },
  });

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetAddChore());
    if (state.dataAdd.ejecucion.estado) {
      window.location.replace("/dashboard/company");
    }
  };

  const intl = useIntl();
  const placeholderNombreFaena = intl.formatMessage({
    id: "EscribaNombreFaena",
  });
  const placeholderIndiqueCalle = intl.formatMessage({ id: "IndiqueCalle" });
  const placeholderIndique = intl.formatMessage({ id: "Indique" });
  const placeholderRepresentanteLegal = intl.formatMessage({
    id: "EscribaNombreRepresentanteLegal",
  });
  const placeholderCorreoRepresentanteLegal = intl.formatMessage({
    id: "EscribaCorreoRepresentanteLegal",
  });
  const placeholderRutEjemplo = intl.formatMessage({
    id: "RutRepresentanteEjemplo",
  });
  const placeholderDireccionRepresentanteLegal = intl.formatMessage({
    id: "EscribaDireccionRepresentanteLegal",
  });
  const placeholderTelefonoRepresentanteLegal = intl.formatMessage({
    id: "EscribaTelefonoRepresentanteLegal",
  });
  const placeholderNombreProfesionalResponsable = intl.formatMessage({
    id: "EscribaNombreProfesionalResponsable",
  });
  const placeholderCorreoProfesionalResponsable = intl.formatMessage({
    id: "EscribaCorreoProfesionalResponsable",
  });
  const placeholderTelefonoProfesionalResponsable = intl.formatMessage({
    id: "EscribaTelefonoProfesionalResponsable",
  });
  const placeholderCargoProfesionalResponsable = intl.formatMessage({
    id: "EscribaCargoProfesionalResponsable",
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="container-card-add-faena">
        <h3 className="card-faena-title">
          <FormattedMessage id="NuevaFaena" />
        </h3>
        <Image
          src={closeBtn}
          alt="Cerrar"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Form.Group widths="equal">
        <Form.Input
          name="nombre"
          label={<FormattedMessage id="NombreFaenaMayus" />}
          type="text"
          placeholder={placeholderNombreFaena}
          onChange={formik.handleChange}
          error={formik.errors.nombre}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="direccion"
          label={<FormattedMessage id="DireccionMayus" />}
          type="text"
          placeholder={placeholderIndiqueCalle}
          onChange={formik.handleChange}
          error={formik.errors.direccion}
        />
        <Form.Input
          name="utmNorte"
          label={<FormattedMessage id="UTMNorteMayus" />}
          type="text"
          placeholder={placeholderIndique}
          onChange={formik.handleChange}
          error={formik.errors.utmNorte}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <DropdownAllRegion formik={formik} />
        <Form.Input
          name="utmEste"
          label={<FormattedMessage id="UTMEsteMayus" />}
          type="text"
          placeholder={placeholderIndique}
          onChange={formik.handleChange}
          error={formik.errors.utmEste}
        />
      </Form.Group>

      <DropdownAllProvidencia
        formik={formik}
        region_id={formik.values.region_id}
      />
      <DropdownAllComuna
        formik={formik}
        providencia_id={formik.values.providencia_id}
      />

      <Form.Group>
        <Label className="addFaena__label">
          <FormattedMessage id="RepresentanteLegal" />
        </Label>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="representante"
          label={<FormattedMessage id="NombreRepresentanteLegalMayus" />}
          type="text"
          placeholder={placeholderRepresentanteLegal}
          onChange={formik.handleChange}
          error={formik.errors.representante}
        />

        <Form.Input
          name="correoRepresentante"
          label={<FormattedMessage id="CorreoRepresentanteLegalMayus" />}
          type="email"
          placeholder={placeholderCorreoRepresentanteLegal}
          onChange={formik.handleChange}
          error={formik.errors.correoRepresentante}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="rutRepresentante"
          label={<FormattedMessage id="RutRepresentanteLegalMayus" />}
          type="text"
          placeholder={placeholderRutEjemplo}
          onChange={(e) => handleChange(e)}
          className={
            validarRut === true
              ? "card-faena__input-green"
              : "card-faena__input-red"
          }
        />

        <Form.Input
          name="direccionRepresentante"
          label={<FormattedMessage id="DireccionRepresentanteLegalMayus" />}
          type="text"
          placeholder={placeholderDireccionRepresentanteLegal}
          onChange={formik.handleChange}
          error={formik.errors.direccionRepresentante}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="telefonoRepresentante"
          label={<FormattedMessage id="TelefonoRepresentanteLegalMayus" />}
          type="text"
          placeholder={placeholderTelefonoRepresentanteLegal}
          onChange={formik.handleChange}
          error={formik.errors.telefonoRepresentante}
        />
      </Form.Group>

      <Form.Group>
        <Label className="addFaena__label">
          <FormattedMessage id="ProfesionalResponsable" />
        </Label>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="nombreProfesional"
          label={<FormattedMessage id="NombreProfesionalResponsableMayus" />}
          type="text"
          placeholder={placeholderNombreProfesionalResponsable}
          onChange={formik.handleChange}
          error={formik.errors.nombreProfesional}
        />

        <Form.Input
          name="correoProfesional"
          label={<FormattedMessage id="CorreoProfesionalResponsableMayus" />}
          type="email"
          placeholder={placeholderCorreoProfesionalResponsable}
          onChange={formik.handleChange}
          error={formik.errors.correoProfesional}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          name="telefonoProfesional"
          label={<FormattedMessage id="TelefonoProfesionalResponsableMayus" />}
          type="text"
          placeholder={placeholderTelefonoProfesionalResponsable}
          onChange={formik.handleChange}
          error={formik.errors.telefonoProfesional}
        />

        <Form.Input
          name="cargoProfesional"
          label={<FormattedMessage id="CargoProfesionalResponsableMayus" />}
          type="text"
          placeholder={placeholderCargoProfesionalResponsable}
          onChange={formik.handleChange}
          error={formik.errors.cargoProfesional}
        />
      </Form.Group>

      <div>
        <Button
          type="submit"
          onClick={onShowModal}
          disabled={!(formik.isValid && formik.dirty && validarRut)}
        >
          <FormattedMessage id="ActualizarMayus" />
        </Button>
      </div>

      <ModalMessage
        title={<FormattedMessage id="CrearFaena" />}
        message={
          state.statusAdd === 0 ? (
            <Loading />
          ) : state.dataAdd.ejecucion.estado ? (
            <div className="container-modal">
              <p className="message-ok">{state.dataAdd.ejecucion.mensaje}</p>
              <Icon name="checkmark" className="status-ok" />
            </div>
          ) : (
            <div className="container-modal">
              <p className="message-fail">{state.dataAdd.ejecucion.mensaje}</p>
              <Image src={ohError} className="status-fail" />
            </div>
          )
        }
        show={showModal}
        setShow={setShowModal}
        onCloseModal={handleCloseModal}
      />
    </Form>
  );

  function initialValues() {
    return {
      nombre: "",
      direccion: "",
      region_id: "",
      utmNorte: "",
      comuna_id: "",
      utmEste: "",
      providencia_id: "",

      representante: "",
      correoRepresentante: "",

      direccionRepresentante: "",
      telefonoRepresentante: "",

      nombreProfesional: "",
      correoProfesional: "",
      telefonoProfesional: "",
      cargoProfesional: "",
    };
  }

  function validationSchema() {
    return {
      nombre: Yup.string().required(true),
      direccion: Yup.string().required(true),

      utmNorte: Yup.string().required(true),
      utmEste: Yup.string().required(true),

      representante: Yup.string().required(true),
      correoRepresentante: Yup.string().email(true).required(true),
      direccionRepresentante: Yup.string().required(true),
      telefonoRepresentante: Yup.string().required(true),

      nombreProfesional: Yup.string().required(true),
      correoProfesional: Yup.string().email(true).required(true),
      telefonoProfesional: Yup.string().required(true),
      cargoProfesional: Yup.string().required(true),
    };
  }
}
