import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Container, Image, Tab, Form, Button, Icon } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { recoverPassword } from "../../actions/password";
import ModalMessage from "../../components/Modal";
import Loading from "../../components/Loading";

import bannerLogin from "../../assets/img/img-login.png";
import { resetRecoverPassword } from "./../../actions/password/index";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const RecoverPassword = () => {
  return (
    <div className="login-container">
      <Container>
        <div className="login-container__left">
          <Image
            src={bannerLogin}
            alt="Banner Login"
            className="banner-login"
            fluid
            wrapped
            style={{ height: "850px" }}
          />
        </div>
        <div className="login-container__right">
          <div className="login-title-welcome">
            <div className="vl"></div>
            <h1>
              <FormattedMessage id="Bienvenido" />
            </h1>
          </div>
          <p>
            <FormattedMessage id="IngreseCorreoRecuperarContraseña" />{" "}
            <b>SERNAGEOMIN</b>
          </p>

          <TabLogin />
        </div>
      </Container>
    </div>
  );
};

export default RecoverPassword;

const panes = [
  {
    menuItem: { key: 1, content: <FormattedMessage id="RecuperarClave" /> },
    render: () => (
      <Tab.Pane attached={false}>
        <RecoverPasswordComponent />
      </Tab.Pane>
    ),
  },
];

function TabLogin() {
  return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
}

function RecoverPasswordComponent() {
  const navigate = useNavigate();

  let auth = localStorage.getItem("auth");
  auth = auth === "true";

  const dispatch = useDispatch();
  const state = useSelector((state) => state.password);

  const [showModalRecover, setShowModalRecover] = useState(false);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      dispatch(
        recoverPassword({
          email: formData.email,
        })
      );
    },
  });

  function initialValues() {
    return {
      email: "",
    };
  }

  function validationSchema() {
    return {
      email: Yup.string().email(true).required(true),
    };
  }

  useEffect(() => {
    if (state.statusRecover === 200) {
      setShowModalRecover(true);
    }
  }, [state.statusRecover]);

  const resetData = () => {
    dispatch(resetRecoverPassword());
  };

  const intl = useIntl();
  const placeholderCargandoMayus = intl.formatMessage({
    id: "CargandoMayus",
  });
  const placeholderCorreo = intl.formatMessage({ id: "Correo" });
  const placeholderRecuperar = intl.formatMessage({
    id: "RecuperarClaveMayus",
  });

  return auth ? (
    <Navigate to="/dashboard/company" replace={true} />
  ) : (
    <Form className="login-inputs" onSubmit={formik.handleSubmit}>
      <Form.Input
        name="email"
        type="text"
        placeholder={placeholderCorreo}
        onChange={formik.handleChange}
        error={formik.errors.email}
      />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button className="btn-login" type="submit">
          {state.loadingRecover
            ? placeholderCargandoMayus
            : placeholderRecuperar}
        </Button>

        <Button className="btn-back" type="button" onClick={() => navigate(-1)}>
          <FormattedMessage id="VolverAtras" />
        </Button>
      </div>

      <ModalMessage
        title={<FormattedMessage id="RecuperarClave" />}
        message={
          state.statusRecover === 0 ? (
            <Loading />
          ) : state.dataRecover.ejecucion.estado ? (
            <div className="container-modal">
              <p className="message-ok">
                {state.dataRecover.ejecucion.mensaje}
              </p>
              <Icon name="checkmark" className="status-ok" />
            </div>
          ) : (
            <div className="container-modal">
              <p className="message-fail">
                {state.dataRecover.ejecucion.mensaje}
              </p>
              <Image src={ohError} className="status-fail" />
            </div>
          )
        }
        show={showModalRecover}
        setShow={setShowModalRecover}
        reset={resetData}
        recover={"recover"}
        dataRecover={state.dataRecover}
      />
    </Form>
  );
}
