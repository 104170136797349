import initialState from "./initialState";

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case "RESET_LOGIN":
      localStorage.clear();
      return {
        ...state,
        data: false,
        loading: false,
        status: 0,
      };
    case "LOGIN_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_SUCESS":
      const auth = action.status === 200 ? true : false;
      if (auth) {
        localStorage.setItem("auth", auth);
        localStorage.setItem("email", action.payload.usuario.email);
        localStorage.setItem("empresa", action.payload.empresa?.id);
        localStorage.setItem("minero", action.payload.es_minero);
        localStorage.setItem("servicio", action.payload.es_servicio);
        localStorage.setItem("ambiente", action.payload.ambiente);
        localStorage.setItem(
          "empresa_name",
          action.payload.empresa?.razon_social
        );
      }
      return {
        ...state,
        data: auth,
        loading: false,
        status: action.status,
      };

    case "LOGIN_FAIL":
      return {
        ...state,
        data: true,
        loading: false,
        status: 501,
      };
    default:
      return state;
  }
}
