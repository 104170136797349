import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { changePassword, resetChangePassword } from "../../actions/password";
import { FormattedMessage, useIntl } from "react-intl";

const ModalActions = (props) => {
  const { title, show, setShow } = props;

  const state = useSelector((state) => state.password);

  const [form, setForm] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(true);

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const dispatch = useDispatch();

  const email = localStorage.getItem("email");

  useEffect(() => {
    if (form.newPassword1 !== form.newPassword2 && error) {
      setError(false);
    }
    // eslint-disable-next-line
  }, [form.newPassword1, form.newPassword2]);

  useEffect(() => {
    if (
      form.oldPassword !== "" &&
      form.newPassword1 !== "" &&
      form.newPassword2 !== ""
    ) {
      setError2(false);
    } else {
      setError2(true);
    }
  }, [form]);

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  const onClose = () => {
    setShow(false);
    dispatch(resetChangePassword());
  };

  const cambiarPassword = () => {
    if (form.newPassword1 !== form.newPassword2) {
      setError(true);
    } else {
      setError(false);
      dispatch(
        changePassword({
          email: email,
          password_antigua: form.oldPassword,
          password_nueva: form.newPassword1,
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetChangePassword());
    };
  }, [dispatch]);

  const reset = () => {
    dispatch(resetChangePassword());
  };

  const intl = useIntl();

  const placeholderContraseñaActual = intl.formatMessage({
    id: "IngreseContraseñaActual",
  });
  const placeholderNuevaContraseña = intl.formatMessage({
    id: "IngreseNuevaContraseña",
  });
  const placeholderRepitaNuevaContraseña = intl.formatMessage({
    id: "RepitaNuevaContraseña",
  });

  return (
    <Modal className="basic-modal password-modal" open={show} onClose={onClose}>
      <Modal.Header>
        <span>{title}</span>
        <Icon
          name="close"
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Header>
      <Modal.Content>
        {state.statusChange === 0 ? (
          <Form className="form-change-password">
            <Form.Input
              name="oldPassword"
              label={<FormattedMessage id="ContraseñaActual" />}
              type={showPassword1 ? "text" : "password"}
              placeholder={placeholderContraseñaActual}
              onChange={(e) => handleChange(e)}
            >
              <input />
              <div className="container-icon" onClick={handleShowPassword1}>
                {!showPassword1 ? (
                  <Icon name="eye" />
                ) : (
                  <Icon name="eye slash" />
                )}
              </div>
            </Form.Input>

            <Form.Input
              name="newPassword1"
              label={<FormattedMessage id="NuevaContraseña" />}
              type={showPassword2 ? "text" : "password"}
              placeholder={placeholderNuevaContraseña}
              onChange={(e) => handleChange(e)}
              className={error ? "red" : ""}
            >
              <input />
              <div className="container-icon" onClick={handleShowPassword2}>
                {!showPassword2 ? (
                  <Icon name="eye" />
                ) : (
                  <Icon name="eye slash" />
                )}
              </div>
            </Form.Input>

            <Form.Input
              name="newPassword2"
              label={<FormattedMessage id="RepitaNuevaContraseña" />}
              type={showPassword3 ? "text" : "password"}
              placeholder={placeholderRepitaNuevaContraseña}
              onChange={(e) => handleChange(e)}
              className={error ? "red" : ""}
            >
              <input />
              <div className="container-icon" onClick={handleShowPassword3}>
                {!showPassword3 ? (
                  <Icon name="eye" />
                ) : (
                  <Icon name="eye slash" />
                )}
              </div>
            </Form.Input>

            {error && (
              <p style={{ color: "red", fontWeight: "bold" }}>
                <FormattedMessage id="ContraseñasNoCoinciden" />
              </p>
            )}
          </Form>
        ) : (
          <p>{state.dataChange.ejecucion.mensaje}</p>
        )}
      </Modal.Content>
      <Modal.Actions>
        {state.statusChange === 0 ? (
          <>
            <Button
              color="red"
              content={<FormattedMessage id="Cerrar" />}
              onClick={() => {
                onClose();
              }}
            />

            <Button
              color="blue"
              content={
                state.loadingChange ? (
                  <FormattedMessage id="CargandoMinus" />
                ) : (
                  <FormattedMessage id="CambiarContraseña" />
                )
              }
              type="submit"
              onClick={cambiarPassword}
              disabled={
                state.loadingChange || error2 === true || error === true
                  ? true
                  : false
              }
            />
          </>
        ) : (
          <Button
            color="red"
            content={<FormattedMessage id="Cerrar" />}
            onClick={() => {
              onClose();
              reset();
            }}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ModalActions;
