import {
  GET_USERS_TO_APROVE_INIT,
  GET_USERS_TO_APROVE_SUCCESS,
  GET_USERS_TO_APROVE_FAILURE,
  RESET_USERS_TO_APROVE,
  APROVE_USER_INIT,
  APROVE_USER_SUCCESS,
  APROVE_USER_FAILURE,
  RESET_APROVE_USER,
  DISCARD_USER_INIT,
  DISCARD_USER_SUCCESS,
  DISCARD_USER_FAILURE,
  RESET_DISCARD_USER,
} from "./types";
import API from "./api";

// GET USERS
export function resetUsersToAprove() {
  return {
    type: RESET_USERS_TO_APROVE,
  };
}
function getUsersToAproveInit() {
  return {
    type: GET_USERS_TO_APROVE_INIT,
  };
}
function getUsersToAproveSuccess(data) {
  return {
    type: GET_USERS_TO_APROVE_SUCCESS,
    payload: data,
  };
}
function getUsersToAproveFailure(error) {
  return {
    type: GET_USERS_TO_APROVE_FAILURE,
    payload: error,
  };
}
export function getUsersToAprove(data) {
  return async (dispatch) => {
    dispatch(getUsersToAproveInit());
    try {
      const resp = await API.data.getUsersToAprove(data);
      dispatch(getUsersToAproveSuccess(resp));
    } catch (error) {
      dispatch(getUsersToAproveFailure());
    }
  };
}

// APROVE USER
export function resetAproveUser() {
  return {
    type: RESET_APROVE_USER,
  };
}
function aproveUserInit() {
  return {
    type: APROVE_USER_INIT,
  };
}
function aproveUserSuccess(data) {
  return {
    type: APROVE_USER_SUCCESS,
    payload: data,
  };
}
function aproveUserFailure(error) {
  return {
    type: APROVE_USER_FAILURE,
    payload: error,
  };
}
export function aproveUser(data) {
  return async (dispatch) => {
    dispatch(aproveUserInit());
    try {
      const resp = await API.data.aproveUser(data);
      dispatch(aproveUserSuccess(resp));
    } catch (error) {
      dispatch(aproveUserFailure(error));
    }
  };
}

// DISCARD USER
export function resetDiscardUser() {
  return {
    type: RESET_DISCARD_USER,
  };
}
function discardUserInit() {
  return {
    type: DISCARD_USER_INIT,
  };
}
function discardUserSuccess(data) {
  return {
    type: DISCARD_USER_SUCCESS,
    payload: data,
  };
}
function discardUserFailure(error) {
  return {
    type: DISCARD_USER_FAILURE,
    payload: error,
  };
}
export function discardUser(data) {
  return async (dispatch) => {
    dispatch(discardUserInit());
    try {
      const resp = await API.data.discardUser(data);
      dispatch(discardUserSuccess(resp));
    } catch (error) {
      dispatch(discardUserFailure(error));
    }
  };
}
