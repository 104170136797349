import axios from "axios";

const API = {
  data: {
    async getHomeDocs() {
      const response = await axios.get(`/api/home-docs/`);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
