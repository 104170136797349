import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { getListStatus } from "../../actions/status";
import { useIntl } from "react-intl";

export function DropdownStatus({ formik }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.status);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getListStatus());
  }, [dispatch]);

  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        state.data.datos.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.nombre,
          });
        });
      }
    }
    setData(arr);
  }, [state.data, state.status]);

  const intl = useIntl();

  const placeHolderCargando = intl.formatMessage({ id: "CargandoMayus" });
  const placeHolderEstado = intl.formatMessage({ id: "EstadoMayus" });

  return (
    <Form.Dropdown
      placeholder={state.loading ? placeHolderCargando : placeHolderEstado}
      label={placeHolderEstado}
      selection
      options={data}
      value={formik.values.estado_id}
      onChange={(_, data) => formik.setFieldValue("estado_id", data.value)}
      errors={formik.errors.estado_id}
      disabled={state.loading ? true : false}
    />
  );
}
