import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./../reducer/login/index";
import geolocations from "./../reducer/geolocations/index";
import enrollment from "./../reducer/enrollment/index";
import register from "./../reducer/register/index";
import password from "./../reducer/password/index";
import addChore from "./../reducer/add_chore/index";
import chore from "./../reducer/chore/index";
import updateChore from "./../reducer/update_chore/index";
import company from "./../reducer/company/index";
import deposit from "./../reducer/deposit/index";
import updateDeposit from "./../reducer/update_deposit/index";
import uploadFile from "./../reducer/uploadFile/index";
import listDocuments from "./../reducer/listDocuments/index";
import url from "./../reducer/url/index";
import matriz1 from "./../reducer/matriz1/index";
import matriz2 from "./../reducer/matriz2/index";
import matriz3 from "../reducer/matriz3/index";
import matriz4 from "./../reducer/matriz4/index";
import matriz5 from "./../reducer/matriz5/index";
import matriz6 from "./../reducer/matriz6/index";
import matriz7 from "./../reducer/matriz7/index";
import matriz8 from "./../reducer/matriz8/index";
import listDocsDeposit from "./../reducer/list-documents/index";
import addDeposit from "./../reducer/add_deposit/index";
import resource from "./../reducer/resource/index";
import status from "./../reducer/status/index";
import homeDocs from "./../reducer/homeDocs/index";
import params from "./../reducer/params/index";
import instruments from "./../reducer/instruments/index";
import addRegistro from "./../reducer/add_register/index";
import updateComentario from "./../reducer/update_comentario/index";
import aprove from "./../reducer/aprove/index";
import evaluacion from "./../reducer/evaluacion/index";
import allGeolocations from "./../reducer/all-geolocations/index";
import matricesOk from "./../reducer/matrices/index";
import updateCompany from "./../reducer/update_company/index";

export const store = configureStore({
  reducer: {
    Login: loginReducer,
    geolocations,
    allGeolocations,
    enrollment,
    register,
    password,
    addChore,
    chore,
    updateChore,
    company,
    updateCompany,
    addDeposit,
    deposit,
    updateDeposit,
    uploadFile,
    listDocuments,
    url,
    matriz1,
    matriz2,
    matriz3,
    matriz4,
    matriz5,
    matriz6,
    matriz7,
    matriz8,
    matricesOk,
    listDocsDeposit,
    resource,
    status,
    homeDocs,
    params,
    instruments,
    addRegistro,
    updateComentario,
    aprove,
    evaluacion,
  },
});
