import {
  RESET_UPDATE_COMPANY,
  UPDATE_COMPANY_INIT,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
} from "./types";
import API from "./api";

export function resetUpdateCompany() {
  return {
    type: RESET_UPDATE_COMPANY,
  };
}
function updateCompanyInit() {
  return {
    type: UPDATE_COMPANY_INIT,
  };
}
function updateCompanySuccess(data) {
  return {
    type: UPDATE_COMPANY_SUCCESS,
    payload: data,
  };
}
function updateCompanyFailure(error) {
  return {
    type: UPDATE_COMPANY_FAILURE,
    payload: error,
  };
}
export function updateCompany(data) {
  return async (dispatch) => {
    dispatch(updateCompanyInit());
    try {
      const resp = await API.data.updateCompany(data);
      dispatch(updateCompanySuccess(resp));
    } catch (error) {
      dispatch(updateCompanyFailure(error));
    }
  };
}
