import {
  RESET_GET_MATRIZ2,
  GET_MATRIZ2_INIT,
  GET_MATRIZ2_SUCCESS,
  GET_MATRIZ2_FAILURE,
  RESET_UPDATE_MATRIZ2,
  UPDATE_MATRIZ2_INIT,
  UPDATE_MATRIZ2_SUCCESS,
  UPDATE_MATRIZ2_FAILURE,
} from "./types";
import API from "./api";

// GET
export function resetGetMatriz2() {
  return {
    type: RESET_GET_MATRIZ2,
  };
}
function getMatriz2Init() {
  return {
    type: GET_MATRIZ2_INIT,
  };
}
function getMatriz2Success(data) {
  return {
    type: GET_MATRIZ2_SUCCESS,
    payload: data,
  };
}
function getMatriz2Failure(error) {
  return {
    type: GET_MATRIZ2_FAILURE,
    payload: error,
  };
}
export function getMatriz2(data) {
  return async (dispatch) => {
    dispatch(getMatriz2Init());
    try {
      const resp = await API.data.getMatriz2(data);
      dispatch(getMatriz2Success(resp));
    } catch (error) {
      dispatch(getMatriz2Failure(error));
    }
  };
}

// UPDATE
export function resetUpdateMatriz2() {
  return {
    type: RESET_UPDATE_MATRIZ2,
  };
}
function updateMatriz2Init() {
  return {
    type: UPDATE_MATRIZ2_INIT,
  };
}
function updateMatriz2Success(data) {
  return {
    type: UPDATE_MATRIZ2_SUCCESS,
    payload: data,
  };
}
function updateMatriz2Failure(error) {
  return {
    type: UPDATE_MATRIZ2_FAILURE,
    payload: error,
  };
}
export function updateMatriz2(data) {
  return async (dispatch) => {
    dispatch(updateMatriz2Init());
    try {
      const resp = await API.data.updateMatriz2(data);
      dispatch(updateMatriz2Success(resp));
    } catch (error) {
      dispatch(updateMatriz2Failure(error));
    }
  };
}
