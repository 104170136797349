import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Form, Image, Button, Container, Label, Icon } from "semantic-ui-react";

import { DropdownAllComuna } from "../../components/Dropdown/DropdownAllComuna";
import { DropdownAllRegion } from "../../components/Dropdown/DropdownAllRegion";
import { DropdownAllProvidencia } from "../../components/Dropdown/DropdownAllProvincia";

import { useFormik } from "formik";
import * as Yup from "yup";

import { getDataChore } from "../../actions/chore";

import closeBtn from "../../assets/icons/icono_close-grey.png";
import Loading from "./../../components/Loading/index";
import ButtonsDashboard from "../../components/ButtonsDashboard";
import DividerGrey from "./../../components/DividerGrey/index";
import { resetGetDataChore } from "./../../actions/chore/index";
import ModalActions from "./../../components/Modal/ModalActions";
import {
  resetUpdateChore,
  updateChore,
} from "./../../actions/update_chore/index";
import Expired from "../../components/Expired";
import {
  resetRegiones,
  resetProvidencias,
  resetComunas,
} from "./../../actions/geolocations/index";
import verificador from "verificador-rut";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const EditChore = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chore);
  const stateUpdate = useSelector((state) => state.updateChore);
  const location = useLocation();

  const navigate = useNavigate();

  let empresa = localStorage.getItem("empresa");

  const [dataForm, setDataForm] = useState("");

  const updateChoreTrue = true;

  const [rut, setRut] = useState("");
  const [validarRut, setValidarRut] = useState(false);

  useEffect(() => {
    if (verificador(rut) === true) {
      setValidarRut(true);
    } else {
      setValidarRut(false);
    }
  }, [rut]);

  const handleChange = (e) => {
    setRut(e.target.value);
  };

  useEffect(() => {
    return () => {
      dispatch(resetGetDataChore());
      dispatch(resetRegiones());
      dispatch(resetProvidencias());
      dispatch(resetComunas());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getDataChore({
        id: id,
      })
    );
  }, [dispatch, id]);

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const formik = useFormik({
    initialValues: initialValues(state),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      setDataForm(formData);
    },
  });

  useEffect(() => {
    async function setInitialValues() {
      if (state.statusChore === 200)
        await formik.setValues({
          nombre:
            state.dataChore.datos.nombre !== null
              ? state.dataChore.datos.nombre
              : "",
          direccion:
            state.dataChore.datos.calle !== null
              ? state.dataChore.datos.calle
              : "",
          utmNorte:
            state.dataChore.datos.utm_norte !== null
              ? state.dataChore.datos.utm_norte
              : "",
          utmEste:
            state.dataChore.datos.utm_este !== null
              ? state.dataChore.datos.utm_este
              : "",

          region_id: state.dataChore.datos.region_id,
          comuna_id: state.dataChore.datos.comuna_id,
          providencia_id: state.dataChore.datos.provincia_id,

          representante:
            state.dataChore.datos.nombre_representante_legal !== null
              ? state.dataChore.datos.nombre_representante_legal
              : "",
          correoRepresentante:
            state.dataChore.datos.email_representante_legal !== null
              ? state.dataChore.datos.email_representante_legal
              : "",

          direccionRepresentante:
            state.dataChore.datos.direccion_representante_legal !== null
              ? state.dataChore.datos.direccion_representante_legal
              : "",
          telefonoRepresentante:
            state.dataChore.datos.telefono_representante_legal !== null
              ? state.dataChore.datos.telefono_representante_legal
              : "",

          nombreProfesional:
            state.dataChore.datos.nombre_profesional_responsable !== null
              ? state.dataChore.datos.nombre_profesional_responsable
              : "",
          correoProfesional:
            state.dataChore.datos.correo_profesional_responsable !== null
              ? state.dataChore.datos.correo_profesional_responsable
              : "",
          telefonoProfesional:
            state.dataChore.datos.telefono_profesional_responsable !== null
              ? state.dataChore.datos.telefono_profesional_responsable
              : "",
          cargoProfesional:
            state.dataChore.datos.cargo_profesional_responsable !== null
              ? state.dataChore.datos.cargo_profesional_responsable
              : "",
        });
    }

    setInitialValues();
    // eslint-disable-next-line
  }, [state.statusChore]);

  useEffect(() => {
    if (state.statusChore === 200) {
      setRut(
        state.dataChore.datos.rut_representante_legal !== null
          ? state.dataChore.datos.rut_representante_legal
          : ""
      );
    }

    // eslint-disable-next-line
  }, [state.statusChore]);

  const handleUpdate = () => {
    dispatch(
      updateChore({
        nombre: dataForm.nombre,
        calle: dataForm.direccion,
        region_id: dataForm.region_id,
        utm_norte: dataForm.utmNorte,
        comuna_id: dataForm.comuna_id,
        utm_este: dataForm.utmEste,
        provincia_id: dataForm.providencia_id,

        nombre_representante_legal: dataForm.representante,
        email_representante_legal: dataForm.correoRepresentante,
        rut_representante_legal: rut,
        direccion_representante_legal: dataForm.direccionRepresentante,
        telefono_representante_legal: dataForm.telefonoRepresentante,

        nombre_profesional_responsable: dataForm.nombreProfesional,
        correo_profesional_responsable: dataForm.correoProfesional,
        telefono_profesional_responsable: dataForm.telefonoProfesional,
        cargo_profesional_responsable: dataForm.cargoProfesional,

        faena_id: id,
        empresa_id: parseInt(empresa),
      })
    );
  };

  const resetData = () => {
    dispatch(resetUpdateChore());
  };

  const intl = useIntl();
  const placeholderNombreFaena = intl.formatMessage({
    id: "EscribaNombreFaena",
  });
  const placeholderIndiqueCalle = intl.formatMessage({ id: "IndiqueCalle" });
  const placeholderIndique = intl.formatMessage({ id: "Indique" });
  const placeholderRepresentanteLegal = intl.formatMessage({
    id: "EscribaNombreRepresentanteLegal",
  });
  const placeholderCorreoRepresentanteLegal = intl.formatMessage({
    id: "EscribaCorreoRepresentanteLegal",
  });
  const placeholderRutEjemplo = intl.formatMessage({
    id: "RutRepresentanteEjemplo",
  });
  const placeholderDireccionRepresentanteLegal = intl.formatMessage({
    id: "EscribaDireccionRepresentanteLegal",
  });
  const placeholderTelefonoRepresentanteLegal = intl.formatMessage({
    id: "EscribaTelefonoRepresentanteLegal",
  });
  const placeholderNombreProfesionalResponsable = intl.formatMessage({
    id: "EscribaNombreProfesionalResponsable",
  });
  const placeholderCorreoProfesionalResponsable = intl.formatMessage({
    id: "EscribaCorreoProfesionalResponsable",
  });
  const placeholderTelefonoProfesionalResponsable = intl.formatMessage({
    id: "EscribaTelefonoProfesionalResponsable",
  });
  const placeholderCargoProfesionalResponsable = intl.formatMessage({
    id: "EscribaCargoProfesionalResponsable",
  });

  if (
    /* When the token is expired*/
    state.statusChore === 501
  ) {
    return <Expired />;
  }

  return (
    <Container>
      <DividerGrey />
      <div style={{ display: "flex" }}>
        <ButtonsDashboard id={id} />

        {location.pathname === `/dashboard/edit-chore/${id}` &&
          (state.loadingChore && state.statusChore === 0 ? (
            <Loading />
          ) : (
            <Container className="card-faena">
              <Form onSubmit={formik.handleSubmit}>
                <div className="container-card-add-faena">
                  <h3 className="card-faena-title">
                    <FormattedMessage id="EditarFaena" />
                  </h3>
                  <Image
                    src={closeBtn}
                    alt="Cerrar"
                    onClick={() => navigate(-1)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Form.Group widths="equal">
                  <Form.Input
                    name="nombre"
                    label={<FormattedMessage id="NombreFaenaMayus" />}
                    type="text"
                    placeholder={placeholderNombreFaena}
                    onChange={formik.handleChange}
                    error={formik.errors.nombre}
                    value={formik.values.nombre}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="direccion"
                    label={<FormattedMessage id="DireccionMayus" />}
                    type="text"
                    placeholder={placeholderIndiqueCalle}
                    onChange={formik.handleChange}
                    error={formik.errors.direccion}
                    value={formik.values.direccion}
                  />
                  <Form.Input
                    name="utmNorte"
                    label={<FormattedMessage id="UTMNorteMayus" />}
                    type="text"
                    placeholder={placeholderIndique}
                    onChange={formik.handleChange}
                    error={formik.errors.utmNorte}
                    value={formik.values.utmNorte}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <DropdownAllRegion
                    formik={formik}
                    dataChore={state.dataChore.datos.region_id}
                    updateChore={updateChoreTrue}
                  />
                  <Form.Input
                    name="utmEste"
                    label={<FormattedMessage id="UTMEsteMayus" />}
                    type="text"
                    placeholder={placeholderIndique}
                    onChange={formik.handleChange}
                    error={formik.errors.utmEste}
                    value={formik.values.utmEste}
                  />
                </Form.Group>

                <DropdownAllProvidencia
                  formik={formik}
                  region_id={state.dataChore.datos.region_id}
                  dataChore={state.dataChore.datos.provincia_id}
                  updateChore={updateChoreTrue}
                />

                <DropdownAllComuna
                  formik={formik}
                  providencia_id={state.dataChore.datos.provincia_id}
                  dataChore={state.dataChore.datos.comuna_id}
                  updateChore={updateChoreTrue}
                />

                <Form.Group>
                  <Label className="addFaena__label">
                    <FormattedMessage id="RepresentanteLegal" />
                  </Label>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="representante"
                    label={
                      <FormattedMessage id="NombreRepresentanteLegalMayus" />
                    }
                    type="text"
                    placeholder={placeholderRepresentanteLegal}
                    onChange={formik.handleChange}
                    error={formik.errors.representante}
                    value={formik.values.representante}
                  />

                  <Form.Input
                    name="correoRepresentante"
                    label={
                      <FormattedMessage id="CorreoRepresentanteLegalMayus" />
                    }
                    type="email"
                    placeholder={placeholderCorreoRepresentanteLegal}
                    onChange={formik.handleChange}
                    error={formik.errors.correoRepresentante}
                    value={formik.values.correoRepresentante}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="rutRepresentante"
                    label={<FormattedMessage id="RutRepresentanteLegalMayus" />}
                    type="text"
                    placeholder={placeholderRutEjemplo}
                    onChange={(e) => handleChange(e)}
                    className={
                      validarRut === true
                        ? "card-faena__input-green"
                        : "card-faena__input-red"
                    }
                    value={rut}
                  />

                  <Form.Input
                    name="direccionRepresentante"
                    label={
                      <FormattedMessage id="DireccionRepresentanteLegalMayus" />
                    }
                    type="text"
                    placeholder={placeholderDireccionRepresentanteLegal}
                    onChange={formik.handleChange}
                    error={formik.errors.direccionRepresentante}
                    value={formik.values.direccionRepresentante}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="telefonoRepresentante"
                    label={
                      <FormattedMessage id="TelefonoRepresentanteLegalMayus" />
                    }
                    type="text"
                    placeholder={placeholderTelefonoRepresentanteLegal}
                    onChange={formik.handleChange}
                    error={formik.errors.telefonoRepresentante}
                    value={formik.values.telefonoRepresentante}
                  />
                </Form.Group>

                <Form.Group>
                  <Label className="addFaena__label">
                    <FormattedMessage id="ProfesionalResponsable" />
                  </Label>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="nombreProfesional"
                    label={
                      <FormattedMessage id="NombreProfesionalResponsableMayus" />
                    }
                    type="text"
                    placeholder={placeholderNombreProfesionalResponsable}
                    onChange={formik.handleChange}
                    error={formik.errors.nombreProfesional}
                    value={formik.values.nombreProfesional}
                  />

                  <Form.Input
                    name="correoProfesional"
                    label={
                      <FormattedMessage id="CorreoProfesionalResponsableMayus" />
                    }
                    type="email"
                    placeholder={placeholderCorreoProfesionalResponsable}
                    onChange={formik.handleChange}
                    error={formik.errors.correoProfesional}
                    value={formik.values.correoProfesional}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="telefonoProfesional"
                    label={
                      <FormattedMessage id="TelefonoProfesionalResponsableMayus" />
                    }
                    type="text"
                    placeholder={placeholderTelefonoProfesionalResponsable}
                    onChange={formik.handleChange}
                    error={formik.errors.telefonoProfesional}
                    value={formik.values.telefonoProfesional}
                  />

                  <Form.Input
                    name="cargoProfesional"
                    label={
                      <FormattedMessage id="CargoProfesionalResponsableMayus" />
                    }
                    type="text"
                    placeholder={placeholderCargoProfesionalResponsable}
                    onChange={formik.handleChange}
                    error={formik.errors.cargoProfesional}
                    value={formik.values.cargoProfesional}
                  />
                </Form.Group>

                <div>
                  <Button
                    type="submit"
                    onClick={onShowModal}
                    disabled={!(formik.isValid && formik.dirty && validarRut)}
                  >
                    <FormattedMessage id="ActualizarMayus" />
                  </Button>
                </div>

                <ModalActions
                  title={<FormattedMessage id="ActualizarFaena" />}
                  show={showModal}
                  setShow={setShowModal}
                  status={stateUpdate.statusUpdate}
                  action={handleUpdate}
                  reset={resetData}
                  estado={stateUpdate.dataUpdate}
                >
                  {stateUpdate.statusUpdate === 0 ? (
                    <FormattedMessage id="EstaSeguroActualizarFaena" />
                  ) : stateUpdate.dataUpdate.ejecucion.estado ? (
                    <div className="container-modal">
                      <p className="message-ok">
                        {stateUpdate.dataUpdate.ejecucion.mensaje}
                      </p>
                      <Icon name="checkmark" className="status-ok" />
                    </div>
                  ) : (
                    <div className="container-modal">
                      <p className="message-fail">
                        {stateUpdate.dataUpdate.ejecucion.mensaje}
                      </p>
                      <Image src={ohError} className="status-fail" />
                    </div>
                  )}
                </ModalActions>
              </Form>
            </Container>
          ))}
      </div>
    </Container>
  );

  function initialValues() {
    return {
      nombre: "",
      direccion: "",
      region_id: "",
      utmNorte: "",
      comuna_id: "",
      utmEste: "",
      providencia_id: "",

      representante: "",
      correoRepresentante: "",
      rutRepresentante: "",
      direccionRepresentante: "",
      telefonoRepresentante: "",

      nombreProfesional: "",
      correoProfesional: "",
      telefonoProfesional: "",
      cargoProfesional: "",
    };
  }

  function validationSchema() {
    return {
      nombre: Yup.string().required(true),
      direccion: Yup.string().required(true),

      utmNorte: Yup.string().required(true),
      utmEste: Yup.string().required(true),

      representante: Yup.string().required(true),
      correoRepresentante: Yup.string().email(true).required(true),
      direccionRepresentante: Yup.string().required(true),
      telefonoRepresentante: Yup.string().required(true),

      nombreProfesional: Yup.string().required(true),
      correoProfesional: Yup.string().email(true).required(true),
      telefonoProfesional: Yup.string().required(true),
      cargoProfesional: Yup.string().required(true),
    };
  }
};

export default EditChore;
