const initialState = {
  dataParams: {},
  loadingParams: true,
  statusParams: 0,

  dataUpdateParams: {},
  loadingUpdateParams: false,
  statusUpdateParams: 0,

  dataHistoricParams: {},
  loadingHistoricParams: true,
  statusHistoricParams: 0,

  dataDelete: {},
  loadingDelete: false,
  statusDelete: 0,
};

export default initialState;
