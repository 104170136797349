import initialState from "./initialState";
import {
  RESET_GET_CODE_REGISTER,
  GET_CODE_REGISTER_INIT,
  GET_CODE_REGISTER_SUCCESS,
  GET_CODE_REGISTER_FAILURE,
  RESET_SEND_CODE_REGISTER,
  SEND_CODE_REGISTER_INIT,
  SEND_CODE_REGISTER_SUCCESS,
  SEND_CODE_REGISTER_FAILURE,
} from "../../actions/register/types";

export default function register(state = initialState, action) {
  switch (action.type) {
    case RESET_GET_CODE_REGISTER:
      return {
        ...state,
        dataGetCode: {},
        loadingGetCode: false,
        statusGetCode: 0,
      };
    case GET_CODE_REGISTER_INIT:
      return {
        ...state,
        loadingGetCode: true,
      };
    case GET_CODE_REGISTER_SUCCESS:
      return {
        ...state,
        dataGetCode: action.payload.data,
        loadingGetCode: false,
        statusGetCode: action.payload.status,
      };
    case GET_CODE_REGISTER_FAILURE:
      return {
        ...state,
        dataGetCode: {},
        loadingGetCode: false,
        statusGetCode: 501,
      };

    case RESET_SEND_CODE_REGISTER:
      return {
        ...state,
        dataSendCode: {},
        loadingSendCode: false,
        statusSendCode: 0,
      };
    case SEND_CODE_REGISTER_INIT:
      return {
        ...state,
        loadingSendCode: true,
      };
    case SEND_CODE_REGISTER_SUCCESS:
      return {
        ...state,
        dataSendCode: action.payload.data,
        loadingSendCode: false,
        statusSendCode: action.payload.status,
      };
    case SEND_CODE_REGISTER_FAILURE:
      return {
        ...state,
        dataSendCode: {},
        loadingSendCode: false,
        statusSendCode: 501,
      };

    default:
      return state;
  }
}
