import {
  RESET_GET_MATRIZ8,
  GET_MATRIZ8_INIT,
  GET_MATRIZ8_SUCCESS,
  GET_MATRIZ8_FAILURE,
  RESET_UPDATE_MATRIZ8,
  UPDATE_MATRIZ8_INIT,
  UPDATE_MATRIZ8_SUCCESS,
  UPDATE_MATRIZ8_FAILURE,
} from "./types";
import API from "./api";

// GET
export function resetGetMatriz8() {
  return {
    type: RESET_GET_MATRIZ8,
  };
}
function getMatriz8Init() {
  return {
    type: GET_MATRIZ8_INIT,
  };
}
function getMatriz8Success(data) {
  return {
    type: GET_MATRIZ8_SUCCESS,
    payload: data,
  };
}
function getMatriz8Failure(error) {
  return {
    type: GET_MATRIZ8_FAILURE,
    payload: error,
  };
}
export function getMatriz8(data) {
  return async (dispatch) => {
    dispatch(getMatriz8Init());
    try {
      const resp = await API.data.getMatriz8(data);
      dispatch(getMatriz8Success(resp));
    } catch (error) {
      dispatch(getMatriz8Failure(error));
    }
  };
}

// UPDATE
export function resetUpdateMatriz8() {
  return {
    type: RESET_UPDATE_MATRIZ8,
  };
}
function updateMatriz8Init() {
  return {
    type: UPDATE_MATRIZ8_INIT,
  };
}
function updateMatriz8Success(data) {
  return {
    type: UPDATE_MATRIZ8_SUCCESS,
    payload: data,
  };
}
function updateMatriz8Failure(error) {
  return {
    type: UPDATE_MATRIZ8_FAILURE,
    payload: error,
  };
}
export function updateMatriz8(data) {
  return async (dispatch) => {
    dispatch(updateMatriz8Init());
    try {
      const resp = await API.data.updateMatriz8(data);
      dispatch(updateMatriz8Success(resp));
    } catch (error) {
      dispatch(updateMatriz8Failure(error));
    }
  };
}
