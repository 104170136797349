import {
  RESET_LIST_HOME_DOCS,
  GET_LIST_HOME_DOCS_INIT,
  GET_LIST_HOME_DOCS_SUCCESS,
  GET_LIST_HOME_DOCS_FAILURE,
} from "../../actions/homeDocs/types";
import initialState from "./initialState";

export default function homeDocs(state = initialState, action) {
  switch (action.type) {
    case RESET_LIST_HOME_DOCS:
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case GET_LIST_HOME_DOCS_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_HOME_DOCS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_LIST_HOME_DOCS_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };

    default:
      return state;
  }
}
