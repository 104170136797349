import {
  RESET_GET_CODE_REGISTER,
  GET_CODE_REGISTER_INIT,
  GET_CODE_REGISTER_SUCCESS,
  GET_CODE_REGISTER_FAILURE,
  RESET_SEND_CODE_REGISTER,
  SEND_CODE_REGISTER_INIT,
  SEND_CODE_REGISTER_SUCCESS,
  SEND_CODE_REGISTER_FAILURE,
} from "./types";
import API from "./api";

// GET CODE
export function resetGetCodeToRegister() {
  return {
    type: RESET_GET_CODE_REGISTER,
  };
}
function getCodeToRegisterInit() {
  return {
    type: GET_CODE_REGISTER_INIT,
  };
}
function getCodeToRegisterSuccess(data) {
  return {
    type: GET_CODE_REGISTER_SUCCESS,
    payload: data,
  };
}
function getCodeToRegisterFailure(error) {
  return {
    type: GET_CODE_REGISTER_FAILURE,
    payload: error,
  };
}
export function getCodeToRegister(data) {
  return async (dispatch) => {
    dispatch(getCodeToRegisterInit());
    try {
      const resp = await API.data.getCodeToRegister(data);
      dispatch(getCodeToRegisterSuccess(resp));
    } catch (error) {
      dispatch(getCodeToRegisterFailure(error));
    }
  };
}

// SEND CODE TO VALIDATE
export function resetSendCodeToValidate() {
  return {
    type: RESET_SEND_CODE_REGISTER,
  };
}
function sendCodeToRegisterInit() {
  return {
    type: SEND_CODE_REGISTER_INIT,
  };
}
function sendCodeToRegisterSuccess(data) {
  return {
    type: SEND_CODE_REGISTER_SUCCESS,
    payload: data,
  };
}
function sendCodeToRegisterFailure(error) {
  return {
    type: SEND_CODE_REGISTER_FAILURE,
    payload: error,
  };
}
export function sendCodeToRegister(data) {
  return async (dispatch) => {
    dispatch(sendCodeToRegisterInit());
    try {
      const resp = await API.data.sendCodeToRegister(data);
      dispatch(sendCodeToRegisterSuccess(resp));
    } catch (error) {
      dispatch(sendCodeToRegisterFailure(error));
    }
  };
}
