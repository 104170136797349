import {
  RESET_LIST_DOCUMENTS,
  GET_LIST_DOCUMENTS_INIT,
  GET_LIST_DOCUMENTS_SUCCESS,
  GET_LIST_DOCUMENTS_FAILURE,
} from "./types";
import API from "./api";

export function resetListDocuments() {
  return {
    type: RESET_LIST_DOCUMENTS,
  };
}
function getListDocumentsInit() {
  return {
    type: GET_LIST_DOCUMENTS_INIT,
  };
}
function getListDocumentsSuccess(data) {
  return {
    type: GET_LIST_DOCUMENTS_SUCCESS,
    payload: data,
  };
}
function getListDocumentsFailure(error) {
  return {
    type: GET_LIST_DOCUMENTS_FAILURE,
    payload: error,
  };
}
export function getListDocuments(data) {
  return async (dispatch) => {
    dispatch(getListDocumentsInit());
    try {
      const resp = await API.data.getDocuments(data);
      dispatch(getListDocumentsSuccess(resp));
    } catch (error) {
      dispatch(getListDocumentsFailure(error));
    }
  };
}
