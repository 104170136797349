import axios from "axios";

const API = {
  data: {
    async matricesOk(obj) {
      const response = await axios.get(
        `/api/matriz/matrices-ok/${encodeURIComponent(JSON.stringify(obj))}`
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
