export const RESET_UPDATE_COMENTARIO = "RESET_UPDATE_COMENTARIO";
export const UPDATE_COMENTARIO_INIT = "UPDATE_COMENTARIO_INIT";
export const UPDATE_COMENTARIO_SUCCESS = "UPDATE_COMENTARIO_SUCCESS";
export const UPDATE_COMENTARIO_FAILURE = "UPDATE_COMENTARIO_FAILURE";

export const RESET_UPDATE_CHECKBOX = "RESET_UPDATE_CHECKBOX";
export const UPDATE_CHECKBOX_INIT = "UPDATE_CHECKBOX_INIT";
export const UPDATE_CHECKBOX_SUCCESS = "UPDATE_CHECKBOX_SUCCESS";
export const UPDATE_CHECKBOX_FAILURE = "UPDATE_CHECKBOX_FAILURE";

export const RESET_UPDATE_DROPDOWN = "RESET_UPDATE_DROPDOWN";
export const UPDATE_DROPDOWN_INIT = "UPDATE_DROPDOWN_INIT";
export const UPDATE_DROPDOWN_SUCCESS = "UPDATE_DROPDOWN_SUCCESS";
export const UPDATE_DROPDOWN_FAILURE = "UPDATE_DROPDOWN_FAILURE";

export const SAVE_COMENTARIO = "SAVE_COMENTARIO";
export const SAVE_DROPDOWN = "SAVE_DROPDOWN";
export const SAVE_DATA_SELECTED = "SAVE_DATA_SELECTED";
