import {
  RESET_ADD_REGISTER,
  ADD_REGISTER_INIT,
  ADD_REGISTER_SUCCESS,
  ADD_REGISTER_FAILURE,
} from "./types";
import API from "./api";

export function resetAddRegistro() {
  return {
    type: RESET_ADD_REGISTER,
  };
}
function addRegistroInit() {
  return {
    type: ADD_REGISTER_INIT,
  };
}
function addRegistroSuccess(data) {
  return {
    type: ADD_REGISTER_SUCCESS,
    payload: data,
  };
}
function addRegistroFailure(error) {
  return {
    type: ADD_REGISTER_FAILURE,
    payload: error,
  };
}

export function addRegistro(data) {
  return async (dispatch) => {
    dispatch(addRegistroInit());
    try {
      const resp = await API.data.addRegistro(data);
      dispatch(addRegistroSuccess(resp));
    } catch (error) {
      dispatch(addRegistroFailure(error));
    }
  };
}
