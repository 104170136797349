import {
  RESET_GET_MATRIZ1,
  GET_MATRIZ1_INIT,
  GET_MATRIZ1_SUCCESS,
  GET_MATRIZ1_FAILURE,
  RESET_UPDATE_MATRIZ1,
  UPDATE_MATRIZ1_INIT,
  UPDATE_MATRIZ1_SUCCESS,
  UPDATE_MATRIZ1_FAILURE,
} from "./types";
import API from "./api";

// GET
export function resetGetMatriz1() {
  return {
    type: RESET_GET_MATRIZ1,
  };
}
function getMatriz1Init() {
  return {
    type: GET_MATRIZ1_INIT,
  };
}
function getMatriz1Success(data) {
  return {
    type: GET_MATRIZ1_SUCCESS,
    payload: data,
  };
}
function getMatriz1Failure(error) {
  return {
    type: GET_MATRIZ1_FAILURE,
    payload: error,
  };
}
export function getMatriz1(data) {
  return async (dispatch) => {
    dispatch(getMatriz1Init());
    try {
      const resp = await API.data.getMatriz1(data);
      dispatch(getMatriz1Success(resp));
    } catch (error) {
      dispatch(getMatriz1Failure(error));
    }
  };
}

// UPDATE
export function resetUpdateMatriz1() {
  return {
    type: RESET_UPDATE_MATRIZ1,
  };
}
function updateMatriz1Init() {
  return {
    type: UPDATE_MATRIZ1_INIT,
  };
}
function updateMatriz1Success(data) {
  return {
    type: UPDATE_MATRIZ1_SUCCESS,
    payload: data,
  };
}
function updateMatriz1Failure(error) {
  return {
    type: UPDATE_MATRIZ1_FAILURE,
    payload: error,
  };
}
export function updateMatriz1(data) {
  return async (dispatch) => {
    dispatch(updateMatriz1Init());
    try {
      const resp = await API.data.updateMatriz1(data);
      dispatch(updateMatriz1Success(resp));
    } catch (error) {
      dispatch(updateMatriz1Failure(error));
    }
  };
}
