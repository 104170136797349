import {
  RESET_DEPOSIT,
  GET_DEPOSIT_INIT,
  GET_DEPOSIT_SUCCESS,
  GET_DEPOSIT_FAILURE,
  RESET_DATA_DEPOSIT,
  GET_DATA_DEPOSIT_INIT,
  GET_DATA_DEPOSIT_SUCCESS,
  GET_DATA_DEPOSIT_FAILURE,
  RESET_DELETE_DEPOSIT,
  DELETE_DEPOSIT_INIT,
  DELETE_DEPOSIT_SUCCESS,
  DELETE_DEPOSIT_FAILURE,
  RESET_GET_ALL_DEPOSITS,
  GET_ALL_DEPOSITS_INIT,
  GET_ALL_DEPOSITS_SUCCESS,
  GET_ALL_DEPOSITS_FAILURE,
} from "./types";
import API from "./api";

// GET ALL DEPOSITS
export function resetGetDeposits() {
  return {
    type: RESET_DEPOSIT,
  };
}
function getDepositsInit() {
  return {
    type: GET_DEPOSIT_INIT,
  };
}
function getDepositsSuccess(data) {
  return {
    type: GET_DEPOSIT_SUCCESS,
    payload: data,
  };
}
function getDepositsFailure(error) {
  return {
    type: GET_DEPOSIT_FAILURE,
    payload: error,
  };
}
export function getDeposits(data) {
  return async (dispatch) => {
    dispatch(getDepositsInit());
    try {
      const resp = await API.data.getDeposits(data);
      dispatch(getDepositsSuccess(resp));
    } catch (error) {
      dispatch(getDepositsFailure(error));
    }
  };
}

// GET DATA DEPOSIT
export function resetGetDataDeposit() {
  return {
    type: RESET_DATA_DEPOSIT,
  };
}
function getDataDepositInit() {
  return {
    type: GET_DATA_DEPOSIT_INIT,
  };
}
function getDataDepositSuccess(data) {
  return {
    type: GET_DATA_DEPOSIT_SUCCESS,
    payload: data,
  };
}
function getDataDepositFailure(error) {
  return {
    type: GET_DATA_DEPOSIT_FAILURE,
    payload: error,
  };
}
export function getDataDeposit(data) {
  return async (dispatch) => {
    dispatch(getDataDepositInit());
    try {
      const resp = await API.data.getDataDeposit(data);
      dispatch(getDataDepositSuccess(resp));
    } catch (error) {
      dispatch(getDataDepositFailure(error));
    }
  };
}

// DELETE DEPOSIT
export function resetDeleteDeposit() {
  return {
    type: RESET_DELETE_DEPOSIT,
  };
}
function deleteDepositInit() {
  return {
    type: DELETE_DEPOSIT_INIT,
  };
}
function deleteDepositSuccess(data) {
  return {
    type: DELETE_DEPOSIT_SUCCESS,
    payload: data,
  };
}
function deleteDepositFailure(error) {
  return {
    type: DELETE_DEPOSIT_FAILURE,
    payload: error,
  };
}
export function deleteDeposit(data) {
  return async (dispatch) => {
    dispatch(deleteDepositInit());
    try {
      const resp = await API.data.deleteDeposit(data);
      dispatch(deleteDepositSuccess(resp));
    } catch (error) {
      dispatch(deleteDepositFailure(error));
    }
  };
}

// GET ALL DEPOSITS
export function resetAllDeposits() {
  return {
    type: RESET_GET_ALL_DEPOSITS,
  };
}
function getAllDepositsInit() {
  return {
    type: GET_ALL_DEPOSITS_INIT,
  };
}
function getAllDepositsSuccess(data) {
  return {
    type: GET_ALL_DEPOSITS_SUCCESS,
    payload: data,
  };
}
function getAllDepositsFailure(error) {
  return {
    type: GET_ALL_DEPOSITS_FAILURE,
    payload: error,
  };
}
export function getAllDeposits(data) {
  return async (dispatch) => {
    dispatch(getAllDepositsInit());
    try {
      const resp = await API.data.getAllDeposits(data);
      dispatch(getAllDepositsSuccess(resp));
    } catch (error) {
      dispatch(getAllDepositsFailure(error));
    }
  };
}
