const initialState = {
  dataComuna: {},
  loadingComuna: false,
  statusComuna: 0,

  dataRegion: {},
  loadingRegion: false,
  statusRegion: 0,

  dataProvidencia: {},
  loadingProvidencia: false,
  statusProvidencia: 0,
};

export default initialState;
