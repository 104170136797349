import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Table,
  TextArea,
  Image,
} from "semantic-ui-react";

import { updateMatriz3 } from "../../../actions/matriz3/index";

import ModalActions from "../../Modal/ModalActions";
import { resetUpdateMatriz3 } from "./../../../actions/matriz3/index";
import Expired from "./../../Expired/index";
import ohError from "../../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

export function Matrix3({ id, state }) {
  const dispatch = useDispatch();

  const minero = localStorage.getItem("minero");

  const [error, setError] = useState(false);

  const [form, setForm] = useState({
    valor_compactacion: "",
    valor_espesor: "",
    valor_limite_inferior_4: "",
    valor_limite_inferior_5: 100,
    valor_limite_inferior_10: "",
    valor_limite_inferior_2: "",
    valor_limite_inferior_40: "",
    valor_limite_inferior_042: "",
    valor_limite_inferior_200: "",
    valor_limite_inferior_0075: 20,
    comentario: "",
  });

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [dataDropdown, setDataDropdown] = useState([]);
  const [dataDropdownId, setDataDropdownId] = useState("");

  const [dataCompactacion, setDataCompactacion] = useState([]);
  const [dataCompactacionId, setDataCompactacionId] = useState("");

  const [dataEspesor, setDataEspesor] = useState([]);
  const [dataEspesorId, setDataEspesorId] = useState("");

  const [dataDistribucion, setDataDistribucion] = useState([]);
  const [dataDistribucionId, setDataDistribucionId] = useState("");

  const [checkedCompactacion, setCheckedCompactacion] = useState([]);
  const [checkedEspesor, setCheckedEspesor] = useState([]);
  const [checkedDistribucion, setCheckedDistribucion] = useState([]);

  const [datos, setDatos] = useState();

  const [newDataCompactacion, setNewDataCompactacion] = useState("");
  const [newDataEspesor, setNewDataEspesor] = useState("");
  const [newDataDistribucion, setNewDataDistribucion] = useState("");
  const [newDataDropdown, setNewDataDropdown] = useState("");

  useEffect(() => {
    if (
      (form.valor_compactacion === "" && dataCompactacionId === 1) ||
      (form.valor_espesor === "" && dataEspesorId === 1) ||
      (form.valor_limite_inferior_4 === "" && dataDistribucionId === 1) ||
      (form.valor_limite_inferior_10 === "" && dataDistribucionId === 1) ||
      (form.valor_limite_inferior_2 === "" && dataDistribucionId === 1) ||
      (form.valor_limite_inferior_40 === "" && dataDistribucionId === 1) ||
      (form.valor_limite_inferior_042 === "" && dataDistribucionId === 1) ||
      (form.valor_limite_inferior_200 === "" && dataDistribucionId === 1) ||
      (form.valor_limite_inferior_0075 === "" && dataDistribucionId === 1)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [form, dataDistribucionId, dataCompactacionId, dataEspesorId]);

  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.compactacion_muro_resistente.nivel_compactacion.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataDropdown(arr);
  }, [state.status, state.data.datos]);

  //   DATA COMPACTACION
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.compactacion_muro_resistente.nivel_compactacion.referencia_para_control_seleccionada.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataCompactacion(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxCompactacion = (obj) => {
    const arr = [...checkedCompactacion];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedCompactacion(arr);
  };

  //   DATA ESPESOR
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.espesor.dropdown.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataEspesor(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxEspesor = (obj) => {
    const arr = [...checkedEspesor];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedEspesor(arr);
  };

  //   DATA DISTRIBUCION
  useEffect(() => {
    const arr = [];

    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.distribucion_granulometrica.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataDistribucion(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxDistribucion = (obj) => {
    const arr = [...checkedDistribucion];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedDistribucion(arr);
  };

  useEffect(() => {
    const arrCompactacion = [...checkedCompactacion];
    const arrEspesor = [...checkedEspesor];

    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        state.data.datos.datos.compactacion_muro_resistente.nivel_compactacion.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrCompactacion.push(e.clave)
        );
        setCheckedCompactacion(arrCompactacion);

        state.data.datos.datos.compactacion_muro_resistente.nivel_compactacion.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrEspesor.push(e.clave)
        );
        setCheckedEspesor(arrEspesor);
      }
    }

    // eslint-disable-next-line
  }, [state.status]);

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz3({
        tipo_matriz: state.data.datos.tipo_matriz,
        deposito_id: id,
        compactacion_muro_resistente: {
          nivel_compactacion: {
            sin_antecedentes: null,
            opcion_informacion_id: dataDropdownId,
            referencia_para_control_seleccionada_id: dataCompactacionId,
            porcentaje: form.valor_compactacion,
            documentos_que_respaldan: checkedCompactacion,
          },
        },
        espesor: {
          opcion_informacion_id: dataEspesorId,
          valor: form.valor_espesor,
          documentos_que_respaldan: checkedEspesor,
        },
        distribucion_granulometrica: {
          opcion_informacion_id: dataDistribucionId,
          valor_fila3_col3: form.valor_limite_inferior_4,
          valor_fila3_col4: form.valor_limite_inferior_5,
          valor_fila4_col3: form.valor_limite_inferior_10,
          valor_fila4_col4: form.valor_limite_inferior_2,
          valor_fila5_col3: form.valor_limite_inferior_40,
          valor_fila5_col4: form.valor_limite_inferior_042,
          valor_fila6_col3: form.valor_limite_inferior_200,
          valor_fila6_col4: form.valor_limite_inferior_0075,
          documentos_que_respaldan: checkedDistribucion,
        },
        comentario: form.comentario,
      })
    );
  };

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setForm({
              valor_compactacion:
                state.data.datos.datos.compactacion_muro_resistente
                  .nivel_compactacion.porcentaje.length > 0
                  ? state.data.datos.datos.compactacion_muro_resistente
                      .nivel_compactacion.porcentaje[0].valor
                  : "",
              valor_espesor:
                state.data.datos.datos.espesor.valor.length > 0
                  ? state.data.datos.datos.espesor.valor[0].valor
                  : "",
              valor_limite_inferior_4:
                state.data.datos.datos.distribucion_granulometrica.fila3
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila3
                      .valor_col3.valor
                  : "",
              valor_limite_inferior_5:
                state.data.datos.datos.distribucion_granulometrica.fila3
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila3
                      .valor_col4.valor
                  : 100,
              valor_limite_inferior_10:
                state.data.datos.datos.distribucion_granulometrica.fila4
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila4
                      .valor_col3.valor
                  : "",
              valor_limite_inferior_2:
                state.data.datos.datos.distribucion_granulometrica.fila4
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila4
                      .valor_col4.valor
                  : "",
              valor_limite_inferior_40:
                state.data.datos.datos.distribucion_granulometrica.fila5
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila5
                      .valor_col3.valor
                  : "",
              valor_limite_inferior_042:
                state.data.datos.datos.distribucion_granulometrica.fila5
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila5
                      .valor_col4.valor
                  : "",
              valor_limite_inferior_200:
                state.data.datos.datos.distribucion_granulometrica.fila6
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila6
                      .valor_col3.valor
                  : "",
              valor_limite_inferior_0075:
                state.data.datos.datos.distribucion_granulometrica.fila6
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila6
                      .valor_col4.valor
                  : 20,
              comentario:
                state.data.datos.datos.comentario.valor !== null
                  ? state.data.datos.datos.comentario.valor
                  : "",
            });

            setDataCompactacionId(newDataCompactacion);
            setDataEspesorId(newDataEspesor);
            setDataDistribucionId(newDataDistribucion);
            setDataDropdownId(newDataDropdown);

            const arrCompactacion = [];
            const arrEspesor = [];
            const arrDistribucion = [];

            state.data.datos.datos.compactacion_muro_resistente.nivel_compactacion.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrCompactacion.push(e.clave)
            );
            setCheckedCompactacion(arrCompactacion);
            state.data.datos.datos.espesor.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrEspesor.push(e.clave)
            );
            setCheckedEspesor(arrEspesor);

            state.data.datos.datos.distribucion_granulometrica.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrDistribucion.push(e.clave)
            );
            setCheckedDistribucion(arrDistribucion);
          }
        }
      }
    };
    callsAPI();
    // eslint-disable-next-line
  }, [
    state.loading,
    state.status,
    state.data,
    newDataCompactacion,
    newDataEspesor,
    newDataDistribucion,
    newDataDropdown,
  ]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          if (dataCompactacion.length > 0) {
            const dataSelectedCompactacion = dataCompactacion.find(
              (element) => element.seleccionado === "true"
            );
            if (dataSelectedCompactacion !== undefined) {
              setNewDataCompactacion(dataSelectedCompactacion.value);
            }
          }

          if (dataEspesor.length > 0) {
            const dataSelectedEspesor = dataEspesor.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataEspesor(dataSelectedEspesor.value);
          }

          if (dataDistribucion.length > 0) {
            const dataSelectedDistribucion = dataDistribucion.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataDistribucion(dataSelectedDistribucion.value);
          }

          if (dataDropdown.length > 0) {
            const dataSelectedDropdown = dataDropdown.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataDropdown(dataSelectedDropdown.value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [dataCompactacion, dataEspesor, dataDistribucion, dataDropdown]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.loading]);

  const resetData = () => {
    dispatch(resetUpdateMatriz3());
  };

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({
    id: "IngreseComentario",
  });
  const placeholderValor = intl.formatMessage({ id: "IngreseValor" });
  const placeholderCompactacion = intl.formatMessage({
    id: "SeleccioneCompactacion",
  });
  const placeholderEspesor = intl.formatMessage({ id: "SeleccioneEspesor" });
  const placeholderDistribucion = intl.formatMessage({
    id: "SeleccioneDistribucion",
  });

  /* When the token is expired*/
  if (state.status === 501) {
    return <Expired />;
  }

  return (
    <>
      <Card fluid className="card-deposit">
        {state.data.ejecucion.estado ? (
          <>
            <Card.Content>
              <div
                className="card-deposit-info"
                style={{ flexDirection: "row" }}
              >
                {state.data.datos.datos.titulo_matriz}
              </div>
            </Card.Content>

            <Card.Content className="card-container__matrix2">
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.compactacion_muro_resistente.titulo}
                </p>
                <div className="matrix3__container-nivel">
                  <div>
                    <Dropdown
                      placeholder={placeholderCompactacion}
                      selection
                      options={dataDropdown}
                      value={datos === false ? newDataDropdown : dataDropdownId}
                      onChange={(_, data) => {
                        setDataDropdownId(data.value);
                        setNewDataDropdown(data.value);
                      }}
                      style={{ width: "95%" }}
                      disabled={minero === "false" ? true : false}
                    />

                    {newDataDropdown === "" && dataDropdownId === "" && (
                      <Icon name="warning" className="icon-warning" />
                    )}
                  </div>

                  {newDataDropdown === 1 && dataDropdownId === 1 && (
                    <div>
                      <Dropdown
                        placeholder={placeholderCompactacion}
                        selection
                        options={dataCompactacion}
                        value={
                          datos === false
                            ? newDataCompactacion
                            : dataCompactacionId
                        }
                        onChange={(_, data) => {
                          setDataCompactacionId(data.value);
                          setNewDataCompactacion(data.value);
                        }}
                        style={{ width: "95%" }}
                        disabled={minero === "false" ? true : false}
                      />
                      {newDataCompactacion === "" &&
                        dataCompactacionId === "" && (
                          <Icon name="warning" className="icon-warning" />
                        )}
                    </div>
                  )}

                  <>
                    {(newDataCompactacion !== "" ||
                      dataCompactacionId !== "") &&
                      (newDataDropdown === 1 || dataDropdownId === 1) && (
                        <div className="matrix3__container-valor">
                          <div>
                            <p>
                              {
                                state.data.datos.datos
                                  .compactacion_muro_resistente
                                  .nivel_compactacion.titulo
                              }
                            </p>
                            <Input
                              name="valor_compactacion"
                              placeholder="Ingrese el valor"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => handleChange(e)}
                              maxLength="2"
                              value={form.valor_compactacion}
                              disabled={minero === "false" ? true : false}
                              className={
                                error && form.valor_compactacion === ""
                                  ? "border-red"
                                  : "border-blue"
                              }
                            />
                          </div>

                          <div className="matrix3__compactacion-docs">
                            <p>
                              {
                                state.data.datos.datos
                                  .titulo_documentos_respaldan_matriz
                              }
                            </p>
                            {state.data.datos.datos.compactacion_muro_resistente.nivel_compactacion.documentos_que_respaldan.map(
                              (item, i) => {
                                let index = checkedCompactacion.includes(
                                  item.clave
                                );
                                return (
                                  <Checkbox
                                    key={i}
                                    label={item.label}
                                    onChange={() =>
                                      handleChangeCheckboxCompactacion(item)
                                    }
                                    value={item.clave}
                                    checked={index}
                                    disabled={
                                      minero === "false"
                                        ? true
                                        : !item.editable
                                        ? true
                                        : false
                                    }
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}

                    {/* ESPESOR */}
                    <div>
                      <p className="card-matrix__title">
                        {state.data.datos.datos.espesor.titulo}
                      </p>
                      <div>
                        <Dropdown
                          placeholder={placeholderEspesor}
                          selection
                          options={dataEspesor}
                          value={
                            datos === false ? newDataEspesor : dataEspesorId
                          }
                          onChange={(_, data) => {
                            setDataEspesorId(data.value);
                            setNewDataEspesor(data.value);
                          }}
                          style={{ width: "95%" }}
                          disabled={minero === "false" ? true : false}
                        />
                        {dataEspesorId === "" && newDataEspesor === "" && (
                          <Icon name="warning" className="icon-warning" />
                        )}
                      </div>
                    </div>

                    <div>
                      <>
                        {(dataEspesorId === 1 || newDataEspesor === 1) && (
                          <div className="matrix3__container-valor">
                            <div>
                              <p>{state.data.datos.datos.espesor.label}</p>
                              <Input
                                name="valor_espesor"
                                placeholder={placeholderValor}
                                onChange={(e) => handleChange(e)}
                                value={form.valor_espesor}
                                onKeyPress={(event) => {
                                  if (!/[0-9.]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                disabled={minero === "false" ? true : false}
                                className={
                                  error && form.valor_espesor === ""
                                    ? "border-red"
                                    : "border-blue"
                                }
                              />
                            </div>

                            <div className="matrix3__compactacion-docs">
                              <p>
                                {
                                  state.data.datos.datos
                                    .titulo_documentos_respaldan_matriz
                                }
                              </p>
                              {state.data.datos.datos.compactacion_muro_resistente.nivel_compactacion.documentos_que_respaldan.map(
                                (item, i) => {
                                  let index = checkedEspesor.includes(
                                    item.clave
                                  );
                                  return (
                                    <Checkbox
                                      key={i}
                                      label={item.label}
                                      onChange={() =>
                                        handleChangeCheckboxEspesor(item)
                                      }
                                      value={item.clave}
                                      checked={index}
                                      disabled={
                                        minero === "false"
                                          ? true
                                          : !item.editable
                                          ? true
                                          : false
                                      }
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    </div>

                    <div>
                      <p className="card-matrix__title">
                        {
                          state.data.datos.datos.distribucion_granulometrica
                            .titulo
                        }
                      </p>
                      <div className="matrix3__container-nivel">
                        <div>
                          <Dropdown
                            placeholder={placeholderDistribucion}
                            selection
                            options={dataDistribucion}
                            value={
                              datos === false
                                ? newDataDistribucion
                                : dataDistribucionId
                            }
                            onChange={(_, data) => {
                              setDataDistribucionId(data.value);
                              setNewDataDistribucion(data.value);
                            }}
                            style={{ width: "95%" }}
                            disabled={minero === "false" ? true : false}
                          />

                          {dataDistribucionId === "" &&
                            newDataDistribucion === "" && (
                              <Icon name="warning" className="icon-warning" />
                            )}
                        </div>

                        {dataDistribucionId === 1 && (
                          <>
                            <div
                              className="matrix3__container-valor"
                              style={{ display: "block" }}
                            >
                              <div>
                                <p className="card-matrix__title">
                                  {
                                    state.data.datos.datos
                                      .distribucion_granulometrica.titulo
                                  }
                                </p>

                                <Table celled fixed className="table__matrix3">
                                  <Table.Header>
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.HeaderCell></Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila1
                                            .lbl_col1
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila1
                                            .lbl_col2
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>

                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila2
                                            .lbl_col1
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila2
                                            .lbl_col2
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila2
                                            .lbl_col3
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila2
                                            .lbl_col4
                                        }
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>

                                  <Table.Body>
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila3
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila3
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_limite_inferior_4"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_limite_inferior_4}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error &&
                                            form.valor_limite_inferior_4 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_limite_inferior_5"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          // onChange={(e) => handleChange(e)}
                                          maxLength="3"
                                          value={form.valor_limite_inferior_5}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error &&
                                            form.valor_limite_inferior_5 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>

                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila4
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila4
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_limite_inferior_10"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_limite_inferior_10}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error &&
                                            form.valor_limite_inferior_10 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_limite_inferior_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_limite_inferior_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error &&
                                            form.valor_limite_inferior_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>

                                    {/* 3 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila5
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila5
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_limite_inferior_40"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_limite_inferior_40}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error &&
                                            form.valor_limite_inferior_40 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_limite_inferior_042"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_limite_inferior_042}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error &&
                                            form.valor_limite_inferior_042 ===
                                              ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* 4 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila6
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila6
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_limite_inferior_200"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_limite_inferior_200}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error &&
                                            form.valor_limite_inferior_200 ===
                                              ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_limite_inferior_0075"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={
                                            form.valor_limite_inferior_0075
                                          }
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error &&
                                            form.valor_limite_inferior_0075 ===
                                              ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                  </Table.Body>
                                </Table>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {
                                      state.data.datos.datos
                                        .titulo_documentos_respaldan_matriz
                                    }
                                  </p>
                                  {state.data.datos.datos.distribucion_granulometrica.documentos_que_respaldan.map(
                                    (item, i) => {
                                      let index = checkedDistribucion.includes(
                                        item.clave
                                      );
                                      return (
                                        <Checkbox
                                          key={i}
                                          label={item.label}
                                          onChange={() =>
                                            handleChangeCheckboxDistribucion(
                                              item
                                            )
                                          }
                                          value={item.clave}
                                          checked={index}
                                          disabled={
                                            minero === "false"
                                              ? true
                                              : !item.editable
                                              ? true
                                              : false
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {dataDropdownId !== "" &&
                      dataEspesorId !== "" &&
                      dataDistribucionId !== "" && (
                        <>
                          <TextArea
                            placeholder={placeholderComentario}
                            name="comentario"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{ width: "100%", marginTop: "20px" }}
                            value={form.comentario}
                            disabled={minero === "false" ? true : false}
                            maxLength="1000"
                          />
                          {minero === "true" && error === false && (
                            <div
                              className="btn-update-matrix1"
                              style={{ marginTop: "15px" }}
                            >
                              <Button onClick={onShowModal}>
                                <FormattedMessage id="GuardarMayus" />
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                  </>
                </div>
              </div>

              <ModalActions
                title={<FormattedMessage id="ActualizarMatriz3" />}
                show={showModal}
                setShow={setShowModal}
                status={state.statusUpdate}
                action={handleClickUpdate}
                reset={resetData}
                deleteAction={"Delete"}
                estado={state.dataUpdate}
                loading={state.loadingUpdate}
              >
                {state.statusUpdate === 0 ? (
                  <FormattedMessage id="EstaSeguroMatriz3" />
                ) : state.dataUpdate.ejecucion.estado ? (
                  <div className="container-modal">
                    <p className="message-ok">
                      {state.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Icon name="checkmark" className="status-ok" />
                  </div>
                ) : (
                  <div className="container-modal">
                    <p className="message-fail">
                      {state.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Image src={ohError} className="status-fail" />
                  </div>
                )}
              </ModalActions>
            </Card.Content>
          </>
        ) : (
          <Card.Content>{state.data.ejecucion.mensaje}</Card.Content>
        )}
      </Card>
    </>
  );
}
