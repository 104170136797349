import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Container,
  Dropdown,
  Icon,
  TextArea,
  Image,
} from "semantic-ui-react";

import {
  getMatriz6,
  resetGetMatriz6,
  updateMatriz6,
} from "../../actions/matriz6/index";

import Loading from "./../Loading/index";
import ModalActions from "./../Modal/ModalActions";
import { resetUpdateMatriz6 } from "./../../actions/matriz6/index";
import Expired from "./../Expired/index";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

export function Matrix6({ id }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.matriz6);

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [datos, setDatos] = useState();

  const [dataMecanico, setDataMecanico] = useState([]);
  const [dataMecanicoId, setDataMecanicoId] = useState("");

  const [newDataMecanico, setNewDataMecanico] = useState("");

  const [checkedMecanico, setCheckedMecanico] = useState([]);

  const [form, setForm] = useState({
    comentario: "",
  });

  const minero = localStorage.getItem("minero");

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  useEffect(() => {
    dispatch(
      getMatriz6({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetMatriz6());
    };
  }, [dispatch]);

  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.tipo_comportamiento_mecanico.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataMecanico(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxMecanico = (obj) => {
    const arr = [...checkedMecanico];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedMecanico(arr);
  };

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz6({
        deposito_id: id,
        tipo_comportamiento_mecanico_id: dataMecanicoId,
        documentos_que_respaldan: checkedMecanico,
        comentario: form.comentario,
      })
    );
  };

  //   CARGAR DATA
  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setForm({
              comentario:
                state.data.datos.datos.comentario.valor !== null
                  ? state.data.datos.datos.comentario.valor
                  : "",
            });

            setDataMecanicoId(newDataMecanico);

            const arrMecanico = [];

            state.data.datos.datos.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrMecanico.push(e.clave)
            );
            setCheckedMecanico(arrMecanico);
          }
        }
      }
    };

    callsAPI();
    // eslint-disable-next-line
  }, [state.loading, state.status, state.data, newDataMecanico]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.loading]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos) {
          if (state.data.datos.datos.sin_datos === false) {
            if (dataMecanico.length > 0) {
              const dataSelectedMecanico = dataMecanico.find(
                (element) => element.seleccionado === "true"
              );
              setNewDataMecanico(dataSelectedMecanico.value);
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [dataMecanico]);

  const resetData = () => {
    dispatch(resetUpdateMatriz6());
  };

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({ id: "IngreseComentario" });
  const placeholderSeleccione = intl.formatMessage({ id: "Seleccione" });

  /* When the token is expired*/
  if (state.status === 501 || state.status === 401) {
    return <Expired />;
  }

  return (
    <Container>
      {state.loading ? (
        <Loading />
      ) : (
        <>
          <Card fluid className="card-deposit">
            {state.data.ejecucion.estado ? (
              <>
                <Card.Content>
                  <div
                    className="card-deposit-info"
                    style={{ flexDirection: "row" }}
                  >
                    {state.data.datos.datos.titulo_matriz}
                  </div>
                </Card.Content>

                <Card.Content className="card-container__matrix2">
                  <>
                    <div className="matrix6__container-dropdown">
                      <Dropdown
                        placeholder={placeholderSeleccione}
                        selection
                        options={dataMecanico}
                        style={{ width: "100%" }}
                        value={
                          datos === false ? newDataMecanico : dataMecanicoId
                        }
                        onChange={(_, data) => {
                          setDataMecanicoId(data.value);
                          setNewDataMecanico(data.value);
                        }}
                        disabled={minero === "false" ? true : false}
                      />
                      {dataMecanicoId === "" && dataMecanicoId === "" && (
                        <Icon name="warning" className="icon-warning" />
                      )}
                    </div>

                    {dataMecanicoId !== "" && dataMecanicoId !== 6 && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          {
                            state.data.datos.datos
                              .titulo_documentos_respaldan_matriz
                          }
                        </p>
                        {state.data.datos.datos.documentos_que_respaldan.map(
                          (item, i) => {
                            let index = checkedMecanico.includes(item.clave);

                            return (
                              <Checkbox
                                key={i}
                                label={item.label}
                                onChange={() =>
                                  handleChangeCheckboxMecanico(item)
                                }
                                value={item.clave}
                                checked={index}
                                disabled={
                                  minero === "false"
                                    ? true
                                    : !item.editable
                                    ? true
                                    : false
                                }
                              />
                            );
                          }
                        )}
                      </div>
                    )}

                    {dataMecanicoId !== "" && newDataMecanico !== "" && (
                      <>
                        <TextArea
                          placeholder={placeholderComentario}
                          name="comentario"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{ width: "100%", marginTop: "5px" }}
                          value={form.comentario}
                          disabled={minero === "false" ? true : false}
                          maxLength="1000"
                        />

                        {minero === "true" && (
                          <div
                            className="btn-update-matrix1"
                            style={{ marginTop: "15px" }}
                          >
                            <Button onClick={onShowModal}>
                              <FormattedMessage id="GuardarMayus" />
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </>

                  <ModalActions
                    title={<FormattedMessage id="ActualizarMatriz6" />}
                    show={showModal}
                    setShow={setShowModal}
                    status={state.statusUpdate}
                    action={handleClickUpdate}
                    reset={resetData}
                    deleteAction={"Delete"}
                    estado={state.dataUpdate}
                    loading={state.loadingUpdate}
                  >
                    {state.statusUpdate === 0 ? (
                      <FormattedMessage id="EstaSeguroMatriz6" />
                    ) : state.dataUpdate.ejecucion.estado ? (
                      <div className="container-modal">
                        <p className="message-ok">
                          {state.dataUpdate.ejecucion.mensaje}
                        </p>
                        <Icon name="checkmark" className="status-ok" />
                      </div>
                    ) : (
                      <div className="container-modal">
                        <p className="message-fail">
                          {state.dataUpdate.ejecucion.mensaje}
                        </p>
                        <Image src={ohError} className="status-fail" />
                      </div>
                    )}
                  </ModalActions>
                </Card.Content>
              </>
            ) : (
              <Card.Content>{state.data.ejecucion.mensaje}</Card.Content>
            )}
          </Card>
        </>
      )}
    </Container>
  );
}
