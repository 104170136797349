import {
  RESET_LIST_HOME_DOCS,
  GET_LIST_HOME_DOCS_INIT,
  GET_LIST_HOME_DOCS_SUCCESS,
  GET_LIST_HOME_DOCS_FAILURE,
} from "./types";
import API from "./api";

export function resetListHomeDocuments() {
  return {
    type: RESET_LIST_HOME_DOCS,
  };
}
function getListHomeDocumentsInit() {
  return {
    type: GET_LIST_HOME_DOCS_INIT,
  };
}
function getListHomeDocsSuccess(data) {
  return {
    type: GET_LIST_HOME_DOCS_SUCCESS,
    payload: data,
  };
}
function getListHomeDocsFailure(error) {
  return {
    type: GET_LIST_HOME_DOCS_FAILURE,
    payload: error,
  };
}
export function getListHomeDocuments(data) {
  return async (dispatch) => {
    dispatch(getListHomeDocumentsInit());
    try {
      const resp = await API.data.getHomeDocs(data);
      dispatch(getListHomeDocsSuccess(resp));
    } catch (error) {
      dispatch(getListHomeDocsFailure(error));
    }
  };
}
