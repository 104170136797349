import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Container,
  Dropdown,
  Icon,
  Image,
  TextArea,
} from "semantic-ui-react";

import {
  getMatriz1,
  resetGetMatriz1,
  updateMatriz1,
} from "../../actions/matriz1/index";
import Loading from "./../Loading/index";
import ModalActions from "./../Modal/ModalActions";
import { resetUpdateMatriz1 } from "./../../actions/matriz1/index";
import Expired from "../Expired";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

export function Matrix1({ id }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.matriz1);

  const [datos, setDatos] = useState();

  const [data, setData] = useState([]);
  const [checked, setChecked] = useState([]);
  const [tranque, setTranque] = useState();
  const [form, setForm] = useState({
    tranques_relaves_aguas_abajo: false,
    tranques_relaves_eje_central: false,
    tranques_relaves_aguas_abajo_eje_central: false,
    tranques_de_arena_relaves_aguas_arriba: false,
    tipo_tranque_sin_antecedentes: false,
    comentario: "",
  });

  const [deposit, setDeposit] = useState("");

  const [newDataDeposit, setNewDataDeposit] = useState("");

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const minero = localStorage.getItem("minero");

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  useEffect(() => {
    dispatch(
      getMatriz1({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetMatriz1());
    };
  }, [dispatch]);

  useEffect(() => {
    const arr = [];

    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.combobox.forEach((e) => {
          arr.push({
            key: e.tipo_id,
            value: e.tipo_id,
            text: e.nombre,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setData(arr);
  }, [state.status, state.data.datos]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.data.ejecucion, state.status, state.loading]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.datos.sin_datos === false) {
        if (data.length > 0) {
          const newData = data.find(
            (element) => element.seleccionado === "true"
          );
          setNewDataDeposit(newData.value);
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const arr = [...checked];
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        state.data.datos.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arr.push(e.clave)
        );
        setChecked(arr);
      }
    }
    //eslint-disable-next-line
  }, [state.status]);

  const handleChangeCheckbox = (obj) => {
    const arr = [...checked];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setChecked(arr);
  };

  //   RADIO BUTTONS
  const handleChangeBox = (e) => {
    setTranque(e.target.value);
  };

  // LOAD INPUT WITH DATA
  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (datos === false) {
            setForm({
              tranques_relaves_aguas_abajo:
                state.data.datos.check_excluyente_tipo_tranque
                  .tranques_relaves_aguas_abajo.valor,
              tranques_relaves_eje_central:
                state.data.datos.check_excluyente_tipo_tranque
                  .tranques_relaves_eje_central.valor,
              tranques_relaves_aguas_abajo_eje_central:
                state.data.datos.check_excluyente_tipo_tranque
                  .tranques_relaves_aguas_abajo_eje_central.valor,
              tranques_de_arena_relaves_aguas_arriba:
                state.data.datos.check_excluyente_tipo_tranque
                  .tranques_de_arena_relaves_aguas_arriba.valor,
              tipo_tranque_sin_antecedentes:
                state.data.datos.check_excluyente_tipo_tranque
                  .tipo_tranque_sin_antecedentes.valor,
              comentario:
                state.data.datos.comentario !== null
                  ? state.data.datos.comentario
                  : "",
            });

            setDeposit(newDataDeposit);
          }
        }
      }
    };
    callsAPI();
  }, [state.loading, state.status, state.data, newDataDeposit, datos]);

  useEffect(() => {
    if (tranque === "tranques_relaves_aguas_abajo") {
      setForm((prev) => ({
        ...prev,
        tranques_relaves_aguas_abajo: true,
        tranques_relaves_eje_central: false,
        tranques_relaves_aguas_abajo_eje_central: false,
        tranques_de_arena_relaves_aguas_arriba: false,
        tipo_tranque_sin_antecedentes: false,
      }));
    } else if (tranque === "tranques_relaves_eje_central") {
      setForm((prev) => ({
        ...prev,
        tranques_relaves_aguas_abajo: false,
        tranques_relaves_eje_central: true,
        tranques_relaves_aguas_abajo_eje_central: false,
        tranques_de_arena_relaves_aguas_arriba: false,
        tipo_tranque_sin_antecedentes: false,
      }));
    } else if (tranque === "tranques_relaves_aguas_abajo_eje_central") {
      setForm((prev) => ({
        ...prev,
        tranques_relaves_aguas_abajo: false,
        tranques_relaves_eje_central: false,
        tranques_relaves_aguas_abajo_eje_central: true,
        tranques_de_arena_relaves_aguas_arriba: false,
        tipo_tranque_sin_antecedentes: false,
      }));
    } else if (tranque === "tranques_de_arena_relaves_aguas_arriba") {
      setForm((prev) => ({
        ...prev,
        tranques_relaves_aguas_abajo: false,
        tranques_relaves_eje_central: false,
        tranques_relaves_aguas_abajo_eje_central: false,
        tranques_de_arena_relaves_aguas_arriba: true,
        tipo_tranque_sin_antecedentes: false,
      }));
    } else if (tranque === "tipo_tranque_sin_antecedentes") {
      setForm((prev) => ({
        ...prev,
        tranques_relaves_aguas_abajo: false,
        tranques_relaves_eje_central: false,
        tranques_relaves_aguas_abajo_eje_central: false,
        tranques_de_arena_relaves_aguas_arriba: false,
        tipo_tranque_sin_antecedentes: true,
      }));
    }
  }, [tranque]);

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz1({
        deposito_id: id,
        tipo_deposito_id: deposit,
        tranques_relaves_aguas_abajo: form.tranques_relaves_aguas_abajo,
        tranques_relaves_eje_central: form.tranques_relaves_eje_central,
        tranques_relaves_aguas_abajo_eje_central:
          form.tranques_relaves_aguas_abajo_eje_central,
        tranques_de_arena_relaves_aguas_arriba:
          form.tranques_de_arena_relaves_aguas_arriba,
        tipo_tranque_sin_antecedentes: form.tipo_tranque_sin_antecedentes,
        tipo_deposito_documentos: checked,

        comentario: form.comentario,
      })
    );
  };

  const resetData = () => {
    dispatch(resetUpdateMatriz1());
  };

  const intl = useIntl();

  const placeholderTipoDeposito = intl.formatMessage({
    id: "SeleccioneTipoDeposito",
  });
  const placeholderComentario = intl.formatMessage({
    id: "IngreseComentario",
  });

  /* When the token is expired*/
  if (state.status === 501 || state.status === 401) {
    return <Expired />;
  }

  return (
    <Container>
      {state.loading ? (
        <Loading />
      ) : (
        <Card fluid className="card-deposit">
          <Card.Content>
            <div className="card-deposit-info" style={{ flexDirection: "row" }}>
              {state.data.datos.titulo_matriz}
            </div>
          </Card.Content>

          <Card.Content style={{ borderTop: "none" }}>
            {state.data.ejecucion.estado ? (
              <>
                <div className="matrix__container-dropdown">
                  <p>
                    <FormattedMessage id="TipoDeposito" />
                  </p>

                  <Dropdown
                    placeholder={placeholderTipoDeposito}
                    selection
                    options={data}
                    value={datos === false ? newDataDeposit : deposit}
                    onChange={(_, data) => {
                      setDeposit(data.value);
                      setNewDataDeposit(data.value);
                    }}
                    disabled={minero === "false" ? true : false}
                  />
                  {deposit === "" && newDataDeposit === "" && (
                    <Icon name="warning" className="icon-warning" />
                  )}
                </div>

                {(deposit === 1294 || newDataDeposit === 1294) && (
                  <div className="matrix__container-docs">
                    <div className="matrix__container-tranques">
                      <p>
                        <FormattedMessage id="TipoTranque" />
                      </p>

                      <div className="matrix__container-radio">
                        {/* TRANQUES DE RELAVES */}
                        <div>
                          <input
                            type="radio"
                            name="radio"
                            value="tranques_relaves_aguas_abajo"
                            onChange={handleChangeBox}
                            checked={form.tranques_relaves_aguas_abajo}
                            disabled={minero === "false" ? true : false}
                          />
                          <label>
                            {
                              state.data.datos.check_excluyente_tipo_tranque
                                .tranques_relaves_aguas_abajo.label
                            }
                          </label>
                        </div>

                        {/* TRANQUES DE EJE CENTRAL */}
                        <div>
                          <input
                            type="radio"
                            name="radio"
                            value="tranques_relaves_eje_central"
                            onChange={handleChangeBox}
                            checked={form.tranques_relaves_eje_central}
                            disabled={minero === "false" ? true : false}
                          />
                          <label>
                            {
                              state.data.datos.check_excluyente_tipo_tranque
                                .tranques_relaves_eje_central.label
                            }
                          </label>
                        </div>

                        {/* TRANQUES DE AGUA */}
                        <div>
                          <input
                            type="radio"
                            name="radio"
                            value="tranques_relaves_aguas_abajo_eje_central"
                            onChange={handleChangeBox}
                            checked={
                              form.tranques_relaves_aguas_abajo_eje_central
                            }
                            disabled={minero === "false" ? true : false}
                          />
                          <label>
                            {
                              state.data.datos.check_excluyente_tipo_tranque
                                .tranques_relaves_aguas_abajo_eje_central.label
                            }
                          </label>
                        </div>

                        {/* TRANQUES DE ARENA */}
                        <div>
                          <input
                            type="radio"
                            name="radio"
                            value="tranques_de_arena_relaves_aguas_arriba"
                            onChange={handleChangeBox}
                            checked={
                              form.tranques_de_arena_relaves_aguas_arriba
                            }
                            disabled={minero === "false" ? true : false}
                          />
                          <label>
                            {
                              state.data.datos.check_excluyente_tipo_tranque
                                .tranques_de_arena_relaves_aguas_arriba.label
                            }
                          </label>
                        </div>

                        {/* SIN ANTECEDENTES */}
                        <div>
                          <input
                            type="radio"
                            name="radio"
                            value="tipo_tranque_sin_antecedentes"
                            onChange={handleChangeBox}
                            checked={form.tipo_tranque_sin_antecedentes}
                            disabled={minero === "false" ? true : false}
                          />
                          <label>
                            {
                              state.data.datos.check_excluyente_tipo_tranque
                                .tipo_tranque_sin_antecedentes.label
                            }
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {(deposit !== 7777 || newDataDeposit !== 7777) &&
                  (deposit !== "" || newDataDeposit !== "") && (
                    <div className="matrix__documentos_respaldo">
                      <p>
                        {state.data.datos.titulo_documentos_respaldan_matriz}
                      </p>
                      {state.data.datos.documentos_que_respaldan.map(
                        (item, i) => {
                          let index = checked.includes(item.clave);
                          return (
                            <Checkbox
                              key={i}
                              label={item.label}
                              onChange={() => handleChangeCheckbox(item)}
                              value={item.clave}
                              checked={index}
                              disabled={
                                minero === "false"
                                  ? true
                                  : !item.editable
                                  ? true
                                  : false
                              }
                            />
                          );
                        }
                      )}
                    </div>
                  )}

                {(deposit !== "" || newDataDeposit !== "") && (
                  <TextArea
                    placeholder={placeholderComentario}
                    name="comentario"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ width: "100%", marginTop: "20px" }}
                    value={form.comentario}
                    disabled={minero === "false" ? true : false}
                    maxLength="1000"
                  />
                )}

                {minero === "true" &&
                  (deposit !== "" || newDataDeposit !== "") && (
                    <div className="btn-update-matrix1">
                      <Button onClick={onShowModal}>
                        <FormattedMessage id="GuardarMayus" />
                      </Button>
                    </div>
                  )}

                <ModalActions
                  title={<FormattedMessage id="ActualizarMatriz1" />}
                  show={showModal}
                  setShow={setShowModal}
                  status={state.statusUpdate}
                  action={handleClickUpdate}
                  reset={resetData}
                  deleteAction={"Delete"}
                  estado={state.dataUpdate}
                  loading={state.loadingUpdate}
                >
                  {state.statusUpdate === 0 ? (
                    <FormattedMessage id="EstaSeguroMatriz1" />
                  ) : state.dataUpdate.ejecucion.estado ? (
                    <div className="container-modal">
                      <p className="message-ok">
                        {state.dataUpdate.ejecucion.mensaje}
                      </p>
                      <Icon name="checkmark" className="status-ok" />
                    </div>
                  ) : (
                    <div className="container-modal">
                      <p className="message-fail">
                        {state.dataUpdate.ejecucion.mensaje}
                      </p>
                      <Image src={ohError} className="status-fail" />
                    </div>
                  )}
                </ModalActions>
              </>
            ) : (
              <div>{state.data.ejecucion.mensaje}</div>
            )}
          </Card.Content>
        </Card>
      )}
    </Container>
  );
}
