import {
  RESET_CHORE,
  GET_CHORE_INIT,
  GET_CHORE_SUCCESS,
  GET_CHORE_FAILURE,
  RESET_DATA_CHORE,
  GET_DATA_CHORE_INIT,
  GET_DATA_CHORE_SUCCESS,
  GET_DATA_CHORE_FAILURE,
  RESET_DELETE_CHORE,
  DELETE_CHORE_INIT,
  DELETE_CHORE_SUCCESS,
  DELETE_CHORE_FAILURE,
} from "../../actions/chore/types";
import initialState from "./initialState";

export default function chore(state = initialState, action) {
  switch (action.type) {
    // GET ALL CHORES
    case RESET_CHORE:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_CHORE_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_CHORE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_CHORE_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    // GET DATA CHORE
    case RESET_DATA_CHORE:
      return {
        ...state,
        dataChore: {},
        loadingChore: true,
        statusChore: 0,
      };
    case GET_DATA_CHORE_INIT:
      return {
        ...state,
        loadingChore: true,
      };
    case GET_DATA_CHORE_SUCCESS:
      return {
        ...state,
        dataChore: action.payload.data,
        loadingChore: false,
        statusChore: action.payload.status,
      };
    case GET_DATA_CHORE_FAILURE:
      return {
        ...state,
        dataChore: {},
        loadingChore: false,
        statusChore: 501,
      };
    // DELETE CHORE
    case RESET_DELETE_CHORE:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 0,
      };
    case DELETE_CHORE_INIT:
      return {
        ...state,
        loadingDelete: true,
      };
    case DELETE_CHORE_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload.data,
        loadingDelete: false,
        statusDelete: action.payload.status,
      };
    case DELETE_CHORE_FAILURE:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 501,
      };

    default:
      return state;
  }
}
