import { useEffect, useState } from "react";
import { Container, Card, Image, Icon, Table } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../components/Loading";
import ModalActions from "./../../components/Modal/ModalActions";
import DashboardUbication from "../../components/DashboardUbication/index";
import { getAllDeposits, resetAllDeposits } from "../../actions/deposits";
import Expired from "./../../components/Expired/index";
import { resetGetChores } from "../../actions/chore";
import { deleteDeposit, resetDeleteDeposit } from "../../actions/deposits";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage } from "react-intl";

const AllDeposits = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.deposit);

  let empresaName = localStorage.getItem("empresa_name");

  const location = useLocation();

  const { dataAllDeposits, loadingAllDeposits, statusAllDeposits } = state;

  const empresa = localStorage.getItem("empresa");
  let minero = localStorage.getItem("minero");

  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [dataId, setDataId] = useState("");
  const [nombreEmpresa, setNombreEmpresa] = useState("");

  useEffect(() => {
    if (location.state) {
      if (location.state.empresa_id !== undefined) {
        setDataId(location.state.empresa_id);
        setNombreEmpresa(location.state.state.state.data.datos.razon_social);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (minero === "true") {
      dispatch(
        getAllDeposits({
          empresa_id: empresa,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, empresa]);

  useEffect(() => {
    if (minero === "false" && dataId !== "") {
      dispatch(
        getAllDeposits({
          empresa_id: dataId,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, dataId]);

  useEffect(() => {
    return () => {
      dispatch(resetAllDeposits());
      dispatch(resetGetChores());
    };
  }, [dispatch]);

  const deleteDepositId = () => {
    dispatch(
      deleteDeposit({
        id: id,
      })
    );
  };

  const resetDataAllDeposits = () => {
    dispatch(resetDeleteDeposit());
  };

  const onShowModal = (id) => {
    setId(id);
    setShowModal(true);
  };

  if (statusAllDeposits === 501 || statusAllDeposits === 401) {
    return <Expired />;
  }

  return loadingAllDeposits ? (
    <Loading />
  ) : (
    <Container>
      {minero === "true" && (
        <DashboardUbication>
          {state.statusAllDeposits === 200 ? (
            `${empresaName}`
          ) : (
            <>
              <FormattedMessage id="CargandoMinus" />
            </>
          )}
        </DashboardUbication>
      )}

      {minero === "false" && (
        <DashboardUbication>
          {state.statusAllDeposits === 200 ? (
            `${nombreEmpresa}`
          ) : (
            <>
              <FormattedMessage id="CargandoMinus" />
            </>
          )}
        </DashboardUbication>
      )}

      <Card
        className="dashboard-card faena list-faenas"
        style={{ maxHeight: "60vh" }}
      >
        <Card.Content>
          <div className="dashboard-card__header-faena">
            <h2>
              <FormattedMessage id="Depositos" />
            </h2>
          </div>
        </Card.Content>

        <Table celled>
          <Table.Header style={{ textAlign: "center" }}>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="NombreDeposito" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="NombreProfesionalResponsableMinus" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="NombreRepresentanteLegal" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="UTMNorte" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="UTMEste" />
              </Table.HeaderCell>
              {minero === "true" && (
                <Table.HeaderCell>
                  <FormattedMessage id="Eliminar" />
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>
                <FormattedMessage id="Ver" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {dataAllDeposits.ejecucion.estado ? (
              dataAllDeposits.datos.map((item, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{item.id}</Table.Cell>
                    <Table.Cell>{item.nombre}</Table.Cell>
                    <Table.Cell>
                      {item.nombre_profesional_responsable}
                    </Table.Cell>
                    <Table.Cell>{item.nombre_representante_legal}</Table.Cell>
                    <Table.Cell>{item.utm_norte}</Table.Cell>
                    <Table.Cell>{item.utm_este}</Table.Cell>

                    {minero === "true" && (
                      <Table.Cell>
                        <Icon
                          name="trash"
                          style={{ cursor: "pointer", marginRight: "15px" }}
                          onClick={() => onShowModal(item.id)}
                        />
                      </Table.Cell>
                    )}
                    <Table.Cell>
                      <Link
                        to={`/dashboard/deposit/${item.id}`}
                        state={{ state: item }}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon name="eye" style={{ color: "grey" }} />
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <>{dataAllDeposits.ejecucion.mensaje}</>
            )}
          </Table.Body>
        </Table>

        <ModalActions
          title={<FormattedMessage id="EliminarDeposito" />}
          show={showModal}
          setShow={setShowModal}
          status={state.statusDelete}
          action={deleteDepositId}
          reset={resetDataAllDeposits}
          deleteAction={"Delete"}
          estado={state.dataDelete}
        >
          {state.statusDelete === 0 ? (
            <FormattedMessage id="EstaSeguroEliminarDeposito" />
          ) : state.dataDelete.ejecucion.estado ? (
            <div className="container-modal">
              <p className="message-ok">{state.dataDelete.ejecucion.mensaje}</p>
              <Icon name="checkmark" className="status-ok" />
            </div>
          ) : (
            <div className="container-modal">
              <p className="message-fail">
                {state.dataDelete.ejecucion.mensaje}
              </p>
              <Image src={ohError} className="status-fail" />
            </div>
          )}
        </ModalActions>
      </Card>
    </Container>
  );
};

export default AllDeposits;
