import axios from "axios";

const API = {
  data: {
    async updateComentario(obj) {
      const response = await axios.put(
        `/api/instruments/update-comentario`,
        obj
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async updateCheckbox(obj) {
      const response = await axios.put(`/api/instruments/update-doc`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async updateDropdown(obj) {
      const response = await axios.put(`/api/instruments/update-opcion`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
