import {
  RESET_UPDATE_COMENTARIO,
  UPDATE_COMENTARIO_INIT,
  UPDATE_COMENTARIO_SUCCESS,
  UPDATE_COMENTARIO_FAILURE,
  RESET_UPDATE_CHECKBOX,
  UPDATE_CHECKBOX_INIT,
  UPDATE_CHECKBOX_SUCCESS,
  UPDATE_CHECKBOX_FAILURE,
  RESET_UPDATE_DROPDOWN,
  UPDATE_DROPDOWN_INIT,
  UPDATE_DROPDOWN_SUCCESS,
  UPDATE_DROPDOWN_FAILURE,
  SAVE_COMENTARIO,
  SAVE_DROPDOWN,
  SAVE_DATA_SELECTED,
} from "./types";
import API from "./api";

export function resetUpdateComentario() {
  return {
    type: RESET_UPDATE_COMENTARIO,
  };
}

function updateComentarioInit() {
  return {
    type: UPDATE_COMENTARIO_INIT,
  };
}
function updateComentarioSuccess(data) {
  return {
    type: UPDATE_COMENTARIO_SUCCESS,
    payload: data,
  };
}
function updateComentarioFailure(error) {
  return {
    type: UPDATE_COMENTARIO_FAILURE,
    payload: error,
  };
}

export function updateComentario(data) {
  return async (dispatch) => {
    dispatch(updateComentarioInit());
    try {
      const resp = await API.data.updateComentario(data);
      dispatch(updateComentarioSuccess(resp));
    } catch (error) {
      dispatch(updateComentarioFailure(error));
    }
  };
}

// UPDATE CHECKBOX
export function resetUpdateCheckbox() {
  return {
    type: RESET_UPDATE_CHECKBOX,
  };
}
function updateCheckboxInit() {
  return {
    type: UPDATE_CHECKBOX_INIT,
  };
}
function updateCheckboxSuccess(data) {
  return {
    type: UPDATE_CHECKBOX_SUCCESS,
    payload: data,
  };
}
function updateCheckboxFailure(error) {
  return {
    type: UPDATE_CHECKBOX_FAILURE,
    payload: error,
  };
}

export function updateCheckbox(data) {
  return async (dispatch) => {
    dispatch(updateCheckboxInit());
    try {
      const resp = await API.data.updateCheckbox(data);
      dispatch(updateCheckboxSuccess(resp));
    } catch (error) {
      dispatch(updateCheckboxFailure(error));
    }
  };
}

// UPDATE OPCION
export function resetUpdateDropdown() {
  return {
    type: RESET_UPDATE_DROPDOWN,
  };
}
function updateDropdownInit() {
  return {
    type: UPDATE_DROPDOWN_INIT,
  };
}
function updateDropdownSuccess(data) {
  return {
    type: UPDATE_DROPDOWN_SUCCESS,
    payload: data,
  };
}
function updateDropdownFailure(error) {
  return {
    type: UPDATE_DROPDOWN_FAILURE,
    pyaload: error,
  };
}

export function updateDropdown(data) {
  return async (dispatch) => {
    dispatch(updateDropdownInit());
    try {
      const resp = await API.data.updateDropdown(data);
      dispatch(updateDropdownSuccess(resp));
    } catch (error) {
      dispatch(updateDropdownFailure(error));
    }
  };
}

export function saveDropdown(index, value, dataId) {
  return {
    type: SAVE_DROPDOWN,
    action: { index, value, dataId },
  };
}

export function saveComentario(index, value) {
  return {
    type: SAVE_COMENTARIO,
    action: { index, value },
  };
}

export function saveDataSelected(index, value) {
  return {
    type: SAVE_DATA_SELECTED,
    action: { index, value },
  };
}
