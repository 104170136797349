import {
  RESET_UPDATE_DEPOSIT,
  UPDATE_DEPOSIT_INIT,
  UPDATE_DEPOSIT_SUCCESS,
  UPDATE_DEPOSIT_FAILURE,
} from "./types";
import API from "./api";

export function resetUpdateDeposit() {
  return {
    type: RESET_UPDATE_DEPOSIT,
  };
}
function updateDepositInit() {
  return {
    type: UPDATE_DEPOSIT_INIT,
  };
}
function updateDepositSuccess(data) {
  return {
    type: UPDATE_DEPOSIT_SUCCESS,
    payload: data,
  };
}
function updateDepositFailure(error) {
  return {
    type: UPDATE_DEPOSIT_FAILURE,
    payload: error,
  };
}
export function updateDeposit(data) {
  return async (dispatch) => {
    dispatch(updateDepositInit());
    try {
      const resp = await API.data.updateDeposit(data);
      dispatch(updateDepositSuccess(resp));
    } catch (error) {
      dispatch(updateDepositFailure(error));
    }
  };
}
