import axios from "axios";

const API = {
  data: {
    async getAllParams(obj) {
      const response = await axios.get(
        `/api/params/${encodeURIComponent(JSON.stringify(obj))}`
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async updateParams(obj) {
      const response = await axios.put("/api/params/update", obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getHistoricParams(obj) {
      const response = await axios.get(
        `/api/params/historic/${encodeURIComponent(JSON.stringify(obj))}`
      );

      const { status } = response;
      let data = response.data;

      return {
        data,
        status,
      };
    },

    async deleteHistoricParams(obj) {
      const response = await axios.delete(
        `/api/params/historic/delete/${encodeURIComponent(JSON.stringify(obj))}`
      );

      const { status } = response;
      let data = response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
