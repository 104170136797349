import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import { useFormik } from "formik";
import { Button, Form } from "semantic-ui-react";

import { getCompañiasConFaena } from "../../actions/enrollment";

import DividerGrey from "../../components/DividerGrey";
import { DropdownComuna } from "../../components/Dropdown/DropdownComuna";
import { DropdownRegion } from "../../components/Dropdown/DropdownRegion";
import { DropdownProvidencia } from "../../components/Dropdown/DropdownProvincia";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonsDashboard from "../../components/ButtonsDashboard";
import { FormattedMessage, useIntl } from "react-intl";

const userLang = navigator.language;
const Lang = userLang.slice(0, 2);

const Pucv = () => {
  const formik = useFormik({
    initialValues: initialValues(),
  });

  return (
    <Container>
      <>
        <DividerGrey />

        <div style={{ display: "flex" }}>
          <ButtonsDashboard />

          <div className="pucv-home">
            <h3>
              <FormattedMessage id="CriteriosEmpresa" />
            </h3>
            <Form onSubmit={formik.handleSubmit}>
              <DropdownRegion formik={formik} />

              <DropdownProvidencia
                formik={formik}
                region_id={formik.values.region_id}
              />

              <DropdownComuna
                formik={formik}
                providencia_id={formik.values.providencia_id}
              />

              <DropdownCompanyWithChore
                formik={formik}
                comuna_id={formik.values.comuna_id}
              />
            </Form>

            {formik.values.empresa_id && (
              <Link
                to="/dashboard/company"
                state={{ empresa_id: formik.values.empresa_id }}
              >
                <Button
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    color: "white",
                    background: "#006fb3",
                  }}
                >
                  <FormattedMessage id="IrEmpresa" />
                </Button>
              </Link>
            )}
          </div>
        </div>
      </>
    </Container>
  );
};

export default Pucv;

function initialValues() {
  return {
    region_id: "",
    providencia_id: "",
    comuna_id: "",
    empresa_id: "",
  };
}

// DROPDOWN EMPRESAS CON FAENA
function DropdownCompanyWithChore({ formik, comuna_id }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.enrollment);

  const [dataCompany, setDataCompany] = useState([]);

  const { data, status } = state;

  useEffect(() => {
    dispatch(
      getCompañiasConFaena({
        lenguaje: Lang,
        comuna_id: comuna_id,
      })
    );
  }, [dispatch, comuna_id]);

  useEffect(() => {
    const arr = [];
    if (status === 200) {
      if (data.datos.length > 0) {
        data.datos.forEach((e) => {
          arr.push({
            key: e.empresa_id,
            value: e.empresa_id,
            text: e.nombre,
          });
        });
      }
    }
    setDataCompany(arr);
  }, [data, status, formik.values]);

  const intl = useIntl();

  const placeholderCargando = intl.formatMessage({ id: "CargandoMinus" });
  const placeholderEmpresa = intl.formatMessage({ id: "Empresa" });

  return (
    <>
      <Form.Dropdown
        placeholder={status === 200 ? placeholderEmpresa : placeholderCargando}
        label={<FormattedMessage id="Empresa" />}
        selection
        options={dataCompany}
        value={formik.values.empresa_id}
        onChange={(_, data) => formik.setFieldValue("empresa_id", data.value)}
        errors={formik.errors.empresa_id}
      />
      {dataCompany.length <= 0 && status === 200 && comuna_id !== "" && (
        <p>
          <FormattedMessage id="NoHayEmpresasAsociadas" />
        </p>
      )}
    </>
  );
}
