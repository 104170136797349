import {
  RESET_GET_LIST_STATUS,
  GET_LIST_STATUS_INIT,
  GET_LIST_STATUS_SUCCESS,
  GET_LIST_STATUS_FAILURE,
} from "./types";
import API from "./api";

export function resetListStatus() {
  return {
    type: RESET_GET_LIST_STATUS,
  };
}
function getListStatusInit() {
  return {
    type: GET_LIST_STATUS_INIT,
  };
}
function getListStatusSuccess(data) {
  return {
    type: GET_LIST_STATUS_SUCCESS,
    payload: data,
  };
}
function getListStatusFailure(error) {
  return {
    type: GET_LIST_STATUS_FAILURE,
    payload: error,
  };
}
export function getListStatus(data) {
  return async (dispatch) => {
    dispatch(getListStatusInit());
    try {
      const resp = await API.data.getListStatus(data);
      dispatch(getListStatusSuccess(resp));
    } catch (error) {
      dispatch(getListStatusFailure(error));
    }
  };
}
