const initialState = {
  data: {},
  loading: true,
  status: 0,

  dataDeposit: {},
  loadingDeposit: true,
  statusDeposit: 0,

  dataDelete: {},
  loadingDelete: false,
  statusDelete: 0,

  dataAllDeposits: {},
  loadingAllDeposits: true,
  statusAllDeposits: 0,
};

export default initialState;
