const initialState = {
  data: {},
  loading: true,
  status: 0,

  dataCompany: {},
  loadingCompany: true,
  statusCompany: 0,
};

export default initialState;
