const initialState = {
  data: {},
  loading: true,
  status: 0,

  dataAprove: {},
  loadingAprove: false,
  statusAprove: 0,

  dataDiscard: {},
  loadingDiscard: false,
  statusDiscard: 0,
};

export default initialState;
