import axios from "axios";

const API = {
  data: {
    async updateCompany(obj) {
      const response = await axios.put("/api/company/update", obj);

      const { status } = response;
      let data = response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
