import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getRegiones } from "../../actions/geolocations";

import { Form } from "semantic-ui-react";
import { useIntl } from "react-intl";

const userLang = navigator.language;
const Lang = userLang.slice(0, 2);

// DROPDOWN REGION
export function DropdownRegion({ formik, updateChore }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.geolocations);

  const [data, setData] = useState([]);

  const { dataRegion, statusRegion, loadingRegion } = state;

  useEffect(() => {
    dispatch(getRegiones({ lenguaje: Lang }));
  }, [dispatch]);

  useEffect(() => {
    const arr = [];
    if (statusRegion === 200) {
      if (dataRegion?.datos.length > 0) {
        dataRegion?.datos.forEach((e) => {
          arr.push({
            key: e.region_id,
            value: e.region_id,
            text: e.nombre,
          });
        });
      }
    }
    setData(arr);
  }, [dataRegion, statusRegion]);

  const intl = useIntl();

  const placeHolderCargando = intl.formatMessage({ id: "CargandoMayus" });
  const placeHolderRegion = intl.formatMessage({ id: "RegionMayus" });

  return (
    <Form.Dropdown
      placeholder={loadingRegion ? placeHolderCargando : placeHolderRegion}
      label={placeHolderRegion}
      selection
      options={data}
      value={!loadingRegion ? updateChore && formik.values.region_id : null}
      onChange={(_, data) => {
        formik.setFieldValue("region_id", data.value);
      }}
      errors={formik.errors.region_id}
      disabled={loadingRegion ? true : false}
    />
  );
}
