import axios from "axios";

const API = {
  data: {
    async getTabs(obj) {
      const response = await axios.get(
        `/api/instruments/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getOpcion(obj) {
      const response = await axios.get(
        `/api/instruments/opcion/${encodeURIComponent(JSON.stringify(obj))}`
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async deleteRegistro(obj) {
      const response = await axios.delete(
        `/api/instruments/delete/${encodeURIComponent(JSON.stringify(obj))}`
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
