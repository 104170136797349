import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProvidencias } from "../../actions/geolocations";

import { Form } from "semantic-ui-react";

import { useIntl } from "react-intl";

// DROPDOWN DE PROVINCIA
export function DropdownProvidencia({ formik, region_id, updateChore }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.geolocations);

  const [data, setData] = useState([]);

  const { dataProvidencia, statusProvidencia, loadingProvidencia } = state;

  useEffect(() => {
    if (updateChore) {
      if (region_id !== "") {
        dispatch(getProvidencias({ region_id: region_id }));
      }
    } else {
      if (region_id !== "") {
        dispatch(getProvidencias({ region_id: region_id }));
      }
    }
  }, [dispatch, region_id, updateChore]);

  useEffect(() => {
    const arr = [];
    if (statusProvidencia === 200) {
      if (dataProvidencia.ejecucion.estado) {
        dataProvidencia?.datos.forEach((e) => {
          arr.push({
            key: e.provincia_id,
            value: e.provincia_id,
            text: e.nombre,
          });
        });
      }
    }
    setData(arr);
  }, [dataProvidencia, statusProvidencia, region_id]);

  const intl = useIntl();

  const placeHolderCargando = intl.formatMessage({ id: "CargandoMayus" });
  const placeHolderProvincia = intl.formatMessage({ id: "ProvinciaMayus" });

  return (
    <Form.Dropdown
      placeholder={
        loadingProvidencia ? placeHolderCargando : placeHolderProvincia
      }
      label={placeHolderProvincia}
      selection
      options={data}
      value={
        !loadingProvidencia ? updateChore && formik.values.providencia_id : null
      }
      onChange={(_, data) => formik.setFieldValue("providencia_id", data.value)}
      errors={formik.errors.providencia_id}
      disabled={loadingProvidencia ? true : false}
    />
  );
}
