import {
  RESET_ADD_CHORE,
  ADD_CHORE_INIT,
  ADD_CHORE_SUCCESS,
  ADD_CHORE_FAILURE,
} from "../../actions/add_chore/types";
import initialState from "./initialState";

export default function addChore(state = initialState, action) {
  switch (action.type) {
    case RESET_ADD_CHORE:
      return {
        ...state,
        dataAdd: {},
        loadingAdd: false,
        statusAdd: 0,
      };
    case ADD_CHORE_INIT:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_CHORE_SUCCESS:
      return {
        ...state,
        dataAdd: action.payload.data,
        loadingAdd: false,
        statusAdd: action.payload.status,
      };
    case ADD_CHORE_FAILURE:
      return {
        ...state,
        dataAdd: {},
        loadingAdd: false,
        statusAdd: 501,
      };

    default:
      return state;
  }
}
