import initialState from "./initialState";
import {
  RESET_UPLOAD_FILE,
  UPLOAD_FILE_INIT,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  RESET_DELETE_DOCUMENT,
  DELETE_DOCUMENT_INIT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
} from "../../actions/uploadFile/types";

export default function uploadFile(state = initialState, action) {
  switch (action.type) {
    case RESET_UPLOAD_FILE:
      return {
        ...state,
        dataFile: {},
        loadingFile: false,
        statusFile: 0,
      };
    case UPLOAD_FILE_INIT:
      return {
        ...state,
        loadingFile: true,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        dataFile: action.payload.data,
        loadingFile: false,
        statusFile: action.payload.status,
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        dataFile: {},
        loadingFile: false,
        statusFile: 500,
      };

    // DELETE DOCUMENT
    case RESET_DELETE_DOCUMENT:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 0,
      };
    case DELETE_DOCUMENT_INIT:
      return {
        ...state,
        loadingDelete: true,
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload.data,
        loadingDelete: false,
        statusDelete: action.payload.status,
      };
    case DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 501,
      };

    default:
      return state;
  }
}
