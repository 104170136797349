export const RESET_GET_TABS = "RESET_GET_TABS";
export const GET_TABS_INIT = "GET_TABS_INIT";
export const GET_TABS_SUCCESS = "GET_TABS_SUCCESS";
export const GET_TABS_FAILURE = "GET_TABS_FAILURE";

export const RESET_GET_OPCION = "RESET_GET_OPCION";
export const GET_OPCION_INIT = "GET_OPCION_INIT";
export const GET_OPCION_SUCCESS = "GET_OPCION_SUCCESS";
export const GET_OPCION_FAILURE = "GET_OPCION_FAILURE";

export const RESET_DELETE_REGISTER = "RESET_DELETE_REGISTER";
export const DELETE_REGISTER_INIT = "DELETE_REGISTER_INIT";
export const DELETE_REGISTER_SUCCESS = "DELETE_REGISTER_SUCCESS";
export const DELETE_REGISTER_FAILURE = "DELETE_REGISTER_FAILURE";
