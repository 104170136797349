import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const MenuHome = () => {
  return (
    <Dropdown text="Menú" icon="align justify" labeled button className="icon">
      <Dropdown.Menu>
        <Link to="/dashboard/company">
          <Dropdown.Item className="menuhome__dropdown">
            <FormattedMessage id="Empresa" />
          </Dropdown.Item>
        </Link>
        <Link to="/dashboard/list-chores">
          <Dropdown.Item className="menuhome__dropdown">
            <FormattedMessage id="Faenas" />
          </Dropdown.Item>
        </Link>
        <Link to="/dashboard/list-deposits">
          <Dropdown.Item className="menuhome__dropdown">
            <FormattedMessage id="Depositos" />
          </Dropdown.Item>
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MenuHome;
