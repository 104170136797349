import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Icon, Image } from "semantic-ui-react";
import { getDataChore, resetGetDataChore } from "../../actions/chore";
import {
  getDataDeposit,
  resetGetDataDeposit,
  resetGetDeposits,
} from "../../actions/deposits";
import DashboardUbication from "../../components/DashboardUbication";
import { useParams, useLocation, Link } from "react-router-dom";
import DividerGrey from "../../components/DividerGrey";
import Loading from "./../../components/Loading/index";

import folder from "../../assets/icons/icono_folder-red.png";
import edit from "../../assets/icons/icono_edit.png";
import Expired from "../../components/Expired";
import { format, parse } from "date-fns";
import { FormattedMessage } from "react-intl";

const Deposits = () => {
  const dispatch = useDispatch();
  const stateDeposit = useSelector((state) => state.deposit);
  const stateChore = useSelector((state) => state.chore);

  const { id } = useParams();
  const location = useLocation();
  const { state } = location.state;

  useEffect(() => {
    dispatch(
      getDataDeposit({
        id: id,
      })
    );
    if (state.statusChore === 200) {
      dispatch(
        getDataChore({
          id: state.dataChore.datos.id,
        })
      );
    } else if (state.faena_id !== "") {
      dispatch(
        getDataChore({
          id: state.faena_id,
        })
      );
    }
  }, [dispatch, id, state.dataChore, state.faena_id, state.statusChore]);

  useEffect(() => {
    return () => {
      dispatch(resetGetDataChore());
      dispatch(resetGetDataDeposit());
      dispatch(resetGetDeposits());
    };
  }, [dispatch]);

  if (
    /* When the token is expired*/
    stateDeposit.statusDeposit === 501 ||
    state.status === 501
  ) {
    return <Expired />;
  }

  return (
    <Container>
      <>
        <DashboardUbication>
          {stateDeposit.statusDeposit === 200 &&
          stateChore.statusChore === 200 ? (
            `${stateChore.dataChore.datos.razon_social}/${stateChore.dataChore.datos.nombre}/${stateDeposit.dataDeposit.datos.nombre}`
          ) : (
            <>
              <FormattedMessage id="CargandoMinus" />
            </>
          )}
        </DashboardUbication>
        <DividerGrey />

        <div>
          {stateDeposit.loadingDeposit ? (
            <Loading />
          ) : (
            <div style={{ display: "flex", gap: "15px" }}>
              <CardDeposit
                stateDeposit={stateDeposit}
                id={id}
                stateChore={stateChore}
              />
              <CardDataDeposit stateDeposit={stateDeposit} id={id} />
            </div>
          )}
        </div>
      </>
    </Container>
  );
};

function CardDeposit({ stateDeposit, id, stateChore }) {
  return (
    <Container style={{ width: "40%" }}>
      <Card fluid className="card-deposit">
        <Card.Content>
          <div className="card-deposit-title">
            {stateDeposit.dataDeposit.datos.nombre}
          </div>
        </Card.Content>
        <div className="card-deposit-data">
          <FormattedMessage id="DatosMayus" />
        </div>

        <Card.Content style={{ borderTop: "none" }}>
          <div className="card-deposit-container-name">
            <div style={{ display: "flex", gap: "15px" }}>
              <Image src={folder} alt="Icono Usuario" className="icon-folder" />
              <div className="card-chore-name-deposits">
                <FormattedMessage id="InformacionDeposito" />
              </div>
            </div>
            <Icon name="angle right" />
          </div>
        </Card.Content>
        <div className="card-deposit-data">
          <FormattedMessage id="DocumentosMayus" />
        </div>
        <Card.Content style={{ borderTop: "none" }}>
          <div className="card-deposit-container-name">
            <div>
              <Link
                to={`/dashboard/documents/${id}`}
                className="container-card-left-deposit-chore"
                state={{ state: stateChore }}
              >
                <Image
                  src={folder}
                  alt="Icono Usuario"
                  className="icon-folder"
                />
                <div className="card-chore-name-deposits">
                  <FormattedMessage id="DocumentosLineaBase" />
                </div>
              </Link>
            </div>
            <Icon name="angle right" />
          </div>
        </Card.Content>
      </Card>
    </Container>
  );
}

function CardDataDeposit({ stateDeposit, id }) {
  const [startDate, setStartDate] = useState(new Date());

  const minero = localStorage.getItem("minero");

  useEffect(() => {
    if (stateDeposit.dataDeposit.datos.fecha_inicio_operacion !== null) {
      setStartDate(
        parse(
          stateDeposit.dataDeposit.datos.fecha_inicio_operacion.substr(0, 10),
          "yyyy-MM-dd",
          new Date()
        )
      );
    }
  }, [stateDeposit]);

  return (
    <Container>
      <Card fluid className="card-deposit">
        <Card.Content>
          <div className="card-deposit-info" style={{ flexDirection: "row" }}>
            <FormattedMessage id="InformacionDeposito" />
            <div>
              {minero === "true" && (
                <Link to={`/dashboard/edit-deposit/${id}`}>
                  <Image
                    src={edit}
                    className="card-chore-icon"
                    style={{ marginRight: "15px" }}
                  />
                </Link>
              )}
            </div>
          </div>
        </Card.Content>

        <Card.Content
          style={{
            borderTop: "none",
            display: "flex",
            gap: "15px",
            flexDirection: "column",
          }}
        >
          <div className="card-deposit-info__container">
            <h4>
              <FormattedMessage id="NombreDepositoMayus" />
            </h4>
            <h5>{stateDeposit.dataDeposit.datos.nombre}</h5>
          </div>

          <div style={{ display: "flex", gap: "15px" }}>
            <div className="card-deposit-info__container">
              <h4>
                <FormattedMessage id="CantidadRelavesAutorizadoM3Mayus" />
              </h4>
              <h5>
                {stateDeposit.dataDeposit.datos.cantidad_relaves_autorizado_m3}
              </h5>
            </div>
            <div className="card-deposit-info__container">
              <h4>
                <FormattedMessage id="CantidadRelavesActualM3Mayus" />
              </h4>
              <h5>
                {stateDeposit.dataDeposit.datos.cantidad_relaves_actual_m3}
              </h5>
            </div>
          </div>

          <div style={{ display: "flex", gap: "15px" }}>
            <div className="card-deposit-info__container">
              <h4>
                <FormattedMessage id="CantidadRelavesAutorizadoTMayus" />
              </h4>
              <h5>
                {stateDeposit.dataDeposit.datos.cantidad_relaves_autorizado_ton}
              </h5>
            </div>
            <div className="card-deposit-info__container">
              <h4>
                <FormattedMessage id="CantidadRelavesActualTMayus" />
              </h4>
              <h5>
                {stateDeposit.dataDeposit.datos.cantidad_relaves_actual_ton}
              </h5>
            </div>
          </div>

          <div className="card-deposit-info__container">
            <h4>
              <FormattedMessage id="NombreProfesionalResponsableMayus" />
            </h4>
            <h5>
              {stateDeposit.dataDeposit.datos.nombre_profesional_responsable}
            </h5>
          </div>

          <div className="card-deposit-info__container">
            <h4>
              <FormattedMessage id="CorreoProfesionalResponsableMayus" />
            </h4>
            <h5>
              {stateDeposit.dataDeposit.datos.cargo_profesional_responsable}
            </h5>
          </div>

          <div className="card-deposit-info__container">
            <h4>
              <FormattedMessage id="DistanciaPeligrosaMayus" />
            </h4>
            <h5>{stateDeposit.dataDeposit.datos.distancia_peligrosa}</h5>
          </div>

          <div className="card-deposit-info__container">
            <h4>
              <FormattedMessage id="DuracionDepositoMayus" />
            </h4>
            <h5>{stateDeposit.dataDeposit.datos.vida_util}</h5>
          </div>

          <div className="card-deposit-info__container">
            <h4>
              <FormattedMessage id="FechaInicioOperacionMayus" />
            </h4>
            {stateDeposit.dataDeposit.datos.fecha_inicio_operacion !== null ? (
              <h5>{format(startDate, "dd-MM-yyyy")}</h5>
            ) : (
              ""
            )}
          </div>
        </Card.Content>
      </Card>
    </Container>
  );
}

export default Deposits;
