import { FormattedMessage } from "react-intl";
import { Button, Icon, Modal } from "semantic-ui-react";

const ModalDelete = (props) => {
  const {
    children,
    show,
    setShow,
    title,
    status,
    loading,
    estado,
    action,
    reset,
    close,
  } = props;

  const onClose = () => {
    setShow(false);
  };
  return (
    <Modal
      className="basic-modal"
      open={show}
      onClose={onClose}
      style={{ maxHeight: "50vh", overflowY: "scroll" }}
    >
      <Modal.Header>
        <span>{title}</span>
        <Icon
          name="close"
          onClick={() => {
            onClose();
            close();
          }}
        />
      </Modal.Header>

      <Modal.Content>{children}</Modal.Content>

      <Modal.Actions>
        {status === 200 ? (
          estado.ejecucion.estado ? (
            <Button
              color="blue"
              content={<FormattedMessage id="Cerrar" />}
              onClick={() => {
                onClose();
                reset();
              }}
            />
          ) : (
            <Button
              color="blue"
              content={<FormattedMessage id="Cerrar" />}
              onClick={() => {
                onClose();
                reset();
              }}
            />
          )
        ) : (
          <>
            <Button
              color="red"
              onClick={() => {
                onClose();
              }}
            >
              No
            </Button>
            <Button
              color="blue"
              onClick={() => action()}
              disabled={loading ? true : false}
            >
              {loading ? (
                <FormattedMessage id="CargandoMinus" />
              ) : (
                <FormattedMessage id="Si" />
              )}
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ModalDelete;
