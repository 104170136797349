import { useEffect, useState } from "react";
import { Container, Card, Image, Icon, Table } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import addFaena from "../../assets/icons/btn-sumar_mas-red.png";

import {
  resetDeleteChore,
  deleteChore,
  getChores,
} from "./../../actions/chore/index";
import Loading from "../../components/Loading";
import ModalActions from "./../../components/Modal/ModalActions";
import DashboardUbication from "../../components/DashboardUbication/index";
import Expired from "../../components/Expired";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage } from "react-intl";

const AllChores = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chore);

  let empresaName = localStorage.getItem("empresa_name");

  const location = useLocation();

  const { data, loading } = state;

  const empresa = localStorage.getItem("empresa");
  const minero = localStorage.getItem("minero");

  const [nombreEmpresa, setNombreEmpresa] = useState("");

  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [dataId, setDataId] = useState("");

  useEffect(() => {
    if (location.state) {
      if (location.state.empresa_id !== undefined) {
        setDataId(location.state.empresa_id);
        setNombreEmpresa(location.state.state.state.data.datos.razon_social);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (minero === "true") {
      dispatch(
        getChores({
          empresa_id: empresa,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, empresa]);

  useEffect(() => {
    if (minero === "false" && dataId !== "") {
      dispatch(
        getChores({
          empresa_id: dataId,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, dataId]);

  const deleteChoreId = () => {
    dispatch(
      deleteChore({
        id: id,
      })
    );
  };

  const resetData = () => {
    dispatch(resetDeleteChore());
  };

  const onShowModal = (id) => {
    setId(id);
    setShowModal(true);
  };

  if (state.status === 501 || state.status === 401) {
    return <Expired />;
  }

  return loading ? (
    <Loading />
  ) : (
    <Container>
      {minero === "true" && (
        <DashboardUbication>
          {state.status === 200 ? (
            `${empresaName}`
          ) : (
            <>
              <FormattedMessage id="CargandoMinus" />
            </>
          )}
        </DashboardUbication>
      )}

      {minero === "false" && (
        <DashboardUbication>
          {state.status === 200 ? (
            `${nombreEmpresa}`
          ) : (
            <>
              <FormattedMessage id="CargandoMinus" />
            </>
          )}
        </DashboardUbication>
      )}

      <Card
        className="dashboard-card faena list-faenas"
        style={{ maxHeight: "60vh" }}
      >
        <Card.Content>
          <div className="dashboard-card__header-faena">
            <h2>Faenas</h2>
            {minero === "true" && (
              <Link to="/dashboard/add-chore">
                <Image
                  src={addFaena}
                  alt="Agregar Faena"
                  style={{ cursor: "pointer" }}
                />
              </Link>
            )}
          </div>
        </Card.Content>

        <Table celled>
          <Table.Header style={{ textAlign: "center" }}>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="NombreFaena" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="NombreProfesionalResponsableMinus" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="NombreRepresentanteLegal" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="UTMNorte" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="UTMEste" />
              </Table.HeaderCell>
              {minero === "true" && (
                <Table.HeaderCell>
                  <FormattedMessage id="Eliminar" />
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>
                <FormattedMessage id="Ver" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.ejecucion.estado ? (
              data.datos.map((item, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{item.id}</Table.Cell>
                    <Table.Cell>{item.nombre}</Table.Cell>
                    <Table.Cell>
                      {item.nombre_profesional_responsable}
                    </Table.Cell>
                    <Table.Cell>{item.nombre_representante_legal}</Table.Cell>
                    <Table.Cell>{item.utm_norte}</Table.Cell>
                    <Table.Cell>{item.utm_este}</Table.Cell>
                    {minero === "true" && (
                      <Table.Cell>
                        <Icon
                          name="trash"
                          style={{ cursor: "pointer", marginRight: "15px" }}
                          onClick={() => onShowModal(item.id)}
                        />
                      </Table.Cell>
                    )}
                    <Table.Cell>
                      <Link
                        to={`/dashboard/chore/${item.id}`}
                        state={{ state: item }}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon name="eye" style={{ color: "grey" }} />
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <>{data.ejecucion.mensaje}</>
            )}
          </Table.Body>
        </Table>

        <ModalActions
          title={<FormattedMessage id="EliminarFaena" />}
          show={showModal}
          setShow={setShowModal}
          status={state.statusDelete}
          action={deleteChoreId}
          reset={resetData}
          deleteAction={"Delete"}
          estado={state.dataDelete}
        >
          {state.statusDelete === 0 ? (
            <FormattedMessage id="EstaSeguroEliminarFaena" />
          ) : state.dataDelete.ejecucion.estado ? (
            <div className="container-modal">
              <p className="message-ok">{state.dataDelete.ejecucion.mensaje}</p>
              <Icon name="checkmark" className="status-ok" />
            </div>
          ) : (
            <div className="container-modal">
              <p className="message-fail">
                {state.dataDelete.ejecucion.mensaje}
              </p>
              <Image src={ohError} className="status-fail" />
            </div>
          )}
        </ModalActions>
      </Card>
    </Container>
  );
};

export default AllChores;
