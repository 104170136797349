import {
  RESET_UPDATE_DEPOSIT,
  UPDATE_DEPOSIT_INIT,
  UPDATE_DEPOSIT_SUCCESS,
  UPDATE_DEPOSIT_FAILURE,
} from "../../actions/update_deposit/types";
import initialState from "./initialState";

export default function updateDeposit(state = initialState, action) {
  switch (action.type) {
    case RESET_UPDATE_DEPOSIT:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_DEPOSIT_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_DEPOSIT_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_DEPOSIT_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };

    default:
      return state;
  }
}
