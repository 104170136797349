import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Icon, Image, TextArea } from "semantic-ui-react";
import { updateComentario } from "../../../../actions/update_comentario/index";
import ModalDelete from "./../../../Modal/ModalDelete";
import {
  resetUpdateComentario,
  saveComentario,
} from "./../../../../actions/update_comentario/index";
import Loading from "./../../../Loading/index";
import Expired from "./../../../Expired/index";
import ohError from "../../../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const Comentario = ({ state, data, selected }) => {
  const { loadingOpcion, statusOpcion } = state;

  const minero = localStorage.getItem("minero");

  const dispatch = useDispatch();
  const stateComentario = useSelector((state) => state.updateComentario);

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [form, setForm] = useState({
    comentario: stateComentario?.arrComentarios[selected],
  });

  const [com, setCom] = useState("");

  const [datos, setDatos] = useState(true);

  useEffect(() => {
    if (data.data.comentario !== null) {
      setDatos(false);
      setCom(data.data.comentario);
    } else {
      setDatos(true);
      setCom(data.data.comentario);
    }
  }, [data, stateComentario, com]);

  useEffect(() => {
    const callsAPI = async () => {
      if (statusOpcion === 200) {
        if (datos === false) {
          setForm({
            comentario:
              com !== null
                ? stateComentario?.arrComentarios[selected] !== ""
                  ? stateComentario?.arrComentarios[selected]
                  : com
                : "",
          });
        }

        if (datos === true) {
          setForm({ comentario: "" });
        }
      }
    };

    callsAPI();
    // eslint-disable-next-line
  }, [datos, com, statusOpcion, data]);

  const resetData = () => {
    dispatch(resetUpdateComentario());
  };

  const closeModal = () => {
    dispatch(resetUpdateComentario());
  };

  const handleComentario = () => {
    dispatch(
      updateComentario({
        sistema: {
          modelo: data.data.sistema.modelo,
          encabezado_reg_id: data.data.sistema.encabezado_reg_id,
          clave_tipo_deposito: data.data.sistema.clave_tipo_deposito,
          clave_tipo_instrumento: data.data.sistema.clave_tipo_instrumento,
        },
        comentario: form.comentario,
      })
    );
  };

  const handleChange = (event) => {
    dispatch(saveComentario(selected, event.target.value));
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({ id: "IngreseComentario" });

  if (statusOpcion === 501 || statusOpcion === 401) {
    return <Expired />;
  }

  return (
    <div>
      {loadingOpcion || stateComentario.loadingUpdate ? (
        <Loading />
      ) : (
        <>
          <TextArea
            placeholder={placeholderComentario}
            name="comentario"
            onChange={(e) => {
              handleChange(e);
            }}
            style={{ width: "100%" }}
            value={form.comentario}
            disabled={minero === "false" ? true : false}
            maxLength="1000"
          />
          {minero === "true" && (
            <div className="btn-update-matrix1">
              <Button onClick={onShowModal}>
                <FormattedMessage id="Guardar" />
              </Button>
            </div>
          )}

          <ModalDelete
            title={<FormattedMessage id="ActualizarComentario" />}
            show={showModal}
            setShow={setShowModal}
            action={handleComentario}
            reset={resetData}
            status={stateComentario.statusUpdate}
            estado={stateComentario.dataUpdate}
            loading={stateComentario.loadingUpdate}
            close={closeModal}
          >
            {stateComentario.statusUpdate === 0 ? (
              <FormattedMessage id="EstaSeguroComentario" />
            ) : stateComentario.dataUpdate.ejecucion.estado ? (
              <div className="container-modal">
                <p className="message-ok">
                  {stateComentario.dataUpdate.ejecucion.mensaje}
                </p>
                <Icon name="checkmark" className="status-ok" />
              </div>
            ) : (
              <div className="container-modal">
                <p className="message-fail">
                  {stateComentario.dataUpdate.ejecucion.mensaje}
                </p>
                <Image src={ohError} className="status-fail" />
              </div>
            )}
          </ModalDelete>
        </>
      )}
    </div>
  );
};

export default Comentario;
