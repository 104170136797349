import { Icon, Modal } from "semantic-ui-react";
import { useLocation } from "react-router-dom";

function ModalMessage(props) {
  const {
    title,
    message,
    show,
    setShow,
    onCloseModal,
    reset,
    recover,
    dataRecover,
    dataRegister,
    resetCode,
    registerCode,
    ...rest
  } = props;

  const location = useLocation();

  const onClose = () => {
    setShow(false);
    if (recover === "recover") {
      reset();
    }

    if (location.pathname === "/register") {
      if (dataRecover?.ejecucion?.estado) {
        reset();
        window.location.replace("/login");
      }
    }

    if (location.pathname === "/login") {
      if (dataRegister?.ejecucion?.estado) {
        reset();
        window.location.reload();
      }
    }
  };

  const closeModalCode = () => {
    setShow(false);
    resetCode();
  };

  return (
    <Modal className="basic-modal" open={show} onClose={onClose} {...rest}>
      <Modal.Header>
        <span>{title}</span>
        <Icon
          name="close"
          onClick={
            registerCode
              ? closeModalCode
              : title === "Creando Faena" || title === "Crear Depósito"
              ? onCloseModal
              : onClose
          }
        />
      </Modal.Header>
      <Modal.Content>{message}</Modal.Content>
    </Modal>
  );
}

export default ModalMessage;
