import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { getListResource } from "../../actions/resource";
import { useIntl } from "react-intl";

export function DropdownRecurso({ formik, dataUpdate, update }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.resource);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getListResource());
  }, [dispatch]);

  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        state.data.datos.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.nombre,
          });
        });
      }
    }
    setData(arr);
  }, [state.data, state.status]);

  const intl = useIntl();

  const placeHolderCargando = intl.formatMessage({ id: "CargandoMayus" });
  const placeHolderRecurso = intl.formatMessage({ id: "RecursoMayus" });

  return (
    <Form.Dropdown
      placeholder={state.loading ? placeHolderCargando : placeHolderRecurso}
      label={placeHolderRecurso}
      selection
      options={data}
      value={update ? dataUpdate : formik.values.recurso_id}
      onChange={(_, data) => formik.setFieldValue("recurso_id", data.value)}
      errors={formik.errors.recurso_id}
      disabled={state.loading ? true : false}
    />
  );
}
