import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  Dropdown,
  Checkbox,
  TextArea,
  Button,
  Image,
  Icon,
} from "semantic-ui-react";

import Loading from "../Loading";
import {
  getMatriz8,
  resetGetMatriz8,
  updateMatriz8,
} from "../../actions/matriz8/index";
import ModalActions from "./../Modal/ModalActions";
import { resetUpdateMatriz8 } from "./../../actions/matriz8/index";
import Expired from "./../Expired/index";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

export function Matrix8({ id }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.matriz8);

  const [form, setForm] = useState({
    comentario: "",
  });

  const minero = localStorage.getItem("minero");

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [datos, setDatos] = useState();

  const [dataConstruccion, setDataConstruccion] = useState([]);
  const [dataConstruccionId, setDataConstruccionId] = useState("");

  const [newDataConstruccion, setNewDataConstruccion] = useState("");

  const [checkedConstruccion, setCheckedConstruccion] = useState([]);

  useEffect(() => {
    dispatch(
      getMatriz8({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetMatriz8());
    };
  }, [dispatch]);

  //   DATA CONSTRUCCION
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.tipo_construccion.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataConstruccion(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxConstruccion = (obj) => {
    const arr = [...checkedConstruccion];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedConstruccion(arr);
  };

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz8({
        deposito_id: id,
        tipo_construccion_id: dataConstruccionId,
        documentos_que_respaldan_tipo_construccion: checkedConstruccion,
        comentario: form.comentario,
      })
    );
  };

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setForm({
              comentario:
                state.data.datos.datos.comentario.valor !== null
                  ? state.data.datos.datos.comentario.valor
                  : "",
            });

            setDataConstruccionId(newDataConstruccion);

            const arrConstruccion = [];

            state.data.datos.datos.documentos_que_respaldan_tipo_construccion.map(
              (e) => e.seleccionado === true && arrConstruccion.push(e.clave)
            );
            setCheckedConstruccion(arrConstruccion);
          }
        }
      }
    };
    callsAPI();
    // eslint-disable-next-line
  }, [state.loading, state.status, state.data, newDataConstruccion]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          if (dataConstruccion.length > 0) {
            const dataSelectedConstruccion = dataConstruccion.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataConstruccion(dataSelectedConstruccion.value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [dataConstruccion]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.loading]);

  const resetData = () => {
    dispatch(resetUpdateMatriz8());
  };

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({ id: "IngreseComentario" });

  const placeholderConstruccion = intl.formatMessage({
    id: "SeleccioneConstruccion",
  });

  /* When the token is expired*/
  if (state.status === 501 || state.status === 401) {
    return <Expired />;
  }

  return (
    <Container>
      {state.loading ? (
        <Loading />
      ) : (
        <Card fluid className="card-deposit">
          {state.data.ejecucion.estado ? (
            <>
              <Card.Content>
                <div
                  className="card-deposit-info"
                  style={{ flexDirection: "row" }}
                >
                  {state.data.datos.datos.titulo_matriz}
                </div>
              </Card.Content>

              <Card.Content className="card-container__matrix2">
                <div>
                  <div className="matrix3__container-nivel">
                    <Dropdown
                      placeholder={placeholderConstruccion}
                      selection
                      options={dataConstruccion}
                      value={
                        datos === false
                          ? newDataConstruccion
                          : dataConstruccionId
                      }
                      onChange={(_, data) => {
                        setDataConstruccionId(data.value);
                        setNewDataConstruccion(data.value);
                      }}
                      disabled={minero === "false" ? true : false}
                    />
                    <div className="matrix3__container-valor">
                      {dataConstruccionId !== "" &&
                        newDataConstruccion !== "" &&
                        dataConstruccionId !== 31 &&
                        newDataConstruccion !== 31 && (
                          <>
                            <div className="matrix3__compactacion-docs">
                              <p>
                                {
                                  state.data.datos.datos
                                    .titulo_documentos_respaldan_matriz
                                }
                              </p>
                              {state.data.datos.datos.documentos_que_respaldan_tipo_construccion.map(
                                (item, i) => {
                                  let index = checkedConstruccion.includes(
                                    item.clave
                                  );
                                  return (
                                    <Checkbox
                                      key={i}
                                      label={item.label}
                                      onChange={() =>
                                        handleChangeCheckboxConstruccion(item)
                                      }
                                      value={item.clave}
                                      checked={index}
                                      disabled={
                                        minero === "false"
                                          ? true
                                          : !item.editable
                                          ? true
                                          : false
                                      }
                                    />
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                    </div>
                  </div>

                  {dataConstruccionId !== "" && newDataConstruccion !== "" && (
                    <>
                      <TextArea
                        placeholder={placeholderComentario}
                        name="comentario"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{ width: "100%", marginTop: "20px" }}
                        value={form.comentario}
                        disabled={minero === "false" ? true : false}
                        maxLength="1000"
                      />
                      {minero === "true" && (
                        <div
                          className="btn-update-matrix1"
                          style={{ marginTop: "15px" }}
                        >
                          <Button onClick={onShowModal}>
                            <FormattedMessage id="Guardar" />
                          </Button>
                        </div>
                      )}
                    </>
                  )}

                  <ModalActions
                    title={<FormattedMessage id="ActualizarMatriz8" />}
                    show={showModal}
                    setShow={setShowModal}
                    status={state.statusUpdate}
                    action={handleClickUpdate}
                    reset={resetData}
                    deleteAction={"Delete"}
                    estado={state.dataUpdate}
                    loading={state.loadingUpdate}
                  >
                    {state.statusUpdate === 0 ? (
                      <FormattedMessage id="EstaSeguroMatriz8" />
                    ) : state.dataUpdate.ejecucion.estado ? (
                      <div className="container-modal">
                        <p className="message-ok">
                          {state.dataUpdate.ejecucion.mensaje}
                        </p>
                        <Icon name="checkmark" className="status-ok" />
                      </div>
                    ) : (
                      <div className="container-modal">
                        <p className="message-fail">
                          {state.dataUpdate.ejecucion.mensaje}
                        </p>
                        <Image src={ohError} className="status-fail" />
                      </div>
                    )}
                  </ModalActions>
                </div>
              </Card.Content>
            </>
          ) : (
            <Card.Content>{state.data.ejecucion.mensaje}</Card.Content>
          )}
        </Card>
      )}
    </Container>
  );
}
