import { Button, Icon, Modal } from "semantic-ui-react";
import {
  resetAproveUser,
  resetDiscardUser,
} from "./../../actions/aprove/index";
import { FormattedMessage } from "react-intl";

const ModalActions = (props) => {
  const {
    children,
    title,
    show,
    setShow,
    id,
    aprove,
    handleAprove,
    handleDecline,
    state,
    ...rest
  } = props;

  const onClose = () => {
    setShow(false);
    resetAproveUser();
    resetDiscardUser();
  };

  const reload = () => {
    if (state.statusAprove === 200 || state.statusDiscard === 200) {
      window.location.reload();
    }
  };

  return (
    <Modal className="basic-modal" open={show} onClose={onClose} {...rest}>
      <Modal.Header>
        <span>{title}</span>
        <Icon
          name="close"
          onClick={() => {
            onClose();
            reload();
          }}
        />
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        {state.statusAprove === 200 || state.statusDiscard === 200 ? (
          <Button
            color="blue"
            content={<FormattedMessage id="Cerrar" />}
            onClick={() => {
              onClose();
              reload();
            }}
          />
        ) : (
          <>
            <Button
              color="red"
              onClick={() => {
                onClose();
                reload();
              }}
            >
              No
            </Button>
            <Button
              content={<FormattedMessage id="Si" />}
              labelPosition="right"
              icon="checkmark"
              color="blue"
              onClick={aprove ? handleAprove : handleDecline}
            />
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ModalActions;
