import {
  RESET_UPLOAD_FILE,
  UPLOAD_FILE_INIT,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  RESET_DELETE_DOCUMENT,
  DELETE_DOCUMENT_INIT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
} from "./types";
import API from "./api";

// UPLOAD FILE
export function resetUploadFile() {
  return {
    type: RESET_UPLOAD_FILE,
  };
}

function uploadFileInit() {
  return {
    type: UPLOAD_FILE_INIT,
  };
}

function uploadFileInitSuccess(data) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload: data,
  };
}

function uploadFileInitFailure(error) {
  return {
    type: UPLOAD_FILE_FAILURE,
    payload: error,
  };
}

export function uploadFile(obj) {
  return async (dispatch) => {
    dispatch(uploadFileInit());
    try {
      const data = await API.data.uploadFile(obj);
      dispatch(uploadFileInitSuccess(data));
    } catch (error) {
      dispatch(uploadFileInitFailure(error));
    }
  };
}

// DELETE FILE
export function resetDeleteDocument() {
  return {
    type: RESET_DELETE_DOCUMENT,
  };
}
function deleteDocumentInit() {
  return {
    type: DELETE_DOCUMENT_INIT,
  };
}
function deleteDocumentSuccess(data) {
  return {
    type: DELETE_DOCUMENT_SUCCESS,
    payload: data,
  };
}
function deleteDocumentFailure(error) {
  return {
    type: DELETE_DOCUMENT_FAILURE,
    payload: error,
  };
}
export function deleteDocument(data) {
  return async (dispatch) => {
    dispatch(deleteDocumentInit());
    try {
      const resp = await API.data.deleteDocument(data);
      dispatch(deleteDocumentSuccess(resp));
    } catch (error) {
      dispatch(deleteDocumentFailure(error));
    }
  };
}
