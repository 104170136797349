import {
  RESET_GET_MATRIZ3,
  GET_MATRIZ3_INIT,
  GET_MATRIZ3_SUCCESS,
  GET_MATRIZ3_FAILURE,
  RESET_UPDATE_MATRIZ3,
  UPDATE_MATRIZ3_INIT,
  UPDATE_MATRIZ3_SUCCESS,
  UPDATE_MATRIZ3_FAILURE,
} from "./types";
import API from "./api";

// GET
export function resetGetMatriz3() {
  return {
    type: RESET_GET_MATRIZ3,
  };
}
function getMatriz3Init() {
  return {
    type: GET_MATRIZ3_INIT,
  };
}
function getMatriz3Success(data) {
  return {
    type: GET_MATRIZ3_SUCCESS,
    payload: data,
  };
}
function getMatriz3Failure(error) {
  return {
    type: GET_MATRIZ3_FAILURE,
    payload: error,
  };
}
export function getMatriz3(data) {
  return async (dispatch) => {
    dispatch(getMatriz3Init());
    try {
      const resp = await API.data.getMatriz3(data);
      dispatch(getMatriz3Success(resp));
    } catch (error) {
      dispatch(getMatriz3Failure(error));
    }
  };
}

// UPDATE
export function resetUpdateMatriz3() {
  return {
    type: RESET_UPDATE_MATRIZ3,
  };
}
function updateMatriz3Init() {
  return {
    type: UPDATE_MATRIZ3_INIT,
  };
}
function updateMatriz3Success(data) {
  return {
    type: UPDATE_MATRIZ3_SUCCESS,
    payload: data,
  };
}
function updateMatriz3Failure(error) {
  return {
    type: UPDATE_MATRIZ3_FAILURE,
    payload: error,
  };
}
export function updateMatriz3(data) {
  return async (dispatch) => {
    dispatch(updateMatriz3Init());
    try {
      const resp = await API.data.updateMatriz3(data);
      dispatch(updateMatriz3Success(resp));
    } catch (error) {
      dispatch(updateMatriz3Failure(error));
    }
  };
}
