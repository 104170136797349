import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Container, Image, Button } from "semantic-ui-react";

import banner1 from "../../assets/img/Relaves Cerro Bayo (1).jpg";

import pucv from "../../assets/img/logo_pucv.png";
import sernageomin from "../../assets/img/sernageomin-full.jpg";
import sol from "../../assets/img/logo_sol.jpg";
import smp from "../../assets/img/logo_smp.jpg";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./../../components/Loading/index";
import { getListHomeDocuments } from "../../actions/homeDocs";
import { getObtenerUrl } from "../../actions/url";

import { FormattedMessage } from "react-intl";

const HomePage = () => {
  const [selected, setSelected] = useState(1);
  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

  const state = useSelector((state) => state.homeDocs);
  const stateUrl = useSelector((state) => state.url);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListHomeDocuments());
    dispatch(getObtenerUrl());
  }, [dispatch]);

  let auth = localStorage.getItem("auth");
  auth = auth === "true";

  let servicio = localStorage.getItem("servicio");

  return auth ? (
    servicio === "true" ? (
      <Navigate to="/dashboard/pucv" />
    ) : (
      <Navigate to="/dashboard/company" />
    )
  ) : (
    <div>
      <Image src={banner1} alt="banner-home" className="banner_home" />

      <Container>
        <div className="menu-home-title">
          <p className="little-title">
            <FormattedMessage id="Descubre" />
          </p>
          <p className="big-title">
            <FormattedMessage id="SobreProyecto" />
          </p>
        </div>
      </Container>
      <Container>
        <div className="container-menu-home">
          <MenuHome selected={selected} handleClick={handleClick} />
          <MenuHomeText selected={selected} state={state} stateUrl={stateUrl} />
        </div>
      </Container>
    </div>
  );
};

export default HomePage;

function MenuHome({ selected, handleClick }) {
  return (
    <Container style={{ width: "25%" }}>
      <div className="container-buttons-home">
        <Button
          className={selected === 1 ? "btn-home__selected" : "btn-home"}
          onClick={handleClick(1)}
        >
          <FormattedMessage id="Objetivos" />
        </Button>
        <Button
          className={selected === 2 ? "btn-home__selected" : "btn-home"}
          onClick={handleClick(2)}
        >
          <FormattedMessage id="Intestigadores" />
        </Button>
        <Button
          className={selected === 3 ? "btn-home__selected" : "btn-home"}
          onClick={handleClick(3)}
        >
          <FormattedMessage id="Documentacion" />
        </Button>
        <Button
          className={selected === 4 ? "btn-home__selected" : "btn-home"}
          onClick={handleClick(4)}
        >
          <FormattedMessage id="EntidadesParticipantes" />
        </Button>
      </div>
    </Container>
  );
}

function MenuHomeText({ selected, state, stateUrl }) {
  return (
    <Container style={{ width: "75%", textAlign: "right" }}>
      <hr />
      <div className="container_text-left-home">
        {selected === 1 && (
          <>
            <h2>
              <FormattedMessage id="SobreRepositorio" />
            </h2>
            <p>
              <FormattedMessage id="SobreRepositorioText" />
            </p>
          </>
        )}
        {selected === 2 && (
          <>
            <h2>
              <FormattedMessage id="EquipoProyecto" />
            </h2>
            <div>
              <h4>Pontificia Universidad Católica de Valparaíso</h4>

              <li>Gabriel Villavicencio Arancibia (Director del proyecto)</li>

              <li>
                Gabriel Hermosilla Vigneau (Director alterno del proyecto y jefe
                de Línea Inteligencia Artificial)
              </li>

              <li>
                Vinicius Ferraz Minatogawa (Investigador, jefe de Líneas Ciencia
                de Datos y Plataforma)
              </li>

              <li>
                José García Conejeros (Investigador, Líneas Inteligencia
                Artificial y Ciencia de Datos)
              </li>

              <li>Osvaldo Piña (Ingeniero del proyecto)</li>

              <li>
                Pamela Valenzuela Toro (Investigador, jefe de Transferencia
                Tecnológica)
              </li>

              <li>
                Juan Palma González (Investigador, jefe de Línea Geotecnia)
              </li>

              <li>
                Héctor Allende Cid Héctor Allende Cid (Investigador, Línea
                Ciencia de Datos)
              </li>

              <li>
                Sergio Medrano Caballero (Ingeniero, Línea Ciencia de Datos y
                Plataforma)
              </li>

              <li>
                Felipe Campos Rojas (Estudiante de pregrado, Apoyo técnico Línea
                Ciencia de Datos y Plataforma)
              </li>
            </div>
            <div>
              <h2>
                <FormattedMessage id="Colaboradores" />
              </h2>

              <li>
                Universidad Técnica Federico Santa María, Gonzalo Suazo
                Fuentealba (Investigador, Línea Geotecnia)
              </li>
              <li>
                Pontificia Universidad Católica de Valparaíso, Alex Carpanetti
                Lanyon (Investigador, Línea Geotecnia)
              </li>
              <h4>DataOn Ingeniería</h4>
              <li>Leonardo Causa Morales</li>
              <li>Max Olaf Munro Muñoz</li>
              <div>
                <h2>
                  <FormattedMessage id="ServicioNacionalGeologiaMineria" />
                </h2>
                <li>Andrés León Riquelme</li>
                <li>Ricardo López Toledo</li>
                <li>Luis Briceño Ponce</li>
                <li>Martin Salazar Ríos</li>
                <li>Gabriel Valdés Cadis</li>
                <li>Jaime Martínez</li>
                <li>Carolina Araya Espinoza</li>
                <li>Gullibert Novoa Godoy</li>
                <li>Cristóbal Carrasco Jaramillo</li>
                <li>Karina Monsalve Stange</li>
              </div>
              <div>
                <h2>Sol Solution</h2>
                <li>Younès HADDANI</li>
                <li>Miguel Benz Navarrete</li>
                <li>Caroline Forestti Oliveira</li>
                <li>Quentin Portailler</li>
                <li>Sébastien Barbier</li>
              </div>
              <div>
                <h2>Nueva Pudahuel S.A.</h2>
                <li>Manuel Bascuñán Valenzuela</li>
              </div>
            </div>
          </>
        )}
        {selected === 3 && (
          <>
            {state.loading || stateUrl.loadingUrl ? (
              <Loading />
            ) : (
              <>
                <h2>
                  <FormattedMessage id="Manual" />
                </h2>
                {state.data.ejecucion.estado ? (
                  <div>
                    {state.data.datos.map((item, i) => (
                      <ul key={i} style={{ marginBottom: "10px" }}>
                        <li style={{ fontWeight: "bold" }}>{item.titulo}</li>
                        <Button
                          className="btn-home-download"
                          href={`${stateUrl.dataUrl.datos}${item.archivo_imagen}`}
                          download
                          target="_blank"
                        >
                          <FormattedMessage id="DescargarMayus" />
                        </Button>
                      </ul>
                    ))}
                  </div>
                ) : (
                  <div>{state.data.ejecucion.mensaje}</div>
                )}
              </>
            )}
          </>
        )}
        {selected === 4 && (
          <>
            <div>
              <div className="container-empresas-asociadas">
                <p>
                  <FormattedMessage id="PontificiaUniversidadCatolicaValparaiso" />
                </p>
                <a href="https://www.pucv.cl" target="_blank" rel="noreferrer">
                  <Image src={pucv} />
                </a>
              </div>
            </div>
            <div>
              <div className="container-empresas-asociadas">
                <p>
                  <FormattedMessage id="Sernageomin" />
                </p>
                <a
                  href="https://www.sernageomin.cl"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src={sernageomin} />
                </a>
              </div>
            </div>
            <div>
              <div className="container-empresas-asociadas">
                <p>Sol Solution</p>
                <a
                  href="https://www.sol-solution.com/es/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src={sol} />
                </a>
              </div>
            </div>
            <div>
              <div className="container-empresas-asociadas">
                <p>Nueva Pudahuel S.A.</p>
                <a
                  href="https://www.nuevapudahuel.cl/index.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src={smp} />
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
}
