export const GET_USERS_TO_APROVE_INIT = "GET_USERS_TO_APROVE_INIT";
export const GET_USERS_TO_APROVE_SUCCESS = "GET_USERS_TO_APROVE_SUCCESS";
export const GET_USERS_TO_APROVE_FAILURE = "GET_USERS_TO_APROVE_FAILURE";
export const RESET_USERS_TO_APROVE = "RESET_USERS_TO_APROVE";

export const APROVE_USER_INIT = "APROVE_USER_INIT";
export const APROVE_USER_SUCCESS = "APROVE_USER_SUCCESS";
export const APROVE_USER_FAILURE = "APROVE_USER_FAILURE";
export const RESET_APROVE_USER = "RESET_APROVE_USER";

export const DISCARD_USER_INIT = "DISCARD_USER_INIT";
export const DISCARD_USER_SUCCESS = "DISCARD_USER_SUCCESS";
export const DISCARD_USER_FAILURE = "DISCARD_USER_FAILURE";
export const RESET_DISCARD_USER = "RESET_DISCARD_USER";
