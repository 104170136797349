import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";

const ButtonsDashboard = ({ id, dataId, state }) => {
  const location = useLocation();

  let servicio = localStorage.getItem("servicio");

  return (
    <div className="container-buttons-home">
      {location.pathname !== "/dashboard/pucv" && (
        <>
          <Link to="/dashboard/company">
            <Button
              className={
                location.pathname === "/dashboard/company" ||
                location.pathname === "/dashboard/add-chore" ||
                location.pathname === `/dashboard/edit-chore/${id}` ||
                location.pathname === `/dashboard/edit-deposit/${id}` ||
                location.pathname === `/dashboard/add-deposit` ||
                location.pathname === `/dashboard/edit-company/${id}`
                  ? "btn__dashboard-btn__active"
                  : "btn__dashboard-btn"
              }
            >
              <FormattedMessage id="Empresa" />
            </Button>
          </Link>

          <Link
            to="/dashboard/list-chores"
            state={{ empresa_id: dataId, state }}
          >
            <Button className="btn__dashboard-btn">
              <FormattedMessage id="Faenas" />
            </Button>
          </Link>
          <Link
            to="/dashboard/list-deposits"
            state={{ empresa_id: dataId, state }}
          >
            <Button className="btn__dashboard-btn">
              <FormattedMessage id="Depositos" />
            </Button>
          </Link>
          {servicio === "true" && (
            <>
              <Link to="/dashboard/enrollment">
                <Button className="btn__dashboard-btn">
                  <FormattedMessage id="Enrolar" />
                </Button>
              </Link>
              <Link to="/dashboard/pucv">
                <Button className="btn__dashboard-btn">
                  <FormattedMessage id="SeleccionarEmpresa" />
                </Button>
              </Link>
            </>
          )}
        </>
      )}

      {location.pathname === "/dashboard/pucv" && (
        <>
          <Link to="/dashboard/pucv">
            <Button
              className={
                location.pathname === "/dashboard/pucv"
                  ? "btn__dashboard-btn__active"
                  : "btn__dashboard-btn"
              }
              onClick={() => window.location.reload()}
            >
              <FormattedMessage id="SeleccionarEmpresa" />
            </Button>
          </Link>
          <Link to="/dashboard/enrollment">
            <Button className="btn__dashboard-btn">
              <FormattedMessage id="Enrolar" />
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default ButtonsDashboard;
