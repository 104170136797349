import { Image } from "semantic-ui-react";
import goBack from "../../assets/icons/icono_reset-grey_dark.png";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const DashboardUbication = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-ubication-vl">
      <div>
        <p>{children}</p>
      </div>

      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => navigate(-1)}
      >
        <Image src={goBack} style={{ marginTop: "5px" }} />
        <h5 style={{ margin: 0, marginLeft: "15px" }}>
          <FormattedMessage id="VolverAtras" />
        </h5>
      </div>
    </div>
  );
};

export default DashboardUbication;
