import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Container, Icon, Image } from "semantic-ui-react";

import {
  getDataDeposit,
  resetGetDataDeposit,
  resetGetDeposits,
} from "../../actions/deposits";
import DashboardUbication from "../../components/DashboardUbication";

import { useParams, useLocation, Link } from "react-router-dom";
import DividerGrey from "../../components/DividerGrey";
import Loading from "./../../components/Loading/index";

import folder from "../../assets/icons/icono_folder-red.png";

import { getListDocuments } from "../../actions/listDocuments";
import AccordionDocuments from "./../../components/Accordion/index";

import { getObtenerUrl, resetObtenerUrl } from "./../../actions/url/index";
import Expired from "./../../components/Expired/index";
import { matricesOk, resetMatricesOk } from "../../actions/matrices";
import { FormattedMessage } from "react-intl";

const Documents = () => {
  const dispatch = useDispatch();
  const stateDeposit = useSelector((state) => state.deposit);
  const stateDocuments = useSelector((state) => state.listDocuments);
  const stateMatrices = useSelector((state) => state.matricesOk);

  const { id } = useParams();
  const location = useLocation();
  const { state } = location.state;

  useEffect(() => {
    dispatch(
      getListDocuments({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      getDataDeposit({
        id: id,
      })
    );
    dispatch(
      matricesOk({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetDataDeposit());
      dispatch(resetGetDeposits());
      dispatch(resetMatricesOk());
    };
  }, [dispatch]);

  if (stateDeposit.statusDeposit === 501) {
    return <Expired />;
  }

  return (
    <Container>
      <>
        <DashboardUbication>
          {stateDeposit.statusDeposit === 200 ? (
            `${state.dataChore.datos.razon_social}/${state.dataChore.datos.nombre}/${stateDeposit.dataDeposit.datos.nombre}`
          ) : (
            <>
              <FormattedMessage id="CargandoMinus" />
            </>
          )}
        </DashboardUbication>
        <DividerGrey />

        <div>
          {stateDeposit.loadingDeposit ? (
            <Loading />
          ) : (
            <div style={{ display: "flex", gap: "15px" }}>
              <CardDeposit
                stateDeposit={stateDeposit}
                stateDocuments={stateDocuments}
                state={state}
                id={id}
                stateMatrices={stateMatrices}
              />
              <CardDocuments state={stateDocuments} />
            </div>
          )}
        </div>
      </>
    </Container>
  );
};

function CardDeposit({
  stateDeposit,
  stateDocuments,
  id,
  state,
  stateMatrices,
}) {
  return (
    <Container style={{ width: "40%" }}>
      <Card fluid className="card-deposit">
        <Card.Content>
          <div className="card-deposit-title">
            {stateDeposit.dataDeposit.datos.nombre}
          </div>
        </Card.Content>
        <div className="card-deposit-data">
          <FormattedMessage id="DatosMayus" />
        </div>

        <Card.Content style={{ borderTop: "none" }}>
          <div className="card-deposit-container-name">
            <div style={{ display: "flex", gap: "15px" }}>
              <Link
                to={`/dashboard/deposit/${stateDeposit.dataDeposit?.datos.id}`}
                state={{ state: stateDeposit }}
                className="container-card-left-deposit-chore"
              >
                <Image
                  src={folder}
                  alt="Icono Usuario"
                  className="icon-folder"
                />
                <div className="card-chore-name-deposits">
                  <FormattedMessage id="InformacionDeposito" />
                </div>
              </Link>
            </div>
            <Icon name="angle right" />
          </div>
        </Card.Content>
        <div className="card-deposit-data">
          <FormattedMessage id="DocumentosMayus" />
        </div>
        <Card.Content
          style={{ borderTop: "none", borderRight: "2px solid #fe6565" }}
        >
          <div className="card-deposit-container-name">
            <div className="container-card-left-deposit-chore">
              <Image src={folder} alt="Icono Usuario" className="icon-folder" />
              <div className="card-chore-name-deposits">
                <FormattedMessage id="DocumentosLineaBase" />
              </div>
            </div>
            <Icon name="angle right" />
          </div>
        </Card.Content>
        <Card.Content>
          {stateDocuments.status === 200 &&
            stateDocuments.data.datos.tiene_minimo_de_documentos && (
              <Link
                to={`/dashboard/documents/params/${id}/matriz1`}
                state={{ state: state }}
              >
                <div className="card-deposit-container-name">
                  <div className="container-card-left-deposit-chore">
                    <Image
                      src={folder}
                      alt="Icono Usuario"
                      className="icon-folder"
                    />
                    <div className="card-chore-name-deposits">
                      <FormattedMessage id="ParametrosLineaBase" />
                    </div>
                  </div>
                  <Icon name="angle right" style={{ color: "black" }} />
                </div>
              </Link>
            )}
        </Card.Content>
        {stateMatrices.statusOk === 200 &&
          stateMatrices.dataOk.datos.completitud_lb && (
            <>
              <Card.Content>
                <Link
                  to={`/dashboard/ef-params/tailing`}
                  state={{ state, stateDeposit: stateDeposit }}
                >
                  <div className="card-deposit-container-name">
                    <div className="container-card-left-deposit-chore">
                      <Image
                        src={folder}
                        alt="Icono Usuario"
                        className="icon-folder"
                      />
                      <div className="card-chore-name-deposits">
                        <FormattedMessage id="ParametrosEvaluacionEF" />
                      </div>
                    </div>
                    <Icon name="angle right" style={{ color: "black" }} />
                  </div>
                </Link>
              </Card.Content>

              <Card.Content>
                <Link
                  to={`/dashboard/evaluation`}
                  state={{ state, stateDeposit: stateDeposit }}
                >
                  <div className="card-deposit-container-name">
                    <div className="container-card-left-deposit-chore">
                      <Image
                        src={folder}
                        alt="Icono Usuario"
                        className="icon-folder"
                      />
                      <div className="card-chore-name-deposits">
                        <FormattedMessage id="Evaluacion" />
                      </div>
                    </div>
                    <Icon name="angle right" style={{ color: "black" }} />
                  </div>
                </Link>
              </Card.Content>
            </>
          )}
      </Card>
    </Container>
  );
}

function CardDocuments({ state }) {
  const dispatch = useDispatch();

  const stateUrl = useSelector((state) => state.url);

  // URL
  useEffect(() => {
    return () => {
      dispatch(resetObtenerUrl());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getObtenerUrl());
  }, [dispatch]);

  return (
    <Container className="main-container-documents">
      {state.loading || stateUrl.loadingUrl ? (
        <Loading />
      ) : (
        <>
          <Card fluid className="card-deposit">
            <Card.Content>
              <div className="card-deposit-info">
                <h2>
                  <FormattedMessage id="DocumentosLineaBaseRelaves" />
                </h2>
                <p>
                  <FormattedMessage id="DocumentosObligatorios" />
                </p>
              </div>

              <AccordionDocuments
                estado={state.data.ejecucion}
                state={state.data.datos.arr_proyecto_de_disenno}
                name={"proyecto_de_disenno"}
                url={stateUrl.dataUrl.datos}
              />

              <AccordionDocuments
                state={state.data.datos.arr_resolucion_sernageomin}
                name={"resolucion_sernageomin"}
                estado={state.data.ejecucion}
                url={stateUrl.dataUrl.datos}
              />

              <AccordionDocuments
                state={state.data.datos.arr_plan_cierre}
                name={"plan_cierre"}
                estado={state.data.ejecucion}
                url={stateUrl.dataUrl.datos}
              />

              <AccordionDocuments
                state={state.data.datos.arr_manual_de_operacion}
                name={"manual_de_operacion"}
                estado={state.data.ejecucion}
                url={stateUrl.dataUrl.datos}
              />

              <AccordionDocuments
                state={state.data.datos.arr_manual_de_emergencia}
                name={"manual_de_emergencia"}
                estado={state.data.ejecucion}
                url={stateUrl.dataUrl.datos}
              />

              <AccordionDocuments
                state={state.data.datos.arr_formulario_e700}
                name={"formulario_e700"}
                estado={state.data.ejecucion}
                url={stateUrl.dataUrl.datos}
              />

              <AccordionDocuments
                state={state.data.datos.arr_resolucion_calificacion_ambiental}
                name={"resolucion_calificacion_ambiental"}
                estado={state.data.ejecucion}
                url={stateUrl.dataUrl.datos}
              />

              <AccordionDocuments
                state={state.data.datos.arr_informe_tecnico_consultora}
                name={"informe_tecnico_consultora"}
                estado={state.data.ejecucion}
                url={stateUrl.dataUrl.datos}
              />

              <AccordionDocuments
                state={state.data.datos.arr_informe_tecnico_sernageomin}
                name={"informe_tecnico_sernageomin"}
                estado={state.data.ejecucion}
                url={stateUrl.dataUrl.datos}
              />
            </Card.Content>
          </Card>
        </>
      )}
    </Container>
  );
}

export default Documents;
