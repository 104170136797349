const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <a href="https://www.dataon.cl" target="_blank" rel="noreferrer">
          Powered By <b>DataOn</b>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
