import {
  RESET_OBTENER_URL,
  GET_OBTENER_URL_INIT,
  GET_OBTENER_URL_SUCCESS,
  GET_OBTENER_URL_FAILURE,
} from "./types";
import API from "./api";

// Obtener URL
export function resetObtenerUrl() {
  return {
    type: RESET_OBTENER_URL,
  };
}
function getObtenerUrlInit() {
  return {
    type: GET_OBTENER_URL_INIT,
  };
}
function getObtenerUrlSuccess(data) {
  return {
    type: GET_OBTENER_URL_SUCCESS,
    payload: data,
  };
}
function getObtenerUrlFailure(error) {
  return {
    type: GET_OBTENER_URL_FAILURE,
    payload: error,
  };
}
export function getObtenerUrl(data) {
  return async (dispatch) => {
    dispatch(getObtenerUrlInit());
    try {
      const resp = await API.data.getObtenerUrl(data);
      return dispatch(getObtenerUrlSuccess(resp));
    } catch (error) {
      return dispatch(getObtenerUrlFailure(error));
    }
  };
}
