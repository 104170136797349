import axios from "axios";

const API = {
  data: {
    async getAllRegiones(obj) {
      const response = await axios.get(`
            /api/geolocations/all-regiones/${encodeURIComponent(
              JSON.stringify(obj)
            )}`);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getAllComunas(obj) {
      const response = await axios.get(`
            /api/geolocations/all-comunas/${encodeURIComponent(
              JSON.stringify(obj)
            )}`);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getAllProvidencias(obj) {
      const response = await axios.get(`
              /api/geolocations/all-providencias/${encodeURIComponent(
                JSON.stringify(obj)
              )}`);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
