import initialState from "./initialState";
import {
  RESET_GET_TABS,
  GET_TABS_INIT,
  GET_TABS_SUCCESS,
  GET_TABS_FAILURE,
  RESET_GET_OPCION,
  GET_OPCION_INIT,
  GET_OPCION_SUCCESS,
  GET_OPCION_FAILURE,
  RESET_DELETE_REGISTER,
  DELETE_REGISTER_INIT,
  DELETE_REGISTER_SUCCESS,
  DELETE_REGISTER_FAILURE,
} from "../../actions/instruments/types";

export default function instruments(state = initialState, action) {
  switch (action.type) {
    // TABS
    case RESET_GET_TABS:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_TABS_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_TABS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_TABS_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };

    // OPCION
    case RESET_GET_OPCION:
      return {
        ...state,
        dataOpcion: {},
        loadingOpcion: true,
        statusOpcion: 0,
      };
    case GET_OPCION_INIT:
      return {
        ...state,
        loadingOpcion: true,
      };
    case GET_OPCION_SUCCESS:
      return {
        ...state,
        dataOpcion: action.payload.data,
        loadingOpcion: false,
        statusOpcion: action.payload.status,
      };
    case GET_OPCION_FAILURE:
      return {
        ...state,
        dataOpcion: {},
        loadingOpcion: false,
        statusOpcion: 501,
      };

    // DELETE
    case RESET_DELETE_REGISTER:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 0,
      };
    case DELETE_REGISTER_INIT:
      return {
        ...state,
        loadingDelete: true,
      };
    case DELETE_REGISTER_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload.data,
        loadingDelete: false,
        statusDelete: action.payload.status,
      };
    case DELETE_REGISTER_FAILURE:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 501,
      };

    default:
      return state;
  }
}
