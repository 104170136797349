import {
  RESET_GET_LIST_STATUS,
  GET_LIST_STATUS_INIT,
  GET_LIST_STATUS_SUCCESS,
  GET_LIST_STATUS_FAILURE,
} from "../../actions/status/types";
import initialState from "./initialState";

export default function status(state = initialState, action) {
  switch (action.type) {
    case RESET_GET_LIST_STATUS:
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case GET_LIST_STATUS_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_LIST_STATUS_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };

    default:
      return state;
  }
}
