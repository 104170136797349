import {
  RESET_MATRICES_OK,
  MATRICES_OK_INIT,
  MATRICES_OK_SUCCESS,
  MATRICES_OK_FAILURE,
} from "./types";
import API from "./api";

export function resetMatricesOk() {
  return {
    type: RESET_MATRICES_OK,
  };
}
function matricesOkInit() {
  return {
    type: MATRICES_OK_INIT,
  };
}
function matricesOkSuccess(data) {
  return {
    type: MATRICES_OK_SUCCESS,
    payload: data,
  };
}
function matricesOkFailure(error) {
  return {
    type: MATRICES_OK_FAILURE,
    payload: error,
  };
}
export function matricesOk(data) {
  return async (dispatch) => {
    dispatch(matricesOkInit());
    try {
      const resp = await API.data.matricesOk(data);
      dispatch(matricesOkSuccess(resp));
    } catch (error) {
      dispatch(matricesOkFailure(error));
    }
  };
}
