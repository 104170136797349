import {
  RESET_REGIONES,
  GET_REGIONES_INIT,
  GET_REGIONES_SUCCESS,
  GET_REGIONES_FAILURE,
  RESET_COMUNAS,
  GET_COMUNAS_INIT,
  GET_COMUNAS_SUCCESS,
  GET_COMUNAS_FAILURE,
  RESET_PROVIDENCIAS,
  GET_PROVIDENCIAS_INIT,
  GET_PROVIDENCIAS_SUCCESS,
  GET_PROVIDENCIAS_FAILURE,
} from "./types";
import API from "./api";

export function resetRegiones() {
  return {
    type: RESET_REGIONES,
  };
}
function getRegionesInit() {
  return {
    type: GET_REGIONES_INIT,
  };
}
function getRegionesSuccess(data) {
  return {
    type: GET_REGIONES_SUCCESS,
    payload: data,
  };
}
function getRegionesFailure(error) {
  return {
    type: GET_REGIONES_FAILURE,
    payload: error,
  };
}
export function getRegiones(data) {
  return async (dispatch) => {
    dispatch(getRegionesInit());
    try {
      const resp = await API.data.getRegiones(data);
      dispatch(getRegionesSuccess(resp));
    } catch (error) {
      dispatch(getRegionesFailure(error));
    }
  };
}

export function resetProvidencias() {
  return {
    type: RESET_PROVIDENCIAS,
  };
}
function getProvidenciasInit() {
  return {
    type: GET_PROVIDENCIAS_INIT,
  };
}
function getProvidenciasSuccess(data) {
  return {
    type: GET_PROVIDENCIAS_SUCCESS,
    payload: data,
  };
}
function getProvidenciasFailure(error) {
  return {
    type: GET_PROVIDENCIAS_FAILURE,
    payload: error,
  };
}
export function getProvidencias(data) {
  return async (dispatch) => {
    dispatch(getProvidenciasInit());
    try {
      const resp = await API.data.getProvidencias(data);
      dispatch(getProvidenciasSuccess(resp));
    } catch (error) {
      dispatch(getProvidenciasFailure(error));
    }
  };
}

export function resetComunas() {
  return {
    type: RESET_COMUNAS,
  };
}
function getComunasInit() {
  return {
    type: GET_COMUNAS_INIT,
  };
}
function getComunasSuccess(data) {
  return {
    type: GET_COMUNAS_SUCCESS,
    payload: data,
  };
}
function getComunasFailure(error) {
  return {
    type: GET_COMUNAS_FAILURE,
    payload: error,
  };
}
export function getComunas(data) {
  return async (dispatch) => {
    dispatch(getComunasInit());
    try {
      const resp = await API.data.getComunas(data);
      dispatch(getComunasSuccess(resp));
    } catch (error) {
      dispatch(getComunasFailure(error));
    }
  };
}
