import {
  RESET_GET_MATRIZ6,
  GET_MATRIZ6_INIT,
  GET_MATRIZ6_SUCCESS,
  GET_MATRIZ6_FAILURE,
  RESET_UPDATE_MATRIZ6,
  UPDATE_MATRIZ6_INIT,
  UPDATE_MATRIZ6_SUCCESS,
  UPDATE_MATRIZ6_FAILURE,
} from "./types";
import API from "./api";

// GET
export function resetGetMatriz6() {
  return {
    type: RESET_GET_MATRIZ6,
  };
}
function getMatriz6Init() {
  return {
    type: GET_MATRIZ6_INIT,
  };
}
function getMatriz6Success(data) {
  return {
    type: GET_MATRIZ6_SUCCESS,
    payload: data,
  };
}
function getMatriz6Failure(error) {
  return {
    type: GET_MATRIZ6_FAILURE,
    payload: error,
  };
}
export function getMatriz6(data) {
  return async (dispatch) => {
    dispatch(getMatriz6Init());
    try {
      const resp = await API.data.getMatriz6(data);
      dispatch(getMatriz6Success(resp));
    } catch (error) {
      dispatch(getMatriz6Failure(error));
    }
  };
}

// UPDATE
export function resetUpdateMatriz6() {
  return {
    type: RESET_UPDATE_MATRIZ6,
  };
}
function updateMatriz6Init() {
  return {
    type: UPDATE_MATRIZ6_INIT,
  };
}
function updateMatriz6Success(data) {
  return {
    type: UPDATE_MATRIZ6_SUCCESS,
    payload: data,
  };
}
function updateMatriz6Failure(error) {
  return {
    type: UPDATE_MATRIZ6_FAILURE,
    payload: error,
  };
}
export function updateMatriz6(data) {
  return async (dispatch) => {
    dispatch(updateMatriz6Init());
    try {
      const resp = await API.data.updateMatriz6(data);
      dispatch(updateMatriz6Success(resp));
    } catch (error) {
      dispatch(updateMatriz6Failure(error));
    }
  };
}
