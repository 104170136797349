import initialState from "./initialState";
import {
  RESET_ALL_REGIONES,
  GET_ALL_REGIONES_INIT,
  GET_ALL_REGIONES_SUCCESS,
  GET_ALL_REGIONES_FAILURE,
  RESET_ALL_COMUNAS,
  GET_ALL_COMUNAS_INIT,
  GET_ALL_COMUNAS_SUCCESS,
  GET_ALL_COMUNAS_FAILURE,
  RESET_ALL_PROVIDENCIAS,
  GET_ALL_PROVIDENCIAS_INIT,
  GET_ALL_PROVIDENCIAS_SUCCESS,
  GET_ALL_PROVIDENCIAS_FAILURE,
} from "../../actions/all-geolocations/types";

export default function allGeolocations(state = initialState, action) {
  switch (action.type) {
    case RESET_ALL_REGIONES:
      return {
        ...state,
        dataRegion: {},
        loadingRegion: false,
        statusRegion: 0,
      };
    case GET_ALL_REGIONES_INIT:
      return {
        ...state,
        loadingRegion: true,
      };
    case GET_ALL_REGIONES_SUCCESS:
      return {
        ...state,
        dataRegion: action.payload.data,
        loadingRegion: false,
        statusRegion: action.payload.status,
      };
    case GET_ALL_REGIONES_FAILURE:
      return {
        ...state,
        dataRegion: {},
        loadingRegion: false,
        statusRegion: 501,
      };

    case RESET_ALL_COMUNAS:
      return {
        ...state,
        dataComuna: {},
        loadingComuna: false,
        statusComuna: 0,
      };
    case GET_ALL_COMUNAS_INIT:
      return {
        ...state,
        loadingComuna: true,
      };
    case GET_ALL_COMUNAS_SUCCESS:
      return {
        ...state,
        dataComuna: action.payload.data,
        loadingComuna: false,
        statusComuna: action.payload.status,
      };

    case GET_ALL_COMUNAS_FAILURE:
      return {
        ...state,
        dataComuna: {},
        loadingComuna: false,
        statusComuna: 501,
      };

    case RESET_ALL_PROVIDENCIAS:
      return {
        ...state,
        dataProvidencia: {},
        loadingProvidencia: false,
        statusProvidencia: 0,
      };
    case GET_ALL_PROVIDENCIAS_INIT:
      return {
        ...state,
        loadingProvidencia: true,
      };
    case GET_ALL_PROVIDENCIAS_SUCCESS:
      return {
        ...state,
        dataProvidencia: action.payload.data,
        loadingProvidencia: false,
        statusProvidencia: action.payload.status,
      };
    case GET_ALL_PROVIDENCIAS_FAILURE:
      return {
        ...state,
        dataProvidencia: {},
        loadingProvidencia: false,
        statusProvidencia: 501,
      };

    default:
      return state;
  }
}
