import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import Expired from "./../../components/Expired/index";
import DividerGrey from "../../components/DividerGrey";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Image,
  Label,
  Icon,
} from "semantic-ui-react";
import ButtonsDashboard from "./../../components/ButtonsDashboard/index";

import Loading from "./../../components/Loading/index";
import {
  getDataDeposit,
  resetGetDataDeposit,
} from "./../../actions/deposits/index";

import closeBtn from "../../assets/icons/icono_close-grey.png";
import { updateDeposit } from "../../actions/update_deposit";
import { resetUpdateDeposit } from "./../../actions/update_deposit/index";
import ModalActions from "./../../components/Modal/ModalActions";

import DatePicker from "react-datepicker";
import { parse, format } from "date-fns";

import { DropdownAllRegion } from "../../components/Dropdown/DropdownAllRegion";
import { DropdownAllProvidencia } from "../../components/Dropdown/DropdownAllProvincia";
import { DropdownAllComuna } from "./../../components/Dropdown/DropdownAllComuna";
import { DropdownRecurso } from "../../components/Dropdown/DropdownRecurso";
import { DropdownStatus } from "../../components/Dropdown/DropdownEstado";
import {
  resetRegiones,
  resetProvidencias,
  resetComunas,
} from "./../../actions/geolocations/index";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const selectionRecibido = [
  {
    key: 1,
    value: 1,
    text: <FormattedMessage id="RecibidoMayus" />,
  },
  {
    key: 0,
    value: 0,
    text: <FormattedMessage id="NoRecibidoMayus" />,
  },
];

const selectionEstado = [
  {
    key: 1,
    value: 1,
    text: <FormattedMessage id="AprobadoMayus" />,
  },
  {
    key: 0,
    value: 0,
    text: <FormattedMessage id="NoAprobadoMayus" />,
  },
];

const EditDeposit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.deposit);
  const stateUpdate = useSelector((state) => state.updateDeposit);

  const location = useLocation();
  const navigate = useNavigate();

  const updateChoreTrue = true;

  const [dataForm, setDataForm] = useState("");
  const [startDate, setStartDate] = useState(null);

  const [startDateDeposito, setStartDateDeposito] = useState(null);
  const [startDateFaena, setStartDateFaena] = useState(null);

  const [checkedDistancia, setCheckedDistancia] = useState([]);
  const [checkedVida, setCheckedVida] = useState([]);

  useEffect(() => {
    return () => {
      dispatch(resetGetDataDeposit());
      dispatch(resetRegiones());
      dispatch(resetProvidencias());
      dispatch(resetComunas());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getDataDeposit({
        id: id,
      })
    );
  }, [dispatch, id]);

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  // CHECKBOX DISTANCIA
  const handleChangeCheckBox = (obj) => {
    const arr = [...checkedDistancia];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedDistancia(arr);
  };

  // CHECKBOX VIDA UTIL
  const handleChangeCheckBoxVida = (obj) => {
    const arr = [...checkedVida];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedVida(arr);
  };

  const formik = useFormik({
    initialValues: initialValues(state),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      setDataForm(formData);
    },
  });

  //   CARGAR DATA
  useEffect(() => {
    async function setInitialValues() {
      if (state.statusDeposit === 200)
        await formik.setValues({
          nombre:
            state.dataDeposit.datos.nombre !== null
              ? state.dataDeposit.datos.nombre
              : "",
          utmNorte:
            state.dataDeposit.datos.utm_norte !== null
              ? state.dataDeposit.datos.utm_norte
              : "",
          utmEste:
            state.dataDeposit.datos.utm_este !== null
              ? state.dataDeposit.datos.utm_este
              : "",

          id_recurso: state.dataDeposit.datos.id_recurso_primario,
          comuna_id: state.dataDeposit.datos.comuna_id,
          region_id: state.dataDeposit.datos.region_id,
          providencia_id: state.dataDeposit.datos.provincia_id,

          cantidadRelavesAutorizadoM3:
            state.dataDeposit.datos.cantidad_relaves_autorizado_m3 !== null
              ? state.dataDeposit.datos.cantidad_relaves_autorizado_m3
              : "",
          cantidadRelavesActualM3:
            state.dataDeposit.datos.cantidad_relaves_actual_m3 !== null
              ? state.dataDeposit.datos.cantidad_relaves_actual_m3
              : "",
          cantidadRelavesAutorizadoT:
            state.dataDeposit.datos.cantidad_relaves_autorizado_ton !== null
              ? state.dataDeposit.datos.cantidad_relaves_autorizado_ton
              : "",
          cantidadRelavesActualT:
            state.dataDeposit.datos.cantidad_relaves_actual_ton !== null
              ? state.dataDeposit.datos.cantidad_relaves_actual_ton
              : "",
          metodoConstruccion:
            state.dataDeposit.datos.metodo_construccion !== null
              ? state.dataDeposit.datos.metodo_construccion
              : "",

          cargo:
            state.dataDeposit.datos.cargo_profesional_responsable !== null
              ? state.dataDeposit.datos.cargo_profesional_responsable
              : "",
          representante:
            state.dataDeposit.datos.nombre_profesional_responsable !== null
              ? state.dataDeposit.datos.nombre_profesional_responsable
              : "",
          distanciaPeligrosa:
            state.dataDeposit.datos.distancia_peligrosa !== null
              ? state.dataDeposit.datos.distancia_peligrosa
              : "",
          vidaUtilAprobada:
            state.dataDeposit.datos.vida_util !== null
              ? state.dataDeposit.datos.vida_util
              : "",

          estado_id: state.dataDeposit.datos.estado_id,

          resolucionAprobatoria:
            state.dataDeposit.datos.res_pdc_aprueba !== null
              ? state.dataDeposit.datos.res_pdc_aprueba
              : "",
          resolucionAprobatoriaD:
            state.dataDeposit.datos.res_aprueba !== null
              ? state.dataDeposit.datos.res_aprueba
              : "",

          recibido_id: state.dataDeposit.datos.plan_de_cierre_recibido ? 1 : 0,
          cierre_estado_id: state.dataDeposit.datos.plan_de_cierre_estado
            ? 1
            : 0,
        });
    }
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.statusDeposit]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.statusDeposit === 200) {
        if (
          state.dataDeposit.datos.fecha_inicio_operacion !== null
            ? setStartDate(
                parse(
                  state.dataDeposit.datos.fecha_inicio_operacion.substr(0, 10),
                  "yyyy-MM-dd",
                  new Date()
                )
              )
            : ""
        );
        if (
          state.dataDeposit.datos.res_pdc_fecha !== null
            ? setStartDateFaena(
                parse(
                  state.dataDeposit.datos.res_pdc_fecha.substr(0, 10),
                  "yyyy-MM-dd",
                  new Date()
                )
              )
            : ""
        );

        if (
          state.dataDeposit.datos.res_aprueba_fecha !== null
            ? setStartDateDeposito(
                parse(
                  state.dataDeposit.datos.res_aprueba_fecha.substr(0, 10),
                  "yyyy-MM-dd",
                  new Date()
                )
              )
            : ""
        );
      }
    };
    callsAPI();
  }, [state.dataDeposit, state.statusDeposit]);

  const handleUpdate = () => {
    dispatch(
      updateDeposit({
        nombre: dataForm.nombre,
        comuna_id: dataForm.comuna_id,
        utm_norte: dataForm.utmNorte,
        utm_este: dataForm.utmEste,
        id_recurso_primario: dataForm.id_recurso,

        cantidad_relaves_autorizado_m3: dataForm.cantidadRelavesAutorizadoM3,
        cantidad_relaves_actual_m3: dataForm.cantidadRelavesActualM3,
        cantidad_relaves_autorizado_ton: dataForm.cantidadRelavesAutorizadoT,
        cantidad_relaves_actual_ton: dataForm.cantidadRelavesActualT,
        metodo_construccion: dataForm.metodoConstruccion,

        nombre_profesional_responsable: dataForm.representante,
        cargo_profesional_responsable: dataForm.cargo,

        distancia_peligrosa: dataForm.distanciaPeligrosa,
        vida_util: dataForm.vidaUtilAprobada,
        estado_id: dataForm.estado_id,

        res_pdc_aprueba: dataForm.resolucionAprobatoria,
        res_aprueba: dataForm.resolucionAprobatoriaD,
        res_pdc_fecha: format(startDateFaena, "dd-MM-yyyy"),
        res_aprueba_fecha: format(startDateDeposito, "dd-MM-yyyy"),
        plan_de_cierre_recibido: dataForm.recibido_id,
        plan_de_cierre_estado: dataForm.cierre_estado_id,

        distancia_peligrosa_documentos: checkedDistancia,
        vida_util_documentos: checkedVida,

        deposito_id: id,

        fecha_inicio_operacion: format(startDate, "dd-MM-yyyy"),
      })
    );
  };

  useEffect(() => {
    const arrDistancia = [...checkedDistancia];
    const arrVida = [...checkedVida];

    if (state.statusDeposit === 200) {
      if (state.dataDeposit.ejecucion.estado) {
        state.dataDeposit.datos.distancia_peligrosa_documentos.map(
          (e) => e.seleccionado === true && arrDistancia.push(e.clave)
        );
        setCheckedDistancia(arrDistancia);

        state.dataDeposit.datos.vida_util_documentos.map(
          (e) => e.seleccionado === true && arrVida.push(e.clave)
        );
        setCheckedVida(arrVida);
      }
    }
    // eslint-disable-next-line
  }, [state.statusDeposit]);

  const resetData = () => {
    dispatch(resetUpdateDeposit());
  };

  const intl = useIntl();
  const placeholderNombreDeposito = intl.formatMessage({
    id: "EscribaNombreDeposito",
  });
  const placeholderIndique = intl.formatMessage({ id: "Indique" });
  const placeholderCantidadRelavesM3Autorizado = intl.formatMessage({
    id: "CantidadRelavesAutorizadoM3Minus",
  });
  const placeholderCantidadRelavesM3Actual = intl.formatMessage({
    id: "CantidadRelavesActualM3Minus",
  });
  const placeholderCantidadRelavesAutorizadoT = intl.formatMessage({
    id: "CantidadRelavesAutorizadoTMinus",
  });
  const placeholderCantidadRelavesActualT = intl.formatMessage({
    id: "CantidadRelavesActualTMinus",
  });
  const placeholderMetodoConstruccion = intl.formatMessage({
    id: "MetodoConstruccionMinus",
  });
  const placeholderResolucionAprobatoria = intl.formatMessage({
    id: "ResolucionAprobatoriaFaenaMinus",
  });
  const placeholderResolucionAprobatoriaDeposito = intl.formatMessage({
    id: "ResolucionAprobatoriaDepositoMinus",
  });

  const placeholderRecibido = intl.formatMessage({ id: "RecibidoMayus" });
  const placeholderEstado = intl.formatMessage({ id: "EstadoMayus" });
  const placeholderNombreProfesional = intl.formatMessage({
    id: "NombreProfesionalResponsableMinus",
  });
  const placeholderCargo = intl.formatMessage({
    id: "EscribaCargoProfesionalResponsable",
  });
  const placeholderDistanciaPeligrosa = intl.formatMessage({
    id: "DistanciaPeligrosaMinus",
  });
  const placeholderDuracionDeposito = intl.formatMessage({
    id: "DuracionDepositoMinus",
  });

  if (
    /* When the token is expired*/
    state.statusDeposit === 501
  ) {
    return <Expired />;
  }

  return (
    <Container>
      <DividerGrey />
      <div style={{ display: "flex" }}>
        <ButtonsDashboard id={id} />

        {location.pathname === `/dashboard/edit-deposit/${id}` &&
          (state.loadingDeposit && state.statusDeposit === 0 ? (
            <Loading />
          ) : (
            <Container className="card-faena">
              <Form onSubmit={formik.handleSubmit}>
                <div className="container-card-add-faena">
                  <h3 className="card-faena-title">
                    <FormattedMessage id="EditarDeposito" />
                  </h3>
                  <Image
                    src={closeBtn}
                    alt="Cerrar"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  />
                </div>

                <Form.Input
                  name="nombre"
                  label={<FormattedMessage id="NombreMayus" />}
                  type="text"
                  placeholder={placeholderNombreDeposito}
                  onChange={formik.handleChange}
                  error={formik.errors.nombre}
                  value={formik.values.nombre}
                />

                <Form.Group widths="equal">
                  <DropdownAllRegion
                    formik={formik}
                    dataChore={state.dataDeposit.datos.region_id}
                    updateChore={updateChoreTrue}
                  />

                  <DropdownAllProvidencia
                    formik={formik}
                    region_id={formik.values.region_id}
                    dataChore={state.dataDeposit.datos.provincia_id}
                    updateChore={updateChoreTrue}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <DropdownAllComuna
                    formik={formik}
                    providencia_id={formik.values.providencia_id}
                    dataChore={state.dataDeposit.datos.comuna_id}
                    updateChore={updateChoreTrue}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="utmNorte"
                    label={<FormattedMessage id="UTMNorteMayus" />}
                    type="text"
                    placeholder={placeholderIndique}
                    onChange={formik.handleChange}
                    error={formik.errors.utmNorte}
                    onKeyPress={(event) => {
                      if (!/[0-9,-]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={formik.values.utmNorte}
                  />
                  <Form.Input
                    name="utmEste"
                    label={<FormattedMessage id="UTMEsteMayus" />}
                    type="text"
                    placeholder={placeholderIndique}
                    onChange={formik.handleChange}
                    error={formik.errors.utmEste}
                    onKeyPress={(event) => {
                      if (!/[0-9,-]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={formik.values.utmEste}
                  />
                </Form.Group>

                <DropdownRecurso
                  formik={formik}
                  dataUpdate={state.dataDeposit.datos.id_recurso_primario}
                  update={"true"}
                />

                <Form.Group widths="equal">
                  <Form.Input
                    name="cantidadRelavesAutorizadoM3"
                    label={
                      <FormattedMessage id="CantidadRelavesAutorizadoM3Mayus" />
                    }
                    type="number"
                    step=".01"
                    placeholder={placeholderCantidadRelavesM3Autorizado}
                    onChange={formik.handleChange}
                    error={formik.errors.cantidadRelavesAutorizadoM3}
                    value={formik.values.cantidadRelavesAutorizadoM3}
                  />

                  <Form.Input
                    name="cantidadRelavesActualM3"
                    label={
                      <FormattedMessage id="CantidadRelavesActualM3Mayus" />
                    }
                    type="number"
                    step=".01"
                    placeholder={placeholderCantidadRelavesM3Actual}
                    onChange={formik.handleChange}
                    error={formik.errors.cantidadRelavesActualM3}
                    value={formik.values.cantidadRelavesActualM3}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="cantidadRelavesAutorizadoT"
                    label={
                      <FormattedMessage id="CantidadRelavesAutorizadoTMayus" />
                    }
                    type="number"
                    step=".01"
                    placeholder={placeholderCantidadRelavesAutorizadoT}
                    onChange={formik.handleChange}
                    error={formik.errors.cantidadRelavesAutorizadoT}
                    value={formik.values.cantidadRelavesAutorizadoT}
                  />

                  <Form.Input
                    name="cantidadRelavesActualT"
                    label={
                      <FormattedMessage id="CantidadRelavesActualTMayus" />
                    }
                    type="number"
                    step=".01"
                    placeholder={placeholderCantidadRelavesActualT}
                    onChange={formik.handleChange}
                    error={formik.errors.cantidadRelavesActualT}
                    value={formik.values.cantidadRelavesActualT}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="metodoConstruccion"
                    label={<FormattedMessage id="MetodoConstruccionMayus" />}
                    type="text"
                    placeholder={placeholderMetodoConstruccion}
                    onChange={formik.handleChange}
                    error={formik.errors.metodoConstruccion}
                    value={formik.values.metodoConstruccion}
                  />

                  <DropdownStatus formik={formik} />
                </Form.Group>

                <Form.Group
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Form.Input
                    name="resolucionAprobatoria"
                    label={
                      <FormattedMessage id="ResolucionAprobatoriaFaenaMayus" />
                    }
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder={placeholderResolucionAprobatoria}
                    onChange={formik.handleChange}
                    error={formik.errors.resolucionAprobatoria}
                    value={formik.values.resolucionAprobatoria}
                  />

                  <Label
                    style={{
                      width: "100%",
                      background: "transparent",
                      fontSize: ".92857143em",
                      fontWeight: "700",
                      color: startDateFaena === null ? "#9f3a38" : "#8a8a8a",
                    }}
                  >
                    <FormattedMessage id="FechaResolucionAprobatoriaFaenaMayus" />
                  </Label>
                  <DatePicker
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    selected={startDateFaena}
                    onChange={(date) => setStartDateFaena(date)}
                  />
                </Form.Group>

                <Form.Group
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Form.Input
                    name="resolucionAprobatoriaD"
                    label={
                      <FormattedMessage id="ResolucionAprobatoriaDepositoMayus" />
                    }
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder={placeholderResolucionAprobatoriaDeposito}
                    onChange={formik.handleChange}
                    error={formik.errors.resolucionAprobatoriaD}
                    value={formik.values.resolucionAprobatoriaD}
                  />

                  <Label
                    style={{
                      width: "100%",
                      background: "transparent",
                      fontSize: ".92857143em",
                      fontWeight: "700",
                      color: startDateDeposito === null ? "#9f3a38" : "#8a8a8a",
                    }}
                  >
                    <FormattedMessage id="FechaResolucionAprobatoriaDepositoMayus" />
                  </Label>
                  <DatePicker
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    selected={startDateDeposito}
                    onChange={(date) => setStartDateDeposito(date)}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Dropdown
                    placeholder={placeholderRecibido}
                    label={<FormattedMessage id="PlanCierreRecibidoMayus" />}
                    selection
                    options={selectionRecibido}
                    value={formik.values.recibido_id}
                    onChange={(_, data) =>
                      formik.setFieldValue("recibido_id", data.value)
                    }
                  />

                  {formik.values.recibido_id !== 0 && (
                    <Form.Dropdown
                      placeholder={placeholderEstado}
                      label={<FormattedMessage id="PlanCierreEstadoMayus" />}
                      selection
                      options={selectionEstado}
                      value={formik.values.cierre_estado_id}
                      onChange={(_, data) =>
                        formik.setFieldValue("cierre_estado_id", data.value)
                      }
                    />
                  )}
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="representante"
                    label={
                      <FormattedMessage id="NombreProfesionalResponsableMayus" />
                    }
                    type="text"
                    placeholder={placeholderNombreProfesional}
                    onChange={formik.handleChange}
                    error={formik.errors.representante}
                    value={formik.values.representante}
                  />

                  <Form.Input
                    name="cargo"
                    label={
                      <FormattedMessage id="CargoProfesionalResponsableMayus" />
                    }
                    type="text"
                    placeholder={placeholderCargo}
                    onChange={formik.handleChange}
                    error={formik.errors.cargo}
                    value={formik.values.cargo}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="distanciaPeligrosa"
                    label={<FormattedMessage id="DistanciaPeligrosaMayus" />}
                    type="number"
                    step=".01"
                    placeholder={placeholderDistanciaPeligrosa}
                    onChange={formik.handleChange}
                    error={formik.errors.distanciaPeligrosa}
                    value={formik.values.distanciaPeligrosa}
                  />
                </Form.Group>

                <Form.Group
                  widths="equal"
                  style={{ marginLeft: "15px", flexDirection: "column" }}
                >
                  {state.dataDeposit.datos.distancia_peligrosa_documentos.map(
                    (item, i) => {
                      let index = checkedDistancia.includes(item.clave);
                      return (
                        <Checkbox
                          key={i}
                          label={item.label}
                          onChange={() => handleChangeCheckBox(item)}
                          value={item.clave}
                          checked={index}
                          name="distanciaPeligrosaDocumentos"
                          // disabled={!item.editable ? true : false}
                        />
                      );
                    }
                  )}
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Input
                    name="vidaUtilAprobada"
                    label={<FormattedMessage id="DuracionDepositoMayus" />}
                    type="number"
                    step=".01"
                    placeholder={placeholderDuracionDeposito}
                    onChange={formik.handleChange}
                    error={formik.errors.vidaUtilAprobada}
                    value={formik.values.vidaUtilAprobada}
                  />
                </Form.Group>

                <Form.Group
                  widths="equal"
                  style={{ marginLeft: "15px", flexDirection: "column" }}
                >
                  {state.dataDeposit.datos.vida_util_documentos.map(
                    (item, i) => {
                      let index = checkedVida.includes(item.clave);
                      return (
                        <Checkbox
                          key={i}
                          label={item.label}
                          onChange={() => handleChangeCheckBoxVida(item)}
                          value={item.clave}
                          checked={index}
                          name="vidaUtilDocumentos"
                        />
                      );
                    }
                  )}
                </Form.Group>

                <Form.Group
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Label
                    style={{
                      width: "100%",
                      background: "transparent",
                      fontSize: ".92857143em",
                      fontWeight: "700",
                      color: startDate === null ? "#9f3a38" : "#8a8a8a",
                    }}
                  >
                    <FormattedMessage id="FechaInicioOperacionMayus" />
                  </Label>
                  <DatePicker
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </Form.Group>

                <div>
                  <Button
                    type="submit"
                    onClick={onShowModal}
                    disabled={
                      !(
                        formik.isValid &&
                        formik.dirty &&
                        startDate &&
                        startDateFaena &&
                        startDateDeposito
                      )
                    }
                  >
                    <FormattedMessage id="ActualizarMayus" />
                  </Button>
                </div>

                <ModalActions
                  title={<FormattedMessage id="ActualizarDeposito" />}
                  show={showModal}
                  setShow={setShowModal}
                  status={stateUpdate.statusUpdate}
                  action={handleUpdate}
                  reset={resetData}
                  estado={stateUpdate.dataUpdate}
                >
                  {stateUpdate.statusUpdate === 0 ? (
                    <FormattedMessage id="EstaSeguroActualizarDeposito" />
                  ) : stateUpdate.dataUpdate.ejecucion.estado ? (
                    <div className="container-modal">
                      <p className="message-ok">
                        {stateUpdate.dataUpdate.ejecucion.mensaje}
                      </p>
                      <Icon name="checkmark" className="status-ok" />
                    </div>
                  ) : (
                    <div className="container-modal">
                      <p className="message-fail">
                        {stateUpdate.dataUpdate.ejecucion.mensaje}
                      </p>
                      <Image src={ohError} className="status-fail" />
                    </div>
                  )}
                </ModalActions>
              </Form>
            </Container>
          ))}
      </div>
    </Container>
  );
};

function initialValues() {
  return {
    nombre: "",
    comuna_id: "",
    utmNorte: "",
    utmEste: "",
    cantidadRelavesAutorizadoM3: "",
    cantidadRelavesActualM3: "",
    cantidadRelavesAutorizadoT: "",
    cantidadRelavesActualT: "",
    estado_id: "",
    resolucionAprobatoria: "",
    resolucionAprobatoriaD: "",
    cargo: "",
    distanciaPeligrosa: "",
    vidaUtilAprobada: "",
    representante: "",

    recurso_id: "",
    metodoConstruccion: "",
    recibido_id: "",
    cierre_estado_id: "",
  };
}

function validationSchema() {
  return {
    nombre: Yup.string().required(true),
    cantidadRelavesAutorizadoM3: Yup.number().required(true),
    cantidadRelavesActualM3: Yup.number().required(true),
    cantidadRelavesAutorizadoT: Yup.number().required(true),
    cantidadRelavesActualT: Yup.number().required(true),
    cargo: Yup.string().required(true),
    distanciaPeligrosa: Yup.number().required(true),
    vidaUtilAprobada: Yup.number().required(true),
    utmNorte: Yup.string().required(true),
    utmEste: Yup.string().required(true),
    metodoConstruccion: Yup.string().required(true),
    resolucionAprobatoria: Yup.string().required(true),
    resolucionAprobatoriaD: Yup.string().required(true),
    representante: Yup.string().required(true),
  };
}

export default EditDeposit;
