import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Dropdown, Icon, Image, Table } from "semantic-ui-react";

import { getOpcion, resetOpcion } from "../../../../actions/instruments/index";
import AddRegistro from "./AddRegistro";
import Grilla from "./Grilla";
import Comentario from "./Comentario";
import {
  updateCheckbox,
  updateDropdown,
  resetUpdateDropdown,
  saveDropdown,
  saveDataSelected,
} from "../../../../actions/update_comentario";
import ModalDelete from "./../../../Modal/ModalDelete";
import ohError from "../../../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const Selector = ({ stateInstruments, clave }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.instruments);

  const selected = stateInstruments.data.datos.tabs.findIndex(
    (o) => o.clave_instrumento === clave
  );

  const stateUpdate = useSelector((state) => state.updateComentario);

  let minero = localStorage.getItem("minero");

  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("");
  const [objDataSelected, setObjDataSelected] = useState(
    stateUpdate.arrDataSelected[selected]
  );

  const [dataDropdown, setDataDropdown] = useState([]);
  const [dataDropdownId, setDataDropdownId] = useState(
    stateUpdate?.arrDropdown[selected]
  );

  const [checkedDoc, setCheckedDoc] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const reloadOption = () => {
    if (dataId !== "") {
      dispatch(
        getOpcion({
          sistema: {
            modelo:
              stateInstruments.data.datos.tabs[selected].instrumentos[dataId]
                .sistema.modelo,
            encabezado_reg_id:
              stateInstruments.data.datos.tabs[selected].instrumentos[dataId]
                .sistema.encabezado_reg_id,
            clave_tipo_deposito:
              stateInstruments.data.datos.tabs[selected].instrumentos[dataId]
                .sistema.clave_tipo_deposito,
            clave_tipo_instrumento:
              stateInstruments.data.datos.tabs[selected].instrumentos[dataId]
                .sistema.clave_tipo_instrumento,
          },
        })
      );
    }
  };

  useEffect(() => {
    reloadOption();
    // eslint-disable-next-line
  }, [dataId]);

  useEffect(() => {
    return () => {
      dispatch(resetOpcion());
    };
  }, [dispatch]);

  // Se llena data en el primer dropdown de instrumentos
  useEffect(() => {
    const arr = [];
    if (stateInstruments.status === 200) {
      if (stateInstruments.data.datos.tabs.length > 0) {
        stateInstruments.data.datos.tabs[selected].instrumentos.forEach(
          (e, i) => {
            arr.push({
              key: i,
              value: i,
              text: e.titulo,
              data: e,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setData(arr);
  }, [selected, stateInstruments]);

  // Dropdown de ingresar data o sin antecedentes
  useEffect(() => {
    const arr = [];
    const arrCheckBox = [];
    if (stateInstruments.status === 200) {
      if (stateInstruments.data.datos.tabs.length > 0) {
        if (data[dataId]) {
          if (data[dataId].data) {
            data[dataId].data.documentos_que_respaldan.forEach((e) => {
              if (e.seleccionado === true) {
                arrCheckBox.push(e.clave);
              }
            });

            data[dataId].data.dropdown.forEach((e) => {
              arr.push({
                key: e.id,
                value: e.id,
                text: e.glosa,
                seleccionado: e.seleccionado.toString(),
              });
            });
          }
        }
      }
    }
    setCheckedDoc(arrCheckBox);

    let count = arr.filter((o) => o.seleccionado === "true");

    if (count.length === 0) {
      const newArr = [
        {
          key: -1,
          value: "",
          text: "Seleccione",
          seleccionado: "true",
        },
      ];

      arr.forEach((e, i) => {
        newArr.push({
          ...e,
          key: i,
        });
      });

      setDataDropdown(newArr);
    } else {
      setDataDropdown(arr);
    }
  }, [data, dataId, stateInstruments]);

  // Checkbox
  const handleChangeCheckbox = (obj) => {
    const arr = [...checkedDoc];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedDoc(arr);

    dispatch(
      updateCheckbox({
        sistema: {
          modelo: data[dataId].data.sistema.modelo,
          encabezado_reg_id: data[dataId].data.sistema.encabezado_reg_id,
          clave_tipo_deposito: data[dataId].data.sistema.clave_tipo_deposito,
          clave_tipo_instrumento:
            data[dataId].data.sistema.clave_tipo_instrumento,
        },
        documentos_que_respaldan: arr,
      })
    );
  };

  const [valueDrop, setValueDrop] = useState("");

  const handleDropdownChange = (_, { value }) => {
    setDataDropdownId(value);
    setValueDrop(value);

    const selectedOption = dataDropdown.find(
      (option) => option.value === value
    );

    setObjDataSelected(selectedOption);
    dispatch(saveDataSelected(selectedOption));

    const arr = [];

    data[dataId].data.dropdown.forEach((e) => {
      arr.push({
        key: e.id,
        value: e.id,
        text: e.glosa,
        seleccionado: e.seleccionado.toString(),
      });
    });

    dispatch(saveDropdown(selected, value, dataId));

    if (value !== 1) {
      dispatch(resetUpdateDropdown());
      setShowModal(true);
    } else if (value === 1) {
      dispatch(
        updateDropdown({
          sistema: {
            modelo: data[dataId].data.sistema.modelo,
            encabezado_reg_id: data[dataId].data.sistema.encabezado_reg_id,
            clave_tipo_deposito: data[dataId].data.sistema.clave_tipo_deposito,
            clave_tipo_instrumento:
              data[dataId].data.sistema.clave_tipo_instrumento,
          },
          opcion_informacion_id: value,
        })
      );
    }
  };

  const resetData = () => {
    dispatch(resetUpdateDropdown());
  };

  const closeModal = () => {
    dispatch(resetUpdateDropdown());
  };

  const handleChangeOption = () => {
    setDataDropdownId(valueDrop);

    dispatch(
      updateDropdown({
        sistema: {
          modelo: data[dataId].data.sistema.modelo,
          encabezado_reg_id: data[dataId].data.sistema.encabezado_reg_id,
          clave_tipo_deposito: data[dataId].data.sistema.clave_tipo_deposito,
          clave_tipo_instrumento:
            data[dataId].data.sistema.clave_tipo_instrumento,
        },
        opcion_informacion_id: valueDrop,
      })
    );
  };

  useEffect(
    () => {
      if (dataDropdown.length > 0) {
        const dataSelectedDropdown = dataDropdown.find(
          (element) => element.seleccionado === "true"
        );

        if (stateUpdate.arrDropdown[selected][`pozo-${dataId}`]) {
          setDataDropdownId(
            stateUpdate.arrDropdown[selected][`pozo-${dataId}`]
          );
          setObjDataSelected(stateUpdate.arrDataSelected[selected]);
        } else {
          setDataDropdownId(dataSelectedDropdown.value);
          setObjDataSelected(dataSelectedDropdown);
        }
      }
    },
    // eslint-disable-next-line
    [
      stateUpdate.arrDropdown,
      selected,
      dataDropdown,
      stateUpdate.arrDataSelected,
    ]
  );

  useEffect(() => {
    if (stateInstruments.status === 200) {
      if (stateInstruments.data.ejecucion.estado) {
        if (data.length > 0) {
          let dataSelected =
            dataId !== ""
              ? data.find(
                  (element) => element.value.toString() === dataId.toString()
                )
              : data.find((element) => element.seleccionado === "true");

          dispatch(saveDataSelected(objDataSelected));

          if (dataSelected !== undefined) {
            setDataId(dataSelected.value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const intl = useIntl();

  const placeholderSeleccione = intl.formatMessage({ id: "Seleccione" });

  return (
    <>
      {data.length > 0 && (
        <>
          <Dropdown
            placeholder={placeholderSeleccione}
            selection
            options={data}
            value={dataId}
            onChange={(_, { value }) => {
              setDataId(value);
              reloadOption();
            }}
            style={{ width: "100%", marginBottom: "10px " }}
          />

          {dataId !== "" && data[dataId].data && (
            <div>
              <Table>
                <Table.Header style={{ textAlign: "center" }}>
                  <Table.Row>
                    {Object.keys(data[dataId].data.generales.linea1).map(
                      // eslint-disable-next-line array-callback-return
                      (item, i) => {
                        if (item.includes("col")) {
                          return (
                            <Table.HeaderCell
                              colSpan={`${
                                115 *
                                data[dataId].data.generales.linea1[item].ancho
                              }`}
                              key={i}
                            >
                              {data[dataId].data.generales.linea1[item].label}
                            </Table.HeaderCell>
                          );
                        }
                      }
                    )}
                  </Table.Row>
                  <Table.Row>
                    {Object.keys(data[dataId].data.generales.linea2).map(
                      // eslint-disable-next-line array-callback-return
                      (item, i) => {
                        if (item.includes("col")) {
                          return (
                            <Table.HeaderCell
                              colSpan={`${
                                115 *
                                data[dataId].data.generales.linea2[item].ancho
                              }`}
                              key={i}
                            >
                              {data[dataId].data.generales.linea2[item].label}
                            </Table.HeaderCell>
                          );
                        }
                      }
                    )}
                  </Table.Row>
                </Table.Header>

                <Table.Body style={{ textAlign: "center" }}>
                  <Table.Row>
                    {Object.keys(data[dataId].data.generales.linea3).map(
                      // eslint-disable-next-line array-callback-return
                      (item, i) => {
                        if (item.includes("col")) {
                          return (
                            <Table.Cell
                              colSpan={`${
                                115 *
                                data[dataId].data.generales.linea3[item].ancho
                              }`}
                              key={i}
                            >
                              {data[dataId].data.generales.linea3[item].label}
                            </Table.Cell>
                          );
                        }
                      }
                    )}
                  </Table.Row>
                </Table.Body>
              </Table>

              <Dropdown
                placeholder={placeholderSeleccione}
                selection
                options={dataDropdown}
                onChange={handleDropdownChange}
                value={dataDropdownId}
                style={{ width: "100%", marginBottom: "10px " }}
                disabled={minero === "false" ? true : false}
              />

              {dataDropdownId === 1 && (
                <>
                  <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                    <FormattedMessage id="DocumentosRespaldan" />
                  </p>
                  {data[dataId].data.documentos_que_respaldan.map((item, i) => {
                    let index = checkedDoc.includes(item.clave);
                    return (
                      <Checkbox
                        key={i}
                        label={item.label}
                        onChange={() => {
                          handleChangeCheckbox(item);
                        }}
                        value={item.clave}
                        checked={index}
                        disabled={
                          minero === "false"
                            ? true
                            : !item.editable
                            ? true
                            : false
                        }
                      />
                    );
                  })}
                  <div>
                    <p className="instruments__title">
                      <FormattedMessage id="DatosRegistrados" />
                    </p>
                    <Grilla
                      state={state}
                      data={data[dataId]}
                      reloadOption={reloadOption}
                    />

                    {minero === "true" && (
                      <>
                        <p className="instruments__title">
                          <FormattedMessage id="NuevoRegistro" />
                        </p>
                        <AddRegistro
                          data={data[dataId]}
                          reloadOption={reloadOption}
                        />
                      </>
                    )}

                    <p className="instruments__title">
                      <FormattedMessage id="Comentario" />
                    </p>
                    <Comentario
                      state={state}
                      data={data[dataId]}
                      selected={selected}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
      <ModalDelete
        title={<FormattedMessage id="CambiarRegistro" />}
        show={showModal}
        setShow={setShowModal}
        action={handleChangeOption}
        reset={resetData}
        state={stateUpdate}
        status={stateUpdate.statusUpdateDropdown}
        estado={stateUpdate.dataUpdateDropdown}
        loading={stateUpdate.loadUpdateDropdown}
        close={closeModal}
      >
        {stateUpdate.statusUpdateDropdown === 0 ? (
          <FormattedMessage id="CambiarRegistroText" />
        ) : stateUpdate.dataUpdateDropdown.ejecucion.estado ? (
          <div className="container-modal">
            <p className="message-ok">
              {stateUpdate.dataUpdateDropdown.ejecucion.mensaje}
            </p>
            <Icon name="checkmark" className="status-ok" />
          </div>
        ) : (
          <div className="container-modal">
            <p className="message-fail">
              {stateUpdate.dataUpdateDropdown.ejecucion.mensaje}
            </p>
            <Image src={ohError} className="status-fail" />
          </div>
        )}
      </ModalDelete>
    </>
  );
};

export default Selector;
