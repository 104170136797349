import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "../src/assets/scss/index.scss";
import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.css";

import { Provider } from "react-redux";
import { store } from "./store/index";
import { App } from "./App";
import { IntlProvider } from "react-intl";

import HomePage from "./pages/home";
import LoginPage from "./pages/login/index";
import Dashboard from "./pages/dashboard";
import Company from "./pages/company";
import RecoverPassword from "./pages/recover/index";
import EditChore from "./pages/updateChore/index";
import Chore from "./pages/chore";
import Deposits from "./pages/deposits";
import EditDeposit from "./pages/updateDeposit/index";
import Documents from "./pages/documents";
import ParamsLine from "./pages/params/index";
import { Matrix1 } from "./components/Matrices/Matrix1";
import { Matrix2 } from "./components/Matrices/Matrix2";
import NewMatrix3 from "./components/Matrices/Matrices3/index";
import { Matrix4 } from "./components/Matrices/Matrix4";
import { Matrix6 } from "./components/Matrices/Matrix6";
import { Matrix7 } from "./components/Matrices/Matrix7";
import { Matrix8 } from "./components/Matrices/Matrix8";
import AllChores from "./pages/allChores/index";
import AllDeposits from "./pages/allDeposits/index";
import EfParams from "./pages/EfParams/index";
import Tailing from "./components/Params/Tailing";
import Geometrical from "./components/Params/Geometrical";
import Geotechnical from "./components/Params/Geotechnical";
import Instruments from "./components/Params/Instruments";
import Pucv from "./pages/pucv";
import Enrollment from "./pages/enrollment";
import Evaluacion from "./pages/evaluacion";
import Matriz5 from "./components/Matrices/Matriz5";
import EditCompany from "./pages/updateCompany";

import messages_es from "./translate/es";
import messages_en from "./translate/en";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "recover-password",
        element: <RecoverPassword />,
      },

      {
        path: "dashboard",
        element: <Dashboard />,
        children: [
          {
            path: "pucv",
            element: <Pucv />,
          },
          {
            path: "enrollment",
            element: <Enrollment />,
          },
          {
            path: "evaluation",
            element: <Evaluacion />,
          },
          {
            path: "company",
            element: <Company />,
          },
          {
            path: "add-chore",
            element: <Company />,
          },
          {
            path: "list-chores",
            element: <AllChores />,
          },
          {
            path: "list-deposits",
            element: <AllDeposits />,
          },
          {
            path: "edit-chore/:id",
            element: <EditChore />,
          },
          {
            path: "chore/:id",
            element: <Chore />,
          },
          {
            path: "add-deposit",
            element: <Chore />,
          },
          {
            path: "deposit/:id",
            element: <Deposits />,
          },
          {
            path: "edit-deposit/:id",
            element: <EditDeposit />,
          },
          {
            path: "documents/:id",
            element: <Documents />,
          },
          {
            path: "edit-company/:id",
            element: <EditCompany />,
          },
          {
            path: "ef-params",
            element: <EfParams />,
            children: [
              {
                path: "tailing",
                element: <Tailing />,
              },
              {
                path: "geometrical",
                element: <Geometrical />,
                children: [
                  {
                    path: "wall",
                    element: <Geometrical />,
                  },
                  {
                    path: "crown",
                    element: <Geometrical />,
                  },
                  {
                    path: "operational",
                    element: <Geometrical />,
                  },
                  {
                    path: "angle",
                    element: <Geometrical />,
                  },
                  {
                    path: "p1-wall",
                    element: <Geometrical />,
                  },
                  {
                    path: "p1-crown",
                    element: <Geometrical />,
                  },
                  {
                    path: "p1-operational",
                    element: <Geometrical />,
                  },
                  {
                    path: "p1-angle",
                    element: <Geometrical />,
                  },
                  {
                    path: "p2-depot",
                    element: <Geometrical />,
                  },
                  {
                    path: "p2-angle",
                    element: <Geometrical />,
                  },
                  {
                    path: "f-wall",
                    element: <Geometrical />,
                  },
                  {
                    path: "f-crown",
                    element: <Geometrical />,
                  },
                  {
                    path: "f-operational",
                    element: <Geometrical />,
                  },
                  {
                    path: "f-angle",
                    element: <Geometrical />,
                  },
                  {
                    path: "gb-wall",
                    element: <Geometrical />,
                  },
                  {
                    path: "gb-angle",
                    element: <Geometrical />,
                  },
                  {
                    path: "gb-b",
                    element: <Geometrical />,
                  },
                  {
                    path: "gl-wall",
                    element: <Geometrical />,
                  },
                  {
                    path: "gl-berm",
                    element: <Geometrical />,
                  },
                  {
                    path: "gl-angle",
                    element: <Geometrical />,
                  },
                ],
              },
              {
                path: "geotechnical",
                element: <Geotechnical />,
                children: [
                  {
                    path: "wall",
                    element: <Geotechnical />,
                  },
                  {
                    path: "gran",
                    element: <Geotechnical />,
                  },
                  {
                    path: "specific",
                    element: <Geotechnical />,
                  },
                  {
                    path: "solid",
                    element: <Geotechnical />,
                  },
                  {
                    path: "gran",
                    element: <Geotechnical />,
                  },
                  {
                    path: "atterbergs",
                    element: <Geotechnical />,
                  },
                  {
                    path: "densities",
                    element: <Geotechnical />,
                  },
                  {
                    path: "tension",
                    element: <Geotechnical />,
                  },
                  {
                    path: "thickness",
                    element: <Geotechnical />,
                  },
                  {
                    path: "compaction",
                    element: <Geotechnical />,
                  },
                ],
              },
              {
                path: "instruments",
                element: <Instruments />,
                children: [
                  {
                    path: "piezometro",
                    element: <Instruments />,
                  },
                  {
                    path: "pozo",
                    element: <Instruments />,
                  },
                  {
                    path: "distancia",
                    element: <Instruments />,
                  },
                  {
                    path: "drenaje_basal",
                    element: <Instruments />,
                  },
                  {
                    path: "acelerometro",
                    element: <Instruments />,
                  },
                  {
                    path: "inclinometro",
                    element: <Instruments />,
                  },
                  {
                    path: "placa_corrimiento",
                    element: <Instruments />,
                  },
                  {
                    path: "celda_asentamiento",
                    element: <Instruments />,
                  },
                ],
              },
            ],
          },

          {
            path: "documents/params/:id",
            element: <ParamsLine />,
            children: [
              {
                path: "matriz1",
                element: <Matrix1 />,
              },
              {
                path: "matriz2",
                element: <Matrix2 />,
              },
              {
                path: "matriz3",
                element: <NewMatrix3 />,
              },
              {
                path: "matriz4",
                element: <Matrix4 />,
              },
              {
                path: "matriz5",
                element: <Matriz5 />,
                children: [
                  {
                    path: "piezometro",
                    element: <Matriz5 />,
                  },
                  {
                    path: "pozo",
                    element: <Matriz5 />,
                  },
                  {
                    path: "distancia",
                    element: <Matriz5 />,
                  },
                  {
                    path: "drenaje_basal",
                    element: <Matriz5 />,
                  },
                  {
                    path: "acelerometro",
                    element: <Matriz5 />,
                  },
                  {
                    path: "inclinometro",
                    element: <Matriz5 />,
                  },
                  {
                    path: "placa_corrimiento",
                    element: <Matriz5 />,
                  },
                  {
                    path: "celda_asentamiento",
                    element: <Matriz5 />,
                  },
                ],
              },
              {
                path: "matriz6",
                element: <Matrix6 />,
              },
              {
                path: "matriz7",
                element: <Matrix7 />,
              },
              {
                path: "matriz8",
                element: <Matrix8 />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

const userLang = navigator.language;
const Lang = userLang.slice(0, 2);

const messages = {
  es: messages_es,
  en: messages_en,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider locale={Lang} messages={messages[Lang]}>
        <RouterProvider router={router} />
      </IntlProvider>
    </Provider>
  </React.StrictMode>
);
