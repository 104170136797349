import { Button, Icon, Modal } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const ModalActions = (props) => {
  const {
    children,
    title,
    show,
    setShow,
    status,
    action,
    reset,
    deleteAction,
    estado,
    loading,
    matricesOk,
  } = props;

  const navigate = useNavigate();

  const onClose = () => {
    setShow(false);
  };

  const reload = () => {
    if (status === 200) {
      window.location.reload();
      if (deleteAction !== "Delete") {
        navigate(-1);
      }
    }
  };

  return (
    <Modal className="basic-modal" open={show} onClose={onClose}>
      <Modal.Header>
        <span>{title}</span>
        <Icon
          name="close"
          onClick={() => {
            onClose();
            if (!matricesOk) {
              reload();
            }
          }}
        />
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        {status === 200 ? (
          estado.ejecucion.estado ? (
            <Button
              color="blue"
              content={<FormattedMessage id="Cerrar" />}
              onClick={() => {
                onClose();
                if (!matricesOk) {
                  reload();
                }
                reset();
              }}
            />
          ) : (
            <Button
              color="blue"
              content={<FormattedMessage id="Cerrar" />}
              onClick={() => {
                onClose();
                reset();
              }}
            />
          )
        ) : (
          <>
            <Button
              color="red"
              onClick={() => {
                onClose();
                reload();
              }}
            >
              No
            </Button>
            <Button color="blue" onClick={action} disabled={loading}>
              {loading ? (
                <FormattedMessage id="CargandoMinus" />
              ) : (
                <FormattedMessage id="Si" />
              )}
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ModalActions;
