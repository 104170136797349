import {
  RESET_ADD_CHORE,
  ADD_CHORE_INIT,
  ADD_CHORE_SUCCESS,
  ADD_CHORE_FAILURE,
} from "./types";
import API from "./api";

export function resetAddChore() {
  return {
    type: RESET_ADD_CHORE,
  };
}
function addChoreInit() {
  return {
    type: ADD_CHORE_INIT,
  };
}
function addChoreSuccess(data) {
  return {
    type: ADD_CHORE_SUCCESS,
    payload: data,
  };
}
function addChoreFailure(error) {
  return {
    type: ADD_CHORE_FAILURE,
    payload: error,
  };
}
export function addChore(data) {
  return async (dispatch) => {
    dispatch(addChoreInit());
    try {
      const resp = await API.data.addChore(data);
      dispatch(addChoreSuccess(resp));
    } catch (error) {
      dispatch(addChoreFailure(error));
    }
  };
}
