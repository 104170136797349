import {
  RESET_GET_EVALUACIONES,
  GET_EVALUACIONES_INIT,
  GET_EVALUACIONES_SUCCESS,
  GET_EVALUACIONES_FAILURE,
  RESET_DELETE_EVALUACION,
  DELETE_EVALUACION_INIT,
  DELETE_EVALUACION_SUCCESS,
  DELETE_EVALUACION_FAILURE,
  RESET_PUBLICAR_EVALUACION,
  PUBLICAR_EVALUACION_INIT,
  PUBLICAR_EVALUACION_SUCCESS,
  PUBLICAR_EVALUACION_FAILURE,
  RESET_NUEVA_EVALUACION,
  NUEVA_EVALUACION_INIT,
  NUEVA_EVALUACION_SUCCESS,
  NUEVA_EVALUACION_FAILURE,
  RESET_VALIDAR_EVALUACION,
  VALIDAR_EVALUACION_INIT,
  VALIDAR_EVALUACION_SUCCESS,
  VALIDAR_EVALUACION_FAILURE,
} from "../../actions/evaluation/types";
import initialState from "./initialState";

export default function evaluacion(state = initialState, action) {
  switch (action.type) {
    case RESET_GET_EVALUACIONES:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_EVALUACIONES_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_EVALUACIONES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_EVALUACIONES_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    // DELETE
    case RESET_DELETE_EVALUACION:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 0,
      };
    case DELETE_EVALUACION_INIT:
      return {
        ...state,
        loadingDelete: true,
      };
    case DELETE_EVALUACION_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload.data,
        loadingDelete: false,
        statusDelete: action.payload.status,
      };
    case DELETE_EVALUACION_FAILURE:
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 501,
      };

    // PUBLICAR
    case RESET_PUBLICAR_EVALUACION:
      return {
        ...state,
        dataPublicar: {},
        loadingPublicar: false,
        statusPublicar: 0,
      };
    case PUBLICAR_EVALUACION_INIT:
      return {
        ...state,
        loadingPublicar: true,
      };
    case PUBLICAR_EVALUACION_SUCCESS:
      return {
        ...state,
        dataPublicar: action.payload.data,
        loadingPublicar: false,
        statusPublicar: action.payload.status,
      };
    case PUBLICAR_EVALUACION_FAILURE:
      return {
        ...state,
        dataPublicar: {},
        loadingPublicar: false,
        statusPublicar: 501,
      };

    // NUEVA
    case RESET_NUEVA_EVALUACION:
      return {
        ...state,
        dataNueva: {},
        loadingNueva: false,
        statusNueva: 0,
      };
    case NUEVA_EVALUACION_INIT:
      return {
        ...state,
        loadingNueva: true,
      };
    case NUEVA_EVALUACION_SUCCESS:
      return {
        ...state,
        dataNueva: action.payload.data,
        loadingNueva: false,
        statusNueva: action.payload.status,
      };
    case NUEVA_EVALUACION_FAILURE:
      return {
        ...state,
        dataNueva: {},
        loadingNueva: false,
        statusNueva: 501,
      };

    // NUEVA
    case RESET_VALIDAR_EVALUACION:
      return {
        ...state,
        dataValidar: {},
        loadingValidar: false,
        statusValidar: 0,
      };
    case VALIDAR_EVALUACION_INIT:
      return {
        ...state,
        loadingValidar: true,
      };
    case VALIDAR_EVALUACION_SUCCESS:
      return {
        ...state,
        dataValidar: action.payload.data,
        loadingValidar: false,
        statusValidar: action.payload.status,
      };
    case VALIDAR_EVALUACION_FAILURE:
      return {
        ...state,
        dataValidar: {},
        loadingValidar: false,
        statusValidar: 501,
      };

    default:
      return state;
  }
}
