import { useEffect, useState } from "react";
import { Icon, Image, Input, Table } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import ModalDelete from "./../../../Modal/ModalDelete";
import { format } from "date-fns";
import {
  addRegistro,
  resetAddRegistro,
} from "./../../../../actions/add_register/index";
import ohError from "../../../../assets/icons/ohhh_error.png";
import { FormattedMessage } from "react-intl";

const AddRegistro = ({ data, reloadOption }) => {
  const dispatch = useDispatch();
  const stateAdd = useSelector((state) => state.addRegistro);

  const [startDate, setStartDate] = useState(new Date());

  const minero = localStorage.getItem("minero");

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [form, setForm] = useState({
    nivel: "",
    profundidad: "",
  });

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleAddRegistro = () => {
    dispatch(
      addRegistro({
        sistema: {
          modelo: data.data.sistema.modelo,
          encabezado_reg_id: data.data.sistema.encabezado_reg_id,
          clave_tipo_deposito: data.data.sistema.clave_tipo_deposito,
          clave_tipo_instrumento: data.data.sistema.clave_tipo_instrumento,
        },
        registro: {
          dato1: format(startDate, "dd-MM-yyyy"),
          dato2: form.nivel,
          dato3: form.profundidad,
        },
      })
    );
  };

  useEffect(() => {
    if (stateAdd.statusAdd === 200) {
      if (stateAdd.dataAdd.ejecucion.estado) {
        reloadOption();
        resetAddRegistro();
      }
    }
    //eslint-disable-next-line
  }, [stateAdd]);

  useEffect(() => {
    return () => {
      resetAddRegistro();
    };
  }, [dispatch]);

  const resetData = () => {
    dispatch(resetAddRegistro());
  };

  const closeModal = () => {
    dispatch(resetAddRegistro());
  };

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {data.data.nuevo_registro.campos.dato1.label}
            </Table.HeaderCell>

            <Table.HeaderCell>
              {data.data.nuevo_registro.campos.dato2.label}
            </Table.HeaderCell>

            {data.data.nuevo_registro.campos.dato3 ? (
              <Table.HeaderCell>
                {data.data.nuevo_registro.campos.dato3.label}
              </Table.HeaderCell>
            ) : null}

            <Table.HeaderCell>
              <FormattedMessage id="Agregar" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <DatePicker
                placeholderText="DD-MM-YYYY"
                dateFormat="dd-MM-yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="datepicker"
              />
            </Table.Cell>

            <Table.Cell>
              <Input
                name="nivel"
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => handleChange(e)}
                value={form.nivel}
              />
            </Table.Cell>

            {data.data.nuevo_registro.campos.dato3 ? (
              <Table.Cell>
                <Input
                  name="profundidad"
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => handleChange(e)}
                  value={form.profundidad}
                />
              </Table.Cell>
            ) : null}

            <Table.Cell>
              {minero === "true" && (
                <Icon
                  name="add circle"
                  style={{ cursor: "pointer" }}
                  onClick={onShowModal}
                />
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <ModalDelete
        title={<FormattedMessage id="CrearRegistro" />}
        show={showModal}
        setShow={setShowModal}
        action={handleAddRegistro}
        reset={resetData}
        status={stateAdd.statusAdd}
        estado={stateAdd.dataAdd}
        loading={stateAdd.loadingAdd}
        close={closeModal}
      >
        {stateAdd.statusAdd === 0 ? (
          <FormattedMessage id="EstaSeguroRegistro" />
        ) : stateAdd.dataAdd.ejecucion.estado ? (
          <div className="container-modal">
            <p className="message-ok">{stateAdd.dataAdd.ejecucion.mensaje}</p>
            <Icon name="checkmark" className="status-ok" />
          </div>
        ) : (
          <div className="container-modal">
            <p className="message-fail">{stateAdd.dataAdd.ejecucion.mensaje}</p>
            <Image src={ohError} className="status-fail" />
          </div>
        )}
      </ModalDelete>
    </>
  );
};

export default AddRegistro;
