import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Button, Card, Container, Icon, Image } from "semantic-ui-react";
import DividerGrey from "../../components/DividerGrey";

import DashboardUbication from "../../components/DashboardUbication";

import folder from "../../assets/icons/icono_folder-red.png";
import Tailing from "../../components/Params/Tailing";
import Geometrical from "../../components/Params/Geometrical";
import Geotechnical from "../../components/Params/Geotechnical";
import Instruments from "../../components/Params/Instruments";
import { FormattedMessage } from "react-intl";

const EfParams = () => {
  const location = useLocation();
  const [selected, setSelected] = useState(1);

  const { state } = location.state;
  const { stateDeposit } = location.state;

  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

  useEffect(() => {
    if (location.pathname === `/dashboard/ef-params/tailing`) {
      setSelected(1);
    }
    if (
      location.pathname === `/dashboard/ef-params/geometrical` ||
      location.pathname === `/dashboard/ef-params/geometrical/wall` ||
      location.pathname === `/dashboard/ef-params/geometrical/crown` ||
      location.pathname === `/dashboard/ef-params/geometrical/operational` ||
      location.pathname === `/dashboard/ef-params/geometrical/angle` ||
      location.pathname === `/dashboard/ef-params/geometrical/p1-wall` ||
      location.pathname === `/dashboard/ef-params/geometrical/p1-crown` ||
      location.pathname === `/dashboard/ef-params/geometrical/p1-operational` ||
      location.pathname === `/dashboard/ef-params/geometrical/p1-angle` ||
      location.pathname === `/dashboard/ef-params/geometrical/p2-depot` ||
      location.pathname === `/dashboard/ef-params/geometrical/p2-angle` ||
      location.pathname === "/dashboard/ef-params/geometrical/f-wall" ||
      location.pathname === "/dashboard/ef-params/geometrical/f-crown" ||
      location.pathname === "/dashboard/ef-params/geometrical/f-operational" ||
      location.pathname === "/dashboard/ef-params/geometrical/f-angle" ||
      location.pathname === "/dashboard/ef-params/geometrical/gb-wall" ||
      location.pathname === "/dashboard/ef-params/geometrical/gb-angle" ||
      location.pathname === "/dashboard/ef-params/geometrical/gb-b" ||
      location.pathname === "/dashboard/ef-params/geometrical/gl-wall" ||
      location.pathname === "/dashboard/ef-params/geometrical/gl-berm" ||
      location.pathname === "/dashboard/ef-params/geometrical/gl-angle"
    ) {
      setSelected(2);
    }
    if (
      location.pathname === `/dashboard/ef-params/geotechnical` ||
      location.pathname === "/dashboard/ef-params/geotechnical/wall" ||
      location.pathname === "/dashboard/ef-params/geotechnical/specific" ||
      location.pathname === "/dashboard/ef-params/geotechnical/solid" ||
      location.pathname === "/dashboard/ef-params/geotechnical/gran" ||
      location.pathname === "/dashboard/ef-params/geotechnical/atterbergs" ||
      location.pathname === "/dashboard/ef-params/geotechnical/densities" ||
      location.pathname === "/dashboard/ef-params/geotechnical/tension" ||
      location.pathname === "/dashboard/ef-params/geotechnical/thickness" ||
      location.pathname === "/dashboard/ef-params/geotechnical/compaction"
    ) {
      setSelected(3);
    }
    if (
      location.pathname === `/dashboard/ef-params/instruments` ||
      location.pathname === `/dashboard/ef-params/instruments/piezometro` ||
      location.pathname === `/dashboard/ef-params/instruments/pozo` ||
      location.pathname === `/dashboard/ef-params/instruments/distancia` ||
      location.pathname === `/dashboard/ef-params/instruments/drenaje_basal` ||
      location.pathname === `/dashboard/ef-params/instruments/acelerometro` ||
      location.pathname === `/dashboard/ef-params/instruments/inclinometro` ||
      location.pathname ===
        `/dashboard/ef-params/instruments/placa_corrimiento` ||
      location.pathname ===
        `/dashboard/ef-params/instruments/celda_asentamiento`
    ) {
      setSelected(4);
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  return (
    <Container>
      <>
        <DashboardUbication>
          {stateDeposit.statusDeposit === 200 ? (
            `${state.dataChore.datos.razon_social}/${state.dataChore.datos.nombre}/${stateDeposit.dataDeposit.datos.nombre}/Parámetros Evaluación E.F.`
          ) : (
            <>
              <FormattedMessage id="CargandoMinus" />
            </>
          )}
        </DashboardUbication>
        <DividerGrey />

        <div>
          <div style={{ display: "flex", gap: "15px" }}>
            <CardParams
              stateDeposit={stateDeposit}
              state={state}
              selected={selected}
              handleClick={handleClick}
            />

            {selected === 1 && <Tailing stateDeposit={stateDeposit} />}
            {selected === 2 && <Geometrical stateDeposit={stateDeposit} />}
            {selected === 3 && <Geotechnical stateDeposit={stateDeposit} />}
            {selected === 4 && <Instruments stateDeposit={stateDeposit} />}
          </div>
        </div>
      </>
    </Container>
  );
};

function CardParams({ selected, handleClick, stateDeposit, state }) {
  return (
    <Container style={{ width: "40%" }}>
      <Card fluid className="card-deposit">
        <Card.Content>
          <div
            className="card-deposit-title"
            style={{ borderBottom: "1px solid #a8b7c7", paddingBottom: "15px" }}
          >
            {stateDeposit.dataDeposit.datos.nombre}
          </div>
        </Card.Content>

        <Card.Content
          style={{ borderTop: "none", borderRight: "2px solid #fe6565" }}
        >
          <div className="card-deposit-container-name">
            <div>
              <Link
                to={`/dashboard/documents/${stateDeposit.dataDeposit.datos.id}`}
                className="container-card-left-deposit-chore"
                state={{ state: state }}
              >
                <Image
                  src={folder}
                  alt="Icono Usuario"
                  className="icon-folder"
                />
                <div className="card-chore-name-deposits">
                  <FormattedMessage id="ParametrosEFControl" />
                </div>
              </Link>
            </div>
            <Icon name="chevron down" />
          </div>
        </Card.Content>

        <Card.Content style={{ paddingRight: "0px" }}>
          <div className="params__container-matrices">
            <Link
              to={`/dashboard/ef-params/tailing`}
              state={{ state, stateDeposit }}
            >
              <Button
                className={selected === 1 ? "selected" : ""}
                onClick={handleClick(1)}
              >
                <FormattedMessage id="CantidadRelaves" />
              </Button>
            </Link>
            <Link
              to={`/dashboard/ef-params/geometrical`}
              state={{ state, stateDeposit }}
            >
              <Button
                className={selected === 2 ? "selected" : ""}
                onClick={handleClick(2)}
              >
                <FormattedMessage id="ControlesGeometricos" />
              </Button>
            </Link>
            <Link
              to={`/dashboard/ef-params/geotechnical`}
              state={{ state, stateDeposit }}
            >
              <Button
                className={selected === 3 ? "selected" : ""}
                onClick={handleClick(3)}
              >
                <FormattedMessage id="ControlesGeotecnicos" />
              </Button>
            </Link>

            <Link
              to={`/dashboard/ef-params/instruments`}
              state={{ state, stateDeposit }}
            >
              <Button
                className={selected === 4 ? "selected" : ""}
                onClick={handleClick(4)}
              >
                <FormattedMessage id="MonitoreosInstrumentos" />
              </Button>
            </Link>
          </div>
        </Card.Content>
      </Card>
    </Container>
  );
}

export default EfParams;
