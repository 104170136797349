const messages = {
  ActivaMayus: "ACTIVA",
  ActualizarAlturaDeposito: "Actualizar Altura Depósito",
  ActualizarAlturaGlobal: "Actualizar Altura Global",
  ActualizarAlturaLocal: "Actualizar Altura Local",
  ActualizarAlturaMuro: "Actualizar Altura Muro",
  ActualizarAnchoBermaLocal: "Actualizar Ancho Berma Local",
  ActualizarAnchoCoronamiento: "Actualizar Ancho Coronamiento",
  ActualizarAnguloDeposito: "Actualizar Ángulo Depósito",
  ActualizarAnguloGlobal: "Actualizar Ángulo Global",
  ActualizarAnguloLocal: "Actualizar Ángulo Local",
  ActualizarAnguloMuro: "Actualizar Ángulo Muro",
  ActualizarAnguloTaludExterno: "Actualizar Ángulo Talud Externo",
  ActualizarAtterbergs: "Actualizar Límites de Atterberg",
  ActualizarCantidadRelaves: "Actualizar Cantidad de Relaves",
  ActualizarComentario: "Actualizar Comentario",
  ActualizarCompactacion: "Actualizar Compactación",
  ActualizarDensidades: "Actualizar Densidades",
  ActualizarDeposito: "Actualizar Depósito",
  ActualizarDistribucionGranulometrica:
    "Actualizar Distribución Granulométrica",
  ActualizarEmpresa: "Actualizar Empresa",
  ActualizarEspesorCapa: "Actualizar Espesor Capa",
  ActualizarFaena: "Actualizar Faena",
  ActualizarGravedad: "Actualizar Gravedad Específica",
  ActualizarMatriz1: "Actualizar Matriz 1",
  ActualizarMatriz2: "Actualizar Matriz 2",
  ActualizarMatriz3: "Actualizar Matriz 3",
  ActualizarMatriz4: "Actualizar Matriz 4",
  ActualizarMatriz5: "Actualizar Matriz 5",
  ActualizarMatriz6: "Actualizar Matriz 6",
  ActualizarMatriz7: "Actualizar Matriz 7",
  ActualizarMatriz8: "Actualizar Matriz 8",
  ActualizarMayus: "ACTUALIZAR",
  ActualizarPorcentaje: "Actualizar Porcentaje de Sólidos",
  ActualizarRevanchaOperacional: "Actualizar Revancha Operacional",
  ActualizarTension: "Actualizar Tensión de Corte",
  Agregar: "Agregar",
  AlturaDeposito: "Altura Depósito",
  AlturaGlobal: "Altura Global",
  AlturaLocal: "Altura Local",
  AlturaMuro: "Altura Muro",
  AlturaMuroValor: "Altura de banco [m]",
  AnchoBermaLocal: "Ancho Berma Local",
  AnchoBermaValor: "Ancho berma [m]",
  AnchoCoronamiento: "Ancho Coronamiento",
  AnguloDeposito: "Ángulo Depósito",
  AnguloGlobal: "Ángulo Global",
  AnguloLocal: "Ángulo Local",
  AnguloMuro: "Ángulo Muro",
  AnguloTaludValor: "Ángulo de banco [°]",
  AprobadoMayus: "APROBADO",
  Aprobar: "Aprobar",
  AprobarUsuario: "Aprobar Usuario",
  Archivos: "Archivo (s)",
  Bienvenido: "BIENVENIDO",
  CalleMayus: "CALLE",
  CalleEmpresa: "Calle empresa",
  CambiarContraseña: "Cambiar contraseña",
  CambiarRegistro: "Cambiar Registro",
  CambiarRegistroText:
    "¿Está seguro de cambiar este registro?, se borrarar todos los datos almacenados",
  CantidadPuntosControl: "Cantidad de Puntos de Control",
  CantidadRelaves: "Cantidad de Relaves",
  CantidadRelavesActualM3Mayus: "CANTIDAD DE RELAVES ACTUAL (m³)",
  CantidadRelavesActualM3Minus: "Escriba la cantidad de relaves actual (m³)",
  CantidadRelavesActualTMayus: "CANTIDAD DE RELAVES ACTUAL (t)",
  CantidadRelavesActualTMinus: "Escriba la cantidad de relaves actual (t)",
  CantidadRelavesAutorizadoM3Mayus: "CANTIDAD DE RELAVES AUTORIZADO (m³)",
  CantidadRelavesAutorizadoM3Minus:
    "Escriba la cantidad de relaves autorizado (m³)",
  CantidadRelavesAutorizadoTMayus: "CANTIDAD DE RELAVES AUTORIZADO (t)",
  CantidadRelavesAutorizadoTMinus:
    "Escriba la cantidad de relaves autorizado (t)",
  CargandoMayus: "CARGANDO...",
  CargandoMinus: "Cargando...",
  CargoProfesionalResponsableMayus: "CARGO PROFESIONAL RESPONSABLE",
  CategoriaEmpresa: "Categoría empresa",
  Cerrar: "Cerrar",
  CerrarSesion: "Cerrar sesión",
  CheckOk: "Bien, ahora puede realizar una evaluación del déposito",
  ChequearData: "Chequear data para Evaluación",
  ClickChequeo:
    "Click para realizar un chequeo del déposito para proceder a realizar una evaluación",
  Codigo: "Ingrese el código recibido en su correo",
  Colaboradores: "Colaboradores",
  Comentario: "Comentario",
  ComentarioGeneral: "Comentario General Instrumentación",
  CompactacionMuro: "Compactación muro",
  ComunaMayus: "COMUNA",
  Contraseña: "Contraseña",
  ContraseñaActual: "Contraseña actual",
  ContraseñasNoCoinciden: "Las contraseñas no coinciden",
  ContratistaMinero: "Contratista minero",
  ControlesGeometricos: "Controles Geométricos",
  ControlesGeotecnicos: "Controles Geotécnicos",
  Correo: "Correo",
  CorreoProfesionalResponsableMayus: "CORREO PROFESIONAL RESPONSABLE",
  CorreoRepresentanteLegalMayus: "CORREO REPRESENTANTE LEGAL",
  CreadoPor: "Creado por",
  CrearDeposito: "Crear Depósito",
  CrearFaena: "Crear Faena",
  CrearRegistro: "Crear registro",
  CriteriosEmpresa: "Criterios de búsqueda de empresa con depósitos asociados",
  DatosMayus: "DATOS",
  DatosMinus: "Datos",
  DatosRegistrados: "Datos registrados",
  DebeIngresarTodosLosValores:
    "Debe ingresar todos los valores de la tabla y que el valor final sea un número positivo",
  Deposito: "Depósito",
  Depositos: "Depósitos",
  DepositosMayus: "DEPÓSITOS",
  DescargarMayus: "DESCARGAR",
  DescargarMinus: "Descargar",
  Descubre: "Descubre",
  DireccionMayus: "DIRECCIÓN",
  DireccionProfesionalResponsable: "Dirección Profesional Responsable",
  DireccionRepresentanteLegalMayus: "DIRECCIÓN REPRESENTANTE LEGAL",
  DistanciaPeligrosaMayus: "DISTANCIA PELIGROSA (km)",
  DistanciaPeligrosaMinus: "Escriba la distancia peligrosa (km)",
  DistribucionGranulometrica: "Distribución Granulometrica",
  Documentacion: "Documentación",
  DocumentosLineaBase: "Documentos Línea Base",
  DocumentosLineaBaseRelaves: "Documentos Línea Base de Relaves",
  DocumentosMayus: "DOCUMENTOS",
  DocumentosObligatorios:
    " *: documento que de manera obligatoria deben estar cargados en Módulo “Línea Base”.",
  DocumentosRespaldan: "Documentos que respaldan la información ingresada",
  EditarDeposito: "Editar Depósito",
  EditarEmpresa: "Editar Empresa",
  EditarFaena: "Editar Faena",
  Eliminar: "Eliminar",
  EliminarDeposito: "Eliminar Depósito",
  EliminarDocumento: "Eliminar documento",
  EliminarEvaluacion: "Eliminar Evaluación",
  EliminarFaena: "Eliminar Faena",
  EliminarRegistro: "Eliminar Registro",
  EliminarRegistroHistorico: "Eliminar Registro Histórico",
  Empresa: "Empresa",
  EmpresaActiva: "Empresa activa",
  EmpresaID: "Empresa ID",
  Empresas: "Empresas",
  EntidadesParticipantes: "Entidades participantes",
  EnrolamientoUsuarios: "Enrolamiento de Usuarios",
  Enrolar: "Enrolar",
  EquipoProyecto: "Equipo del proyecto",
  ErrorLogin: "Correo o contraseña incorrectos",
  ErrorSubida: "Hubo un problema, reintente o contacte a su administrador",
  EscribaCalleEmpresa: "Escriba la calle de la empresa",
  EscribaCargoProfesionalResponsable:
    "Escriba el cargo del profesional responsable",
  EscribaCorreoProfesionalResponsable:
    "Escriba el correo del profesional responsable",
  EscribaCorreoRepresentanteLegal: "Escriba el correo del representante legal",
  EscribaDireccionRepresentanteLegal:
    "Escriba la dirección del representante legal",
  EscribaNombreDeposito: "Escriba el nombre del depósito",
  EscribaNombreEmpresa: "Escriba el nombre de la empresa",
  EscribaNombreFaena: "Escriba el nombre de la faena",
  EscribaNombreProfesionalResponsable:
    "Escriba el nombre del profesional responsable",
  EscribaNombreRepresentanteLegal: "Escriba el nombre del representante legal",
  EscribaTelefonoProfesionalResponsable:
    "Escriba el teléfono del profesional responsable",
  EscribaTelefonoRepresentanteLegal:
    "Escriba el teléfono del representante legal",
  EspecifiqueHerramienta: "Especifique herramienta",
  EspesorCapa: "Espesor capa",
  EstadoMayus: "ESTADO",
  EstaSeguroActualizarDeposito: "¿Está seguro de actualizar este depósito?",
  EstaSeguroActualizarEmpresa: "¿Está seguro de actualizar esta empresa?",
  EstaSeguroActualizarFaena: "¿Está seguro de actualizar esta faena?",
  EstaSeguroActualizarInformacion:
    "¿Está seguro de actualizar la información ingresada?",
  EstaSeguroAprobarUsuario: "¿Está seguro de aprobar este usuario?",
  EstaSeguroComentario: "¿Está seguro de actualizar el comentario?",
  EstaSeguroEliminarDeposito: "¿Está seguro de eliminar este Depósito?",
  EstaSeguroEliminarEvaluacion: "¿Está seguro de eliminar esta evaluación?",
  EstaSeguroEliminarFaena: "¿Está seguro de eliminar esta Faena?",
  EstaSeguroEliminarRegistro: "¿Está seguro de eliminar este registro?",
  EstaSeguroEliminarRegistroHistorico:
    "¿Está seguro de eliminar el registro seleccionado?",
  EstaSeguroMatriz1: "¿Está seguro de actualizar la matriz 1?",
  EstaSeguroMatriz2: "¿Está seguro de actualizar la matriz 2?",
  EstaSeguroMatriz3: "¿Está seguro de actualizar la matriz 3?",
  EstaSeguroMatriz4: "¿Está seguro de actualizar la matriz 4?",
  EstaSeguroMatriz5: "¿Está seguro de actualizar la matriz 5?",
  EstaSeguroMatriz6: "¿Está seguro de actualizar la matriz 6?",
  EstaSeguroMatriz7: "¿Está seguro de actualizar la matriz 7?",
  EstaSeguroMatriz8: "¿Está seguro de actualizar la matriz 8?",
  EstaSeguroPublicarEvaluacion:
    "¿Está seguro de publicar esta nueva evaluación?",
  EstaSeguroRealizarEvaluacion:
    "¿Está seguro de realizar una nueva Evaluación?",
  EstaSeguroRechazarUsuario: "¿Está seguro de rechazar este usuario?",
  EstaSeguroRegistro: "¿Está seguro de crear este registro?",
  EstaSeguroValidarEvaluacion:
    "Se realizara una validación para verificar si puede crear una nueva Evaluación",
  EtapaConstruccion: "Etapa de Construcción",
  Evaluacion: "Evaluación",
  Faenas: "Faenas",
  FaenasMayus: "FAENAS",
  FechaControl: "Fecha de control",
  FechaEvaluacion: "Fecha Evaluación",
  FechaInicioOperacionMayus: "FECHA DE INICIO OPERACIÓN",
  FechaResolucionAprobatoriaDepositoMayus:
    "SELECCIONE FECHA DE RESOLUCIÓN QUE APRUEBA EL DEPÓSITO",
  FechaResolucionAprobatoriaFaenaMayus:
    "SELECCIONE FECHA DE RESOLUCIÓN QUE APRUEBA LA FAENA",
  GravedadEspecifica: "Gravedad Específica",
  Guardar: "Guardar",
  GuardarMayus: "GUARDAR",
  Herramienta: "Herramienta",
  HumedadDensidades: "Humedad y Densidades",
  Indique: "Índique",
  IndiqueCalle: "Índique calle, avenida, pasaje...",
  Informacion: "Información",
  InformacionDeposito: "Información Depósito",
  Ingresar: "Ingresar",
  IngresarMayus: "INGRESAR",
  IngresarNombre: "Ingrese su nombre",
  IngresarTelefono: "Ingrese su número de teléfono",
  IngreseCantidadPuntosControl:
    "Ingrese la cantidad de puntos de control de la Tabla",
  IngreseCantidadSector: "Ingrese la cantidad de Sector(es)",
  IngreseComentario: "Deje aquí su comentario",
  IngreseContraseñaActual: "Ingrese contraseña actual",
  IngreseCorreoRecuperarContraseña:
    "Ingrese su correo para recuperar su contraseña y poder iniciar sesión en",
  IngreseNuevaContraseña: "Ingrese nueva contraseña",
  IngresePlataforma:
    "Ingrese a la plataforma con los datos que se enviaron a su correo por ",
  IngreseValor: "Ingrese valor",
  Intestigadores: "Investigadores",
  IrEmpresa: "Ir a la Empresa",
  LimitesAtterbergs: "Límites de Atterberg",
  Manual: "Manual",
  MatricesOk:
    "Usted ya puede realizar los Parámetros E.F. (Haga click aquí para empezar)",
  Matriz1: "Matriz 1",
  Matriz2: "Matriz 2",
  Matriz3: "Matriz 3",
  Matriz4: "Matriz 4",
  Matriz5: "Matriz 5",
  Matriz6: "Matriz 6",
  Matriz7: "Matriz 7",
  Matriz8: "Matriz 8",
  MetodoCompactacion: "Método de Compactación",
  MetodoConstruccionMayus: "MÉTODO DE CONSTRUCCIÓN",
  MetodoConstruccionMinus: "Escriba el método de construcción",
  MonitoreosInstrumentos: "Monitoreos Instrumentales",
  NivelCompactacion: "Nivel Compactación",
  NoActivaMayus: "NO ACTIVA",
  NoAprobadoMayus: "NO APROBADO",
  NoHayEmpresasAsociadas: "No hay empresas asociadas a esta comuna",
  NoHayUsuariosPendientes: "No hay usuarios pendientes de aprobación",
  Nombre: "Nombre",
  NombreArchivo: "Nombre Archivo",
  NombreComuna: "Nombre comuna",
  NombreDeposito: "Nombre Depósito",
  NombreDepositoMayus: "NOMBRE DEL DEPÓSITO",
  NombreEmpresa: "Nombre Empresa",
  NombreFaena: "Nombre Faena",
  NombreFaenaMayus: "NOMBRE DE LA FAENA",
  NombreFantasia: "Nombre fantasía",
  NombreMayus: "NOMBRE",
  NombreProfesionalResponsableMayus: "NOMBRE PROFESIONAL RESPONSABLE",
  NombreProfesionalResponsableMinus: "Nombre profesional responsable",
  NombreRepresentanteLegal: "Nombre representante legal",
  NombreRepresentanteLegalMayus: "NOMBRE REPRESENTANTE LEGAL",
  NoRecibidoMayus: "NO RECIBIDO",
  NoSeleccionoArchivo: "No se selecciono ningun archivo para subir, reintente",
  NoSeSeleccionoNivelCompactacion: `No se seleccionó "Nivel de Compactación" en Matriz 3`,
  Notas: "Notas",
  Nueva: "Nueva",
  NuevaContraseña: "Nueva contraseña",
  NuevaEvaluacion: "Nueva Evaluación",
  NuevaFaena: "Registro de nueva Faena",
  NuevoRegistro: "Nuevo registro",
  Numero: "Número",
  NumeroContacto: "Número contacto",
  Objetivos: "Objetivos",
  ParametrosEFControl: "Parámetros de Evaluación E.F. (control operacional)",
  ParametrosEvaluacionEF: "Parámetros Evaluación E.F.",
  ParametrosLineaBase: "Parámetros Línea Base",
  Pendientes: "Pendientes",
  Periodo: "Periodo",
  PlanCierreEstadoMayus: "PLAN DE CIERRE ESTADO",
  PlanCierreRecibidoMayus: "PLAN DE CIERRE RECIBIDO",
  PontificiaUniversidadCatolicaValparaiso:
    "Pontificia Universidad Católica de Valparaíso",
  PorcentajeSolidos: "Porcentaje de Sólidos",
  ProfesionalResponsable: "Profesional Responsable",
  ProfundidadExtraccionMuestra: "Profundidad de Extracción de Muestra (m)",
  ProvinciaMayus: "PROVINCIA",
  Publicar: "Publicar",
  PublicarEvaluacion: "Publicar Evaluación",
  PuntoControl: "Punto de control",
  RazonSocial: "Razón social",
  RealizarEvaluacion: "Realizar Evaluación",
  Rechazar: "Rechazar",
  RechazarUsuario: "Rechazar Usuario",
  RecibidoMayus: "RECIBIDO",
  RecuperarClave: "Recuperar contraseña",
  RecuperarClaveMayus: "RECUPERAR CONTRASEÑA",
  RecursoMayus: "RECURSO",
  RegionMayus: "REGIÓN",
  Registrarse: "Registrarse",
  RegistroNuevoDeposito: "Registro Nuevo Depósito",
  RepitaNuevaContraseña: "Repita nueva contraseña",
  RepresentanteLegal: "Representante Legal",
  ResolucionAprobatoriaDepositoMayus:
    "NÚMERO DE RESOLUCIÓN APROBATORIA DEL DEPÓSITO",
  ResolucionAprobatoriaDepositoMinus:
    "Escriba el número de resolución aprobatoria del depósito",
  ResolucionAprobatoriaFaenaMayus:
    "NÚMERO DE RESOLUCIÓN APROBATORIA DE LA FAENA",
  ResolucionAprobatoriaFaenaMinus:
    "Escriba el número de resolución aprobatoria de la faena",
  RevanchaOperacional: "Revancha Operacional",
  RutEmpresa: "Rut empresa",
  RutRepresentanteEjemplo: "Ejemplo RUT (Debe ir con GUÓN) 11278721-6",
  RutRepresentanteLegalMayus: "RUT REPRESENTANTE LEGAL",
  Sector: "Sector",
  SeleccionarEmpresa: "Seleccionar Empresa",
  Seleccione: "Seleccione",
  SeleccioneAlturaGlobal: "Seleccione Altura Global",
  SeleccioneAlturaMuro: "Seleccione Altura Muro",
  SeleccioneAnchoCoronamiento: "Seleccione Ancho Coronamiento",
  SeleccioneAngulo: "Seleccione Ángulo Operacional",
  SeleccioneAnguloTaludGlobal: "Seleccione Ángulo Talud Global",
  SeleccioneAtterberg: "Seleccione Límites de Atterberg",
  SeleccioneBancoGlobal: "Seleccione Banco Global",
  SeleccioneCompactacion: "Seleccione Nivel de Compactación",
  SeleccioneConstruccion: "Seleccione Construcción",
  SeleccioneDensidad: "Seleccione Densidad Seca",
  SeleccioneDepositoAngulo: "Seleccione Depósito Ángulo",
  SeleccioneDistribucion: "Seleccione Distribución Granulométrica",
  SeleccioneEspesor: "Seleccione Espesor",
  SeleccioneGravedad: "Seleccione Gravedad Específica",
  SeleccioneHumedad: "Seleccione el Porcentaje de Sólidos",
  SeleccioneNivel: "Seleccione Nivel",
  SeleccioneNivelEstimación: "Seleccione Nivel de Estimación Crecidas",
  SeleccioneNivelSismicidad: "Seleccione Nivel Sismicidad",
  SeleccionePastaAltura: "Seleccione Pasta Altura",
  SeleccioneRevancha: "Seleccione Revancha Operacional",
  SeleccioneSolidos: "Seleccione Porcentaje Sólidos",
  SeleccioneTension: "Seleccione Tensión Corte",
  SeleccioneTipoDeposito: "Seleccione tipo de depósito",
  Sernageomin: "Servicio Nacional de Geología y Minería (SERNAGEOMIN)",
  ServicioNacionalGeologiaMineria: "Servicio Nacional de Geología y Minería",
  Si: "Si",
  SobreProyecto: "Sobre el proyecto",
  SobreRepositorio: "Sobre el repositorio",
  SobreRepositorioText:
    "El objetivo del proyecto fue generar una plataforma inteligente para monitorear y registrar de forma periódica las variables críticas que definen los potenciales mecanismos de falla de depósitos de relave pertenecientes a la mediana minería, durante la etapa de operación, para evaluar de forma periódica la estabilidad física con miras a desarrollar un proceso de cierre más seguro.",
  Solicitando: "Solicitando...",
  SolicitandoCodigo: "Solicitando Código",
  SolicitarCodigo: "Solicitar Código",
  Subir: "Subir",
  SubirDocumento: "Subir documento",
  TelefonoEmpresa: "Teléfono empresa",
  TelefonoProfesionalResponsableMayus: "TELÉFONO PROFESIONAL RESPONSABLE",
  TelefonoRepresentanteLegalMayus: "TELÉFONO REPRESENTANTE LEGAL",
  TensionCorte: "Tensión de Corte",
  TipoDeposito: "Tipo de depósito",
  TipoTranque: "Tipo de tranque",
  UTMEste: "UTM Este",
  UTMEsteMayus: "UTM ESTE",
  UTMNorte: "UTM Norte",
  UTMNorteMayus: "UTM NORTE",
  ValidandoDatos: "Validando datos ingresados",
  ValidarEvaluacion: "Validar Evaluación",
  Valor: "Valor",
  Ver: "Ver",
  Verificando: "Verificando...",
  VerificandoCodigo: "Verificando Código",
  VerificarCodigo: "Verificar Código",
  DuracionDepositoMayus: "DURACIÓN DEL DEPÓSITO (años)",
  DuracionDepositoMinus: "Escriba la duración del depósito (años)",
  VolverAtras: "Volver atrás",
};
export default messages;
