import axios from "axios";

const API = {
  data: {
    async getMatriz1(obj) {
      const response = await axios.get(
        `/api/matriz/matriz1/${encodeURIComponent(JSON.stringify(obj))}`
      );

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async updateMatriz1(obj) {
      const response = await axios.put("/api/matriz/matriz1/update", obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
