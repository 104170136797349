import {
  RESET_UPDATE_COMENTARIO,
  UPDATE_COMENTARIO_INIT,
  UPDATE_COMENTARIO_SUCCESS,
  UPDATE_COMENTARIO_FAILURE,
  RESET_UPDATE_CHECKBOX,
  UPDATE_CHECKBOX_INIT,
  UPDATE_CHECKBOX_SUCCESS,
  UPDATE_CHECKBOX_FAILURE,
  RESET_UPDATE_DROPDOWN,
  UPDATE_DROPDOWN_INIT,
  UPDATE_DROPDOWN_SUCCESS,
  UPDATE_DROPDOWN_FAILURE,
  SAVE_COMENTARIO,
  SAVE_DROPDOWN,
  SAVE_DATA_SELECTED,
} from "../../actions/update_comentario/types";
import initialState from "./initialState";

export default function updateComentario(state = initialState, action) {
  switch (action.type) {
    case RESET_UPDATE_COMENTARIO:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_COMENTARIO_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_COMENTARIO_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_COMENTARIO_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };

    // UPDATE CHECK
    case RESET_UPDATE_CHECKBOX:
      return {
        ...state,
        dataUpdateCheck: {},
        loadingUpdateCheck: false,
        statusUpdateCheck: 0,
      };
    case UPDATE_CHECKBOX_INIT:
      return {
        ...state,
        loadingUpdateCheck: true,
      };
    case UPDATE_CHECKBOX_SUCCESS:
      return {
        ...state,
        dataUpdateCheck: action.payload.data,
        loadingUpdateCheck: false,
        statusUpdateCheck: action.payload.status,
      };
    case UPDATE_CHECKBOX_FAILURE:
      return {
        ...state,
        dataUpdateCheck: {},
        loadingUpdateCheck: false,
        statusUpdateCheck: 501,
      };

    // UPDATE DROPDOWN
    case RESET_UPDATE_DROPDOWN:
      return {
        ...state,
        dataUpdateDropdown: {},
        loadingUpdateDropdown: false,
        statusUpdateDropdown: 0,
      };
    case UPDATE_DROPDOWN_INIT:
      return {
        ...state,
        loadingUpdateDropdown: true,
      };
    case UPDATE_DROPDOWN_SUCCESS:
      return {
        ...state,
        dataUpdateDropdown: action.payload.data,
        loadingUpdateDropdown: false,
        statusUpdateDropdown: action.payload.status,
      };
    case UPDATE_DROPDOWN_FAILURE:
      return {
        ...state,
        dataUpdateDropdown: {},
        loadingUpdateDropdown: false,
        statusUpdateDropdown: 501,
      };
    case SAVE_COMENTARIO:
      const arr = [...state.arrComentarios];

      arr[action.action.index] = action.action.value;
      return {
        ...state,
        arrComentarios: arr,
      };
    case SAVE_DROPDOWN:
      const arrDropdown = [...state.arrDropdown];

      arrDropdown[action.action.index] = {
        ...arrDropdown[action.action.index],
        [`pozo-${action.action.dataId}`]: action.action.value,
      };
      return {
        ...state,
        arrDropdown,
      };
    case SAVE_DATA_SELECTED:
      const arrDataSelected = [...state.arrDataSelected];

      arrDataSelected[action.action.index] = action.action.value;
      return {
        ...state,
        arrDataSelected,
      };

    default:
      return state;
  }
}
