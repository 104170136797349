import {
  RESET_MATRICES_OK,
  MATRICES_OK_INIT,
  MATRICES_OK_SUCCESS,
  MATRICES_OK_FAILURE,
} from "../../actions/matrices/types";
import initialState from "./initialState";

export default function matricesOk(state = initialState, action) {
  switch (action.type) {
    case RESET_MATRICES_OK:
      return {
        ...state,
        dataOk: {},
        loadingOk: false,
        statusOk: 0,
      };
    case MATRICES_OK_INIT:
      return {
        ...state,
        loadingOk: true,
      };
    case MATRICES_OK_SUCCESS:
      return {
        ...state,
        dataOk: action.payload.data,
        loadingOk: false,
        statusOk: action.payload.status,
      };
    case MATRICES_OK_FAILURE:
      return {
        ...state,
        dataOk: {},
        loadingOk: false,
        statusOk: 501,
      };

    default:
      return state;
  }
}
