const initialState = {
  dataUpdate: {},
  loadingUpdate: false,
  statusUpdate: 0,

  dataUpdateCheck: {},
  loadingUpdateCheck: false,
  statusUpdateCheck: 0,

  dataUpdateDropdown: {},
  loadingUpdateDropdown: false,
  statusUpdateDropdown: 0,

  arrComentarios: ["", "", "", "", "", "", "", "", ""],
  arrDropdown: ["", "", "", "", "", "", "", "", ""],
  arrDataSelected: ["", "", "", "", "", "", "", "", ""],
};

export default initialState;
