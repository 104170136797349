import { Container } from "semantic-ui-react";

import Divider from "../../components/Divider";
import Grey from "../../components/Grey";
import MenuHome from "./../../components/MenuHome/index";

import { Outlet, Navigate } from "react-router-dom";

const Dashboard = () => {
  let auth = localStorage.getItem("auth");
  auth = auth === "true";

  let minero = localStorage.getItem("minero");

  return !auth ? (
    <Navigate to="/" />
  ) : (
    <>
      {minero === "true" && (
        <Container>
          <MenuHome />
        </Container>
      )}

      <Divider />
      <Grey />

      <Outlet />
    </>
  );
};

export default Dashboard;
