import {
  RESET_CHORE,
  GET_CHORE_INIT,
  GET_CHORE_SUCCESS,
  GET_CHORE_FAILURE,
  RESET_DATA_CHORE,
  GET_DATA_CHORE_INIT,
  GET_DATA_CHORE_SUCCESS,
  GET_DATA_CHORE_FAILURE,
  RESET_DELETE_CHORE,
  DELETE_CHORE_INIT,
  DELETE_CHORE_SUCCESS,
  DELETE_CHORE_FAILURE,
} from "./types";
import API from "./api";

// GET CHORES
export function resetGetChores() {
  return {
    type: RESET_CHORE,
  };
}
function getChoresInit() {
  return {
    type: GET_CHORE_INIT,
  };
}
function getChoresSuccess(data) {
  return {
    type: GET_CHORE_SUCCESS,
    payload: data,
  };
}
function getChoresFailure(error) {
  return {
    type: GET_CHORE_FAILURE,
    payload: error,
  };
}
export function getChores(data) {
  return async (dispatch) => {
    dispatch(getChoresInit());
    try {
      const resp = await API.data.getChore(data);
      dispatch(getChoresSuccess(resp));
    } catch (error) {
      dispatch(getChoresFailure(error));
    }
  };
}

// GET DATA CHORE
export function resetGetDataChore() {
  return {
    type: RESET_DATA_CHORE,
  };
}
function getDataChoreInit() {
  return {
    type: GET_DATA_CHORE_INIT,
  };
}
function getDataChoreSuccess(data) {
  return {
    type: GET_DATA_CHORE_SUCCESS,
    payload: data,
  };
}
function getDataChoreFailure(error) {
  return {
    type: GET_DATA_CHORE_FAILURE,
    payload: error,
  };
}
export function getDataChore(data) {
  return async (dispatch) => {
    dispatch(getDataChoreInit());
    try {
      const resp = await API.data.getDataChore(data);
      dispatch(getDataChoreSuccess(resp));
    } catch (error) {
      dispatch(getDataChoreFailure(error));
    }
  };
}

// DELETE CHORE
export function resetDeleteChore() {
  return {
    type: RESET_DELETE_CHORE,
  };
}
function deleteChoreInit() {
  return {
    type: DELETE_CHORE_INIT,
  };
}
function deleteChoreSuccess(data) {
  return {
    type: DELETE_CHORE_SUCCESS,
    payload: data,
  };
}
function deleteChoreFailure(error) {
  return {
    type: DELETE_CHORE_FAILURE,
    payload: error,
  };
}
export function deleteChore(data) {
  return async (dispatch) => {
    dispatch(deleteChoreInit());
    try {
      const resp = await API.data.deleteChore(data);
      dispatch(deleteChoreSuccess(resp));
    } catch (error) {
      dispatch(deleteChoreFailure(error));
    }
  };
}
