import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";

import Expired from "./../../../Expired/index";
import Compactacion from "./Compactacion";
import Espesor from "./Espesor";
import Distribucion from "./Distribucion";
import { FormattedMessage } from "react-intl";

const Index = ({ stateParams, stateDeposit, state }) => {
  const [selected, setSelected] = useState("");
  const location = useLocation();

  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/ef-params/geotechnical/compaction") {
      setSelected(1);
    }
    if (location.pathname === "/dashboard/ef-params/geotechnical/thickness") {
      setSelected(2);
    }
    if (location.pathname === "/dashboard/ef-params/geotechnical/gran") {
      setSelected(3);
    }
  }, [location.pathname]);

  if (
    stateParams.statusParams === 501 ||
    stateParams.statusParams === 401 ||
    stateDeposit.status === 501 ||
    stateDeposit.status === 401 ||
    state.status === 401 ||
    state.status === 501
  ) {
    return <Expired />;
  }

  return (
    <>
      <div className="params-ef">
        {stateParams.dataParams.datos.datos.compactacion_calc
          .metodo_compactacion.ingresar_datos && (
          <Link
            to="/dashboard/ef-params/geotechnical/compaction"
            state={{ state, stateDeposit }}
          >
            <Button
              onClick={handleClick(1)}
              className={
                location.pathname ===
                "/dashboard/ef-params/geotechnical/compaction"
                  ? "ef-selected"
                  : stateParams.dataParams.datos.datos.compactacion_calc
                      .metodo_compactacion.opcion_informacion_id !== "" &&
                    stateParams.dataParams.datos.datos.compactacion_calc
                      .metodo_compactacion.opcion_informacion_id !== null
                  ? "ef-ok"
                  : ""
              }
            >
              <FormattedMessage id="CompactacionMuro" />
            </Button>
          </Link>
        )}

        {stateParams.dataParams.datos.datos.espesor.ingresar_datos && (
          <Link
            to="/dashboard/ef-params/geotechnical/thickness"
            state={{ state, stateDeposit }}
          >
            <Button
              onClick={handleClick(2)}
              className={
                location.pathname ===
                "/dashboard/ef-params/geotechnical/thickness"
                  ? "ef-selected"
                  : stateParams.dataParams.datos.datos.espesor
                      .opcion_informacion_id !== "" &&
                    stateParams.dataParams.datos.datos.espesor
                      .opcion_informacion_id !== null
                  ? "ef-ok"
                  : ""
              }
            >
              <FormattedMessage id="EspesorCapa" />
            </Button>
          </Link>
        )}

        {stateParams.dataParams.datos.datos.distribucion_granulometrica
          .ingresar_datos && (
          <Link
            to="/dashboard/ef-params/geotechnical/gran"
            state={{ state, stateDeposit }}
          >
            <Button
              onClick={handleClick(3)}
              className={
                location.pathname === "/dashboard/ef-params/geotechnical/gran"
                  ? "ef-selected"
                  : stateParams.dataParams.datos.datos
                      .distribucion_granulometrica.opcion_informacion_id !==
                      "" &&
                    stateParams.dataParams.datos.datos
                      .distribucion_granulometrica.opcion_informacion_id !==
                      null
                  ? "ef-ok"
                  : ""
              }
            >
              <FormattedMessage id="DistribucionGranulometrica" />
            </Button>
          </Link>
        )}
      </div>

      {selected === 1 && (
        <div>
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Compactacion stateParams={stateParams} />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 2 && (
        <div>
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Espesor stateParams={stateParams} />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 3 && (
        <div>
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Distribucion stateParams={stateParams} />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}
    </>
  );
};

export default Index;
