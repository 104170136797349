import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";

import Coronamiento from "./Coronamiento";
import Operational from "./Operational";
import Angle from "./Angle";
import Altura from "./Altura";
import Expired from "./../../../Expired/index";
import { FormattedMessage } from "react-intl";

const Index = ({ state, stateDeposit, stateParams }) => {
  const [selected, setSelected] = useState("");
  const location = useLocation();

  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/ef-params/geometrical/wall") {
      setSelected(1);
    }
    if (location.pathname === "/dashboard/ef-params/geometrical/crown") {
      setSelected(2);
    }
    if (location.pathname === "/dashboard/ef-params/geometrical/operational") {
      setSelected(3);
    }
    if (location.pathname === "/dashboard/ef-params/geometrical/angle") {
      setSelected(4);
    }
  }, [location.pathname]);

  if (
    stateParams.statusParams === 501 ||
    stateParams.statusParams === 401 ||
    stateDeposit.status === 501 ||
    stateDeposit.status === 401 ||
    state.status === 401 ||
    state.status === 501
  ) {
    return <Expired />;
  }

  return (
    <>
      <div className="params-ef">
        <Link
          to="/dashboard/ef-params/geometrical/wall"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(1)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/wall"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte1.altura_muro.valor
                    .length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AlturaMuro" />
          </Button>
        </Link>
        <Link
          to="/dashboard/ef-params/geometrical/crown"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(2)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/crown"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte1
                    .ancho_de_coronamiento.valor.length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AnchoCoronamiento" />
          </Button>
        </Link>
        <Link
          to="/dashboard/ef-params/geometrical/operational"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(3)}
            className={
              location.pathname ===
              "/dashboard/ef-params/geometrical/operational"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte1.revancha_operacional
                    .valor.length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="RevanchaOperacional" />
          </Button>
        </Link>
        <Link
          to="/dashboard/ef-params/geometrical/angle"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(4)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/angle"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte1.angulo.valor
                    .length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AnguloMuro" />
          </Button>
        </Link>
      </div>

      {selected === 1 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Altura
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 2 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Coronamiento
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 3 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Operational
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 4 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Angle
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}
    </>
  );
};

export default Index;
