import initialState from "./initialState";
import {
  GET_USERS_TO_APROVE_INIT,
  GET_USERS_TO_APROVE_SUCCESS,
  GET_USERS_TO_APROVE_FAILURE,
  RESET_USERS_TO_APROVE,
  APROVE_USER_INIT,
  APROVE_USER_SUCCESS,
  APROVE_USER_FAILURE,
  RESET_APROVE_USER,
  DISCARD_USER_INIT,
  DISCARD_USER_SUCCESS,
  DISCARD_USER_FAILURE,
  RESET_DISCARD_USER,
} from "../../actions/aprove/types";

export default function aprove(state = initialState, action) {
  switch (action.type) {
    // GET USERS TO APROVE
    case RESET_USERS_TO_APROVE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case GET_USERS_TO_APROVE_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_TO_APROVE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_USERS_TO_APROVE_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };

    //   APROVE USER
    case RESET_APROVE_USER:
      return {
        ...state,
        dataAprove: {},
        loadingAprove: false,
        statusAprove: 0,
      };
    case APROVE_USER_INIT:
      return {
        ...state,
        loadingAprove: true,
      };
    case APROVE_USER_SUCCESS:
      return {
        ...state,
        dataAprove: action.payload.data,
        loadingAprove: false,
        statusAprove: action.payload.status,
      };
    case APROVE_USER_FAILURE:
      return {
        ...state,
        dataAprove: {},
        loadingAprove: false,
        statusAprove: 501,
      };

    //   DISCARD USER
    case RESET_DISCARD_USER:
      return {
        ...state,
        dataDiscard: {},
        loadingDiscard: false,
        statusDiscard: 0,
      };
    case DISCARD_USER_INIT:
      return {
        ...state,
        loadingDiscard: true,
      };
    case DISCARD_USER_SUCCESS:
      return {
        ...state,
        dataDiscard: action.payload.data,
        loadingDiscard: false,
        statusDiscard: action.payload.status,
      };
    case DISCARD_USER_FAILURE:
      return {
        ...state,
        dataDiscard: {},
        loadingDiscard: false,
        statusDiscard: 501,
      };

    default:
      return state;
  }
}
