import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Dropdown,
  Input,
  Checkbox,
  TextArea,
  Button,
  Table,
  Icon,
  Image,
} from "semantic-ui-react";

import {
  updateParams,
  resetUpdateParams,
  getHistoricParams,
  deleteHistoricParams,
  resetDeleteHistoricParams,
  resetHistoricParams,
} from "../../../../actions/params";

import ModalActions from "../../../Modal/ModalActions";
import ohError from "../../../../assets/icons/ohhh_error.png";
import Loading from "../../../Loading";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";
import Divider from "../../../Divider";
import historic from "../../../../assets/icons/icono_historia.png";
import { FormattedMessage, useIntl } from "react-intl";

const Coronamiento = ({ stateParams }) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    nro_filas: stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
      .tabla.parte_variable
      ? stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento.tabla
          .parte_variable.length
      : 0,

    comentario: "",
  });

  let servicio = localStorage.getItem("servicio");

  const [formTable, setFormTable] = useState([]);

  const [datos, setDatos] = useState();

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  // DATA MURO
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("");

  // NEW DATA
  const [newData, setNewData] = useState("");

  // CHECKED
  const [checked, setChecked] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalHistoric, setShowModalHistoric] = useState(false);

  const [id, setId] = useState("");

  const onShowModal = () => setShowModal(true);
  const onShowModalDelete = (id) => {
    setId(id);
    setShowModalDelete(true);
  };

  const onShowModalHistoric = () => setShowModalHistoric(true);

  useEffect(() => {
    dispatch(
      getHistoricParams({
        periodo_id: stateParams.dataParams.datos.periodo.id,
        sistema: {
          modelo:
            stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
              .tabla.sistema.modelo,
          corresponde_a:
            stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
              .tabla.sistema.corresponde_a,
        },
      })
    );
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetHistoricParams());
    };
  }, [dispatch]);

  // DATA MURO
  useEffect(() => {
    const arr = [];
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setData(arr);
  }, [stateParams.statusParams, stateParams.dataParams]);

  const handleChangeCheckbox = (obj) => {
    const arr = [...checked];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setChecked(arr);
  };

  const headerTable = (arr) => {
    let col = 0;

    // eslint-disable-next-line array-callback-return
    Object.keys(arr).map((item, i) => {
      if (item.includes("col")) {
        col++;
      }
    });
    return col;
  };

  const handleChangeTable = (index, col, e) => {
    let newFormTable = [...formTable];
    newFormTable[index][col] = e.target.value;
    setFormTable(newFormTable);
  };

  const handleDateChange = (date, index) => {
    const formattedDate = format(date, "yyyy-MM-dd");

    const newFormTable = [...formTable];
    newFormTable[index].col5 = formattedDate;
    setFormTable(newFormTable);
  };

  // Cargar el numero de filas ingresadas en el input
  useEffect(() => {
    if (
      stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
        .parte_variable
    ) {
      setForm({
        nro_filas:
          stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
            .parte_variable.length,
      });
    }
  }, [stateParams.dataParams]);

  // Agregar filas a la tabla
  useEffect(() => {
    const arrFormTable = stateParams.dataParams.datos.datos.parte1
      .ancho_de_coronamiento.tabla.parte_variable
      ? [
          ...stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
            .tabla.parte_variable,
        ]
      : [...formTable];
    const arr = [];

    const number =
      form.nro_filas === 0 || form.nro_filas === "" ? 0 : form.nro_filas;

    for (let i = 0; i < number; i++) {
      if (arrFormTable[i]) {
        arr.push(arrFormTable[i]);
      } else {
        arr.push({
          col1: i + 1,
          col2: "",
          col3: "",
          col4: "",
          col5: "",
        });
      }
    }

    setFormTable(arr);
    // eslint-disable-next-line
  }, [form.nro_filas]);

  //   ACTUALIZAR LA INFORMACION
  const handleClickUpdate = () => {
    const arr = [];
    formTable.forEach((item) => {
      if (
        headerTable(
          stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento.tabla
            .datos_duro.linea2
        ) > 4
      ) {
        arr.push([item.col1, item.col2, item.col3, item.col4, item.col5]);
      } else {
        arr.push([item.col1, item.col2, item.col3, item.col4]);
      }
    });

    dispatch(
      updateParams({
        periodo_id: stateParams.dataParams.datos.periodo.id,
        datos_recopilados: [
          {
            sistema: {
              modelo: "AnchoDeCoronamiento",
              corresponde_a: null,
            },
            opcion_informacion_id: dataId,
            documentos_que_respaldan: checked,
            comentario: form.comentario,
            parte_variable: arr,
          },
        ],
      })
    );
  };

  // Setear datos a false si hay mas de un dato en parte variable de la tabla
  useEffect(() => {
    if (
      stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
        .sin_datos === false
    ) {
      setDatos(false);
    }
  }, [stateParams.dataParams]);

  // CARGAR EL DROPDOWN SI HAY INFORMACION
  useEffect(() => {
    if (stateParams.statusParams === 200) {
      if (datos === false) {
        if (data.length > 0) {
          const dataSelected = data.find(
            (element) => element.seleccionado === "true"
          );
          setNewData(dataSelected.value);
        }
      }
    }

    // eslint-disable-next-line
  }, [data]);

  // CARGAR LA DATA SI VIENE INFORMACION DESDE LA BD
  useEffect(() => {
    const callsAPI = async () => {
      if (stateParams.statusParams === 200) {
        if (stateParams.dataParams.ejecucion.estado) {
          if (datos === false) {
            setForm({
              nro_filas: Array.isArray(
                stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
                  .tabla.parte_variable
              )
                ? stateParams.dataParams.datos.datos.parte1
                    .ancho_de_coronamiento.tabla.parte_variable.length
                : 0,
              comentario:
                stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
                  .tabla.comentario !== null
                  ? stateParams.dataParams.datos.datos.parte1
                      .ancho_de_coronamiento.tabla.comentario
                  : "",
            });
          }
        }

        setDataId(newData);

        const arrTabla = [];

        stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento.documentos_que_respaldan?.map(
          (e) => e.seleccionado === true && arrTabla.push(e.clave)
        );

        setChecked(arrTabla);
      }
    };
    callsAPI();
    // eslint-disable-next-line
  }, [stateParams.statusParams, stateParams.dataParams, newData, datos]);

  const resetData = () => {
    dispatch(resetUpdateParams());
  };

  const resetDataDelete = () => {
    dispatch(resetDeleteHistoricParams());
    dispatch(
      getHistoricParams({
        periodo_id: stateParams.dataParams.datos.periodo.id,
        sistema: {
          modelo:
            stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
              .tabla.sistema.modelo,
          corresponde_a:
            stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
              .tabla.sistema.corresponde_a,
        },
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteHistoricParams({
        id: id,
        sistema: {
          modelo:
            stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
              .tabla.sistema.modelo,
          corresponde_a:
            stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
              .tabla.sistema.corresponde_a,
        },
      })
    );
  };

  const intl = useIntl();
  const placeholderSeleccione = intl.formatMessage({ id: "Seleccione" });
  const placeholderComentario = intl.formatMessage({ id: "Comentario" });

  const renderCeldas = (params) => {
    if (params) {
      const arr = [];
      const number =
        form.nro_filas === 0 || form.nro_filas === "" ? 0 : form.nro_filas;

      if (formTable.length === parseInt(number)) {
        for (let i = 0; i < number; i++) {
          arr.push(
            <Table.Row key={i} className="table__matrix5__row-body">
              <Table.Cell colSpan={115}>
                {params[i] ? params[i].col1 : i + 1}
              </Table.Cell>
              <Table.Cell colSpan={115}>
                <Input
                  name={`valor_${i}`}
                  type="text"
                  onChange={(e) => {
                    let index = parseInt(e.target.id, 10);
                    handleChangeTable(index, "col2", e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={formTable[i]?.col2}
                  id={i}
                  disabled={servicio === "true" ? true : false}
                />
              </Table.Cell>
              <Table.Cell colSpan={115}>
                <Input
                  name={`valor_${i}`}
                  type="text"
                  onChange={(e) => {
                    let index = parseInt(e.target.id, 10);
                    handleChangeTable(index, "col3", e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={formTable[i]?.col3}
                  id={i}
                  disabled={servicio === "true" ? true : false}
                />
              </Table.Cell>

              <Table.Cell colSpan={115}>
                <Input
                  name={`valor_${i}`}
                  type="number"
                  onChange={(e) => {
                    let index = parseInt(e.target.id, 10);
                    handleChangeTable(index, "col4", e);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={formTable[i]?.col4}
                  id={i}
                  disabled={servicio === "true" ? true : false}
                />
              </Table.Cell>

              {headerTable(
                stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
                  .tabla.datos_duro.linea2
              ) > 4 && (
                <Table.Cell colSpan={115}>
                  <DatePicker
                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    selected={
                      formTable[i]?.col5 ? parseISO(formTable[i]?.col5) : null
                    }
                    onChange={(date) => handleDateChange(date, i)}
                    className="datepicker-controles"
                    disabled={servicio === "true" ? true : false}
                  />
                </Table.Cell>
              )}
            </Table.Row>
          );
        }
      }

      return arr;
    }
    return null;
  };

  return (
    <div className="params-ef__data">
      {stateParams.dataParams.ejecucion.estado ? (
        stateParams.loadingHistoricParams ? (
          <Loading />
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Dropdown
                placeholder={placeholderSeleccione}
                selection
                options={data}
                value={datos === false ? newData : dataId}
                onChange={(_, data) => {
                  setNewData(data.value);
                  setDataId(data.value);
                }}
                disabled={servicio === "true" ? true : false}
              />
              {stateParams.statusHistoricParams === 200 &&
                stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento
                  .tabla.parte_variable.length > 0 && (
                  <Image
                    src={historic}
                    className="icon-historic"
                    onClick={onShowModalHistoric}
                  />
                )}
            </div>

            {dataId === 1 || newData === 1 ? (
              <>
                <div>
                  <div>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                      <FormattedMessage id="CantidadPuntosControl" />
                    </p>
                    <Input
                      name="nro_filas"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => handleChange(e)}
                      maxLength="2"
                      value={form.nro_filas}
                      disabled={servicio === "true" ? true : false}
                    />
                  </div>

                  <Table celled fixed className="table__matrix5">
                    <Table.Header className="table__matrix5__row-header">
                      {stateParams.dataParams.datos.datos.parte1
                        .ancho_de_coronamiento.tabla.datos_duro?.linea1 && (
                        <>
                          <Table.Row>
                            {Object.keys(
                              stateParams.dataParams.datos.datos.parte1
                                .ancho_de_coronamiento.tabla.datos_duro.linea1
                            ).map(
                              // eslint-disable-next-line array-callback-return
                              (item, i) => {
                                if (item.includes("col")) {
                                  return (
                                    <Table.HeaderCell
                                      colSpan={`${
                                        115 *
                                        stateParams.dataParams.datos.datos
                                          .parte1.ancho_de_coronamiento.tabla
                                          .datos_duro.linea1[item].ancho
                                      }`}
                                      key={`${stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento.tabla.datos_duro.linea1[item].label}-linea1`}
                                    >
                                      {
                                        stateParams.dataParams.datos.datos
                                          .parte1.ancho_de_coronamiento.tabla
                                          .datos_duro.linea1[item].label
                                      }
                                    </Table.HeaderCell>
                                  );
                                }
                              }
                            )}
                          </Table.Row>

                          <Table.Row>
                            {Object.keys(
                              stateParams.dataParams.datos.datos.parte1
                                .ancho_de_coronamiento.tabla.datos_duro.linea2
                            ).map(
                              // eslint-disable-next-line array-callback-return
                              (item, i) => {
                                if (item.includes("col")) {
                                  return (
                                    <Table.HeaderCell
                                      colSpan={`${
                                        115 *
                                        stateParams.dataParams.datos.datos
                                          .parte1.ancho_de_coronamiento.tabla
                                          .datos_duro.linea2[item].ancho
                                      }`}
                                      key={`${stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento.tabla.datos_duro.linea2[item].label}-linea1`}
                                    >
                                      {
                                        stateParams.dataParams.datos.datos
                                          .parte1.ancho_de_coronamiento.tabla
                                          .datos_duro.linea2[item].label
                                      }
                                    </Table.HeaderCell>
                                  );
                                }
                              }
                            )}
                          </Table.Row>
                        </>
                      )}
                    </Table.Header>
                    <Table.Body>
                      {renderCeldas(
                        stateParams.dataParams.datos.datos.parte1
                          .ancho_de_coronamiento.tabla.parte_variable
                      )}
                    </Table.Body>
                  </Table>
                </div>

                <p style={{ marginTop: "10px" }}>
                  <FormattedMessage id="DocumentosRespaldan" />
                </p>
                <div className="matrix2__documentos_respaldo">
                  {stateParams.dataParams.datos.datos.parte1.ancho_de_coronamiento.documentos_que_respaldan.map(
                    (item, i) => {
                      let index = checked.includes(item.clave);
                      return (
                        <Checkbox
                          key={i}
                          label={item.label}
                          onChange={() => handleChangeCheckbox(item)}
                          value={item.clave}
                          checked={index}
                          disabled={
                            servicio === "true"
                              ? true
                              : !item.editable
                              ? true
                              : false
                          }
                        />
                      );
                    }
                  )}
                </div>
              </>
            ) : null}

            {(dataId !== "" || newData !== "") && (
              <>
                <TextArea
                  placeholder={placeholderComentario}
                  name="comentario"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ width: "100%", marginTop: "20px" }}
                  value={form.comentario}
                  disabled={servicio === "true" ? true : false}
                  maxLength="1000"
                />

                {servicio === "false" && (
                  <div
                    className="btn-update-matrix1"
                    style={{ marginTop: "15px" }}
                  >
                    <Button onClick={onShowModal}>
                      <FormattedMessage id="GuardarMayus" />
                    </Button>
                  </div>
                )}

                <ModalActions
                  title={<FormattedMessage id="ActualizarAnchoCoronamiento" />}
                  show={showModal}
                  setShow={setShowModal}
                  status={stateParams.statusUpdateParams}
                  action={handleClickUpdate}
                  reset={resetData}
                  deleteAction={"Delete"}
                  estado={stateParams.dataUpdateParams}
                  loading={stateParams.loadingParams}
                >
                  {stateParams.statusUpdateParams === 0 ? (
                    <FormattedMessage id="EstaSeguroActualizarInformacion" />
                  ) : stateParams.dataUpdateParams.ejecucion.estado ? (
                    <div className="container-modal">
                      <p className="message-ok">
                        {stateParams.dataUpdateParams.ejecucion.mensaje}
                      </p>
                      <Icon name="checkmark" className="status-ok" />
                    </div>
                  ) : (
                    <div className="container-modal">
                      <p className="message-fail">
                        {stateParams.dataUpdateParams.ejecucion.mensaje}
                      </p>
                      <Image src={ohError} className="status-fail" />
                    </div>
                  )}
                </ModalActions>

                <ModalActions
                  title={
                    stateParams.dataParams.datos.datos.parte1
                      .ancho_de_coronamiento.label
                  }
                  show={showModalHistoric}
                  setShow={setShowModalHistoric}
                  status={stateParams.statusHistoricParams}
                  action={() => {}}
                  reset={() => {}}
                  estado={stateParams.dataHistoricParams}
                  loading={stateParams.loadingHistoricParams}
                  matricesOk={true}
                >
                  {stateParams.statusHistoricParams === 200 &&
                    stateParams.dataHistoricParams.ejecucion.estado && (
                      <div
                        style={{
                          maxHeight: "30vh",
                          minHeight: "30vh",
                          overflowY: "scroll",
                        }}
                      >
                        <Divider />
                        <Table>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>ID</Table.HeaderCell>
                              <Table.HeaderCell>
                                <FormattedMessage id="PuntoControl" />
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <FormattedMessage id="UTMEste" />
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <FormattedMessage id="UTMNorte" />
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <FormattedMessage id="FechaControl" />
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <FormattedMessage id="Valor" />
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <FormattedMessage id="CreadoPor" />
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                <FormattedMessage id="Eliminar" />
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          {stateParams.dataHistoricParams.datos.map((e, i) => (
                            <Table.Body key={i}>
                              <Table.Row>
                                <Table.Cell>{e[0]}</Table.Cell>
                                <Table.Cell>{e[1]}</Table.Cell>
                                <Table.Cell>{e[2]}</Table.Cell>
                                <Table.Cell>{e[3]}</Table.Cell>
                                <Table.Cell>{e[4]}</Table.Cell>
                                <Table.Cell>{e[5]}</Table.Cell>
                                <Table.Cell>{e[7]}</Table.Cell>
                                <Table.Cell>
                                  <Icon
                                    name="trash"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "15px",
                                    }}
                                    onClick={() => onShowModalDelete(e[0])}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          ))}
                        </Table>
                      </div>
                    )}
                </ModalActions>

                <ModalActions
                  title={<FormattedMessage id="EliminarRegistroHistorico" />}
                  show={showModalDelete}
                  setShow={setShowModalDelete}
                  status={stateParams.statusDelete}
                  action={handleDelete}
                  reset={resetDataDelete}
                  estado={stateParams.dataDelete}
                  deleteAction={"Delete"}
                  loading={stateParams.loadingDelete}
                  matricesOk={true}
                >
                  {stateParams.statusDelete === 0 ? (
                    <FormattedMessage id="EstaSeguroEliminarRegistroHistorico" />
                  ) : stateParams.dataDelete.ejecucion.estado ? (
                    <div className="container-modal">
                      <p className="message-ok">
                        {stateParams.dataDelete.ejecucion.mensaje}
                      </p>
                      <Icon name="checkmark" className="status-ok" />
                    </div>
                  ) : (
                    <div className="container-modal">
                      <p className="message-fail">
                        {stateParams.dataDelete.ejecucion.mensaje}
                      </p>
                      <Image src={ohError} className="status-fail" />
                    </div>
                  )}
                </ModalActions>
              </>
            )}
          </div>
        )
      ) : (
        <div>{stateParams.dataParams.ejecucion.mensaje}</div>
      )}
    </div>
  );
};

export default Coronamiento;
