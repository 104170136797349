import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Dropdown,
  Input,
  Checkbox,
  TextArea,
  Button,
  Icon,
  Image,
} from "semantic-ui-react";
import { updateParams, resetUpdateParams } from "../../../../actions/params";

import ModalActions from "../../../Modal/ModalActions";
import ohError from "../../../../assets/icons/ohhh_error.png";
import PN from "../CompactacionTablas/PN";
import DR from "../CompactacionTablas/DR";
import PM from "../CompactacionTablas/PM";
import { FormattedMessage, useIntl } from "react-intl";

const Compactacion = ({ stateParams }) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    nro_sector: stateParams.dataParams.datos.datos.compactacion_muro_embalse
      .nivel.tablas
      ? stateParams.dataParams.datos.datos.compactacion_muro_embalse.nivel
          .tablas?.length
      : 0,
  });

  const minero = localStorage.getItem("minero");

  const [newForm, setNewForm] = useState({
    comentarioCompactacion: "",
    comentarioNivel: "",
    herramienta: "",
  });

  const [formulario, setFormulario] = useState([]);
  const [memoriaFormulario, setMemoriaFormulario] = useState([]);

  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleChangeForm = (event) => {
    setNewForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const [datos, setDatos] = useState();

  // DATA
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("");

  const [dataNivel, setDataNivel] = useState([]);
  const [dataNivelId, setDataNivelId] = useState("");

  const [dataDrop, setDataDrop] = useState([]);
  const [dataDropId, setDataDropId] = useState("");

  const [dataMetodo, setDataMetodo] = useState([]);
  const [dataMetodoId, setDataMetodoId] = useState("");

  // NEW DATA
  const [newData, setNewData] = useState("");
  const [newDataDrop, setNewDataDrop] = useState("");
  const [newDataMetodo, setNewDataMetodo] = useState("");
  const [newDataNivel, setNewDataNivel] = useState("");

  // CHECKED
  const [checked, setChecked] = useState([]);
  const [checkedNivel, setCheckedNivel] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  // DROP NIVEL
  useEffect(() => {
    const arr = [];
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        stateParams.dataParams.datos.datos.compactacion_muro_embalse.nivel.dropdown_ingreso.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataNivel(arr);
  }, [stateParams.statusParams, stateParams.dataParams]);

  // DATA DROPDOWN
  useEffect(() => {
    const arr = [];
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        stateParams.dataParams.datos.datos.compactacion_muro_embalse.metodo_compactacion.dropdown_ingreso.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setData(arr);
  }, [stateParams.statusParams, stateParams.dataParams]);

  // DATA DROPDOWN FIJO
  useEffect(() => {
    const arr = [];
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        stateParams.dataParams.datos.datos.compactacion_muro_embalse.nivel.referencia_para_control_seleccionada.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataDrop(arr);
  }, [stateParams.statusParams, stateParams.dataParams]);

  // DATA METODO
  useEffect(() => {
    const arr = [];
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        stateParams.dataParams.datos.datos.compactacion_muro_embalse.metodo_compactacion.metodo_compactacion.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataMetodo(arr);
  }, [stateParams.statusParams, stateParams.dataParams]);

  const handleChangeCheckbox = (obj) => {
    const arr = [...checked];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setChecked(arr);
  };

  const handleChangeCheckboxNivel = (obj) => {
    const arr = [...checkedNivel];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedNivel(arr);
  };

  useEffect(() => {
    const arrForm = [];

    for (
      let i = 0;
      i < parseInt(form.nro_sector === "" ? 0 : form.nro_sector, 10);
      i++
    ) {
      if (memoriaFormulario[i]) {
        arrForm.push(memoriaFormulario[i]);
      } else {
        arrForm.push({
          index: i,
          value: "",
          rows: [],
        });
      }
    }

    setFormulario(arrForm);
    // eslint-disable-next-line
  }, [form.nro_sector]);

  const handleClickUpdate = () => {
    const newArr = [];

    if (dataDropId !== 52) {
      formulario.forEach((item, i) =>
        item.rows.forEach((e) => {
          newArr.push({
            sector: item.index + 1,
            valores: [
              [
                e.col1,
                e.col2,
                e.col3,
                e.col4,
                e.col5,
                e.col6,
                e.col7,
                e.col8,
                (e.col9 = 100 * (e.col7 / e.col5)),
              ],
            ],
          });
        })
      );
    } else {
      formulario.forEach((item, i) =>
        item.rows.forEach((e) => {
          newArr.push({
            sector: item.index + 1,
            valores: [
              [
                e.col1,
                e.col2,
                e.col3,
                e.col4,
                e.col5,
                e.col6,
                e.col7,
                e.col8,
                (e.col9 =
                  100 *
                  ((e.col5 * (e.col7 - e.col6)) /
                    (e.col7 * (e.col5 - e.col6)))),
              ],
            ],
          });
        })
      );
    }

    dispatch(
      updateParams({
        periodo_id: stateParams.dataParams.datos.periodo.id,
        datos_recopilados: [
          {
            sistema: {
              modelo:
                stateParams.dataParams.datos.datos.compactacion_muro_embalse
                  .nivel.plantilla.sistema.modelo,
              corresponde_a:
                stateParams.dataParams.datos.datos.compactacion_muro_embalse
                  .nivel.plantilla.sistema.corresponde_a,
            },
            metodo_compactacion: {
              metodo_compactacion_id: dataMetodoId,
              metodo_nombre_otro: newForm.herramienta,
              opcion_informacion_id: dataId,
              documentos_que_respaldan: checked,

              comentario: newForm.comentarioCompactacion,
            },
            nivel: {
              referencia_para_control_seleccionada_id: dataDropId,
              opcion_informacion_id: dataNivelId,
              documentos_que_respaldan: checkedNivel,
              comentario: newForm.comentarioNivel,
              parte_variable: newArr,
            },
          },
        ],
      })
    );
  };

  //   SETER DATOS EN FALSE POR SI HAY DATA EN BD
  useEffect(() => {
    if (
      stateParams.dataParams.datos.datos.compactacion_muro_embalse.nivel
        .sin_datos === false
    ) {
      setDatos(false);
    }
  }, [stateParams.dataParams]);

  // CARGAR EL DROPDOWN SI HAY INFORMACION
  useEffect(() => {
    if (stateParams.statusParams === 200) {
      if (datos === false) {
        if (data.length > 0) {
          const dataSelected = data.find(
            (element) => element.seleccionado === "true"
          );
          setNewData(dataSelected.value);
        }

        if (dataMetodo.length > 0) {
          const dataMetodoSelected = dataMetodo.find(
            (element) => element.seleccionado === "true"
          );
          setNewDataMetodo(dataMetodoSelected.value);
        }

        if (dataNivel.length > 0) {
          const dataNivelSelected = dataNivel.find(
            (element) => element.seleccionado === "true"
          );
          setNewDataNivel(dataNivelSelected.value);
        }
      }
    }

    // eslint-disable-next-line
  }, [data, dataMetodo]);

  useEffect(() => {
    if (stateParams.statusParams === 200) {
      if (dataDrop.length > 0) {
        const dataSelected = dataDrop.find((e) => e.seleccionado === "true");

        if (dataSelected !== undefined) {
          setNewDataDrop(dataSelected.text);
          setDataDropId(dataSelected.value);
        }
      }
    }

    // eslint-disable-next-line
  }, [dataDrop]);

  // CARGAR LA DATA SI VIENE INFORMACION DESDE LA BD
  useEffect(() => {
    const callsAPI = async () => {
      if (stateParams.statusParams === 200) {
        if (stateParams.dataParams.ejecucion.estado) {
          if (datos === false) {
            if (formulario.length > 0) {
              const arr = [];
              for (let i = 0; i < formulario.length; i++) {
                arr.push({
                  index: i,
                  value:
                    stateParams.dataParams.datos.datos.compactacion_muro_embalse
                      .nivel?.tablas[i].parte_variable.length,
                  rows: stateParams.dataParams.datos.datos
                    .compactacion_muro_embalse.nivel?.tablas[i].parte_variable,
                });

                setFormulario(arr);
              }
            }
            setNewForm({
              comentarioCompactacion:
                stateParams.dataParams.datos.datos.compactacion_muro_embalse
                  .metodo_compactacion.comentario !== null
                  ? stateParams.dataParams.datos.datos.compactacion_muro_embalse
                      .metodo_compactacion.comentario
                  : "",
              comentarioNivel:
                stateParams.dataParams.datos.datos.compactacion_muro_embalse
                  .nivel.comentario !== null
                  ? stateParams.dataParams.datos.datos.compactacion_muro_embalse
                      .nivel.comentario
                  : "",
            });
          }
        }

        setDataId(newData);
        setDataMetodoId(newDataMetodo);
        setDataNivelId(newDataNivel);

        const arrTabla = [];
        const arrNivel = [];

        stateParams.dataParams.datos.datos.compactacion_muro_embalse.metodo_compactacion.documentos_que_respaldan?.map(
          (e) => e.seleccionado === true && arrTabla.push(e.clave)
        );
        stateParams.dataParams.datos.datos.compactacion_muro_embalse.nivel.documentos_que_respaldan?.map(
          (e) => e.seleccionado === true && arrNivel.push(e.clave)
        );

        setChecked(arrTabla);
        setCheckedNivel(arrNivel);
      }
    };
    callsAPI();
    // eslint-disable-next-line
  }, [stateParams.statusParams, stateParams.dataParams, newData, datos]);

  const resetData = () => {
    dispatch(resetUpdateParams());
  };

  const intl = useIntl();
  const placeholderNoSelecciono = intl.formatMessage({
    id: "NoSeSeleccionoNivelCompactacion",
  });
  const placeholderSeleccione = intl.formatMessage({ id: "Seleccione" });
  const placeholderEspecifique = intl.formatMessage({
    id: "EspecifiqueHerramienta",
  });
  const placeholderComentario = intl.formatMessage({ id: "Comentario" });
  const placeholderCantidadSector = intl.formatMessage({
    id: "IngreseCantidadSector",
  });

  // Re renderizando componentes por sector
  const renderTabla = (index) => {
    const arr = [];

    let arreglo = [...formulario];

    let number = parseInt(
      arreglo[index].value === "" ? 0 : arreglo[index].value,
      10
    );
    for (let i = 0; i < number; i++) {
      arr.push(i);
    }

    return (
      <div key={index}>
        <div>
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            <FormattedMessage id="CantidadPuntosControl" />
          </p>
          <Input
            name="nro_filas"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              let arreglo = [...formulario];
              let columnas = [];
              arreglo[index].value = e.target.value;
              let numero = parseInt(
                e.target.value === "" ? 0 : e.target.value,
                10
              );

              for (let i = 0; i < numero; i++) {
                if (arreglo[index].rows[i]) {
                  columnas.push({ ...arreglo[index].rows[i] });
                } else {
                  columnas.push({
                    col1: "",
                    col2: "",
                    col3: "",
                    col4: "",
                    col5: "",
                    col6: "",
                    col7: "",
                    col8: "",
                    col9: "",
                  });
                }
              }
              arreglo[index].rows = columnas;
              setFormulario(arreglo);
            }}
            maxLength="2"
            value={formulario[index]?.value}
            disabled={minero === "false" ? true : false}
          />
        </div>

        {dataDropId === 50 && (
          <PN
            stateParams={stateParams}
            arreglo={arreglo}
            index={index}
            formulario={formulario}
            setFormulario={setFormulario}
            setError={setError}
          />
        )}

        {dataDropId === 51 && (
          <PM
            stateParams={stateParams}
            arreglo={arreglo}
            index={index}
            formulario={formulario}
            setFormulario={setFormulario}
            setError={setError}
          />
        )}

        {dataDropId === 52 && (
          <DR
            stateParams={stateParams}
            arreglo={arreglo}
            index={index}
            formulario={formulario}
            setFormulario={setFormulario}
            setError={setError}
          />
        )}
      </div>
    );
  };

  return (
    <div className="params-ef__data">
      {stateParams.dataParams.ejecucion.estado ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Input value={newDataDrop} placeholder={placeholderNoSelecciono} />

            <p>
              <FormattedMessage id="MetodoCompactacion" />
            </p>
            <Dropdown
              placeholder={placeholderSeleccione}
              selection
              options={data}
              value={datos === false ? newData : dataId}
              onChange={(_, data) => {
                setNewData(data.value);
                setDataId(data.value);
              }}
              disabled={minero === "false" ? true : false}
            />
          </div>

          {(dataId === 1 || newData === 1) && (
            <>
              <div>
                <p style={{ marginTop: "10px" }}>
                  <FormattedMessage id="Herramienta" />
                </p>
                <Dropdown
                  placeholder={placeholderSeleccione}
                  selection
                  options={dataMetodo}
                  value={datos === false ? newDataMetodo : dataMetodoId}
                  onChange={(_, data) => {
                    setNewDataMetodo(data.value);
                    setDataMetodoId(data.value);
                  }}
                  disabled={minero === "false" ? true : false}
                />

                {(dataMetodoId === 73 || newDataMetodo === 73) && (
                  <Input
                    name="herramienta"
                    onChange={(e) => handleChangeForm(e)}
                    placeholder={placeholderEspecifique}
                    value={newForm.herramienta}
                    style={{ padding: "5px" }}
                    disabled={minero === "false" ? true : false}
                  />
                )}
              </div>

              <p style={{ marginTop: "10px" }}>
                <FormattedMessage id="DocumentosRespaldan" />
              </p>
              <div className="matrix2__documentos_respaldo">
                {stateParams.dataParams.datos.datos.compactacion_muro_embalse.metodo_compactacion.documentos_que_respaldan.map(
                  (item, i) => {
                    let index = checked.includes(item.clave);
                    return (
                      <Checkbox
                        key={i}
                        label={item.label}
                        onChange={() => handleChangeCheckbox(item)}
                        value={item.clave}
                        checked={index}
                        disabled={
                          minero === "false"
                            ? true
                            : !item.editable
                            ? true
                            : false
                        }
                      />
                    );
                  }
                )}
              </div>
              <TextArea
                placeholder={placeholderComentario}
                name="comentarioCompactacion"
                onChange={(e) => {
                  handleChangeForm(e);
                }}
                style={{ width: "100%", marginTop: "20px" }}
                value={newForm.comentarioCompactacion}
                disabled={minero === "false" ? true : false}
                maxLength="1000"
              />
            </>
          )}

          <p>
            <FormattedMessage id="NivelCompactacion" />
          </p>
          <Dropdown
            placeholder={placeholderSeleccione}
            selection
            options={dataNivel}
            value={datos === false ? newDataNivel : dataNivelId}
            onChange={(_, data) => {
              setNewDataNivel(data.value);
              setDataNivelId(data.value);
            }}
            disabled={minero === "false" ? true : false}
          />

          {(dataNivelId === 1 || newDataNivel === 1) && (
            <>
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "15px",
                  }}
                >
                  <FormattedMessage id="IngreseCantidadSector" />
                </p>
                <Input
                  name="nro_sector"
                  placeholder={placeholderCantidadSector}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setMemoriaFormulario(formulario);
                    handleChange(e);
                  }}
                  maxLength="2"
                  value={form.nro_sector}
                  disabled={minero === "false" ? true : false}
                />
              </div>

              {formulario.map((e, i) => {
                return renderTabla(i);
              })}

              <p style={{ marginTop: "10px" }}>
                <FormattedMessage id="DocumentosRespaldan" />
              </p>
              <div className="matrix2__documentos_respaldo">
                {stateParams.dataParams.datos.datos.compactacion_muro_embalse.nivel.documentos_que_respaldan.map(
                  (item, i) => {
                    let index = checkedNivel.includes(item.clave);
                    return (
                      <Checkbox
                        key={i}
                        label={item.label}
                        onChange={() => handleChangeCheckboxNivel(item)}
                        value={item.clave}
                        checked={index}
                        disabled={
                          minero === "false"
                            ? true
                            : !item.editable
                            ? true
                            : false
                        }
                      />
                    );
                  }
                )}
              </div>
            </>
          )}

          {dataId !== "" &&
            newData !== "" &&
            dataNivelId !== "" &&
            newDataNivel !== "" && (
              <>
                <TextArea
                  placeholder={placeholderComentario}
                  name="comentarioNivel"
                  onChange={(e) => {
                    handleChangeForm(e);
                  }}
                  style={{ width: "100%", marginTop: "20px" }}
                  value={newForm.comentarioNivel}
                  disabled={minero === "false" ? true : false}
                  maxLength="1000"
                />
                {minero === "true" && (
                  <div
                    className="btn-update-matrix1"
                    style={{ marginTop: "15px" }}
                  >
                    {error &&
                      dataId === 1 &&
                      dataNivelId === 1 &&
                      newData === 1 &&
                      newDataNivel === 1 && (
                        <p style={{ color: "red" }}>
                          <FormattedMessage id="DebeIngresarTodosLosValores" />
                        </p>
                      )}
                    <Button
                      onClick={onShowModal}
                      disabled={
                        error &&
                        dataId === 1 &&
                        dataNivelId === 1 &&
                        newData === 1 &&
                        newDataNivel === 1
                          ? true
                          : false
                      }
                    >
                      <FormattedMessage id="GuardarMayus" />
                    </Button>
                  </div>
                )}
              </>
            )}

          <ModalActions
            title={<FormattedMessage id="ActualizarCompactacion" />}
            show={showModal}
            setShow={setShowModal}
            status={stateParams.statusUpdateParams}
            action={handleClickUpdate}
            reset={resetData}
            deleteAction={"Delete"}
            estado={stateParams.dataUpdateParams}
            loading={stateParams.loadingUpdateParams}
          >
            {stateParams.statusUpdateParams === 0 ? (
              <FormattedMessage id="EstaSeguroActualizarInformacion" />
            ) : stateParams.dataUpdateParams.ejecucion.estado ? (
              <div className="container-modal">
                <p className="message-ok">
                  {stateParams.dataUpdateParams.ejecucion.mensaje}
                </p>
                <Icon name="checkmark" className="status-ok" />
              </div>
            ) : (
              <div className="container-modal">
                <p className="message-fail">
                  {stateParams.dataUpdateParams.ejecucion.mensaje}
                </p>
                <Image src={ohError} className="status-fail" />
              </div>
            )}
          </ModalActions>
        </div>
      ) : (
        <div>{stateParams.dataParams.ejecucion.mensaje}</div>
      )}
    </div>
  );
};

export default Compactacion;
