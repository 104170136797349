export const RESET_ALL_REGIONES = "RESET_ALL_REGIONES";
export const GET_ALL_REGIONES_INIT = "GET_ALL_REGIONES_INIT";
export const GET_ALL_REGIONES_SUCCESS = "GET_ALL_REGIONES_SUCCESS";
export const GET_ALL_REGIONES_FAILURE = "GET_ALL_REGIONES_FAILURE";

export const RESET_ALL_COMUNAS = "RESET_ALL_COMUNAS";
export const GET_ALL_COMUNAS_INIT = "GET_ALL_COMUNAS_INIT";
export const GET_ALL_COMUNAS_SUCCESS = "GET_ALL_COMUNAS_SUCCESS";
export const GET_ALL_COMUNAS_FAILURE = "GET_ALL_COMUNAS_FAILURE";

export const RESET_ALL_PROVIDENCIAS = "RESET_ALL_PROVIDENCIAS";
export const GET_ALL_PROVIDENCIAS_INIT = "GET_ALL_PROVIDENCIAS_INIT";
export const GET_ALL_PROVIDENCIAS_SUCCESS = "GET_ALL_PROVIDENCIAS_SUCCESS";
export const GET_ALL_PROVIDENCIAS_FAILURE = "GET_ALL_PROVIDENCIAS_FAILURE";
