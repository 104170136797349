import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Card, Container, Icon, Image } from "semantic-ui-react";
import DashboardUbication from "../../components/DashboardUbication";
import { Link, useLocation, useParams } from "react-router-dom";

import { getDataDeposit, resetGetDeposits } from "../../actions/deposits";
import Expired from "./../../components/Expired/index";
import DividerGrey from "./../../components/DividerGrey/index";

import folder from "../../assets/icons/icono_folder-red.png";

import Loading from "./../../components/Loading/index";
import { Matrix1 } from "./../../components/Matrices/Matrix1";
import { Matrix2 } from "./../../components/Matrices/Matrix2";
import { Matrix4 } from "./../../components/Matrices/Matrix4";
import { Matrix6 } from "./../../components/Matrices/Matrix6";
import { Matrix7 } from "./../../components/Matrices/Matrix7";
import { Matrix8 } from "./../../components/Matrices/Matrix8";

import NewMatrix3 from "../../components/Matrices/Matrices3/index";
import { matricesOk, resetMatricesOk } from "../../actions/matrices";
import Matriz5 from "../../components/Matrices/Matriz5";
import { FormattedMessage } from "react-intl";

const ParamsLine = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const stateDeposit = useSelector((state) => state.deposit);
  const stateMatrices = useSelector((state) => state.matricesOk);

  const [selected, setSelected] = useState();

  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

  useEffect(() => {
    if (location.pathname === `/dashboard/documents/params/${id}/matriz1`) {
      setSelected(1);
    }

    if (location.pathname === `/dashboard/documents/params/${id}/matriz2`) {
      setSelected(2);
    }

    if (location.pathname === `/dashboard/documents/params/${id}/matriz3`) {
      setSelected(3);
    }

    if (location.pathname === `/dashboard/documents/params/${id}/matriz4`) {
      setSelected(4);
    }

    if (
      location.pathname === `/dashboard/documents/params/${id}/matriz5` ||
      location.pathname ===
        `/dashboard/documents/params/${id}/matriz5/piezometro` ||
      location.pathname === `/dashboard/documents/params/${id}/matriz5/pozo` ||
      location.pathname ===
        `/dashboard/documents/params/${id}/matriz5/distancia` ||
      location.pathname ===
        `/dashboard/documents/params/${id}/matriz5/drenaje_basal` ||
      location.pathname ===
        `/dashboard/documents/params/${id}/matriz5/acelerometro` ||
      location.pathname ===
        `/dashboard/documents/params/${id}/matriz5/inclinometro` ||
      location.pathname ===
        `/dashboard/documents/params/${id}/matriz5/placa_corrimiento` ||
      location.pathname ===
        `/dashboard/documents/params/${id}/matriz5/celda_asentamiento`
    ) {
      setSelected(5);
    }

    if (location.pathname === `/dashboard/documents/params/${id}/matriz6`) {
      setSelected(6);
    }

    if (location.pathname === `/dashboard/documents/params/${id}/matriz7`) {
      setSelected(7);
    }

    if (location.pathname === `/dashboard/documents/params/${id}/matriz8`) {
      setSelected(8);
    }
    //eslint-disable-next-line
  }, [location.pathname]);

  const { state } = location.state;

  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getDataDeposit({
        id: id,
      })
    );
    dispatch(
      matricesOk({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetDeposits());
      dispatch(resetMatricesOk());
    };
  }, [dispatch]);

  if (
    /* When the token is expired*/
    stateDeposit.statusDeposit === 501 ||
    state.status === 501
  ) {
    return <Expired />;
  }

  return (
    <Container>
      <>
        <DashboardUbication>
          {stateDeposit.statusDeposit === 200 ? (
            `${state.dataChore.datos.razon_social}/${state.dataChore.datos.nombre}/${stateDeposit.dataDeposit.datos.nombre}/Parámetros Línea Base`
          ) : (
            <>
              <FormattedMessage id="CargandoMinus" />
            </>
          )}
        </DashboardUbication>
        <DividerGrey />

        <div>
          {stateDeposit.loadingDeposit ? (
            <Loading />
          ) : (
            <div style={{ display: "flex", gap: "15px" }}>
              <CardMatrices
                stateDeposit={stateDeposit}
                id={id}
                state={state}
                selected={selected}
                handleClick={handleClick}
                stateMatrices={stateMatrices}
              />

              {location.pathname ===
                `/dashboard/documents/params/${id}/matriz1` &&
                selected === 1 && <Matrix1 id={id} />}

              {location.pathname ===
                `/dashboard/documents/params/${id}/matriz2` &&
                selected === 2 && <Matrix2 id={id} />}

              {location.pathname ===
                `/dashboard/documents/params/${id}/matriz3` &&
                selected === 3 && <NewMatrix3 id={id} />}

              {location.pathname ===
                `/dashboard/documents/params/${id}/matriz4` &&
                selected === 4 && <Matrix4 id={id} />}

              {(location.pathname ===
                `/dashboard/documents/params/${id}/matriz5` ||
                location.pathname ===
                  `/dashboard/documents/params/${id}/matriz5/piezometro` ||
                location.pathname ===
                  `/dashboard/documents/params/${id}/matriz5/pozo` ||
                location.pathname ===
                  `/dashboard/documents/params/${id}/matriz5/distancia` ||
                location.pathname ===
                  `/dashboard/documents/params/${id}/matriz5/drenaje_basal` ||
                location.pathname ===
                  `/dashboard/documents/params/${id}/matriz5/acelerometro` ||
                location.pathname ===
                  `/dashboard/documents/params/${id}/matriz5/inclinometro` ||
                location.pathname ===
                  `/dashboard/documents/params/${id}/matriz5/placa_corrimiento` ||
                location.pathname ===
                  `/dashboard/documents/params/${id}/matriz5/celda_asentamiento`) &&
                selected === 5 && (
                  <Matriz5 id={id} stateDeposit={stateDeposit} state={state} />
                )}

              {location.pathname ===
                `/dashboard/documents/params/${id}/matriz6` &&
                selected === 6 && <Matrix6 id={id} />}

              {location.pathname ===
                `/dashboard/documents/params/${id}/matriz7` &&
                selected === 7 && <Matrix7 id={id} />}

              {location.pathname ===
                `/dashboard/documents/params/${id}/matriz8` &&
                selected === 8 && <Matrix8 id={id} />}
            </div>
          )}
        </div>
      </>
    </Container>
  );
};

export default ParamsLine;

function CardMatrices({
  stateDeposit,
  id,
  state,
  selected,
  handleClick,
  stateMatrices,
}) {
  let minero = localStorage.getItem("minero");
  return (
    <Container style={{ width: "40%" }}>
      <Card fluid className="card-deposit">
        <Card.Content>
          <div
            className="card-deposit-title"
            style={{ borderBottom: "1px solid #a8b7c7", paddingBottom: "15px" }}
          >
            {stateDeposit.dataDeposit.datos.nombre}
          </div>
        </Card.Content>

        <Card.Content
          style={{ borderTop: "none", borderRight: "2px solid #fe6565" }}
        >
          <div className="card-deposit-container-name">
            <div>
              <Link
                to={`/dashboard/documents/${id}`}
                className="container-card-left-deposit-chore"
                state={{ state: state }}
              >
                <Image
                  src={folder}
                  alt="Icono Usuario"
                  className="icon-folder"
                />
                <div className="card-chore-name-deposits">
                  <FormattedMessage id="ParametrosLineaBase" />
                </div>
              </Link>
            </div>
            <Icon name="chevron down" />
          </div>
        </Card.Content>

        <Card.Content
          style={{
            paddingRight: "5px",
            overflowY: "scroll",
            maxHeight: "45vh",
          }}
        >
          <div className="params__container-matrices">
            {stateMatrices.statusOk === 200 &&
              stateMatrices.dataOk.datos.completitud_lb &&
              minero === "true" && (
                <Link
                  to="/dashboard/ef-params/tailing"
                  state={{ state, stateDeposit }}
                >
                  <Button
                    className="btn-matricesok"
                    style={{
                      cssText: "background-color: #CAFE69 !important",
                    }}
                  >
                    <FormattedMessage id="MatricesOk" />
                  </Button>
                </Link>
              )}
            <Link
              to={`/dashboard/documents/params/${id}/matriz1`}
              state={{ state: state }}
            >
              <Button
                className={selected === 1 ? "selected " : ""}
                onClick={handleClick(1)}
              >
                <FormattedMessage id="Matriz1" />
                {stateMatrices.statusOk === 200 &&
                  stateMatrices.dataOk.datos.linea_base_ingreso.m1 && (
                    <Icon name="check" />
                  )}
              </Button>
            </Link>

            {stateDeposit.dataDeposit.datos.tiene_matriz1 ? (
              <>
                <Link
                  to={`/dashboard/documents/params/${id}/matriz2`}
                  state={{ state: state }}
                >
                  <Button
                    className={selected === 2 ? "selected" : ""}
                    onClick={handleClick(2)}
                  >
                    <FormattedMessage id="Matriz2" />
                    {stateMatrices.statusOk === 200 &&
                      stateMatrices.dataOk.datos.linea_base_ingreso.m2 && (
                        <Icon name="check" />
                      )}
                  </Button>
                </Link>

                <Link
                  to={`/dashboard/documents/params/${id}/matriz3`}
                  state={{ state: state }}
                >
                  <Button
                    className={selected === 3 ? "selected" : ""}
                    onClick={handleClick(3)}
                  >
                    <FormattedMessage id="Matriz3" />
                    {stateMatrices.statusOk === 200 &&
                      stateMatrices.dataOk.datos.linea_base_ingreso.m3 && (
                        <Icon name="check" />
                      )}
                  </Button>
                </Link>

                <Link
                  to={`/dashboard/documents/params/${id}/matriz4`}
                  state={{ state: state }}
                >
                  <Button
                    className={selected === 4 ? "selected" : ""}
                    onClick={handleClick(4)}
                  >
                    <FormattedMessage id="Matriz4" />
                    {stateMatrices.statusOk === 200 &&
                      stateMatrices.dataOk.datos.linea_base_ingreso.m4 && (
                        <Icon name="check" />
                      )}
                  </Button>
                </Link>

                <Link
                  to={`/dashboard/documents/params/${id}/matriz5`}
                  state={{ state: state }}
                >
                  <Button
                    className={selected === 5 ? "selected" : ""}
                    onClick={handleClick(5)}
                  >
                    <FormattedMessage id="Matriz5" />
                    {stateMatrices.statusOk === 200 &&
                      stateMatrices.dataOk.datos.linea_base_ingreso.m5 && (
                        <Icon name="check" />
                      )}
                  </Button>
                </Link>

                <Link
                  to={`/dashboard/documents/params/${id}/matriz6`}
                  state={{ state: state }}
                >
                  <Button
                    className={selected === 6 ? "selected" : ""}
                    onClick={handleClick(6)}
                  >
                    <FormattedMessage id="Matriz6" />
                    {stateMatrices.statusOk === 200 &&
                      stateMatrices.dataOk.datos.linea_base_ingreso.m6 && (
                        <Icon name="check" />
                      )}
                  </Button>
                </Link>

                <Link
                  to={`/dashboard/documents/params/${id}/matriz7`}
                  state={{ state: state }}
                >
                  <Button
                    className={selected === 7 ? "selected" : ""}
                    onClick={handleClick(7)}
                  >
                    <FormattedMessage id="Matriz7" />
                    {stateMatrices.statusOk === 200 &&
                      stateMatrices.dataOk.datos.linea_base_ingreso.m7 && (
                        <Icon name="check" />
                      )}
                  </Button>
                </Link>

                <Link
                  to={`/dashboard/documents/params/${id}/matriz8`}
                  state={{ state: state }}
                >
                  <Button
                    className={selected === 8 ? "selected" : ""}
                    onClick={handleClick(8)}
                  >
                    <FormattedMessage id="Matriz8" />
                    {stateMatrices.statusOk === 200 &&
                      stateMatrices.dataOk.datos.linea_base_ingreso.m8 && (
                        <Icon name="check" />
                      )}
                  </Button>
                </Link>
              </>
            ) : null}
          </div>
        </Card.Content>
      </Card>
    </Container>
  );
}
