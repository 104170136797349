import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  Dropdown,
  Checkbox,
  TextArea,
  Button,
  Icon,
  Image,
} from "semantic-ui-react";

import Loading from "../Loading";
import {
  getMatriz7,
  resetGetMatriz7,
  updateMatriz7,
} from "../../actions/matriz7/index";
import ModalActions from "./../Modal/ModalActions";
import { resetUpdateMatriz7 } from "./../../actions/matriz7/index";
import Expired from "./../Expired/index";
import ohError from "../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

export function Matrix7({ id }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.matriz7);

  const [form, setForm] = useState({
    comentario: "",
  });

  const minero = localStorage.getItem("minero");

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [datos, setDatos] = useState();

  const [dataSismicidad, setDataSismicidad] = useState([]);
  const [dataSismicidadId, setDataSismicidadId] = useState("");

  const [dataCrecidas, setDataCrecidas] = useState([]);
  const [dataCrecidasId, setDataCrecidasId] = useState("");

  const [newDataSismicidad, setNewDataSismicidad] = useState("");
  const [newDataCrecidas, setNewDataCrecidas] = useState("");

  const [checkedSismicidad, setCheckedSismicidad] = useState([]);
  const [checkedCrecidas, setCheckedCrecidas] = useState([]);

  useEffect(() => {
    dispatch(
      getMatriz7({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetMatriz7());
    };
  }, [dispatch]);

  //   DATA SISMICIDAD
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.caracterizacion_sismicidad.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataSismicidad(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxSismicidad = (obj) => {
    const arr = [...checkedSismicidad];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedSismicidad(arr);
  };

  //   DATA CRECIDAS
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.estimacion_crecidas.forEach((e) => {
          arr.push({
            key: e.id,
            value: e.id,
            text: e.glosa,
            seleccionado: e.seleccionado.toString(),
          });
        });
      }
    }
    setDataCrecidas(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxCrecidas = (obj) => {
    const arr = [...checkedCrecidas];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedCrecidas(arr);
  };

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz7({
        deposito_id: id,
        tipo_caracterizacion_sismicidad_id: dataSismicidadId,
        documentos_que_respaldan_sismicidad: checkedSismicidad,
        tipo_estimacion_crecidas_id: dataCrecidasId,
        documentos_que_respaldan_crecidas: checkedCrecidas,
        comentario: form.comentario,
      })
    );
  };

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setForm({
              comentario:
                state.data.datos.datos.comentario.valor !== null
                  ? state.data.datos.datos.comentario.valor
                  : "",
            });

            setDataSismicidadId(newDataSismicidad);
            setDataCrecidasId(newDataCrecidas);

            const arrSismicidad = [];
            const arrCrecidas = [];

            state.data.datos.datos.documentos_que_respaldan_sismicidad.map(
              (e) => e.seleccionado === true && arrSismicidad.push(e.clave)
            );
            setCheckedSismicidad(arrSismicidad);
            state.data.datos.datos.documentos_que_respaldan_crecidas.map(
              (e) => e.seleccionado === true && arrCrecidas.push(e.clave)
            );
            setCheckedCrecidas(arrCrecidas);
          }
        }
      }
    };
    callsAPI();
    // eslint-disable-next-line
  }, [
    state.loading,
    state.status,
    state.data,
    newDataSismicidad,
    newDataCrecidas,
  ]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          if (dataSismicidad.length > 0) {
            const dataSelectedSismicidad = dataSismicidad.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataSismicidad(dataSelectedSismicidad.value);
          }

          if (dataCrecidas.length > 0) {
            const dataSelectedCrecidas = dataCrecidas.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataCrecidas(dataSelectedCrecidas.value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [dataSismicidad, dataCrecidas]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.loading]);

  const resetData = () => {
    dispatch(resetUpdateMatriz7());
  };

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({ id: "IngreseComentario" });

  const placeholderSeleccioneNivelSismicidad = intl.formatMessage({
    id: "SeleccioneNivelSismicidad",
  });
  const placeholderSeleccioneNivelEstimación = intl.formatMessage({
    id: "SeleccioneNivelEstimación",
  });

  /* When the token is expired*/
  if (state.status === 501 || state.status === 401) {
    return <Expired />;
  }

  return (
    <Container>
      {state.loading ? (
        <Loading />
      ) : (
        <Card fluid className="card-deposit">
          {state.data.ejecucion.estado ? (
            <>
              <Card.Content>
                <div
                  className="card-deposit-info"
                  style={{ flexDirection: "row" }}
                >
                  {state.data.datos.datos.titulo_matriz}
                </div>
              </Card.Content>

              <Card.Content className="card-container__matrix2">
                <div>
                  <div className="matrix3__container-nivel">
                    <div>
                      <Dropdown
                        placeholder={placeholderSeleccioneNivelSismicidad}
                        selection
                        options={dataSismicidad}
                        value={
                          datos === false ? newDataSismicidad : dataSismicidadId
                        }
                        onChange={(_, data) => {
                          setDataSismicidadId(data.value);
                          setNewDataSismicidad(data.value);
                        }}
                        style={{ width: "95%" }}
                        disabled={minero === "false" ? true : false}
                      />
                      {dataSismicidadId === "" && newDataSismicidad === "" && (
                        <Icon name="warning" className="icon-warning" />
                      )}
                    </div>
                    <div className="matrix3__container-valor">
                      {dataSismicidadId !== "" &&
                        dataSismicidadId !== 12 &&
                        newDataSismicidad !== "" &&
                        newDataSismicidad !== 12 && (
                          <div className="matrix3__compactacion-docs">
                            <p>
                              {
                                state.data.datos.datos
                                  .titulo_documentos_respaldan_matriz
                              }
                            </p>
                            {state.data.datos.datos.documentos_que_respaldan_sismicidad.map(
                              (item, i) => {
                                let index = checkedSismicidad.includes(
                                  item.clave
                                );
                                return (
                                  <Checkbox
                                    key={i}
                                    label={item.label}
                                    onChange={() =>
                                      handleChangeCheckboxSismicidad(item)
                                    }
                                    value={item.clave}
                                    checked={index}
                                    disabled={
                                      minero === "false"
                                        ? true
                                        : !item.editable
                                        ? true
                                        : false
                                    }
                                  />
                                );
                              }
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                  <div
                    className="matrix3__container-nivel"
                    style={{ marginTop: "15px" }}
                  >
                    <div>
                      <Dropdown
                        placeholder={placeholderSeleccioneNivelEstimación}
                        selection
                        options={dataCrecidas}
                        value={
                          datos === false ? newDataCrecidas : dataCrecidasId
                        }
                        onChange={(_, data) => {
                          setDataCrecidasId(data.value);
                          setNewDataCrecidas(data.value);
                        }}
                        style={{ width: "95%" }}
                        disabled={minero === "false" ? true : false}
                      />
                      {dataCrecidasId === "" && dataCrecidasId === "" && (
                        <Icon name="warning" className="icon-warning" />
                      )}
                    </div>
                    <div className="matrix3__container-valor">
                      {dataCrecidasId !== "" && dataCrecidasId !== 23 && (
                        <div className="matrix3__compactacion-docs">
                          <p>
                            {
                              state.data.datos.datos
                                .titulo_documentos_respaldan_matriz
                            }
                          </p>
                          {state.data.datos.datos.documentos_que_respaldan_crecidas.map(
                            (item, i) => {
                              let index = checkedCrecidas.includes(item.clave);
                              return (
                                <Checkbox
                                  key={i}
                                  label={item.label}
                                  onChange={() =>
                                    handleChangeCheckboxCrecidas(item)
                                  }
                                  value={item.clave}
                                  checked={index}
                                  disabled={
                                    minero === "false"
                                      ? true
                                      : !item.editable
                                      ? true
                                      : false
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>

                    {dataSismicidadId !== "" && dataCrecidasId !== "" && (
                      <>
                        <TextArea
                          placeholder={placeholderComentario}
                          name="comentario"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{ width: "100%", marginTop: "20px" }}
                          value={form.comentario}
                          disabled={minero === "false" ? true : false}
                          maxLength="1000"
                        />
                        {minero === "true" && (
                          <div
                            className="btn-update-matrix1"
                            style={{ marginTop: "15px" }}
                          >
                            <Button onClick={onShowModal}>
                              <FormattedMessage id="GuardarMayus" />
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <ModalActions
                    title={<FormattedMessage id="ActualizarMatriz7" />}
                    show={showModal}
                    setShow={setShowModal}
                    status={state.statusUpdate}
                    action={handleClickUpdate}
                    reset={resetData}
                    deleteAction={"Delete"}
                    estado={state.dataUpdate}
                    loading={state.loadingUpdate}
                  >
                    {state.statusUpdate === 0 ? (
                      <FormattedMessage id="EstaSeguroMatriz7" />
                    ) : state.dataUpdate.ejecucion.estado ? (
                      <div className="container-modal">
                        <p className="message-ok">
                          {state.dataUpdate.ejecucion.mensaje}
                        </p>
                        <Icon name="checkmark" className="status-ok" />
                      </div>
                    ) : (
                      <div className="container-modal">
                        <p className="message-fail">
                          {state.dataUpdate.ejecucion.mensaje}
                        </p>
                        <Image src={ohError} className="status-fail" />
                      </div>
                    )}
                  </ModalActions>
                </div>
              </Card.Content>
            </>
          ) : (
            <Card.Content>{state.data.ejecucion.mensaje}</Card.Content>
          )}
        </Card>
      )}
    </Container>
  );
}
