const initialState = {
  data: {},
  loading: false,
  status: 0,

  dataRegister: {},
  loadingRegister: false,
  statusRegister: 0,
};

export default initialState;
