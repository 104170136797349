import axios from "axios";

const API = {
  data: {
    async uploadFile(item) {
      const fullPath = document.getElementById("recfile").files;

      let formData = new FormData();
      formData.append("recfile", fullPath[0]);
      formData.append("nombre_archivo", fullPath[0].name);
      formData.append("deposito_id", item.deposito_id);
      formData.append("tipo_documento", item.tipo_documento);

      const response = await axios({
        method: "post",
        url: "/api/upload/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      return { data: response.data, status: response.status };
    },

    async deleteDocument(obj) {
      const response = await axios.delete(
        `/api/upload/delete/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
