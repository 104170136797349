import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Icon,
  Input,
  Table,
  TextArea,
  Image,
} from "semantic-ui-react";
import { updateMatriz3 } from "../../../actions/matriz3";

import ModalActions from "../../../components/Modal/ModalActions";
import { resetUpdateMatriz3 } from "./../../../actions/matriz3/index";
import Expired from "./../../Expired/index";
import ohError from "../../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const EmbalseRelave = ({ id, state }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const [error, setError] = useState(false);

  const [datos, setDatos] = useState();

  const [form, setForm] = useState({
    valor_compactacion: "",

    valor_fila3_1: "",
    valor_fila3_2: 100,

    valor_fila4_1: "",
    valor_fila4_2: "",

    valor_fila5_1: "",
    valor_fila5_2: "",

    valor_fila6_1: "",
    valor_fila6_2: "",

    valor_fila7_1: "",
    valor_fila7_2: "",

    valor_fila8_1: "",
    valor_fila8_2: "",

    valor_fila9_1: "",
    valor_fila9_2: "",

    valor_fila10_1: "",
    valor_fila10_2: "",

    valor_fila11_1: "",
    valor_fila11_2: "",

    valor_fila12_1: "",
    valor_fila12_2: "",

    valor_fila13_1: "",
    valor_fila13_2: "",

    valor_fila14_1: "",
    valor_fila14_2: "",

    valor_fila15_1: "",
    valor_fila15_2: "",

    valor_fila16_1: "",
    valor_fila16_2: "",

    valor_fila17_1: "",
    valor_fila17_2: "",

    comentario: "",
  });

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const minero = localStorage.getItem("minero");

  const [dataDropdown, setDataDropdown] = useState([]);
  const [dataDropdownId, setDataDropdownId] = useState("");

  //   DATA COMPACTACION
  const [dataCompactacion, setDataCompactacion] = useState([]);
  const [dataCompactacionId, setDataCompactacionId] = useState("");

  //   DATA DISTRIBUCION
  const [dataDistribucion, setDataDistribucion] = useState([]);
  const [dataDistribucionId, setDataDistribucionId] = useState("");

  //   CHECKBOX
  const [checkedCompactacion, setCheckedCompactacion] = useState([]);
  const [checkedDistribucion, setCheckedDistribucion] = useState([]);

  // NEW DATA POR SI VIENE INFORMACION EN EL ENDPOINT
  const [newDataCompactacion, setNewDataCompactacion] = useState("");
  const [newDataDistribucion, setNewDataDistribucion] = useState("");

  const [newDataDropdown, setNewDataDropdown] = useState("");

  useEffect(() => {
    if (
      (form.valor_compactacion === "" && dataDropdownId === 1) ||
      (form.valor_fila3_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila4_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila4_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila5_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila5_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila6_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila6_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila7_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila7_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila8_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila8_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila9_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila9_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila10_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila10_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila11_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila11_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila12_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila12_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila13_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila13_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila14_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila14_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila15_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila15_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila16_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila16_2 === "" && dataDistribucionId === 1) ||
      (form.valor_fila17_1 === "" && dataDistribucionId === 1) ||
      (form.valor_fila17_2 === "" && dataDistribucionId === 1)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [form, dataDropdownId, dataDistribucionId]);

  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.compactacion_muro_embalse.nivel_compactacion.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataDropdown(arr);
  }, [state.status, state.data.datos]);

  //   DATA COMPACTACION
  useEffect(() => {
    const arr = [];
    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.compactacion_muro_embalse.nivel_compactacion.referencia_para_control_seleccionada.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataCompactacion(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxCompactacion = (obj) => {
    const arr = [...checkedCompactacion];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedCompactacion(arr);
  };

  //   DATA DISTRIBUCION
  useEffect(() => {
    const arr = [];

    if (state.status === 200) {
      if (state.data.datos) {
        state.data.datos.datos.distribucion_granulometrica.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setDataDistribucion(arr);
  }, [state.status, state.data.datos]);

  const handleChangeCheckboxDistribucion = (obj) => {
    const arr = [...checkedDistribucion];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setCheckedDistribucion(arr);
  };

  //   CAMBIAR DATOS A FALSE SI VIENE INFORMACION EN EL ENDPOINT
  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDatos(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [state.loading]);

  //   CARGAR DATA DE LOS CHECKBOX
  useEffect(() => {
    const arrCompactacion = [...checkedCompactacion];

    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        state.data.datos.datos.compactacion_muro_embalse.nivel_compactacion.documentos_que_respaldan.map(
          (e) => e.seleccionado === true && arrCompactacion.push(e.clave)
        );
        setCheckedCompactacion(arrCompactacion);
      }
    }

    // eslint-disable-next-line
  }, [state.status]);

  const handleClickUpdate = () => {
    dispatch(
      updateMatriz3({
        tipo_matriz: state.data.datos.tipo_matriz,
        deposito_id: id,
        compactacion_muro_embalse: {
          nivel_compactacion: {
            sin_antecedentes: null,
            opcion_informacion_id: dataDropdownId,
            referencia_para_control_seleccionada_id: dataCompactacionId,
            porcentaje: form.valor_compactacion,
            documentos_que_respaldan: checkedCompactacion,
          },
        },
        distribucion_granulometrica: {
          opcion_informacion_id: dataDistribucionId,
          valor_fila3_col3: form.valor_fila3_1,
          valor_fila3_col4: form.valor_fila3_2,
          valor_fila4_col3: form.valor_fila4_1,
          valor_fila4_col4: form.valor_fila4_2,
          valor_fila5_col3: form.valor_fila5_1,
          valor_fila5_col4: form.valor_fila5_2,
          valor_fila6_col3: form.valor_fila6_1,
          valor_fila6_col4: form.valor_fila6_2,
          valor_fila7_col3: form.valor_fila7_1,
          valor_fila7_col4: form.valor_fila7_2,
          valor_fila8_col3: form.valor_fila8_1,
          valor_fila8_col4: form.valor_fila8_2,
          valor_fila9_col3: form.valor_fila9_1,
          valor_fila9_col4: form.valor_fila9_2,
          valor_fila10_col3: form.valor_fila10_1,
          valor_fila10_col4: form.valor_fila10_2,
          valor_fila11_col3: form.valor_fila11_1,
          valor_fila11_col4: form.valor_fila11_2,
          valor_fila12_col3: form.valor_fila12_1,
          valor_fila12_col4: form.valor_fila12_2,
          valor_fila13_col3: form.valor_fila13_1,
          valor_fila13_col4: form.valor_fila13_2,
          valor_fila14_col3: form.valor_fila14_1,
          valor_fila14_col4: form.valor_fila14_2,
          valor_fila15_col3: form.valor_fila15_1,
          valor_fila15_col4: form.valor_fila15_2,
          valor_fila16_col3: form.valor_fila16_1,
          valor_fila16_col4: form.valor_fila16_2,
          valor_fila17_col3: form.valor_fila17_1,
          valor_fila17_col4: form.valor_fila17_2,
          documentos_que_respaldan: checkedDistribucion,
        },
        comentario: form.comentario,
      })
    );
  };

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          if (state.data.datos.datos.sin_datos === false) {
            setForm({
              valor_compactacion:
                state.data.datos.datos.compactacion_muro_embalse
                  .nivel_compactacion.porcentaje.length > 0
                  ? state.data.datos.datos.compactacion_muro_embalse
                      .nivel_compactacion.porcentaje[0].valor
                  : "",

              valor_fila3_1:
                state.data.datos.datos.distribucion_granulometrica.fila3
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila3
                      .valor_col3.valor
                  : "",
              valor_fila3_2:
                state.data.datos.datos.distribucion_granulometrica.fila3
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila3
                      .valor_col4.valor
                  : 100,

              valor_fila4_1:
                state.data.datos.datos.distribucion_granulometrica.fila4
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila4
                      .valor_col3.valor
                  : "",
              valor_fila4_2:
                state.data.datos.datos.distribucion_granulometrica.fila4
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila4
                      .valor_col4.valor
                  : "",

              valor_fila5_1:
                state.data.datos.datos.distribucion_granulometrica.fila5
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila5
                      .valor_col3.valor
                  : "",
              valor_fila5_2:
                state.data.datos.datos.distribucion_granulometrica.fila5
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila5
                      .valor_col4.valor
                  : "",

              valor_fila6_1:
                state.data.datos.datos.distribucion_granulometrica.fila6
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila6
                      .valor_col3.valor
                  : "",
              valor_fila6_2:
                state.data.datos.datos.distribucion_granulometrica.fila6
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila6
                      .valor_col4.valor
                  : "",

              valor_fila7_1:
                state.data.datos.datos.distribucion_granulometrica.fila7
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila7
                      .valor_col3.valor
                  : "",
              valor_fila7_2:
                state.data.datos.datos.distribucion_granulometrica.fila7
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila7
                      .valor_col4.valor
                  : "",

              valor_fila8_1:
                state.data.datos.datos.distribucion_granulometrica.fila8
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila8
                      .valor_col3.valor
                  : "",
              valor_fila8_2:
                state.data.datos.datos.distribucion_granulometrica.fila8
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila8
                      .valor_col4.valor
                  : "",

              valor_fila9_1:
                state.data.datos.datos.distribucion_granulometrica.fila9
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila9
                      .valor_col3.valor
                  : "",
              valor_fila9_2:
                state.data.datos.datos.distribucion_granulometrica.fila9
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila9
                      .valor_col4.valor
                  : "",

              valor_fila10_1:
                state.data.datos.datos.distribucion_granulometrica.fila10
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila10
                      .valor_col3.valor
                  : "",
              valor_fila10_2:
                state.data.datos.datos.distribucion_granulometrica.fila10
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila10
                      .valor_col4.valor
                  : "",

              valor_fila11_1:
                state.data.datos.datos.distribucion_granulometrica.fila11
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila11
                      .valor_col3.valor
                  : "",
              valor_fila11_2:
                state.data.datos.datos.distribucion_granulometrica.fila11
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila11
                      .valor_col4.valor
                  : "",

              valor_fila12_1:
                state.data.datos.datos.distribucion_granulometrica.fila12
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila12
                      .valor_col3.valor
                  : "",
              valor_fila12_2:
                state.data.datos.datos.distribucion_granulometrica.fila12
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila12
                      .valor_col4.valor
                  : "",

              valor_fila13_1:
                state.data.datos.datos.distribucion_granulometrica.fila13
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila13
                      .valor_col3.valor
                  : "",
              valor_fila13_2:
                state.data.datos.datos.distribucion_granulometrica.fila13
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila13
                      .valor_col4.valor
                  : "",

              valor_fila14_1:
                state.data.datos.datos.distribucion_granulometrica.fila14
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila14
                      .valor_col3.valor
                  : "",
              valor_fila14_2:
                state.data.datos.datos.distribucion_granulometrica.fila14
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila14
                      .valor_col4.valor
                  : "",

              valor_fila15_1:
                state.data.datos.datos.distribucion_granulometrica.fila15
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila15
                      .valor_col3.valor
                  : "",
              valor_fila15_2:
                state.data.datos.datos.distribucion_granulometrica.fila15
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila15
                      .valor_col4.valor
                  : "",

              valor_fila16_1:
                state.data.datos.datos.distribucion_granulometrica.fila16
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila16
                      .valor_col3.valor
                  : "",
              valor_fila16_2:
                state.data.datos.datos.distribucion_granulometrica.fila16
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila16
                      .valor_col4.valor
                  : "",
              valor_fila17_1:
                state.data.datos.datos.distribucion_granulometrica.fila17
                  .valor_col3.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila17
                      .valor_col3.valor
                  : "",
              valor_fila17_2:
                state.data.datos.datos.distribucion_granulometrica.fila17
                  .valor_col4.valor !== null
                  ? state.data.datos.datos.distribucion_granulometrica.fila17
                      .valor_col4.valor
                  : "",
              comentario:
                state.data.datos.datos.comentario.valor !== null
                  ? state.data.datos.datos.comentario.valor
                  : "",
            });

            const arrCompactacion = [];
            const arrDistribucion = [];

            state.data.datos.datos.compactacion_muro_embalse.nivel_compactacion.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrCompactacion.push(e.clave)
            );
            setCheckedCompactacion(arrCompactacion);

            state.data.datos.datos.distribucion_granulometrica.documentos_que_respaldan.map(
              (e) => e.seleccionado === true && arrDistribucion.push(e.clave)
            );
            setCheckedDistribucion(arrDistribucion);
          }
        }
      }
    };

    callsAPI();
    // eslint-disable-next-line
  }, [state.loading, state.status, state.data]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          setDataDistribucionId(newDataDistribucion);
          setDataCompactacionId(newDataCompactacion);
          setDataDropdownId(newDataDropdown);
        }
      }
    }
    //eslint-disable-next-line
  }, [newDataCompactacion, newDataDistribucion, newDataDropdown]);

  useEffect(() => {
    if (state.status === 200) {
      if (state.data.ejecucion.estado) {
        if (state.data.datos.datos.sin_datos === false) {
          if (dataCompactacion.length > 0) {
            const dataSelectedCompactacion = dataCompactacion.find(
              (element) => element.seleccionado === "true"
            );
            if (dataSelectedCompactacion !== undefined) {
              setNewDataCompactacion(dataSelectedCompactacion.value);
            }
          }

          if (dataDistribucion.length > 0) {
            const dataSelectedDistribucion = dataDistribucion.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataDistribucion(dataSelectedDistribucion.value);
          }

          if (dataDropdown.length > 0) {
            const dataSelectedDropdown = dataDropdown.find(
              (element) => element.seleccionado === "true"
            );
            setNewDataDropdown(dataSelectedDropdown.value);
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [dataCompactacion, dataDistribucion, dataDropdown]);

  const resetData = () => {
    dispatch(resetUpdateMatriz3());
  };

  const intl = useIntl();

  const placeholderComentario = intl.formatMessage({
    id: "IngreseComentario",
  });
  const placeholderSeleccione = intl.formatMessage({ id: "Seleccione" });
  const placeholderValor = intl.formatMessage({ id: "IngreseValor" });

  /* When the token is expired*/
  if (state.status === 501) {
    return <Expired />;
  }

  return (
    <>
      <Card fluid className="card-deposit">
        {state.data.ejecucion.estado ? (
          <>
            <Card.Content>
              <div
                className="card-deposit-info"
                style={{ flexDirection: "row" }}
              >
                {state.data.datos.datos.titulo_matriz}
              </div>
            </Card.Content>

            <Card.Content className="card-container__matrix2">
              <div>
                <p className="card-matrix__title">
                  {state.data.datos.datos.compactacion_muro_embalse.titulo}
                </p>
                <div className="matrix3__container-nivel">
                  <div>
                    <Dropdown
                      placeholder={placeholderSeleccione}
                      selection
                      options={dataDropdown}
                      value={datos === false ? newDataDropdown : dataDropdownId}
                      onChange={(_, data) => {
                        setDataDropdownId(data.value);
                        setNewDataDropdown(data.value);
                      }}
                      disabled={minero === "false" ? true : false}
                      style={{ width: "95%" }}
                    />

                    {newDataDropdown === "" && dataDropdownId === "" && (
                      <Icon name="warning" className="icon-warning" />
                    )}
                  </div>
                  {newDataDropdown === 1 && dataDropdownId === 1 && (
                    <div>
                      <Dropdown
                        placeholder={placeholderSeleccione}
                        selection
                        options={dataCompactacion}
                        value={
                          datos === false
                            ? newDataCompactacion
                            : dataCompactacionId
                        }
                        onChange={(_, data) => {
                          setDataCompactacionId(data.value);
                          setNewDataCompactacion(data.value);
                        }}
                        style={{ width: "95%" }}
                        disabled={minero === "false" ? true : false}
                      />
                      {newDataCompactacion === "" &&
                        dataCompactacionId === "" && (
                          <Icon name="warning" className="icon-warning" />
                        )}
                    </div>
                  )}
                  <>
                    {(dataCompactacionId !== "" ||
                      newDataCompactacion !== "") &&
                      (newDataDropdown === 1 || dataDropdownId === 1) && (
                        <div className="matrix3__container-valor">
                          <div>
                            <p>
                              {
                                state.data.datos.datos.compactacion_muro_embalse
                                  .nivel_compactacion.titulo
                              }
                            </p>
                            <Input
                              name="valor_compactacion"
                              placeholder={placeholderValor}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => handleChange(e)}
                              maxLength="2"
                              value={form.valor_compactacion}
                              disabled={minero === "false" ? true : false}
                              className={
                                error && form.valor_compactacion === ""
                                  ? "border-red"
                                  : "border-blue"
                              }
                            />
                          </div>
                          <div className="matrix3__compactacion-docs">
                            <p>
                              {
                                state.data.datos.datos
                                  .titulo_documentos_respaldan_matriz
                              }
                            </p>
                            {state.data.datos.datos.compactacion_muro_embalse.nivel_compactacion.documentos_que_respaldan.map(
                              (item, i) => {
                                let index = checkedCompactacion.includes(
                                  item.clave
                                );
                                return (
                                  <Checkbox
                                    key={i}
                                    label={item.label}
                                    onChange={() =>
                                      handleChangeCheckboxCompactacion(item)
                                    }
                                    value={item.clave}
                                    checked={index}
                                    disabled={
                                      minero === "false"
                                        ? true
                                        : !item.editable
                                        ? true
                                        : false
                                    }
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                  </>
                  <>
                    <div>
                      <p className="card-matrix__title">
                        {
                          state.data.datos.datos.distribucion_granulometrica
                            .titulo
                        }
                      </p>

                      <div className="matrix3__container-nivel">
                        <div>
                          <Dropdown
                            placeholder={placeholderSeleccione}
                            selection
                            options={dataDistribucion}
                            value={
                              datos === false
                                ? newDataDistribucion
                                : dataDistribucionId
                            }
                            onChange={(_, data) => {
                              setDataDistribucionId(data.value);
                              setNewDataDistribucion(data.value);
                            }}
                            style={{ width: "95%" }}
                            disabled={minero === "false" ? true : false}
                          />

                          {dataDistribucionId === "" &&
                            newDataDistribucion === "" && (
                              <Icon name="warning" className="icon-warning" />
                            )}
                        </div>

                        {(dataDistribucionId === 1 ||
                          newDataDistribucion === 1) && (
                          <>
                            <div
                              className="matrix3__container-valor"
                              style={{ display: "block" }}
                            >
                              <div>
                                <Table celled fixed className="table__matrix3">
                                  <Table.Header>
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.HeaderCell></Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila1
                                            .lbl_col1
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila1
                                            .lbl_col2
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila2
                                            .lbl_col1
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila2
                                            .lbl_col2
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila2
                                            .lbl_col3
                                        }
                                      </Table.HeaderCell>
                                      <Table.HeaderCell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila2
                                            .lbl_col4
                                        }
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  {/* BODY */}
                                  {/* FILA 3 */}
                                  <Table.Body>
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila3
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila3
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila3_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila3_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila3_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila3_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          // onChange={(e) => handleChange(e)}
                                          maxLength="3"
                                          value={form.valor_fila3_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={"border-blue"}
                                        />
                                      </Table.Cell>
                                    </Table.Row>

                                    {/* FILA 4 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila4
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila4
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila4_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila4_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila4_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila4_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila4_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila4_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>

                                    {/* FILA 5 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila5
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila5
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila5_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila5_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila5_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila5_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila5_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila5_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 6 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila6
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila6
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila6_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila6_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila6_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila6_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila6_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila6_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 7 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila7
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila7
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila7_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila7_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila7_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila7_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila7_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila7_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 8 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila8
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila8
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila8_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila8_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila8_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila8_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila8_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila8_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 9 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila9
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila9
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila9_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila9_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila9_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila9_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila9_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila9_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 10 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila10
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila10
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila10_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila10_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila10_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila10_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila10_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila10_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 11 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila11
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila11
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila11_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila11_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila11_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila11_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila11_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila11_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 12 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila12
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila12
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila12_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila12_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila12_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila12_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila12_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila12_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 13 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila13
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila13
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila13_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila13_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila13_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila13_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila13_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila13_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 14 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila14
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila14
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila14_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila14_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila14_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila14_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila14_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila14_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 15 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila15
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila15
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila15_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila15_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila15_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila15_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila15_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila15_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 16 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila16
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila16
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila16_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila16_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila16_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila16_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila16_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila16_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    {/* FILA 17 */}
                                    <Table.Row style={{ textAlign: "center" }}>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila17
                                            .lbl_col1
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        {
                                          state.data.datos.datos
                                            .distribucion_granulometrica.fila17
                                            .lbl_col2
                                        }
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila17_1"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila17_1}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila17_1 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Input
                                          name="valor_fila17_2"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(e) => handleChange(e)}
                                          maxLength="2"
                                          value={form.valor_fila17_2}
                                          disabled={
                                            minero === "false" ? true : false
                                          }
                                          className={
                                            error && form.valor_fila17_2 === ""
                                              ? "border-red"
                                              : "border-blue"
                                          }
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                  </Table.Body>
                                </Table>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {
                                      state.data.datos.datos
                                        .titulo_documentos_respaldan_matriz
                                    }
                                  </p>
                                  {state.data.datos.datos.distribucion_granulometrica.documentos_que_respaldan.map(
                                    (item, i) => {
                                      let index = checkedDistribucion.includes(
                                        item.clave
                                      );
                                      return (
                                        <Checkbox
                                          key={i}
                                          label={item.label}
                                          onChange={() =>
                                            handleChangeCheckboxDistribucion(
                                              item
                                            )
                                          }
                                          value={item.clave}
                                          checked={index}
                                          disabled={
                                            minero === "false"
                                              ? true
                                              : !item.editable
                                              ? true
                                              : false
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {dataDropdownId !== "" && dataDistribucionId !== "" && (
                        <>
                          <TextArea
                            placeholder={placeholderComentario}
                            name="comentario"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{ width: "100%", marginTop: "20px" }}
                            value={form.comentario}
                            disabled={minero === "false" ? true : false}
                            maxLength="1000"
                          />
                          {minero === "true" && error === false && (
                            <div
                              className="btn-update-matrix1"
                              style={{ marginTop: "15px" }}
                            >
                              <Button onClick={onShowModal}>
                                <FormattedMessage id="GuardarMayus" />
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </>
                </div>
              </div>
              <ModalActions
                title={<FormattedMessage id="ActualizarMatriz3" />}
                show={showModal}
                setShow={setShowModal}
                status={state.statusUpdate}
                action={handleClickUpdate}
                reset={resetData}
                deleteAction={"Delete"}
                estado={state.dataUpdate}
                loading={state.loadingUpdate}
              >
                {state.statusUpdate === 0 ? (
                  <FormattedMessage id="ActualizarMatriz3" />
                ) : state.dataUpdate.ejecucion.estado ? (
                  <div className="container-modal">
                    <p className="message-ok">
                      {state.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Icon name="checkmark" className="status-ok" />
                  </div>
                ) : (
                  <div className="container-modal">
                    <p className="message-fail">
                      {state.dataUpdate.ejecucion.mensaje}
                    </p>
                    <Image src={ohError} className="status-fail" />
                  </div>
                )}
              </ModalActions>
            </Card.Content>
          </>
        ) : (
          <Card.Content>{state.data.ejecucion.mensaje}</Card.Content>
        )}
      </Card>
    </>
  );
};

export default EmbalseRelave;
