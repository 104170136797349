import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container } from "semantic-ui-react";
import { getAllParams, resetAllParams } from "../../../actions/params";
import { useLocation } from "react-router-dom";

import Loading from "../../Loading/index";
import Expired from "../../Expired";
import Embalse from "./Embalse/index";
import Pasta from "./Pasta/index";
import Filtrado from "./Filtrado/index";

const Geometrical = ({ stateDeposit }) => {
  const dispatch = useDispatch();
  const stateParams = useSelector((state) => state.params);

  const location = useLocation();

  const { state } = location.state;

  useEffect(() => {
    dispatch(
      getAllParams({
        deposito_id: stateDeposit.dataDeposit.datos.id,
        tipo_de_dato_solicitado: "m2",
      })
    );

    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetAllParams());
    };
  }, [dispatch]);

  /* When the token is expired*/
  if (stateParams.statusParams === 501 || stateParams.statusParams === 401) {
    return <Expired />;
  }

  const validar = () => {
    if (stateParams.dataParams.ejecucion.estado) {
      if (stateParams.dataParams.datos.tipo_de_dato_solicitado !== "m2") {
        return true;
      }
    }
  };

  return (
    <Container>
      {stateParams.loadingParams || validar() ? (
        <Loading />
      ) : (
        <Card fluid className="card-deposit">
          <Card.Content>
            <div className="card-deposit-info" style={{ flexDirection: "row" }}>
              {stateParams.dataParams.datos?.titulo_pagina}
            </div>
            <div>Periodo: {stateParams.dataParams.datos?.periodo.clave}</div>
          </Card.Content>

          <Card.Content className="card-container__matrix2">
            {stateParams.dataParams.ejecucion.estado ? (
              <>
                <div className="matrix__container-dropdown">
                  {/* TIPO EMBALSE RELAVE */}
                  {(stateParams.dataParams.datos.clave_tipo_deposito ===
                    "embalse_relave" ||
                    stateParams.dataParams.datos.clave_tipo_deposito ===
                      "tranque_arena_relave") && (
                    <Embalse
                      stateDeposit={stateDeposit}
                      stateParams={stateParams}
                      state={state}
                    />
                  )}
                  {(stateParams.dataParams.datos.clave_tipo_deposito ===
                    "pasta_relave" ||
                    stateParams.dataParams.datos.clave_tipo_deposito ===
                      "espesado_relave") && (
                    <Pasta
                      stateDeposit={stateDeposit}
                      stateParams={stateParams}
                      state={state}
                    />
                  )}

                  {stateParams.dataParams.datos.clave_tipo_deposito ===
                    "filtrado_relave" && (
                    <Filtrado
                      stateDeposit={stateDeposit}
                      stateParams={stateParams}
                      state={state}
                    />
                  )}
                </div>
              </>
            ) : (
              <div>{stateParams.dataParams.ejecucion.mensaje}</div>
            )}
          </Card.Content>
        </Card>
      )}
    </Container>
  );
};

export default Geometrical;
