import {
  RESET_EMPRESAS_CON_FAENA,
  GET_EMPRESAS_CON_FAENA_INIT,
  GET_EMPRESAS_CON_FAENA_SUCCESS,
  GET_EMPRESAS_CON_FAENA_FAILURE,
  RESET_COMPLETE_REGISTRATION,
  COMPLETE_REGISTRATION_INIT,
  COMPLETE_REGISTRATION_SUCCESS,
  COMPLETE_REGISTRATION_FAILURE,
} from "./types";
import API from "./api";

export function resetGetCompañiasConFaena() {
  return {
    type: RESET_EMPRESAS_CON_FAENA,
  };
}
function getCompañiasConFaenaInit() {
  return {
    type: GET_EMPRESAS_CON_FAENA_INIT,
  };
}
function getCompañiasConFaenaSuccess(data) {
  return {
    type: GET_EMPRESAS_CON_FAENA_SUCCESS,
    payload: data,
  };
}
function getCompañiasConFaenaFailure(error) {
  return {
    type: GET_EMPRESAS_CON_FAENA_FAILURE,
    payload: error,
  };
}
export function getCompañiasConFaena(data) {
  return async (dispatch) => {
    dispatch(getCompañiasConFaenaInit());
    try {
      const resp = await API.data.getCompaniesWithChore(data);
      dispatch(getCompañiasConFaenaSuccess(resp));
    } catch (error) {
      dispatch(getCompañiasConFaenaFailure(error));
    }
  };
}

// COMPLETE REGISTRATION
export function resetCompleteRegistration() {
  return {
    type: RESET_COMPLETE_REGISTRATION,
  };
}
function completeRegistrationInit() {
  return {
    type: COMPLETE_REGISTRATION_INIT,
  };
}
function completeRegistrationSuccess(data) {
  return {
    type: COMPLETE_REGISTRATION_SUCCESS,
    payload: data,
  };
}
function completeRegistrationFailure(error) {
  return {
    type: COMPLETE_REGISTRATION_FAILURE,
    payload: error,
  };
}
export function completeRegistration(data) {
  return async (dispatch) => {
    dispatch(completeRegistrationInit());
    try {
      const resp = await API.data.completeRegistration(data);
      dispatch(completeRegistrationSuccess(resp));
    } catch (error) {
      dispatch(completeRegistrationFailure(error));
    }
  };
}
