import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form, Icon, Image } from "semantic-ui-react";
import { useEffect, useState } from "react";
import {
  completeRegistration,
  resetCompleteRegistration,
  getCompañiasConFaena,
} from "../../actions/enrollment";
import { getCodeToRegister, sendCodeToRegister } from "../../actions/register";
import ModalMessage from "./../Modal/index";
import Loading from "./../Loading/index";
import {
  resetSendCodeToValidate,
  resetGetCodeToRegister,
} from "./../../actions/register/index";

import { DropdownComuna } from "./../Dropdown/DropdownComuna";
import { DropdownRegion } from "./../Dropdown/DropdownRegion";
import { DropdownProvidencia } from "./../Dropdown/DropdownProvincia";
import ohError from "../../assets/icons/ohhh_error.png";

import { FormattedMessage, useIntl } from "react-intl";

const userLang = navigator.language;
const Lang = userLang.slice(0, 2);

const RegisterComponent = () => {
  const state = useSelector((state) => state.register);
  const stateEnrollment = useSelector((state) => state.enrollment);

  const dispatch = useDispatch();
  const [code, setCode] = useState(null);

  const [showModalSolicitando, setShowModalSolicitando] = useState(false);
  const [showModalVerificando, setShowModalVerificando] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);

  const onShowModal = () => setShowModalSolicitando(true);
  const onShowModalVerificando = () => setShowModalVerificando(true);
  const onShowModalRegister = () => setShowModalRegister(true);

  useEffect(() => {
    if (state.statusSendCode === 200) {
      if (state.dataSendCode.ejecucion.estado) {
        if (state.dataSendCode.datos.validado) {
          setTimeout(() => {
            setCode("code");
          }, 4000);
        }
      }
    }

    if (state.statusSendCode === 0) {
      setCode(null);
    }
  }, [state.dataSendCode, state.statusSendCode]);

  // FORMIK PARA REGISTRARSE CON EMPRESA
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      dispatch(
        completeRegistration({
          lenguaje: Lang,
          usuario: {
            pre_user_id: state.dataSendCode.datos.pre_user.id,
            nombre: formData.nombre,
            fono_contacto_usuario: formData.fono_contacto_usuario,
          },
          empresa: {
            empresa_id: formData.empresa_id,
            campos_propios: null,
          },
        })
      );
    },
  });

  // FORMIK PARA VALIDACION DE CODIGOS
  const formikCode = useFormik({
    initialValues: initialValuesCode(),
    validationSchema: Yup.object(validationSchemaCode()),
    onSubmit: (formData) => {
      state.statusGetCode === 0
        ? dispatch(
            getCodeToRegister({
              lenguaje: Lang,
              email: formData.email,
            })
          )
        : dispatch(
            sendCodeToRegister({
              code: formData.code,
              email: formData.email,
              lenguaje: Lang,
            })
          );
    },
  });

  const resetData = () => {
    dispatch(resetCompleteRegistration());
  };

  const resetDataGetCode = () => {
    dispatch(resetGetCodeToRegister());
  };

  const resetDataSendCode = () => {
    dispatch(resetSendCodeToValidate());
  };

  const intl = useIntl();

  const placeHolderCorreo = intl.formatMessage({ id: "Correo" });
  const placeHolderCodigo = intl.formatMessage({ id: "Codigo" });
  const placeHolderNombre = intl.formatMessage({ id: "IngresarNombre" });
  const placeHolderTelefono = intl.formatMessage({ id: "IngresarTelefono" });

  return !code ? (
    <Form className="login-inputs" onSubmit={formikCode.handleSubmit}>
      <Form.Input
        name="email"
        type="text"
        placeholder={placeHolderCorreo}
        onChange={formikCode.handleChange}
        error={formikCode.errors.email}
        value={formikCode.values.email}
        disabled={state.dataGetCode.ejecucion?.estado ? true : false}
      />
      {state.statusGetCode === 200 && state.dataGetCode.ejecucion.estado ? (
        <Form.Input
          name="code"
          type="text"
          placeholder={placeHolderCodigo}
          onChange={formikCode.handleChange}
          error={formikCode.errors.code}
          value={formikCode.values.code}
        />
      ) : null}
      <div>
        {!state.dataGetCode.ejecucion?.estado ? (
          <Button
            className="btn-login"
            type="submit"
            onClick={onShowModal}
            disabled={!(formikCode.dirty && formikCode.isValid)}
          >
            {state.loadingGetCode ? (
              <FormattedMessage id="Solicitando" />
            ) : (
              <FormattedMessage id="SolicitarCodigo" />
            )}
          </Button>
        ) : (
          <Button
            className="btn-login"
            type="submit"
            onClick={onShowModalVerificando}
            disabled={!(formikCode.dirty && formikCode.isValid)}
          >
            {state.loadingSendCode ? (
              <FormattedMessage id="Verificando" />
            ) : (
              <FormattedMessage id="VerificarCodigo" />
            )}
          </Button>
        )}
      </div>
      <ModalMessage
        title={<FormattedMessage id="SolicitandoCodigo" />}
        message={
          state.statusGetCode === 0 ? (
            <Loading />
          ) : state.dataGetCode.ejecucion.estado ? (
            <div className="container-modal">
              <p className="message-ok">
                {state.dataGetCode.ejecucion.mensaje}
              </p>
              <Icon name="checkmark" className="status-ok" />
            </div>
          ) : (
            <div className="container-modal">
              <p className="message-fail">
                {state.dataGetCode.ejecucion.mensaje}
              </p>
              <Image src={ohError} className="status-fail" />
            </div>
          )
        }
        show={showModalSolicitando}
        setShow={setShowModalSolicitando}
        resetCode={resetDataSendCode}
        registerCode={true}
      />

      <ModalMessage
        title={<FormattedMessage id="VerificandoCodigo" />}
        message={
          state.statusSendCode === 0 ? (
            <Loading />
          ) : state.dataSendCode.ejecucion.estado ? (
            <div className="container-modal">
              <p className="message-ok">
                {state.dataSendCode.ejecucion.mensaje}
              </p>
              <Icon name="checkmark" className="status-ok" />
            </div>
          ) : (
            <div className="container-modal">
              <p className="message-fail">
                {state.dataSendCode.ejecucion.mensaje}
              </p>
              <Image src={ohError} className="status-fail" />
            </div>
          )
        }
        show={showModalVerificando}
        setShow={setShowModalVerificando}
        resetCode={resetDataGetCode}
        registerCode={true}
      />
    </Form>
  ) : (
    <Form className="login-inputs" onSubmit={formik.handleSubmit}>
      <DropdownRegion formik={formik} />

      {formik.values.region_id && (
        <DropdownProvidencia
          formik={formik}
          region_id={formik.values.region_id}
        />
      )}

      {formik.values.providencia_id && (
        <DropdownComuna
          formik={formik}
          providencia_id={formik.values.providencia_id}
        />
      )}

      {formik.values.comuna_id && (
        <DropdownCompanyWithChore
          formik={formik}
          comuna_id={formik.values.comuna_id}
        />
      )}

      {formik.values.empresa_id && (
        <>
          <Form.Input
            name="nombre"
            type="text"
            placeholder={placeHolderNombre}
            onChange={formik.handleChange}
            error={formik.errors.nombre}
            value={formik.values.nombre}
          />
          <Form.Input
            name="fono_contacto_usuario"
            type="text"
            placeholder={placeHolderTelefono}
            onChange={formik.handleChange}
            error={formik.errors.fono_contacto_usuario}
            value={formik.values.fono_contacto_usuario}
          />
        </>
      )}

      {formik.values.empresa_id &&
        formik.values.nombre &&
        formik.values.fono_contacto_usuario && (
          <div>
            <Button
              className="btn-login"
              type="submit"
              onClick={onShowModalRegister}
            >
              <FormattedMessage id="Registrarse" />
            </Button>
          </div>
        )}

      <ModalMessage
        title={<FormattedMessage id="ValidandoDatos" />}
        message={
          stateEnrollment.statusRegister === 0 ? (
            <Loading />
          ) : stateEnrollment.dataRegister.ejecucion.estado ? (
            <div className="container-modal">
              <p className="message-ok">
                {stateEnrollment.dataRegister.ejecucion.mensaje}
              </p>
              <Icon name="checkmark" className="status-ok" />
            </div>
          ) : (
            <div className="container-modal">
              <p className="message-fail">
                {stateEnrollment.dataRegister.ejecucion.mensaje}
              </p>
              <Image src={ohError} className="status-fail" />
            </div>
          )
        }
        show={showModalRegister}
        setShow={setShowModalRegister}
        dataRegister={stateEnrollment.dataRegister}
        reset={resetData}
      />
    </Form>
  );
};

export default RegisterComponent;

function initialValues() {
  return {
    region_id: "",
    providencia_id: "",
    comuna_id: "",
    empresa_id: "",
    nombre: "",
    fono_contacto_usuario: "",
  };
}

function validationSchema() {
  return {
    region_id: Yup.string().required(true),
    providencia_id: Yup.string().required(true),
    comuna_id: Yup.string().required(true),
    empresa_id: Yup.string().required(true),
    nombre: Yup.string().required(true),
    fono_contacto_usuario: Yup.number().required(true),
  };
}

function initialValuesCode() {
  return {
    email: "",
    code: "",
  };
}
function validationSchemaCode() {
  return {
    email: Yup.string().email().required(true),
  };
}

// DROPDOWN EMPRESAS CON FAENA
function DropdownCompanyWithChore({ formik, comuna_id }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.enrollment);

  const [dataCompany, setDataCompany] = useState({});

  const { data, status } = state;

  useEffect(() => {
    dispatch(
      getCompañiasConFaena({
        lenguaje: Lang,
        comuna_id: comuna_id,
      })
    );
  }, [dispatch, comuna_id]);

  useEffect(() => {
    const arr = [];
    if (status === 200) {
      if (data.datos.length > 0) {
        data.datos.forEach((e) => {
          arr.push({
            key: e.empresa_id,
            value: e.empresa_id,
            text: e.nombre,
          });
        });
      }
    }
    setDataCompany(arr);
  }, [data, status, formik.values]);

  const intl = useIntl();

  const placeHolderEmpresa = intl.formatMessage({ id: "Empresa" });
  const placeHolderCargando = intl.formatMessage({ id: "Cargando" });

  return (
    <>
      <Form.Dropdown
        placeholder={status === 200 ? placeHolderEmpresa : placeHolderCargando}
        label="Empresa"
        selection
        options={status === 200 && dataCompany}
        value={formik.values.empresa_id}
        onChange={(_, data) => formik.setFieldValue("empresa_id", data.value)}
        errors={formik.errors.empresa_id}
      />
      {dataCompany.length <= 0 && (
        <p>
          <FormattedMessage id="NoHayEmpresasAsociadas" />
        </p>
      )}
    </>
  );
}
