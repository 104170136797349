import { useLayoutEffect } from "react";

import { useDispatch } from "react-redux";
import { ResetLoginDeport } from "../../actions/login/api";

const Expired = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(ResetLoginDeport());
    window.location.reload();
  });

  return <section></section>;
};

export default Expired;
