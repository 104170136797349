import axios from "axios";

export const LoginDeport = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGIN_LOADING",
    });

    const response = await axios.post(`/api/login`, obj);

    const { status } = response;

    let data = await response.data;

    dispatch({
      type: "LOGIN_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "LOGIN_FAIL",
    });
  }
};

export const ResetLoginDeport = () => {
  return {
    type: "RESET_LOGIN",
  };
};
