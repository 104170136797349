import {
  RESET_GET_DETAILS_COMPANY,
  GET_DETAILS_COMPANY_INIT,
  GET_DETAILS_COMPANY_SUCCESS,
  GET_DETAILS_COMPANY_FAILURE,
  RESET_DATA_COMPANY,
  GET_DATA_COMPANY_INIT,
  GET_DATA_COMPANY_SUCCESS,
  GET_DATA_COMPANY_FAILURE,
} from "./types";
import API from "./api";

export function resetGetDetailsCompany() {
  return {
    type: RESET_GET_DETAILS_COMPANY,
  };
}
function getDetailsCompanyInit() {
  return {
    type: GET_DETAILS_COMPANY_INIT,
  };
}
function getDetailsCompanySuccess(data) {
  return {
    type: GET_DETAILS_COMPANY_SUCCESS,
    payload: data,
  };
}
function getDetailsCompanyFailure(error) {
  return {
    type: GET_DETAILS_COMPANY_FAILURE,
    payload: error,
  };
}
export function getDetailsCompany(data) {
  return async (dispatch) => {
    dispatch(getDetailsCompanyInit());
    try {
      const resp = await API.data.getDetailsCompany(data);
      dispatch(getDetailsCompanySuccess(resp));
    } catch (error) {
      dispatch(getDetailsCompanyFailure(error));
    }
  };
}

export function resetDataCompany() {
  return {
    type: RESET_DATA_COMPANY,
  };
}
function getDataCompanyInit() {
  return {
    type: GET_DATA_COMPANY_INIT,
  };
}
function getDataCompanySuccess(data) {
  return {
    type: GET_DATA_COMPANY_SUCCESS,
    payload: data,
  };
}
function getDataCompanyFailure(error) {
  return {
    type: GET_DATA_COMPANY_FAILURE,
    payload: error,
  };
}
export function getDataCompany(data) {
  return async (dispatch) => {
    dispatch(getDataCompanyInit());
    try {
      const resp = await API.data.getDataCompany(data);
      dispatch(getDataCompanySuccess(resp));
    } catch (error) {
      dispatch(getDataCompanyFailure(error));
    }
  };
}
