import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Image, Table } from "semantic-ui-react";
import ModalDelete from "../../../Modal/ModalDelete";
import Expired from "./../../../Expired/index";
import Loading from "./../../../Loading/index";
import {
  deleteRegistro,
  resetDeleteRegistro,
} from "./../../../../actions/instruments/index";
import ohError from "../../../../assets/icons/ohhh_error.png";
import { FormattedMessage } from "react-intl";

const Grilla = ({ state, data, reloadOption }) => {
  const { dataOpcion, statusOpcion, loadingOpcion } = state;

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState("");

  const [datosVariables, setDatosVariables] = useState([]);

  let minero = localStorage.getItem("minero");

  const dispatch = useDispatch();
  const stateDelete = useSelector((state) => state.instruments);

  const openModalDelete = (id) => {
    setShowModalDelete(true);
    setDataDelete(id);
  };

  useEffect(() => {
    if (dataOpcion.datos?.parte_variable) {
      setDatosVariables(dataOpcion.datos.parte_variable);
    }
  }, [dataOpcion, loadingOpcion]);

  const handleDelete = () => {
    dispatch(
      deleteRegistro({
        sistema: {
          modelo: data.data.sistema.modelo,
          encabezado_reg_id: data.data.sistema.encabezado_reg_id,
          clave_tipo_deposito: data.data.sistema.clave_tipo_deposito,
          clave_tipo_instrumento: data.data.sistema.clave_tipo_instrumento,
        },
        registro_id: dataDelete,
      })
    );
  };

  useEffect(() => {
    if (stateDelete.statusDelete === 200) {
      if (stateDelete.dataDelete.ejecucion.estado) {
        reloadOption();
        resetDeleteRegistro();
      }
    }
    // eslint-disable-next-line
  }, [stateDelete.statusDelete, stateDelete.dataDelete]);

  useEffect(() => {
    return () => {
      resetDeleteRegistro();
    };
  }, [dispatch]);

  const resetData = () => {
    dispatch(resetDeleteRegistro());
  };

  const closeModalDelete = () => {
    dispatch(resetDeleteRegistro());
  };

  if (statusOpcion === 501 || statusOpcion === 401) {
    return <Expired />;
  }

  return (
    <>
      {loadingOpcion ? (
        <Loading />
      ) : (
        <>
          <Table>
            <Table.Header>
              <Table.Row>
                {Object.keys(data.data.grilla_historica.datos_duro.linea1).map(
                  // eslint-disable-next-line array-callback-return
                  (item, i) => {
                    if (item.includes("col")) {
                      return (
                        <Table.HeaderCell
                          colSpan={`${
                            115 *
                            data.data.grilla_historica.datos_duro.linea1[item]
                              .ancho
                          }`}
                          key={i}
                        >
                          {
                            data.data.grilla_historica.datos_duro.linea1[item]
                              .label
                          }
                        </Table.HeaderCell>
                      );
                    }
                  }
                )}
              </Table.Row>

              <Table.Row>
                {Object.keys(data.data.grilla_historica.datos_duro.linea2).map(
                  // eslint-disable-next-line array-callback-return
                  (item, i) => {
                    if (item.includes("col")) {
                      return (
                        <Table.HeaderCell
                          colSpan={`${
                            115 *
                            data.data.grilla_historica.datos_duro.linea2[item]
                              .ancho
                          }`}
                          key={i}
                        >
                          {
                            data.data.grilla_historica.datos_duro.linea2[item]
                              .label
                          }
                        </Table.HeaderCell>
                      );
                    }
                  }
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {datosVariables.map((item, i) => (
                <Table.Row key={i}>
                  <Table.Cell colSpan={115}>{item.col1.label}</Table.Cell>
                  <Table.Cell colSpan={115}>{item.col2.label}</Table.Cell>
                  {item.col4 ? (
                    <Table.Cell colSpan={115}>{item.col3.label}</Table.Cell>
                  ) : null}
                  {item.col3?.tipo === "registro_id" && (
                    <Table.Cell colSpan={115}>
                      {minero === "true" && (
                        <Icon
                          name="trash"
                          style={{ cursor: "pointer" }}
                          onClick={() => openModalDelete(item.col3.label)}
                        />
                      )}
                    </Table.Cell>
                  )}
                  {item.col4?.tipo === "registro_id" && (
                    <Table.Cell colSpan={115}>
                      {minero === "true" && (
                        <Icon
                          name="trash"
                          style={{ cursor: "pointer" }}
                          onClick={() => openModalDelete(item.col4.label)}
                        />
                      )}
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <ModalDelete
            title={<FormattedMessage id="EliminarRegistro" />}
            show={showModalDelete}
            setShow={setShowModalDelete}
            action={handleDelete}
            reset={resetData}
            status={stateDelete.statusDelete}
            estado={stateDelete.dataDelete}
            loading={stateDelete.loadingAdd}
            close={closeModalDelete}
          >
            {stateDelete.statusDelete === 0 ? (
              <FormattedMessage id="EstaSeguroEliminarRegistro" />
            ) : stateDelete.dataDelete.ejecucion.estado ? (
              <div className="container-modal">
                <p className="message-ok">
                  {state.dataDelete.ejecucion.mensaje}
                </p>
                <Icon name="checkmark" className="status-ok" />
              </div>
            ) : (
              <div className="container-modal">
                <p className="message-fail">
                  {state.dataDelete.ejecucion.mensaje}
                </p>
                <Image src={ohError} className="status-fail" />
              </div>
            )}
          </ModalDelete>
        </>
      )}
    </>
  );
};

export default Grilla;
