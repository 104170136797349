import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Container } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";

import Loading from "../../Loading/index";
import Expired from "../../Expired";

import { getTabs, resetTabs } from "./../../../actions/instruments/index";
import Piezometro from "./Piezometro";
import Pozo from "./Pozo";
import Drenaje from "./Drenaje";
import Acelerometro from "./Acelerometro";
import Inclinometro from "./Inclinometro";
import Placas from "./Placas";
import Celdas from "./Celdas";
import Distancia from "./Distancia";

const Instruments = ({ stateDeposit }) => {
  const dispatch = useDispatch();
  const stateInstruments = useSelector((state) => state.instruments);

  const [selected, setSelected] = useState("");
  const [load, setLoad] = useState(false);

  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

  const location = useLocation();

  const { state } = location.state;

  useEffect(() => {
    if (!load) {
      if (location.pathname === "/dashboard/ef-params/instruments/piezometro") {
        setSelected(6);
      } else if (
        location.pathname === "/dashboard/ef-params/instruments/pozo"
      ) {
        setSelected(8);
      } else if (
        location.pathname === "/dashboard/ef-params/instruments/distancia"
      ) {
        setSelected(3);
      } else if (
        location.pathname === "/dashboard/ef-params/instruments/drenaje_basal"
      ) {
        setSelected(4);
      } else if (
        location.pathname === "/dashboard/ef-params/instruments/acelerometro"
      ) {
        setSelected(1);
      } else if (
        location.pathname === "/dashboard/ef-params/instruments/inclinometro"
      ) {
        setSelected(5);
      } else if (
        location.pathname ===
        "/dashboard/ef-params/instruments/placa_corrimiento"
      ) {
        setSelected(7);
      } else if (
        location.pathname ===
        "/dashboard/ef-params/instruments/celda_asentamiento"
      ) {
        setSelected(2);
      }
      setLoad(true);
    }
  }, [location.pathname, load]);

  useEffect(() => {
    dispatch(
      getTabs({
        deposito_id: stateDeposit.dataDeposit.datos.id,
        clave_instrumento: null,
      })
    );
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetTabs());
    };
  }, [dispatch]);

  /* When the token is expired*/
  if (stateInstruments.status === 501 || stateInstruments.status === 401) {
    return <Expired />;
  }

  return (
    <Container>
      {stateInstruments.loading ? (
        <Loading />
      ) : (
        <Card fluid className="card-instruments">
          {stateInstruments.data.ejecucion.estado ? (
            <>
              <div
                className="card-instruments-info"
                style={{ flexDirection: "row" }}
              >
                {stateInstruments.data.datos.titulo_pagina}
              </div>
              <div>Periodo: {stateInstruments.data.datos.periodo.clave}</div>

              <div className="params-ef">
                {stateInstruments.data.datos.tabs.map((e, i) => {
                  return (
                    <div key={i}>
                      <Link
                        to={`/dashboard/ef-params/instruments/${e.clave_instrumento}`}
                        state={{ state, stateDeposit }}
                      >
                        {e.aplica && (
                          <Button
                            onClick={handleClick(e.tab)}
                            className={
                              location.pathname ===
                              `/dashboard/ef-params/instruments/${e.clave_instrumento}`
                                ? "ef-selected"
                                : e.mensaje === "Se ecuentran registros"
                                ? "ef-ok"
                                : ""
                            }
                          >
                            {e.tab_titulo}
                          </Button>
                        )}
                      </Link>
                    </div>
                  );
                })}
              </div>
              {selected === 6 && (
                <div className="params-ef__data">
                  <Piezometro
                    stateInstruments={stateInstruments}
                    selected={selected}
                    clave="piezometro"
                  />
                </div>
              )}

              {selected === 8 && (
                <div className="params-ef__data">
                  <Pozo stateInstruments={stateInstruments} clave="pozo" />
                </div>
              )}

              {selected === 3 && (
                <div className="params-ef__data">
                  <Distancia
                    stateInstruments={stateInstruments}
                    clave="distancia"
                  />
                </div>
              )}

              {selected === 4 && (
                <div className="params-ef__data">
                  <Drenaje
                    stateInstruments={stateInstruments}
                    clave="drenaje_basal"
                  />
                </div>
              )}

              {selected === 1 && (
                <div className="params-ef__data">
                  <Acelerometro
                    stateInstruments={stateInstruments}
                    clave="acelerometro"
                  />
                </div>
              )}

              {selected === 5 && (
                <div className="params-ef__data">
                  <Inclinometro
                    stateInstruments={stateInstruments}
                    clave="inclinometro"
                  />
                </div>
              )}

              {selected === 7 && (
                <div className="params-ef__data">
                  <Placas
                    stateInstruments={stateInstruments}
                    clave="placa_corrimiento"
                  />
                </div>
              )}

              {selected === 2 && (
                <div className="params-ef__data">
                  <Celdas
                    stateInstruments={stateInstruments}
                    clave="celda_asentamiento"
                  />
                </div>
              )}
            </>
          ) : (
            <div>{stateInstruments.data.ejecucion.mensaje}</div>
          )}
        </Card>
      )}
    </Container>
  );
};

export default Instruments;
