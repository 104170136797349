import { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { Button } from "semantic-ui-react";

import React from "react";
import Altura from "./Altura";
import Coronamiento from "./Coronamiento";
import Operational from "./Operational";
import Angle from "./Angle";
import GbAltura from "./GbAltura";
import GbAngulo from "./GbAngulo";

import Expired from "./../../../Expired/index";

import NewGlAltura from "./newGlAltura";
import NewGlAncho from "./newGlAncho";
import NewGlAngulo from "./newGlAngulo";
import { FormattedMessage } from "react-intl";

const Index = ({ state, stateDeposit, stateParams }) => {
  const [selected, setSelected] = useState("");
  const location = useLocation();

  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/ef-params/geometrical/f-wall") {
      setSelected(1);
    }
    if (location.pathname === "/dashboard/ef-params/geometrical/f-crown") {
      setSelected(2);
    }
    if (
      location.pathname === "/dashboard/ef-params/geometrical/f-operational"
    ) {
      setSelected(3);
    }
    if (location.pathname === "/dashboard/ef-params/geometrical/f-angle") {
      setSelected(4);
    }
    if (location.pathname === "/dashboard/ef-params/geometrical/gb-wall") {
      setSelected(5);
    }
    if (location.pathname === "/dashboard/ef-params/geometrical/gb-angle") {
      setSelected(6);
    }

    if (location.pathname === "/dashboard/ef-params/geometrical/gl-wall") {
      setSelected(7);
    }
    if (location.pathname === "/dashboard/ef-params/geometrical/gl-berm") {
      setSelected(8);
    }
    if (location.pathname === "/dashboard/ef-params/geometrical/gl-angle") {
      setSelected(9);
    }
  }, [location.pathname]);

  if (
    stateParams.statusParams === 501 ||
    stateParams.statusParams === 401 ||
    stateDeposit.status === 501 ||
    stateDeposit.status === 401 ||
    state.status === 401 ||
    state.status === 501
  ) {
    return <Expired />;
  }

  return (
    <>
      <div className="params-ef">
        <Link
          to="/dashboard/ef-params/geometrical/f-wall"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(1)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/f-wall"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte1.altura_muro.valor
                    .length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AlturaMuro" />
          </Button>
        </Link>
        <Link
          to="/dashboard/ef-params/geometrical/f-crown"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(2)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/f-crown"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte1
                    .ancho_de_coronamiento.valor.length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AnchoCoronamiento" />
          </Button>
        </Link>
        <Link
          to="/dashboard/ef-params/geometrical/f-operational"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(3)}
            className={
              location.pathname ===
              "/dashboard/ef-params/geometrical/f-operational"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte1.revancha_operacional
                    .valor.length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="RevanchaOperacional" />
          </Button>
        </Link>
        <Link
          to="/dashboard/ef-params/geometrical/f-angle"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(4)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/f-angle"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte1.angulo_talud_externo
                    .valor.length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AnguloMuro" />
          </Button>
        </Link>
        {/* GLOBAL */}
        <Link
          to="/dashboard/ef-params/geometrical/gb-wall"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(5)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/gb-wall"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte2.gb.gb_altura.valor
                    .length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AlturaGlobal" />
          </Button>
        </Link>
        <Link
          to="/dashboard/ef-params/geometrical/gb-angle"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(6)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/gb-angle"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte2.gb
                    .gb_angulo_talud_externo.valor.length > 0
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AnguloGlobal" />
          </Button>
        </Link>

        {/* LOCAL */}

        <Link
          to="/dashboard/ef-params/geometrical/gl-wall"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(7)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/gl-wall"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte2.gl.gl_altura
                    .sin_datos === false
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AlturaLocal" />
          </Button>
        </Link>
        <Link
          to="/dashboard/ef-params/geometrical/gl-berm"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(8)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/gl-berm"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte2.gl.gl_ancho_berma
                    .sin_datos === false
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AnchoBermaLocal" />
          </Button>
        </Link>

        <Link
          to="/dashboard/ef-params/geometrical/gl-angle"
          state={{ state, stateDeposit }}
        >
          <Button
            onClick={handleClick(9)}
            className={
              location.pathname === "/dashboard/ef-params/geometrical/gl-angle"
                ? "ef-selected"
                : stateParams.dataParams.datos.datos.parte2.gl.gl_angulo
                    .sin_datos === false
                ? "ef-ok"
                : ""
            }
          >
            <FormattedMessage id="AnguloLocal" />
          </Button>
        </Link>
      </div>

      {selected === 1 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Altura
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 2 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Coronamiento
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 3 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Operational
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 4 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <Angle
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 5 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <GbAltura
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 6 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <GbAngulo
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 7 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <NewGlAltura
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 8 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <NewGlAncho
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}

      {selected === 9 && (
        <div className="params-ef__data">
          {stateParams.dataParams.ejecucion.estado ? (
            <div>
              <NewGlAngulo
                stateParams={stateParams}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          ) : (
            <div>{stateParams.dataParams.ejecucion.mensaje}</div>
          )}
        </div>
      )}
    </>
  );
};

export default Index;
