import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Container, Icon, Image, Popup } from "semantic-ui-react";

import sernageomin from "../../assets/img/logo_sernageomin.png";
import iconUser from "../../assets/icons/icono_usuario.png";
import pucv from "../../assets/img/logo_pucv.png";
import { useDispatch } from "react-redux";
import { ResetLoginDeport } from "../../actions/login/api";
import ModalPassword from "./../Modal/ModalPassword";

import { FormattedMessage } from "react-intl";

const Topbar = () => {
  const location = useLocation();

  let email = localStorage.getItem("email");

  let ambiente = localStorage.getItem("ambiente");

  const [showModalPassword, setShowModalPassword] = useState();

  const onShowModalPassword = () => {
    setShowModalPassword(true);
  };

  return (
    <Container className="main-container">
      <div className="topbar_container">
        <div className="topbar_container_img">
          <Image
            src={sernageomin}
            alt="Logo Sernageomin"
            className="logo_sernageomin"
          />

          <Image src={pucv} alt="Logo PUCV" className="logo_pucv" />
        </div>

        {location.pathname === "/" && (
          <Link to="login">
            <Button className="btn_ingresar">
              <FormattedMessage id="Ingresar" />
            </Button>
          </Link>
        )}
        {location.pathname !== "/login" &&
        location.pathname !== "/" &&
        location.pathname !== "/recover-password" ? (
          <div className="banner-user">
            <p style={{ marginRight: "15px" }}>{ambiente}</p>
            <Image src={iconUser} alt="Icono Usuario" className="logo-User" />
            <p>{email}</p>

            <Popup
              content={<FormattedMessage id="CambiarContraseña" />}
              trigger={
                <div
                  style={{
                    marginLeft: "10px",
                    marginBottom: "15px",
                    cursor: "pointer",
                  }}
                >
                  <Icon name="key" onClick={onShowModalPassword} />
                </div>
              }
            />

            <Logout />
          </div>
        ) : null}
      </div>

      <ModalPassword
        title={<FormattedMessage id="CambiarContraseña" />}
        show={showModalPassword}
        setShow={setShowModalPassword}
      />
    </Container>
  );
};

function Logout() {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(ResetLoginDeport());
    window.location.reload();
  };

  return (
    <div className="logout" onClick={logOut}>
      <p>
        <FormattedMessage id="CerrarSesion" />
      </p>
      <Icon name="log out" />
    </div>
  );
}

export default Topbar;
