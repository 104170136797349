import {
  RESET_ALL_PARAMS,
  GET_ALL_PARAMS_INIT,
  GET_ALL_PARAMS_FAILURE,
  GET_ALL_PARAMS_SUCCESS,
  RESET_UPDATE_PARAMS,
  UPDATE_PARAMS_INIT,
  UPDATE_PARAMS_SUCCESS,
  UPDATE_PARAMS_FAILURE,
  RESET_HISTORIC_PARAMS,
  GET_HISTORIC_PARAMS_INIT,
  GET_HISTORIC_PARAMS_SUCCESS,
  GET_HISTORIC_PARAMS_FAILURE,
  RESET_DELETE_HISTORIC_PARAMS,
  DELETE_HISTORIC_PARAMS_INIT,
  DELETE_HISTORIC_PARAMS_SUCCESS,
  DELETE_HISTORIC_PARAMS_FAILURE,
} from "./types";
import API from "./api";

export function resetAllParams() {
  return {
    type: RESET_ALL_PARAMS,
  };
}
function getAllParamsInit() {
  return {
    type: GET_ALL_PARAMS_INIT,
  };
}
function getAllParamsSuccess(data) {
  return {
    type: GET_ALL_PARAMS_SUCCESS,
    payload: data,
  };
}
function getAllParamsFailure(error) {
  return {
    type: GET_ALL_PARAMS_FAILURE,
    payload: error,
  };
}
export function getAllParams(data) {
  return async (dispatch) => {
    dispatch(getAllParamsInit());
    try {
      const resp = await API.data.getAllParams(data);
      dispatch(getAllParamsSuccess(resp));
    } catch (error) {
      dispatch(getAllParamsFailure(error));
    }
  };
}

// UPDATE PARAMS
export function resetUpdateParams() {
  return {
    type: RESET_UPDATE_PARAMS,
  };
}
function updateParamsInit() {
  return {
    type: UPDATE_PARAMS_INIT,
  };
}
function updateParamsSuccess(data) {
  return {
    type: UPDATE_PARAMS_SUCCESS,
    payload: data,
  };
}
function updateParamsFailure(error) {
  return {
    type: UPDATE_PARAMS_FAILURE,
    payload: error,
  };
}
export function updateParams(data) {
  return async (dispatch) => {
    dispatch(updateParamsInit());
    try {
      const resp = await API.data.updateParams(data);
      dispatch(updateParamsSuccess(resp));
    } catch (error) {
      dispatch(updateParamsFailure(error));
    }
  };
}

// UPDATE PARAMS
export function resetHistoricParams() {
  return {
    type: RESET_HISTORIC_PARAMS,
  };
}
function getHistoricParamsInit() {
  return {
    type: GET_HISTORIC_PARAMS_INIT,
  };
}
function getHistoricParamsSuccess(data) {
  return {
    type: GET_HISTORIC_PARAMS_SUCCESS,
    payload: data,
  };
}
function getHistoricParamsFailure(error) {
  return {
    type: GET_HISTORIC_PARAMS_FAILURE,
    payload: error,
  };
}
export function getHistoricParams(data) {
  return async (dispatch) => {
    dispatch(getHistoricParamsInit());
    try {
      const resp = await API.data.getHistoricParams(data);
      dispatch(getHistoricParamsSuccess(resp));
    } catch (error) {
      dispatch(getHistoricParamsFailure(error));
    }
  };
}

// DELETE PARAMS
export function resetDeleteHistoricParams() {
  return {
    type: RESET_DELETE_HISTORIC_PARAMS,
  };
}
function deleteHistoricParamsInit() {
  return {
    type: DELETE_HISTORIC_PARAMS_INIT,
  };
}
function deleteHistoricParamsSuccess(data) {
  return {
    type: DELETE_HISTORIC_PARAMS_SUCCESS,
    payload: data,
  };
}
function deleteHistoricParamsFailure(error) {
  return {
    type: DELETE_HISTORIC_PARAMS_FAILURE,
    payload: error,
  };
}
export function deleteHistoricParams(data) {
  return async (dispatch) => {
    dispatch(deleteHistoricParamsInit());
    try {
      const resp = await API.data.deleteHistoricParams(data);
      dispatch(deleteHistoricParamsSuccess(resp));
    } catch (error) {
      dispatch(deleteHistoricParamsFailure(error));
    }
  };
}
