import initialState from "./initialState";
import {
  RESET_REGIONES,
  GET_REGIONES_INIT,
  GET_REGIONES_SUCCESS,
  GET_REGIONES_FAILURE,
  RESET_COMUNAS,
  GET_COMUNAS_INIT,
  GET_COMUNAS_SUCCESS,
  GET_COMUNAS_FAILURE,
  RESET_PROVIDENCIAS,
  GET_PROVIDENCIAS_INIT,
  GET_PROVIDENCIAS_SUCCESS,
  GET_PROVIDENCIAS_FAILURE,
} from "../../actions/geolocations/types";

export default function geolocations(state = initialState, action) {
  switch (action.type) {
    case RESET_REGIONES:
      return {
        ...state,
        dataRegion: {},
        loadingRegion: false,
        statusRegion: 0,
      };
    case GET_REGIONES_INIT:
      return {
        ...state,
        loadingRegion: true,
      };
    case GET_REGIONES_SUCCESS:
      return {
        ...state,
        dataRegion: action.payload.data,
        loadingRegion: false,
        statusRegion: action.payload.status,
      };
    case GET_REGIONES_FAILURE:
      return {
        ...state,
        dataRegion: {},
        loadingRegion: false,
        statusRegion: 501,
      };

    case RESET_COMUNAS:
      return {
        ...state,
        dataComuna: {},
        loadingComuna: false,
        statusComuna: 0,
      };
    case GET_COMUNAS_INIT:
      return {
        ...state,
        loadingComuna: true,
      };
    case GET_COMUNAS_SUCCESS:
      return {
        ...state,
        dataComuna: action.payload.data,
        loadingComuna: false,
        statusComuna: action.payload.status,
      };

    case GET_COMUNAS_FAILURE:
      return {
        ...state,
        dataComuna: {},
        loadingComuna: false,
        statusComuna: 501,
      };

    case RESET_PROVIDENCIAS:
      return {
        ...state,
        dataProvidencia: {},
        loadingProvidencia: false,
        statusProvidencia: 0,
      };
    case GET_PROVIDENCIAS_INIT:
      return {
        ...state,
        loadingProvidencia: true,
      };
    case GET_PROVIDENCIAS_SUCCESS:
      return {
        ...state,
        dataProvidencia: action.payload.data,
        loadingProvidencia: false,
        statusProvidencia: action.payload.status,
      };
    case GET_PROVIDENCIAS_FAILURE:
      return {
        ...state,
        dataProvidencia: {},
        loadingProvidencia: false,
        statusProvidencia: 501,
      };

    default:
      return state;
  }
}
