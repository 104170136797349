import {
  RESET_ADD_DEPOSIT,
  ADD_DEPOSIT_INIT,
  ADD_DEPOSIT_SUCCESS,
  ADD_DEPOSIT_FAILURE,
} from "../../actions/add_deposit/types";
import initialState from "./initialState";

export default function addDeposit(state = initialState, action) {
  switch (action.type) {
    case RESET_ADD_DEPOSIT:
      return {
        ...state,
        dataAdd: {},
        loadingAdd: false,
        statusAdd: 0,
      };
    case ADD_DEPOSIT_INIT:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_DEPOSIT_SUCCESS:
      return {
        ...state,
        dataAdd: action.payload.data,
        loadingAdd: false,
        statusAdd: action.payload.status,
      };
    case ADD_DEPOSIT_FAILURE:
      return {
        ...state,
        dataAdd: {},
        loadingAdd: false,
        statusAdd: 501,
      };

    default:
      return state;
  }
}
