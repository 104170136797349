import {
  RESET_GET_TABS,
  GET_TABS_INIT,
  GET_TABS_SUCCESS,
  GET_TABS_FAILURE,
  RESET_GET_OPCION,
  GET_OPCION_INIT,
  GET_OPCION_SUCCESS,
  GET_OPCION_FAILURE,
  RESET_DELETE_REGISTER,
  DELETE_REGISTER_INIT,
  DELETE_REGISTER_SUCCESS,
  DELETE_REGISTER_FAILURE,
} from "./types";
import API from "./api";

export function resetTabs() {
  return {
    type: RESET_GET_TABS,
  };
}
function getTabsInit() {
  return {
    type: GET_TABS_INIT,
  };
}
function getTabsSuccess(data) {
  return {
    type: GET_TABS_SUCCESS,
    payload: data,
  };
}
function getTabsFailure(error) {
  return {
    type: GET_TABS_FAILURE,
    payload: error,
  };
}
export function getTabs(data) {
  return async (dispatch) => {
    dispatch(getTabsInit());
    try {
      const resp = await API.data.getTabs(data);
      dispatch(getTabsSuccess(resp));
    } catch (error) {
      dispatch(getTabsFailure(error));
    }
  };
}

//  OPCION DROPDOWN
export function resetOpcion() {
  return {
    type: RESET_GET_OPCION,
  };
}
function getOpcionInit() {
  return {
    type: GET_OPCION_INIT,
  };
}
function getOpcionSuccess(data) {
  return {
    type: GET_OPCION_SUCCESS,
    payload: data,
  };
}
function getOpcionFailure(error) {
  return {
    type: GET_OPCION_FAILURE,
    payload: error,
  };
}
export function getOpcion(data) {
  return async (dispatch) => {
    dispatch(getOpcionInit());
    try {
      const resp = await API.data.getOpcion(data);
      dispatch(getOpcionSuccess(resp));
    } catch (error) {
      dispatch(getOpcionFailure(error));
    }
  };
}

// DELETE REGISTRO
export function resetDeleteRegistro() {
  return {
    type: RESET_DELETE_REGISTER,
  };
}
function deleteRegistroInit() {
  return {
    type: DELETE_REGISTER_INIT,
  };
}
function deleteRegistroSuccess(data) {
  return {
    type: DELETE_REGISTER_SUCCESS,
    payload: data,
  };
}
function deleteRegistroFailure(error) {
  return {
    type: DELETE_REGISTER_FAILURE,
    payload: error,
  };
}
export function deleteRegistro(data) {
  return async (dispatch) => {
    dispatch(deleteRegistroInit());
    try {
      const resp = await API.data.deleteRegistro(data);
      dispatch(deleteRegistroSuccess(resp));
    } catch (error) {
      dispatch(deleteRegistroFailure(error));
    }
  };
}
