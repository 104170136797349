import React from "react";
import { Input, Popup, Table } from "semantic-ui-react";

const PN = ({ stateParams, index, formulario, setFormulario, setError }) => {
  let minero = localStorage.getItem("minero");

  const datosDuro =
    stateParams.dataParams.datos.datos.compactacion_calc.nivel.plantilla
      .tabla_pn.datos_duro.linea1;

  const findValue2 = (index, i) => {
    let obj = formulario.find((o) => o.index === index);

    if (obj) {
      return obj.rows[i]?.col2;
    }
  };

  const findValue3 = (index, i) => {
    let obj = formulario.find((o) => o.index === index);

    if (obj) {
      return obj.rows[i]?.col3;
    }
  };

  const findValue4 = (index, i) => {
    let obj = formulario.find((o) => o.index === index);

    if (obj) {
      return obj.rows[i]?.col4;
    }
  };

  const findValue5 = (index, i) => {
    let obj = formulario.find((o) => o.index === index);

    if (obj) {
      return obj.rows[i]?.col5;
    }
  };

  const findValue6 = (index, i) => {
    let obj = formulario.find((o) => o.index === index);

    if (obj) {
      return obj.rows[i]?.col6;
    }
  };

  const findValue7 = (index, i) => {
    let obj = formulario.find((o) => o.index === index);

    if (obj) {
      return obj.rows[i]?.col7;
    }
  };

  const findValue8 = (index, i) => {
    let obj = formulario.find((o) => o.index === index);

    if (obj) {
      return obj.rows[i]?.col8;
    }
  };

  const renderCeldas = () => {
    const arr = [];

    let arreglo = [...formulario];

    let number = parseInt(
      arreglo[index].value === "" ? 0 : arreglo[index].value,
      10
    );

    for (let i = 0; i < number; i++) {
      arr.push({ ...arreglo[i] });
    }

    return arreglo.map((item) => {
      return (
        <React.Fragment key={`index${index}-i$${item.index}`}>
          {/* eslint-disable-next-line */}
          {arr.map((item1, i) => {
            let valor = { ...item };

            let result = 100 * (item.rows[i]?.col7 / item.rows[i]?.col5);

            if (index === item.index) {
              if (
                !isFinite(result) ||
                result <= 0 ||
                item.rows[i].col2 === "" ||
                item.rows[i].col3 === "" ||
                item.rows[i].col4 === "" ||
                item.rows[i].col5 === "" ||
                item.rows[i].col6 === "" ||
                item.rows[i].col7 === "" ||
                item.rows[i].col8 === ""
              ) {
                setError(true);
              } else {
                setError(false);
              }

              return (
                <Table.Row
                  key={`index${index}-i${i}-item.index${item.index}`}
                  id={`index${index}-i${i}-item.index${item.index}-valor${valor}`}
                  className="table__matrix5__row-body"
                >
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>
                    <Input
                      name={`valor_${i}`}
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let arreglo = [...formulario];
                        item.rows[i].col2 = e.target.value;
                        setFormulario([...arreglo]);
                      }}
                      value={findValue2(valor.index, i) || ""}
                      disabled={minero === "false" ? true : false}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      name={`valor_${i}`}
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let arreglo = [...formulario];
                        item.rows[i].col3 = e.target.value;
                        setFormulario([...arreglo]);
                      }}
                      value={findValue3(valor.index, i) || ""}
                      disabled={minero === "false" ? true : false}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      name={`valor_${i}`}
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let arreglo = [...formulario];
                        item.rows[i].col4 = e.target.value;
                        setFormulario([...arreglo]);
                      }}
                      value={findValue4(valor.index, i) || ""}
                      disabled={minero === "false" ? true : false}
                    />
                  </Table.Cell>

                  <Table.Cell>
                    <Input
                      name={`valor_${i}`}
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let arreglo = [...formulario];
                        item.rows[i].col5 = e.target.value;
                        setFormulario([...arreglo]);
                      }}
                      value={findValue5(valor.index, i) || ""}
                      disabled={minero === "false" ? true : false}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      name={`valor_${i}`}
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let arreglo = [...formulario];
                        item.rows[i].col6 = e.target.value;
                        setFormulario([...arreglo]);
                      }}
                      value={findValue6(valor.index, i) || ""}
                      disabled={minero === "false" ? true : false}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      name={`valor_${i}`}
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let arreglo = [...formulario];
                        item.rows[i].col7 = e.target.value;
                        setFormulario([...arreglo]);
                      }}
                      value={findValue7(valor.index, i) || ""}
                      disabled={minero === "false" ? true : false}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      name={`valor_${i}`}
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let arreglo = [...formulario];
                        item.rows[i].col8 = e.target.value;
                        setFormulario([...arreglo]);
                      }}
                      value={findValue8(valor.index, i) || ""}
                      disabled={minero === "false" ? true : false}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      name={`valor_${i}`}
                      type="text"
                      value={isFinite(result) ? Math.round(result) : 0}
                      disabled={true}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            }
          })}
        </React.Fragment>
      );
    });
  };

  return (
    <Table celled className="table__matrix5">
      <Table.Header className="table__matrix5__row-header">
        <Table.Row>
          <Popup
            content={datosDuro.punto_de_control.tooltip}
            style={{ background: "#e4d96f", color: "black" }}
            trigger={
              <Table.HeaderCell style={{ width: "5%" }}>
                {datosDuro.punto_de_control.label}
              </Table.HeaderCell>
            }
          />

          <Popup
            content={datosDuro.utm_este.tooltip}
            style={{ background: "#e4d96f", color: "black" }}
            trigger={
              <Table.HeaderCell style={{ width: "12%" }}>
                {datosDuro.utm_este.label}
              </Table.HeaderCell>
            }
          />

          <Popup
            content={datosDuro.utm_norte.tooltip}
            style={{ background: "#e4d96f", color: "black" }}
            trigger={
              <Table.HeaderCell style={{ width: "12%" }}>
                {datosDuro.utm_norte.label}
              </Table.HeaderCell>
            }
          />

          <Popup
            content={datosDuro.col1.tooltip}
            style={{ background: "#e4d96f", color: "black" }}
            trigger={
              <Table.HeaderCell style={{ width: "7%" }}>
                {datosDuro.col1.label}
              </Table.HeaderCell>
            }
          />
          <Popup
            content={datosDuro.col2.tooltip}
            style={{ background: "#e4d96f", color: "black" }}
            trigger={
              <Table.HeaderCell style={{ width: "7%" }}>
                {datosDuro.col2.label}
              </Table.HeaderCell>
            }
          />
          <Popup
            content={datosDuro.col3.tooltip}
            style={{ background: "#e4d96f", color: "black" }}
            trigger={
              <Table.HeaderCell style={{ width: "7%" }}>
                {datosDuro.col3.label}
              </Table.HeaderCell>
            }
          />
          <Popup
            content={datosDuro.col4.tooltip}
            style={{ background: "#e4d96f", color: "black" }}
            trigger={
              <Table.HeaderCell style={{ width: "7%" }}>
                {datosDuro.col4.label}
              </Table.HeaderCell>
            }
          />
          <Popup
            content={datosDuro.col5.tooltip}
            style={{ background: "#e4d96f", color: "black" }}
            trigger={
              <Table.HeaderCell style={{ width: "7%" }}>
                {datosDuro.col5.label}
              </Table.HeaderCell>
            }
          />
          <Popup
            content={datosDuro.col6.tooltip}
            style={{ background: "#e4d96f", color: "black" }}
            trigger={
              <Table.HeaderCell style={{ width: "8%" }}>
                {datosDuro.col6.label}
              </Table.HeaderCell>
            }
          />
        </Table.Row>
      </Table.Header>

      <Table.Body>{renderCeldas()}</Table.Body>
    </Table>
  );
};

export default PN;
