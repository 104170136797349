import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Icon, Container } from "semantic-ui-react";

import ModalEnrolar from "../../components/Modal/ModalEnrolar";
import Loading from "./../../components/Loading/index";
import Expired from "./../../components/Expired/index";
import {
  getUsersToAprove,
  aproveUser,
  discardUser,
} from "../../actions/aprove";
import DashboardUbication from "../../components/DashboardUbication";
import DividerGrey from "../../components/DividerGrey";
import { FormattedMessage, useIntl } from "react-intl";

const Enrollment = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.aprove);

  const [showModalAprove, setShowModalAprove] = useState(false);
  const [id, setId] = useState("");
  const [select, setSelect] = useState("");

  useEffect(() => {
    dispatch(getUsersToAprove());
  }, [dispatch]);

  const onShowModalAprove = (id, select) => {
    setShowModalAprove(true);
    setSelect(select);
    setId(id);
  };

  const handleAprove = () => {
    dispatch(
      aproveUser({
        preuser_id: id,
      })
    );
  };

  const handleDecline = () => {
    dispatch(
      discardUser({
        preuser_id: id,
      })
    );
  };

  const intl = useIntl();

  const titleAprobar = intl.formatMessage({ id: "AprobarUsuario" });
  const titleRechazar = intl.formatMessage({ id: "RechazarUsuario" });

  if (state.status === 501) {
    return <Expired />;
  }

  return state.loading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <DashboardUbication>
          <FormattedMessage id="EnrolamientoUsuarios" />
        </DashboardUbication>
      </Container>
      <DividerGrey />
      <div style={{ margin: "50px 20px" }}>
        {state.data &&
          state.status === 200 &&
          (state.data.ejecucion.estado ? (
            <Table celled fixed className="table__aprove">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>USER ID</Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="Correo" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="Nombre" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="NumeroContacto" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="EmpresaActiva" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="CalleEmpresa" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="ContratistaMinero" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="EmpresaID" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="CategoriaEmpresa" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="NombreComuna" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="NombreFantasia" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="RazonSocial" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="RutEmpresa" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="TelefonoEmpresa" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="Nueva" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="Aprobar" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="Rechazar" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {state.data.datos.map((item, i) => {
                return (
                  <Table.Body key={i}>
                    <Table.Row key={item.preuser_id}>
                      <Table.Cell>{item.preuser_id}</Table.Cell>
                      <Table.Cell>{item.user_email}</Table.Cell>
                      <Table.Cell>{item.user_nombre}</Table.Cell>
                      <Table.Cell>{item.fono_contacto_usuario}</Table.Cell>
                      <Table.Cell>
                        {item.empresa_activa ? (
                          <FormattedMessage id="Si" />
                        ) : (
                          "No"
                        )}
                      </Table.Cell>
                      <Table.Cell>{item.emmpresa_calle}</Table.Cell>
                      <Table.Cell>
                        {item.contratista_minero ? (
                          <FormattedMessage id="Si" />
                        ) : (
                          "No"
                        )}
                      </Table.Cell>
                      <Table.Cell>{item.empresa_id}</Table.Cell>
                      <Table.Cell>{item.categoria_empresa}</Table.Cell>
                      <Table.Cell>{item.comuna_nombre}</Table.Cell>
                      <Table.Cell>{item.nombre_fantasia}</Table.Cell>
                      <Table.Cell>{item.razon_social}</Table.Cell>
                      <Table.Cell>{item.rut_empresa}</Table.Cell>
                      <Table.Cell>{item.telefono_empresa}</Table.Cell>
                      <Table.Cell>{item.nueva}</Table.Cell>

                      <Table.Cell>
                        <Icon
                          name="check square outline"
                          className="aprove"
                          id="aprove"
                          onClick={(e) => {
                            onShowModalAprove(item.preuser_id, e.target.id);
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Icon
                          name="close"
                          className="decline"
                          id="decline"
                          onClick={(e) =>
                            onShowModalAprove(item.preuser_id, e.target.id)
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                );
              })}

              <ModalEnrolar
                title={select === "aprove" ? titleAprobar : titleRechazar}
                show={showModalAprove}
                setShow={setShowModalAprove}
                id={id}
                aprove={select === "aprove" ? true : false}
                handleAprove={handleAprove}
                handleDecline={handleDecline}
                state={state}
              >
                {state.loadingAprove || state.loadingDiscard ? (
                  <Loading />
                ) : select === "aprove" ? (
                  state.statusAprove === 0 ? (
                    <FormattedMessage id="EstaSeguroAprobarUsuario" />
                  ) : state.loadingAprove ? (
                    <Loading />
                  ) : (
                    state.statusAprove === 200 &&
                    state.dataAprove.ejecucion.estado &&
                    state.dataAprove.ejecucion.mensaje
                  )
                ) : state.statusDiscard === 0 ? (
                  <FormattedMessage id="EstaSeguroRechazarUsuario" />
                ) : state.loadingAprove ? (
                  <Loading />
                ) : (
                  state.statusDiscard === 200 &&
                  state.dataDiscard.ejecucion.estado &&
                  state.dataDiscard.ejecucion.mensaje
                )}
              </ModalEnrolar>
            </Table>
          ) : (
            state.data.ejecucion.mensaje
          ))}
        {state.data.datos.length <= 0 && (
          <h1>
            <FormattedMessage id="NoHayUsuariosPorAprobar" />
          </h1>
        )}
      </div>
    </>
  );
};

export default Enrollment;
