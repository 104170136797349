import {
  RESET_GET_MATRIZ7,
  GET_MATRIZ7_INIT,
  GET_MATRIZ7_SUCCESS,
  GET_MATRIZ7_FAILURE,
  RESET_UPDATE_MATRIZ7,
  UPDATE_MATRIZ7_INIT,
  UPDATE_MATRIZ7_SUCCESS,
  UPDATE_MATRIZ7_FAILURE,
} from "./types";
import API from "./api";

// GET
export function resetGetMatriz7() {
  return {
    type: RESET_GET_MATRIZ7,
  };
}
function getMatriz7Init() {
  return {
    type: GET_MATRIZ7_INIT,
  };
}
function getMatriz7Success(data) {
  return {
    type: GET_MATRIZ7_SUCCESS,
    payload: data,
  };
}
function getMatriz7Failure(error) {
  return {
    type: GET_MATRIZ7_FAILURE,
    payload: error,
  };
}
export function getMatriz7(data) {
  return async (dispatch) => {
    dispatch(getMatriz7Init());
    try {
      const resp = await API.data.getMatriz7(data);
      dispatch(getMatriz7Success(resp));
    } catch (error) {
      dispatch(getMatriz7Failure(error));
    }
  };
}

// UPDATE
export function resetUpdateMatriz7() {
  return {
    type: RESET_UPDATE_MATRIZ7,
  };
}
function updateMatriz7Init() {
  return {
    type: UPDATE_MATRIZ7_INIT,
  };
}
function updateMatriz7Success(data) {
  return {
    type: UPDATE_MATRIZ7_SUCCESS,
    payload: data,
  };
}
function updateMatriz7Failure(error) {
  return {
    type: UPDATE_MATRIZ7_FAILURE,
    payload: error,
  };
}
export function updateMatriz7(data) {
  return async (dispatch) => {
    dispatch(updateMatriz7Init());
    try {
      const resp = await API.data.updateMatriz7(data);
      dispatch(updateMatriz7Success(resp));
    } catch (error) {
      dispatch(updateMatriz7Failure(error));
    }
  };
}
