import axios from "axios";

const API = {
  data: {
    async getRegiones(obj) {
      const response = await axios.get(`
            /api/geolocations/regiones/${encodeURIComponent(
              JSON.stringify(obj)
            )}`);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getComunas(obj) {
      const response = await axios.get(`
            /api/geolocations/comunas/${encodeURIComponent(
              JSON.stringify(obj)
            )}`);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },

    async getProvidencias(obj) {
      const response = await axios.get(`
              /api/geolocations/providencias/${encodeURIComponent(
                JSON.stringify(obj)
              )}`);
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};

export default API;
