import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Container } from "semantic-ui-react";

import { getMatriz5, resetGetMatriz5 } from "../../../actions/matriz5/index";
import Expired from "../../Expired";
import Loading from "../../Loading";
import { Link, useLocation } from "react-router-dom";
import Instrumento from "./Instrumento";

const Matriz5 = ({ id, stateDeposit, state }) => {
  const dispatch = useDispatch();
  const stateMatriz5 = useSelector((state) => state.matriz5);

  const location = useLocation();

  const [selected, setSelected] = useState("");

  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

  useEffect(() => {
    if (
      location.pathname ===
      `/dashboard/documents/params/${id}/matriz5/piezometro`
    ) {
      setSelected(6);
    } else if (
      location.pathname === `/dashboard/documents/params/${id}/matriz5/pozo`
    ) {
      setSelected(8);
    } else if (
      location.pathname ===
      `/dashboard/documents/params/${id}/matriz5/distancia`
    ) {
      setSelected(3);
    } else if (
      location.pathname ===
      `/dashboard/documents/params/${id}/matriz5/drenaje_basal`
    ) {
      setSelected(4);
    } else if (
      location.pathname ===
      `/dashboard/documents/params/${id}/matriz5/acelerometro`
    ) {
      setSelected(1);
    } else if (
      location.pathname ===
      `/dashboard/documents/params/${id}/matriz5/inclinometro`
    ) {
      setSelected(5);
    } else if (
      location.pathname ===
      `/dashboard/documents/params/${id}/matriz5/placa_corrimiento`
    ) {
      setSelected(7);
    } else if (
      location.pathname ===
      `/dashboard/documents/params/${id}/matriz5/celda_asentamiento`
    ) {
      setSelected(2);
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    dispatch(
      getMatriz5({
        deposito_id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetGetMatriz5());
    };
  }, [dispatch]);

  if (stateMatriz5.status === 501 || stateMatriz5.status === 401) {
    return <Expired />;
  }

  return (
    <Container>
      {stateMatriz5.loading ? (
        <Loading />
      ) : (
        <Card fluid className="card-deposit">
          <Card.Content>
            <div className="card-deposit-info" style={{ flexDirection: "row" }}>
              {stateMatriz5.data.datos.datos.titulo_matriz}
            </div>
          </Card.Content>

          <Card.Content className="card-container__matrix2">
            {stateMatriz5.data.ejecucion.estado ? (
              <>
                <div className="params-ef">
                  {stateMatriz5.data.datos.datos.tablas.map((e, i) => {
                    return (
                      <div key={i}>
                        <Link
                          to={`/dashboard/documents/params/${id}/matriz5/${e.tipo_instrumento}`}
                          state={{ state, stateDeposit }}
                        >
                          {e.usable && (
                            <Button
                              onClick={handleClick(e.tipo_instrumento_id)}
                              className={
                                location.pathname ===
                                `/dashboard/documents/params/${id}/matriz5/${e.tipo_instrumento}`
                                  ? "ef-selected"
                                  : e.parte_variable.length > 0 ||
                                    e.opcion_informacion_id !== null
                                  ? "ef-ok"
                                  : ""
                              }
                            >
                              {e.label_link}
                            </Button>
                          )}
                        </Link>
                      </div>
                    );
                  })}
                </div>

                {selected === 6 && (
                  <div className="params-ef__data">
                    <Instrumento
                      state={stateMatriz5}
                      tabla={stateMatriz5.data.datos.datos.tablas[0]}
                      label={stateMatriz5.data.datos.datos.tablas[0].tabla}
                      datos={stateMatriz5.data.datos.datos.sin_datos}
                      id={id}
                    />
                  </div>
                )}

                {selected === 8 && (
                  <div className="params-ef__data">
                    <Instrumento
                      state={stateMatriz5}
                      tabla={stateMatriz5.data.datos.datos.tablas[1]}
                      label={stateMatriz5.data.datos.datos.tablas[1].tabla}
                      datos={stateMatriz5.data.datos.datos.sin_datos}
                      id={id}
                    />
                  </div>
                )}

                {selected === 3 && (
                  <div className="params-ef__data">
                    <Instrumento
                      state={stateMatriz5}
                      tabla={stateMatriz5.data.datos.datos.tablas[2]}
                      label={stateMatriz5.data.datos.datos.tablas[2].tabla}
                      datos={stateMatriz5.data.datos.datos.sin_datos}
                      id={id}
                    />
                  </div>
                )}

                {selected === 4 && (
                  <div className="params-ef__data">
                    <Instrumento
                      state={stateMatriz5}
                      tabla={stateMatriz5.data.datos.datos.tablas[3]}
                      label={stateMatriz5.data.datos.datos.tablas[3].tabla}
                      datos={stateMatriz5.data.datos.datos.sin_datos}
                      id={id}
                    />
                  </div>
                )}

                {selected === 1 && (
                  <div className="params-ef__data">
                    <Instrumento
                      state={stateMatriz5}
                      tabla={stateMatriz5.data.datos.datos.tablas[4]}
                      label={stateMatriz5.data.datos.datos.tablas[4].tabla}
                      datos={stateMatriz5.data.datos.datos.sin_datos}
                      id={id}
                    />
                  </div>
                )}

                {selected === 5 && (
                  <div className="params-ef__data">
                    <Instrumento
                      state={stateMatriz5}
                      tabla={stateMatriz5.data.datos.datos.tablas[5]}
                      label={stateMatriz5.data.datos.datos.tablas[5].tabla}
                      datos={stateMatriz5.data.datos.datos.sin_datos}
                      id={id}
                    />
                  </div>
                )}

                {selected === 7 && (
                  <div className="params-ef__data">
                    <Instrumento
                      state={stateMatriz5}
                      tabla={stateMatriz5.data.datos.datos.tablas[6]}
                      label={stateMatriz5.data.datos.datos.tablas[6].tabla}
                      datos={stateMatriz5.data.datos.datos.sin_datos}
                      id={id}
                    />
                  </div>
                )}

                {selected === 2 && (
                  <div className="params-ef__data">
                    <Instrumento
                      state={stateMatriz5}
                      tabla={stateMatriz5.data.datos.datos.tablas[7]}
                      label={stateMatriz5.data.datos.datos.tablas[7].tabla}
                      datos={stateMatriz5.data.datos.datos.sin_datos}
                      id={id}
                    />
                  </div>
                )}
              </>
            ) : (
              <div>{stateMatriz5.data.ejecucion.mensaje}</div>
            )}
          </Card.Content>
        </Card>
      )}
    </Container>
  );
};

export default Matriz5;
