import { Navigate } from "react-router-dom";
import { Container, Image, Tab } from "semantic-ui-react";
import LoginPageComponent from "../../components/Login";
import RegisterComponent from "../../components/Register";

import bannerLogin2 from "../../assets/img/Relaves Pucobre (3).jpg";

import { FormattedMessage } from "react-intl";

const LoginPage = () => {
  let auth = localStorage.getItem("auth");
  auth = auth === "true";

  let servicio = localStorage.getItem("servicio");

  if (servicio === "true") {
    return <Navigate to="/dashboard/pucv" />;
  }

  return auth ? (
    servicio === "true" ? (
      <Navigate to="/dashboard/pucv" replace={true} />
    ) : (
      <Navigate to="/dashboard/company" replace={true} />
    )
  ) : (
    <div className="login-container">
      <Container>
        <div className="login-container__left">
          <Image
            src={bannerLogin2}
            alt="Banner Login"
            className="banner-login"
            fluid
            wrapped
            style={{ height: "850px" }}
          />
        </div>
        <div className="login-container__right">
          <div className="login-title-welcome">
            <div className="vl"></div>
            <h1>
              <FormattedMessage id="Bienvenido" />
            </h1>
          </div>
          <p>
            <FormattedMessage id="IngresePlataforma" />
            <b>SERNAGEOMIN</b>
          </p>

          <TabLogin />
        </div>
      </Container>
    </div>
  );
};

export default LoginPage;

const panes = [
  {
    menuItem: { key: 1, content: <FormattedMessage id="Ingresar" /> },
    render: () => (
      <Tab.Pane attached={false}>
        <LoginPageComponent />
      </Tab.Pane>
    ),
  },
  {
    menuItem: { key: 2, content: <FormattedMessage id="Registrarse" /> },
    render: (props) => (
      <Tab.Pane attached={false}>
        <RegisterComponent props={props} />
      </Tab.Pane>
    ),
  },
];

function TabLogin() {
  return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
}
