const initialState = {
  dataRecover: {},
  loadingRecover: false,
  statusRecover: 0,

  dataChange: {},
  loadingChange: false,
  statusChange: 0,
};

export default initialState;
