import {
  RESET_GET_EVALUACIONES,
  GET_EVALUACIONES_INIT,
  GET_EVALUACIONES_SUCCESS,
  GET_EVALUACIONES_FAILURE,
  RESET_DELETE_EVALUACION,
  DELETE_EVALUACION_INIT,
  DELETE_EVALUACION_SUCCESS,
  DELETE_EVALUACION_FAILURE,
  RESET_PUBLICAR_EVALUACION,
  PUBLICAR_EVALUACION_INIT,
  PUBLICAR_EVALUACION_SUCCESS,
  PUBLICAR_EVALUACION_FAILURE,
  RESET_NUEVA_EVALUACION,
  NUEVA_EVALUACION_INIT,
  NUEVA_EVALUACION_SUCCESS,
  NUEVA_EVALUACION_FAILURE,
  RESET_VALIDAR_EVALUACION,
  VALIDAR_EVALUACION_INIT,
  VALIDAR_EVALUACION_SUCCESS,
  VALIDAR_EVALUACION_FAILURE,
} from "./types";
import API from "./api";

// EVALUACIONES
export function resetEvaluaciones() {
  return {
    type: RESET_GET_EVALUACIONES,
  };
}
function getEvaluacionesInit() {
  return {
    type: GET_EVALUACIONES_INIT,
  };
}
function getEvaluacionesSuccess(data) {
  return {
    type: GET_EVALUACIONES_SUCCESS,
    payload: data,
  };
}
function getEvaluacionesFailure(error) {
  return {
    type: GET_EVALUACIONES_FAILURE,
    payload: error,
  };
}
export function getEvaluaciones(data) {
  return async (dispatch) => {
    dispatch(getEvaluacionesInit());
    try {
      const resp = await API.data.getEvaluaciones(data);
      dispatch(getEvaluacionesSuccess(resp));
    } catch (error) {
      dispatch(getEvaluacionesFailure(error));
    }
  };
}

// DELETE
export function resetDeleteEvaluacion() {
  return {
    type: RESET_DELETE_EVALUACION,
  };
}
function deleteEvaluacionInit() {
  return {
    type: DELETE_EVALUACION_INIT,
  };
}
function deleteEvaluacionSuccess(data) {
  return {
    type: DELETE_EVALUACION_SUCCESS,
    payload: data,
  };
}
function deleteEvaluacionFailure(error) {
  return {
    type: DELETE_EVALUACION_FAILURE,
    payload: error,
  };
}
export function deleteEvaluacion(data) {
  return async (dispatch) => {
    dispatch(deleteEvaluacionInit());
    try {
      const resp = await API.data.eliminarEvaluacion(data);
      dispatch(deleteEvaluacionSuccess(resp));
    } catch (error) {
      dispatch(deleteEvaluacionFailure(error));
    }
  };
}

// publicar evaluacion
export function resetPublicarEvaluacion() {
  return {
    type: RESET_PUBLICAR_EVALUACION,
  };
}
function publicarEvaluacionInit() {
  return {
    type: PUBLICAR_EVALUACION_INIT,
  };
}
function publicarEvaluacionSuccess(data) {
  return {
    type: PUBLICAR_EVALUACION_SUCCESS,
    payload: data,
  };
}
function publicarEvaluacionFailure(error) {
  return {
    type: PUBLICAR_EVALUACION_FAILURE,
    payload: error,
  };
}
export function publicarEvaluacion(data) {
  return async (dispatch) => {
    dispatch(publicarEvaluacionInit());
    try {
      const resp = await API.data.publicarEvaluacion(data);
      dispatch(publicarEvaluacionSuccess(resp));
    } catch (error) {
      dispatch(publicarEvaluacionFailure(error));
    }
  };
}

// NUEVA EVALUACION
export function resetNuevaEvaluacion() {
  return {
    type: RESET_NUEVA_EVALUACION,
  };
}
function nuevaEvaluacionInit() {
  return {
    type: NUEVA_EVALUACION_INIT,
  };
}
function nuevaEvaluacionSuccess(data) {
  return {
    type: NUEVA_EVALUACION_SUCCESS,
    payload: data,
  };
}
function nuevaEvaluacionFailure(error) {
  return {
    type: NUEVA_EVALUACION_FAILURE,
    payload: error,
  };
}
export function nuevaEvaluacion(data) {
  return async (dispatch) => {
    dispatch(nuevaEvaluacionInit());
    try {
      const resp = await API.data.nuevaEvaluacion(data);
      dispatch(nuevaEvaluacionSuccess(resp));
    } catch (error) {
      dispatch(nuevaEvaluacionFailure(error));
    }
  };
}

// VALIDAR EVALUACION
export function resetValidarEvaluacion() {
  return {
    type: RESET_VALIDAR_EVALUACION,
  };
}
function validarEvaluacionInit() {
  return {
    type: VALIDAR_EVALUACION_INIT,
  };
}
function validarEvaluacionSuccess(data) {
  return {
    type: VALIDAR_EVALUACION_SUCCESS,
    payload: data,
  };
}
function validarEvaluacionFailure(error) {
  return {
    type: VALIDAR_EVALUACION_FAILURE,
    payload: error,
  };
}
export function validarEvaluacion(data) {
  return async (dispatch) => {
    dispatch(validarEvaluacionInit());
    try {
      const resp = await API.data.validarEvaluacion(data);
      dispatch(validarEvaluacionSuccess(resp));
    } catch (error) {
      dispatch(validarEvaluacionFailure(error));
    }
  };
}
