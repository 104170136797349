import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Dropdown,
  Input,
  Checkbox,
  TextArea,
  Button,
  Table,
  Icon,
  Image,
} from "semantic-ui-react";
import { updateParams, resetUpdateParams } from "../../../../actions/params";

import ModalActions from "../../../Modal/ModalActions";
import ohError from "../../../../assets/icons/ohhh_error.png";
import { FormattedMessage, useIntl } from "react-intl";

const Gravedad = ({ stateParams }) => {
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    nro_sector: stateParams.dataParams.datos.datos.porcentaje_solidos.tablas
      ? stateParams.dataParams.datos.datos.porcentaje_solidos.tablas?.length
      : 0,
  });

  const [formComentario, setFormComentario] = useState({
    comentario: "",
  });

  const minero = localStorage.getItem("minero");

  const [formulario, setFormulario] = useState([]);
  const [memoriaFormulario, setMemoriaFormulario] = useState([]);

  const handleChange = (event) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    setFormComentario((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const [datos, setDatos] = useState();

  // DATA MURO
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("");

  // NEW DATA
  const [newData, setNewData] = useState("");

  // CHECKED
  const [checked, setChecked] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  // DATA MURO
  useEffect(() => {
    const arr = [];
    if (stateParams.statusParams === 200) {
      if (stateParams.dataParams.ejecucion.estado) {
        stateParams.dataParams.datos.datos.porcentaje_solidos.dropdown.forEach(
          (e) => {
            arr.push({
              key: e.id,
              value: e.id,
              text: e.glosa,
              seleccionado: e.seleccionado.toString(),
            });
          }
        );
      }
    }
    setData(arr);
  }, [stateParams.statusParams, stateParams.dataParams]);

  const handleChangeCheckbox = (obj) => {
    const arr = [...checked];
    const index = arr.findIndex((item) => item === obj.clave);

    if (index === -1) {
      arr.push(obj.clave);
    } else {
      arr.splice(index, 1);
    }

    setChecked(arr);
  };

  useEffect(() => {
    const arrForm = [];

    for (
      let i = 0;
      i < parseInt(form.nro_sector === "" ? 0 : form.nro_sector, 10);
      i++
    ) {
      if (memoriaFormulario[i]) {
        arrForm.push(memoriaFormulario[i]);
      } else {
        arrForm.push({
          index: i,
          value: "",
          rows: [],
        });
      }
    }

    setFormulario(arrForm);
    // eslint-disable-next-line
  }, [form.nro_sector]);

  const handleClickUpdate = () => {
    const newArr = [];

    formulario.forEach((item, i) =>
      item.rows.forEach((e) => {
        newArr.push({
          sector: item.index + 1,
          utm_norte: item.utm_norte,
          utm_este: item.utm_este,
          valores: [[e.col1, e.col2]],
        });
      })
    );

    dispatch(
      updateParams({
        periodo_id: stateParams.dataParams.datos.periodo.id,
        datos_recopilados: [
          {
            sistema: {
              modelo:
                stateParams.dataParams.datos.datos.porcentaje_solidos.plantilla
                  .sistema.modelo,
              corresponde_a:
                stateParams.dataParams.datos.datos.porcentaje_solidos.plantilla
                  .sistema.corresponde_a,
            },
            opcion_informacion_id: dataId,
            documentos_que_respaldan: checked,
            comentario: formComentario.comentario,
            parte_variable: newArr,
          },
        ],
      })
    );
  };

  //   SETER DATOS EN FALSE POR SI HAY DATA EN BD
  useEffect(() => {
    if (
      stateParams.dataParams.datos.datos.porcentaje_solidos.sin_datos === false
    ) {
      setDatos(false);
    }
  }, [stateParams.dataParams]);

  // CARGAR EL DROPDOWN SI HAY INFORMACION
  useEffect(() => {
    if (stateParams.statusParams === 200) {
      if (datos === false) {
        if (data.length > 0) {
          const dataSelected = data.find(
            (element) => element.seleccionado === "true"
          );
          setNewData(dataSelected.value);
        }
      }
    }

    // eslint-disable-next-line
  }, [data]);

  // CARGAR LA DATA SI VIENE INFORMACION DESDE LA BD
  useEffect(() => {
    const callsAPI = async () => {
      if (stateParams.statusParams === 200) {
        if (stateParams.dataParams.ejecucion.estado) {
          if (datos === false) {
            if (formulario.length > 0) {
              const arr = [];
              for (let i = 0; i < formulario.length; i++) {
                arr.push({
                  index: i,
                  value:
                    stateParams.dataParams.datos.datos.porcentaje_solidos
                      ?.tablas[i].parte_variable.length,
                  sector:
                    stateParams.dataParams.datos.datos.porcentaje_solidos
                      .tablas[i].generales.sector.valor,
                  utm_norte:
                    stateParams.dataParams.datos.datos.porcentaje_solidos
                      .tablas[i].generales.utm_norte.valor,
                  utm_este:
                    stateParams.dataParams.datos.datos.porcentaje_solidos
                      .tablas[i].generales.utm_este.valor,
                  rows: stateParams.dataParams.datos.datos.porcentaje_solidos
                    ?.tablas[i].parte_variable,
                });

                setFormulario(arr);
              }
            }
            setFormComentario({
              comentario:
                stateParams.dataParams.datos.datos.gravedad_especifica
                  .comentario,
            });
          }
        }

        setDataId(newData);

        const arrTabla = [];

        stateParams.dataParams.datos.datos.porcentaje_solidos.documentos_que_respaldan?.map(
          (e) => e.seleccionado === true && arrTabla.push(e.clave)
        );

        setChecked(arrTabla);
      }
    };
    callsAPI();
    // eslint-disable-next-line
  }, [stateParams.statusParams, stateParams.dataParams, newData, datos]);

  const resetData = () => {
    dispatch(resetUpdateParams());
  };

  const intl = useIntl();
  const placeholderSeleccione = intl.formatMessage({ id: "Seleccione" });
  const placeholderComentario = intl.formatMessage({ id: "Comentario" });
  const placeholderCantidadSector = intl.formatMessage({
    id: "IngreseCantidadSector",
  });

  // Re renderizando componentes por sector
  const renderTabla = (index) => {
    const arr = [];

    let arreglo = [...formulario];

    let number = parseInt(
      arreglo[index].value === "" ? 0 : arreglo[index].value,
      10
    );
    for (let i = 0; i < number; i++) {
      arr.push(i);
    }
    return (
      <div key={index}>
        <div>
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            <FormattedMessage id="CantidadPuntosControl" />
          </p>
          <Input
            name="nro_filas"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              let arreglo = [...formulario];
              let columnas = [];
              arreglo[index].value = e.target.value;
              let numero = parseInt(
                e.target.value === "" ? 0 : e.target.value,
                10
              );

              for (let i = 0; i < numero; i++) {
                if (arreglo[index].rows[i]) {
                  columnas.push({ ...arreglo[index].rows[i] });
                } else {
                  columnas.push({
                    col1: "",
                    col2: "",
                  });
                }
              }
              arreglo[index].rows = columnas;
              setFormulario(arreglo);
            }}
            maxLength="2"
            value={formulario[index]?.value}
            disabled={minero === "false" ? true : false}
          />
        </div>
        <div className="container__filas-geotechnical">
          <div>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              <FormattedMessage id="Sector" />
            </p>

            <Input
              name="sector"
              value={index + 1}
              disabled={minero === "false" ? true : false}
            />
          </div>

          <div>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              <FormattedMessage id="UTMNorte" />
            </p>

            <Input
              name="utm_norte"
              onKeyPress={(event) => {
                if (!/[0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                let arreglo = [...formulario];
                arreglo[index].utm_norte = e.target.value;
                setFormulario([...arreglo]);
              }}
              value={formulario[index]?.utm_norte || ""}
              disabled={minero === "false" ? true : false}
            />
          </div>

          <div>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              <FormattedMessage id="UTMEste" />
            </p>

            <Input
              name="utm_este"
              onKeyPress={(event) => {
                if (!/[0-9.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                let arreglo = [...formulario];
                arreglo[index].utm_este = e.target.value;
                setFormulario([...arreglo]);
              }}
              value={formulario[index]?.utm_este || ""}
              disabled={minero === "false" ? true : false}
            />
          </div>
        </div>

        <Table celled fixed className="table__matrix5">
          <Table.Header className="table__matrix5__row-header">
            {stateParams.dataParams.datos.datos.porcentaje_solidos.plantilla
              .datos_duro?.linea1 && (
              <>
                <Table.Row>
                  {Object.keys(
                    stateParams.dataParams.datos.datos.porcentaje_solidos
                      .plantilla.datos_duro.linea1
                  ).map(
                    // eslint-disable-next-line array-callback-return
                    (item, i) => {
                      if (item.includes("col")) {
                        return (
                          <Table.HeaderCell
                            colSpan={`${
                              115 *
                              stateParams.dataParams.datos.datos
                                .porcentaje_solidos.plantilla.datos_duro.linea1[
                                item
                              ].ancho
                            }`}
                            key={`${stateParams.dataParams.datos.datos.porcentaje_solidos.plantilla.datos_duro.linea1[item].label}-linea1`}
                          >
                            {
                              stateParams.dataParams.datos.datos
                                .porcentaje_solidos.plantilla.datos_duro.linea1[
                                item
                              ].label
                            }
                          </Table.HeaderCell>
                        );
                      }
                    }
                  )}
                </Table.Row>
              </>
            )}
          </Table.Header>
          <Table.Body>
            {renderCeldas(
              stateParams.dataParams.datos.datos.porcentaje_solidos.tablas,
              index
            )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const findValue = (index, i) => {
    let obj = formulario.find((o) => o.index === index);

    if (obj) {
      return obj.rows[i]?.col1;
    }
  };

  const findValue2 = (index, i) => {
    let obj = formulario.find((o) => o.index === index);

    if (obj) {
      return obj.rows[i]?.col2;
    }
  };

  const renderCeldas = (params, index) => {
    if (params) {
      const arr = [];

      let arreglo = [...formulario];

      let number = parseInt(
        arreglo[index].value === "" ? 0 : arreglo[index].value,
        10
      );
      for (let i = 0; i < number; i++) {
        arr.push({ ...arreglo[i] });
      }

      return arreglo.map((item) => {
        return (
          <React.Fragment key={`index${index}-i$${item.index}`}>
            {/* eslint-disable-next-line array-callback-return */}
            {arr.map((item1, i) => {
              let valor = { ...item };
              if (index === item.index) {
                return (
                  <Table.Row
                    key={`index${index}-i${i}-item.index${item.index}`}
                    id={`index${index}-i${i}-item.index${item.index}-valor${valor}`}
                    className="table__matrix5__row-body"
                  >
                    <Table.Cell colSpan={115}>
                      <Input
                        name={`valor_${i}`}
                        type="text"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          let arreglo = [...formulario];
                          item.rows[i].col1 = e.target.value;
                          setFormulario([...arreglo]);
                        }}
                        value={findValue(valor.index, i)}
                        disabled={minero === "false" ? true : false}
                      />
                    </Table.Cell>
                    <Table.Cell colSpan={115}>
                      <Input
                        name={`valor_${i}`}
                        type="text"
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          let arreglo = [...formulario];
                          item.rows[i].col2 = e.target.value;
                          setFormulario([...arreglo]);
                        }}
                        value={findValue2(valor.index, i)}
                        disabled={minero === "false" ? true : false}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              }
            })}
          </React.Fragment>
        );
      });
    }
  };

  return (
    <div className="params-ef__data">
      {stateParams.dataParams.ejecucion.estado ? (
        <div>
          <Dropdown
            placeholder={placeholderSeleccione}
            selection
            options={data}
            value={datos === false ? newData : dataId}
            onChange={(_, data) => {
              setNewData(data.value);
              setDataId(data.value);
            }}
            disabled={minero === "false" ? true : false}
          />

          {(dataId === 1 || newData === 1) && (
            <>
              <div>
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "15px",
                    }}
                  >
                    <FormattedMessage id="IngreseCantidadSector" />
                  </p>
                  <Input
                    name="nro_sector"
                    placeholder={placeholderCantidadSector}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setMemoriaFormulario(formulario);
                      handleChange(e);
                    }}
                    maxLength="2"
                    value={form.nro_sector}
                    disabled={minero === "false" ? true : false}
                  />
                </div>

                {formulario.map((e, i) => {
                  return renderTabla(i);
                })}
              </div>

              <p style={{ marginTop: "10px" }}>
                <FormattedMessage id="DocumentosRespaldan" />
              </p>
              <div className="matrix2__documentos_respaldo">
                {stateParams.dataParams.datos.datos.porcentaje_solidos.documentos_que_respaldan.map(
                  (item, i) => {
                    let index = checked.includes(item.clave);
                    return (
                      <Checkbox
                        key={i}
                        label={item.label}
                        onChange={() => handleChangeCheckbox(item)}
                        value={item.clave}
                        checked={index}
                        disabled={
                          minero === "false"
                            ? true
                            : !item.editable
                            ? true
                            : false
                        }
                      />
                    );
                  }
                )}
              </div>
            </>
          )}

          {dataId !== "" && newData !== "" && (
            <>
              <TextArea
                placeholder={placeholderComentario}
                name="comentario"
                onChange={(e) => {
                  handleChange(e);
                }}
                style={{ width: "100%", marginTop: "20px" }}
                value={formComentario.comentario}
                disabled={minero === "false" ? true : false}
                maxLength="1000"
              />
              {minero === "true" && (
                <div
                  className="btn-update-matrix1"
                  style={{ marginTop: "15px" }}
                >
                  <Button onClick={onShowModal}>
                    <FormattedMessage id="GuardarMayus" />
                  </Button>
                </div>
              )}
            </>
          )}

          <ModalActions
            title={<FormattedMessage id="ActualizarPorcentaje" />}
            show={showModal}
            setShow={setShowModal}
            status={stateParams.statusUpdateParams}
            action={handleClickUpdate}
            reset={resetData}
            deleteAction={"Delete"}
            estado={stateParams.dataUpdateParams}
            loading={stateParams.loadingUpdateParams}
          >
            {stateParams.statusUpdateParams === 0 ? (
              <FormattedMessage id="EstaSeguroActualizarInformacion" />
            ) : stateParams.dataUpdateParams.ejecucion.estado ? (
              <div className="container-modal">
                <p className="message-ok">
                  {stateParams.dataUpdateParams.ejecucion.mensaje}
                </p>
                <Icon name="checkmark" className="status-ok" />
              </div>
            ) : (
              <div className="container-modal">
                <p className="message-fail">
                  {stateParams.dataUpdateParams.ejecucion.mensaje}
                </p>
                <Image src={ohError} className="status-fail" />
              </div>
            )}
          </ModalActions>
        </div>
      ) : (
        <div>{stateParams.dataParams.ejecucion.mensaje}</div>
      )}
    </div>
  );
};

export default Gravedad;
